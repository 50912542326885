import {
  AuditApiGetAuditByBetPayMaxRequest,
  AuditApiGetAuditByCategoryExclusionRequest,
  AuditApiGetAuditByCommentRequest,
  AuditApiGetAuditByCompetitorsRequest,
  AuditApiGetAuditByCountryRequest,
  AuditApiGetAuditByCurrencyRequest,
  AuditApiGetAuditByCustomerEntityRequest,
  AuditApiGetAuditByCustomerEventStatusRequest,
  AuditApiGetAuditByCustomerRequest,
  AuditApiGetAuditByDelayRequest,
  AuditApiGetAuditByEntityCommentRequest,
  AuditApiGetAuditByEntityTagRequest,
  AuditApiGetAuditByExclusionRequest,
  AuditApiGetAuditByLanguageRequest,
  AuditApiGetAuditByMarginRequest,
  AuditApiGetAuditByMarketRequest,
  AuditApiGetAuditByMatchPhaseRequest,
  AuditApiGetAuditByMatchTypeRequest,
  AuditApiGetAuditByMaxOddRequest,
  AuditApiGetAuditByOutcomeCategoryRequest,
  AuditApiGetAuditByOutcomeTypeCrossRequest,
  AuditApiGetAuditByOutcomeTypeRequest,
  AuditApiGetAuditByPlayerRequest,
  AuditApiGetAuditBySourceEventMappingRequest,
  AuditApiGetAuditBySportEventMainSourceRequest,
  AuditApiGetAuditBySportEventRequest,
  AuditApiGetAuditBySportEventSuspendRequest,
  AuditApiGetAuditBySportGroupRequest,
  AuditApiGetAuditBySportRequest,
  AuditApiGetAuditByTagRequest,
  AuditApiGetAuditByTournamentRequest,
  AuditApiGetAuditByUserRequest,
  AuditResponse,
  DictionaryDiff,
  PaginationTokenAttribute,
} from 'sportsbook-openapi-react';

export interface PanelState {
  payload: any;
  entity: AuditedEntities | AuditedEntities[];
}

/* --- STATE --- */
export interface AuditProviderState {
  entities: Record<string, AuditEntityState>;
  panel: PanelState | null;
}

export interface AuditEntityState {
  isLoading: boolean;
  items: DictionaryDiff[];
  paginationToken: Maybe<PaginationTokenAttribute[]>;
}

export type ContainerState = AuditProviderState;

export enum AuditedEntities {
  CUSTOMERS = 'customers',
  COMMENTS = 'comments',
  COMPETITORS = 'competitors',
  COUNTRIES = 'countries',
  CURRENCIES = 'currencies',
  EVENTS = 'events',
  GROUPS = 'groups',
  TOURNAMENTS = 'tournaments',
  SPORTS = 'sports',
  LANGUAGES = 'languages',
  MATCH_PHASES = 'match phases',
  MATCH_TYPES = 'match types',
  OUTCOME_MARKETS = 'outcome markets',
  OUTCOME_CATEGORIES = 'outcome categories',
  OUTCOME_TYPES = 'outcome types',
  PLAYERS = 'players',
  TAGS = 'tags',
  CROSS_MAPPINGS = 'cross mappings',
  ENTITY_COMMENTS = 'entity comments',
  ENTITY_BETPAYMAXES = 'betpaymax',
  ENTITY_DELAYS = 'delay',
  ENTITY_MARGINS = 'margin',
  ENTITY_EXCLUSIONS = 'exclusion',
  ENTITY_TAGS = 'entity tags',
  ENTITY_MAXODDS = 'maxOdd',
  EVENT_MAPPING = 'event mapping',
  EVENT_MAIN_SOURCE = 'main source',
  CUSTOMER_CATEGORY_EXLUSION = 'customer exclusion',
  CUSTOMER_EVENT_STATUS = 'event status',
  CUSTOMER_EVENT_SUSPEND = 'event suspend',
  USER_ACTIONS = 'user asctions',
  CUSTOMER_ACTIONS = 'customer actions',
}

export interface EntityToGetAuditParams {
  [AuditedEntities.CUSTOMERS]: AuditApiGetAuditByCustomerEntityRequest;
  [AuditedEntities.COMMENTS]: AuditApiGetAuditByCommentRequest;
  [AuditedEntities.COMPETITORS]: AuditApiGetAuditByCompetitorsRequest;
  [AuditedEntities.COUNTRIES]: AuditApiGetAuditByCountryRequest;
  [AuditedEntities.CURRENCIES]: AuditApiGetAuditByCurrencyRequest;
  [AuditedEntities.EVENTS]: AuditApiGetAuditBySportEventRequest;
  [AuditedEntities.GROUPS]: AuditApiGetAuditBySportGroupRequest;
  [AuditedEntities.TOURNAMENTS]: AuditApiGetAuditByTournamentRequest;
  [AuditedEntities.SPORTS]: AuditApiGetAuditBySportRequest;
  [AuditedEntities.LANGUAGES]: AuditApiGetAuditByLanguageRequest;
  [AuditedEntities.MATCH_PHASES]: AuditApiGetAuditByMatchPhaseRequest;
  [AuditedEntities.MATCH_TYPES]: AuditApiGetAuditByMatchTypeRequest;
  [AuditedEntities.OUTCOME_MARKETS]: AuditApiGetAuditByMarketRequest;
  [AuditedEntities.OUTCOME_CATEGORIES]: AuditApiGetAuditByOutcomeCategoryRequest;
  [AuditedEntities.OUTCOME_TYPES]: AuditApiGetAuditByOutcomeTypeRequest;
  [AuditedEntities.PLAYERS]: AuditApiGetAuditByPlayerRequest;
  [AuditedEntities.TAGS]: AuditApiGetAuditByTagRequest;
  [AuditedEntities.CROSS_MAPPINGS]: AuditApiGetAuditByOutcomeTypeCrossRequest;
  [AuditedEntities.ENTITY_COMMENTS]: AuditApiGetAuditByEntityCommentRequest;
  [AuditedEntities.ENTITY_BETPAYMAXES]: AuditApiGetAuditByBetPayMaxRequest;
  [AuditedEntities.ENTITY_DELAYS]: AuditApiGetAuditByDelayRequest;
  [AuditedEntities.ENTITY_MARGINS]: AuditApiGetAuditByMarginRequest;
  [AuditedEntities.ENTITY_EXCLUSIONS]: AuditApiGetAuditByExclusionRequest;
  [AuditedEntities.ENTITY_TAGS]: AuditApiGetAuditByEntityTagRequest;
  [AuditedEntities.EVENT_MAPPING]: AuditApiGetAuditBySourceEventMappingRequest;
  [AuditedEntities.EVENT_MAIN_SOURCE]: AuditApiGetAuditBySportEventMainSourceRequest;
  [AuditedEntities.CUSTOMER_CATEGORY_EXLUSION]: AuditApiGetAuditByCategoryExclusionRequest;
  [AuditedEntities.CUSTOMER_EVENT_STATUS]: AuditApiGetAuditByCustomerEventStatusRequest;
  [AuditedEntities.CUSTOMER_EVENT_SUSPEND]: AuditApiGetAuditBySportEventSuspendRequest;
  [AuditedEntities.USER_ACTIONS]: AuditApiGetAuditByUserRequest;
  [AuditedEntities.CUSTOMER_ACTIONS]: AuditApiGetAuditByCustomerRequest;
  [AuditedEntities.ENTITY_MAXODDS]: AuditApiGetAuditByMaxOddRequest;
}

export interface GetAuditPayload<T extends AuditedEntities> {
  entity: T;
  payload: EntityToGetAuditParams[T];
}

export interface OpenPanelProps<T extends AuditedEntities>
  extends Omit<GetAuditPayload<T>, 'entity'> {
  entity: T | T[];
}

export interface GetAuditResponse {
  entity: AuditedEntities;
  response: AuditResponse;
}

export interface GetAuditError {
  entity: AuditedEntities;
  error: string;
}
