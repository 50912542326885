import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getLogos = (state: RootState) => getEntities(state).logos;

export const selectLogosItems = createSelector([getLogos], logos =>
  getEntityItems(logos),
);
