import styled from 'styled-components';

interface WrapperProps {
  disabled: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  align-items: center;
  white-space: nowrap;
  ${p => p.disabled && `color: ${p.theme.colors.primary[50]}`}
`;
