import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { EventLineHeader } from 'app/pages/Events/components/EventHeader/EventLineHeader';
import { EventLiveHeader } from 'app/pages/Events/components/EventHeader/EventLiveHeader';
import { EventInfo } from 'app/pages/Events/components/EventInfo';
import { EventOdds } from 'app/pages/Events/components/Odds/EventOdds';
import { getEventTitle } from 'app/pages/Events/utils';

import { LoadingIndicator } from 'app/components/ui';

import { useEvent } from 'app/providers/EntitiesProvider/events';

import * as S from './Event.styles';

export const Event: FC = () => {
  const { t } = useTranslation();
  const { event, loading, error } = useEvent();

  return (
    <>
      {!event || loading ? (
        <S.EventLoading>
          <LoadingIndicator />
        </S.EventLoading>
      ) : error ? (
        <S.EventError>{t('event error')}</S.EventError>
      ) : (
        <>
          <Helmet>
            <title>{`${t('event')}: ${getEventTitle(event)}`}</title>
            <meta name="description" content="Description of Events" />
          </Helmet>
          <S.EventContainer>
            {event.live ? (
              <EventLiveHeader event={event} />
            ) : (
              <EventLineHeader event={event} />
            )}
            <EventInfo event={event} />
            <EventOdds event={event} />
          </S.EventContainer>
        </>
      )}
    </>
  );
};
