import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Comment as CommentType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';

import { CommentForm } from './CommentForm';
import { FormValues, validationSchema } from './constants';

interface Props {
  id: number;
  onClose: () => void;
}

export const CommentEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const comment: CommentType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.COMMENTS, id),
  );

  useRequestState('comments', 'update', onClose);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.commentsUpdate({
        commentId: comment.id,
        commentWithoutId: values,
      }),
    );
  };

  const changeCommentStatus = () => {
    dispatch(
      actionsNT.commentsUpdate({
        commentId: comment.id,
        commentWithoutId: {
          ...comment,
          disabled: !comment?.disabled,
        },
      }),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={{
        name: comment.name,
        translations: comment.translations,
        disabled: comment.disabled,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.comment')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
              leftButtonText={comment.disabled ? t('enable') : t('disable')}
              onLeftButtonClick={changeCommentStatus}
            />
          }
        >
          <CommentForm />
        </Panel>
      )}
    </Formik>
  );
};
