import { SourcesTableState } from './SourcesPage.types';

export const SOURCES_NAMES = {
  unknown: 'unknown',
  betradar: 'BetRadar',
  betgenius: 'BetGenius',
  betconstruct: 'BetConstruct',
  lsports: 'LSports',
  betbazar: 'BetBazar',
  beter: 'Beter',
  baseline: 'BaseLine',
  openbet: 'Openbet',
  fonbet: 'Fonbet',
  sportlevel: 'SportLevel',
  maxsports: 'MaxSports',
};

export const WITH_PROBS_ID = 'withProbs';
export const WITHOUT_PROBS_ID = 'withoutProbs';

export const initialTableState: SourcesTableState = {
  selectedEvents: [],
};
