import { PropsWithChildren } from 'react';

import useLoadApiUrls from 'hooks/useLoadApiUrls';

import { ErrorPage, LoadingPage } from 'app/components/ui';

const ApiProvider = ({ children }: PropsWithChildren) => {
  const { isLoading, isError } = useLoadApiUrls();

  if (isLoading) return <LoadingPage />;
  if (isError) return <ErrorPage />;

  return children;
};

export default ApiProvider;
