import { Event } from 'sportsbook-openapi-react';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';

const getEventsOddsSubstate = (state: RootState) =>
  getEntities(state).eventsOdds;

export const getEventOddsByEventId = (state: RootState, id: Event['id']) => {
  return getEventsOddsSubstate(state).entries[id];
};
