import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Tournament, UserGroupEnum } from 'sportsbook-openapi-react';
import styled from 'styled-components';

import { useRequestState } from 'hooks/useRequestState';

import {
  SelectTournament,
  TournamentsTable,
} from 'app/components/panels/dependency/components';
import { useTournamentsDependenciesUpdate } from 'app/components/panels/dependency/hooks';
import { PanelFooter } from 'app/components/panels/PanelFooter';
import { RoleGuards } from 'app/components/RoleGuards';
import { Panel, Span } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';

import * as S from './styles';
import { TournamentDependencyFormValues } from './types';

interface Props {
  onClose: () => void;
  id: number;
}

export const TournamentDependencyPanel = ({ id, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tournament: Tournament = useSelector(state =>
    getEntityByTypeAndId(state, Entities.TOURNAMENTS, id),
  );

  const { isLoading, error } = useRequestState(
    Entities.TOURNAMENTS,
    'update',
    onClose,
  );

  useEffect(() => {
    dispatch(
      actionsNT.tournamentsFetchItems({
        ids: tournament.dependentTournaments,
        limit: tournament.dependentTournaments.length,
      }),
    );
  }, [dispatch, tournament.dependentTournaments]);

  const updateTournaments = useTournamentsDependenciesUpdate();

  const onSubmit = (values: TournamentDependencyFormValues) => {
    updateTournaments(tournament, values.tournament.dependentTournaments);
  };

  return (
    <Formik<TournamentDependencyFormValues>
      initialValues={{
        tournament,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, dirty }) => (
        <Panel
          title={t('dependencies')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <RoleGuards
              roles={[
                UserGroupEnum.ADMIN,
                UserGroupEnum.OPERATOR,
                UserGroupEnum.SUPERVISOR,
              ]}
            >
              <PanelFooter
                submitButtonText={t('save')}
                disabled={isLoading || !dirty}
              />
            </RoleGuards>
          }
        >
          <TablesBlockWrapper>
            <Span size={18}>
              {t('tournament')}: {tournament.name}
            </Span>
            <SelectTournament
              name="tournament.dependentTournaments"
              excludedTournament={tournament}
            />
            <TournamentsTable name="tournament.dependentTournaments" />
            {!!error && <S.ErrorMessage>{error}</S.ErrorMessage>}
          </TablesBlockWrapper>
        </Panel>
      )}
    </Formik>
  );
};

const TablesBlockWrapper = styled.div`
  display: grid;
  grid-row-gap: 16px;
`;
