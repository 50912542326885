import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';

import {
  ContainerState,
  TranslatedEntities,
  TranslationFilters,
} from './types';

// The initial state of the Translations container
export const initialState: ContainerState = {
  filters: {},
  entity: TranslatedEntities.SPORTS,
};

const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<TranslationFilters>) {
      state.filters = action.payload;
    },
    setEntity(state, action: PayloadAction<TranslatedEntities>) {
      state.entity = action.payload;
    },
    setState(
      state,
      action: PayloadAction<{
        filters?: TranslationFilters;
        entity?: TranslatedEntities;
      }>,
    ) {
      if (action.payload.filters) {
        state.filters = {
          ...state.filters,
          ...action.payload.filters,
        };
      }
      if (action.payload.entity) {
        state.entity = action.payload.entity;
      }
    },
  },
});

export const { actions, reducer, name: sliceKey } = translationsSlice;
