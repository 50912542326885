import { useField } from 'formik';

import { Select } from 'app/components/forms';
import { BaseOptionType } from 'app/components/forms/Select/Select.types';

import { SelectFieldProps } from './SelectField.types';

export const SelectField = <
  OptionType extends BaseOptionType = BaseOptionType,
  isMulti extends boolean = false,
>({
  name,
  readOnly,
  disabled,
  onChangeSideEffect,
  ...props
}: SelectFieldProps<OptionType, isMulti>) => {
  // helpers будут полезны для React Select и везде, где мы используем setFieldValue
  const [field, meta, helpers] = useField(name);

  const onChange = value => {
    helpers.setValue(value);
    if (onChangeSideEffect) {
      onChangeSideEffect(value);
    }
  };

  return (
    <Select
      {...field}
      {...props}
      value={field.value ?? null}
      invalid={!!meta.error}
      readOnly={readOnly}
      disabled={disabled}
      onChange={onChange}
    />
  );
};
