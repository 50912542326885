import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { getAdapterFromSource } from 'app/pages/Events/utils';
import { getHomeAwayCompetitors } from 'app/pages/Sources/utils';
import dayjs from 'dayjs';
import { AdapterEvent } from 'sportsbook-openapi-react';

import { Button, Panel, Table, Text } from 'app/components/ui';

import { adapterActions } from 'app/providers/AdaptersProvider';

import { BlockTimeButton } from './BlockTimeButton';
import { RefreshCell } from './RefreshCell';

interface Props {
  adapterEvents: AdapterEvent[];
  onClose: () => void;
  eventId: number;
}

const getEventName = (event: AdapterEvent): string => {
  const { homeCompetitor, awayCompetitor } = getHomeAwayCompetitors(event);
  return [homeCompetitor?.name, awayCompetitor?.name]
    .filter(name => !!name)
    .join(' - ');
};

export const SourceEventsPanel = ({
  onClose,
  adapterEvents,
  eventId,
}: Props) => {
  const [events, setEvents] = useState(adapterEvents);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const columns = useMemo<Column<AdapterEvent>[]>(
    () => [
      {
        Header: t('id').toString(),
        accessor: 'id',
        Cell: ({ value }) => <>{value}</>,
      },
      {
        Header: t('update probs').toString(),
        id: 'refresh',
        Cell: ({ row: { original } }) => {
          return (
            <RefreshCell
              id={original.id}
              adapter={getAdapterFromSource(original.source!)}
            />
          );
        },
      },
      {
        Header: t('start date').toString(),
        accessor: 'scheduled',
        Cell: ({ value }) => <>{dayjs(value).format('HH:mm DD.MM.YYYY')}</>,
      },
      {
        Header: t('tournament').toString(),
        accessor: 'tournament',
        Cell: ({ value }) => <>{value?.name}</>,
      },
      {
        Header: t('name').toString(),
        id: 'name',
        accessor: 'competitors',
        Cell: ({ row: { original } }) => <>{getEventName(original)}</>,
      },
      {
        Header: t('source').toString(),
        accessor: 'source',
        Cell: ({ value }) => <>{value ? t(value) : null}</>,
      },
      {
        Header: t('cross-mappings').toString(),
        accessor: 'crossmapped',
        Cell: ({ value }) => {
          return t(value ? 'yes' : 'no');
        },
      },
      {
        Header: t('action').toString(),
        id: 'action',
        accessor: 'id',
        Cell: ({ row: { original } }) => (
          <Button
            color="action"
            onClick={() => {
              setEvents(prev => prev.filter(event => event.id !== original.id));
              dispatch(
                adapterActions.eventsDeleteMapping({
                  adapter: getAdapterFromSource(original.source!),
                  id: original.id!,
                }),
              );
            }}
          >
            {t('remove mapping')}
          </Button>
        ),
      },
    ],
    [dispatch, t],
  );

  return (
    <Panel
      title={t(`panels.show.sourceEvents`)}
      onClose={onClose}
      panelWidth={65}
    >
      <BlockTimeButton eventId={eventId} />
      <Table
        emptyPlaceholder={<Text>{t('no source events')}</Text>}
        columns={columns}
        data={events}
      />
    </Panel>
  );
};
