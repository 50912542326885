import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RESULT_STATUSES } from 'app/pages/Events/components/EventsList';
import {
  getSelectedCategory,
  sortSportEventOdds,
} from 'app/pages/Events/utils';
import { ResultCategoriesTable } from 'app/pages/Results/components/EventResultsTable/components/ResultCategoriesTable';
import { get, groupBy, isEqual, uniq } from 'lodash-es';
import {
  Event,
  SportEventOddCategory,
  SportEventOddsLineType,
  SportEventResultType,
  UserGroupEnum,
} from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';
import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT, SportCode } from 'consts';
import { RootState } from 'types';

import { AutocalculateModal } from 'app/components/modals';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Dialog, LoadingIndicator, Span } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCategoriesEntries } from 'app/providers/EntitiesProvider/categories';
import { getEventMappings } from 'app/providers/EntitiesProvider/eventsMappings';
import { getEventOddsByEventId } from 'app/providers/EntitiesProvider/eventsOdds';
import { getEventResults } from 'app/providers/EntitiesProvider/eventsResults';
import { getSportById } from 'app/providers/EntitiesProvider/sports';
import { getTypesEntries } from 'app/providers/EntitiesProvider/type';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

import { ResultsTable } from './components/ResultsTable';
import * as S from './EventResults.styles';

type OwnProps = {
  event: Event;
};

export enum ResultTabs {
  ALL = 'all',
  UNCALCULATED = 'uncalculated',
}

export const EventResults: FC<OwnProps> = ({ event }) => {
  const [selectedCategoryId, selectCategoryId] = useState(0);
  const [selectedSources, selectSource] = useState({});
  const [resultTab, selectResultTab] = useState<ResultTabs>(ResultTabs.ALL);
  const fetchedIds = useRef<number[]>([]);
  const [isReturnDialogVisible, showReturnDialog, hideReturnDialog] =
    useBooleanState();
  const [
    isAutocalculateModalVisible,
    showAutocalculateModal,
    hideAutocalculateModal,
  ] = useBooleanState();

  const mappings = useSelector(state => getEventMappings(state, event.id));

  const { isLoading: mappingsLoading } = useRequestState(
    'eventsMappings',
    'fetchItem',
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isLoading } = useRequestState('eventsOdds', 'fetchItem');
  const odds = useSelector((state: RootState) =>
    getEventOddsByEventId(state, event.id),
  );
  const results = useSelector(state => getEventResults(state, event.id));

  const categories = useSelector(getCategoriesEntries);
  const types = useSelector(getTypesEntries);
  const sport = useSelector((state: RootState) =>
    getSportById(state, event.tournament!.sportId),
  );

  const mappedResults = useMemo(
    () => groupBy(results, result => String(result.outcomeTypeId)),
    [results],
  );

  const handleReturnMatch = () => {
    dispatch(actionsNT.eventsOddsReturnsCreate({ eventId: event.id }));
    window.location.reload();
  };

  useEffect(() => {
    dispatch(
      actionsNT.eventsOddsFetchItem({
        eventId: event.id,
        lineType: SportEventOddsLineType.ALL,
      }),
    );
  }, [dispatch, event.id]);

  useEffect(() => {
    dispatch(actionsNT.sourcesFetchItems());
    dispatch(actionsNT.typesFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.eventsMappingsFetchItem({ eventId: event.id }));
  }, [dispatch, event.id]);

  useEffect(() => {
    dispatch(actionsNT.eventsResultsFetchItem({ eventId: event.id }));
  }, [dispatch, event.id]);

  useEffect(() => {
    const categoriesIdsToFetch = odds?.categories.map(
      category => category.outcomeCategoryId,
    );
    if (categoriesIdsToFetch?.length > 0) {
      dispatch(
        actionsNT.categoriesFetchItems({
          ids: categoriesIdsToFetch,
          limit: categoriesIdsToFetch?.length,
        }),
      );
    }
  }, [dispatch, odds?.categories]);

  const getCategorySource = useCallback(
    (id: SportEventOddCategory['outcomeCategoryId']) => {
      return (
        get(mappings, `[${id}].source`) ?? event.mainSource ?? odds?.mainSource
      );
    },
    [event.mainSource, mappings, odds?.mainSource],
  );

  const selectedCategory = useMemo(
    () => getSelectedCategory(selectedCategoryId, categories, odds),
    [selectedCategoryId, categories, odds],
  );

  const selectedSource = useMemo(() => {
    const selectedSourceName = selectedSources[selectedCategoryId];

    return selectedCategory?.sources?.find(
      source => source.source === selectedSourceName,
    );
  }, [selectedSources, selectedCategoryId, selectedCategory]);

  useEffect(() => {
    if (
      selectedCategory?.outcomeCategoryId &&
      !selectedSources[selectedCategory.outcomeCategoryId] &&
      get(selectedCategory, 'sources.0.source')
    ) {
      selectSource(selectedSources => ({
        ...selectedSources,
        [selectedCategory.outcomeCategoryId]: getCategorySource(
          selectedCategory.outcomeCategoryId,
        ),
      }));
    }
  }, [getCategorySource, selectedCategory, selectedSources]);

  useEffect(() => {
    if (!isLoading && !mappingsLoading && odds) {
      const selectedCategory = odds.categories[0];
      const selectedCategoryId = selectedCategory?.outcomeCategoryId;
      if (selectedCategory) {
        selectCategoryId(selectedCategoryId);
        selectSource(selectedSources => ({
          ...selectedSources,
          [selectedCategoryId]: getCategorySource(selectedCategoryId),
        }));
      }
    }
  }, [
    odds,
    isLoading,
    mappings,
    mappingsLoading,
    event.mainSource,
    getCategorySource,
  ]);

  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState({});
  const filteredResults = useMemo(() => {
    let filteredResults = [...(selectedSource?.probabilities ?? [])];

    selectedCategory?.sources.forEach(source => {
      source.probabilities.forEach(odd => {
        if (
          !filteredResults.some(
            resultOdd =>
              resultOdd.outcomeTypeId === odd.outcomeTypeId &&
              isEqual(new Set(resultOdd.parameters), new Set(odd.parameters)),
          )
        ) {
          filteredResults.push(odd);
        }
      });
    });

    filteredResults = filteredResults.filter(eventOdd => {
      const isMatchByType =
        resultTab !== ResultTabs.UNCALCULATED ||
        !results.some(
          result =>
            result.outcomeTypeId === eventOdd.outcomeTypeId &&
            isEqual(new Set(eventOdd.parameters), new Set(result.params)),
        ) ||
        mappedResults[eventOdd.outcomeTypeId]?.find(result =>
          isEqual(new Set(result.params), new Set(eventOdd.parameters)),
        )?.result === SportEventResultType.NA;
      if (!isMatchByType) return false;

      const isMatchByCodeFilter =
        !filters['code'] ||
        types[eventOdd.outcomeTypeId].code
          .toLowerCase()
          .includes(filters['code'].toLowerCase());
      if (!isMatchByCodeFilter) return false;

      const isMatchByFilters = eventOdd.parameters?.every(parameter => {
        if (!filters[`parameters.${parameter.type}`]) return true;
        return (
          String(filters[`parameters.${parameter.type}`].id) === parameter.value
        );
      });

      if (!isMatchByFilters) return false;
      return isMatchByType && isMatchByCodeFilter && isMatchByFilters;
    });

    filteredResults.sort(sortSportEventOdds(types, sorting));

    return filteredResults;
  }, [
    filters,
    mappedResults,
    resultTab,
    results,
    selectedCategory?.sources,
    selectedSource?.probabilities,
    sorting,
    types,
  ]);

  useEffect(() => {
    const ids = uniq(filteredResults.map(p => p.outcomeTypeId));
    const idsToFetch = ids.filter(id => !fetchedIds.current.includes(id));
    if (idsToFetch.length) {
      dispatch(
        actionsNT.typesFetchItems({
          ids: idsToFetch,
          withPagination: true,
        }),
      );
      fetchedIds.current.push(...idsToFetch);
    }
  }, [dispatch, filteredResults]);

  const { isCustomer } = useUserStore(getIsCustomer);

  if (isLoading) {
    return (
      <S.StyledOddsLoading>
        <LoadingIndicator />
      </S.StyledOddsLoading>
    );
  }

  if (!RESULT_STATUSES.includes(event.status)) {
    return <Navigate to="/events" />;
  }

  const showAutoCalculation = sport?.code === SportCode.FOOTBALL;

  return (
    <S.StyledOddsContainer>
      <S.StyledOddsTitle>
        <S.StyledLink target="_blank" to={`/events/${event.id}`}>
          <S.StyledHeading>{t('coefficients')}</S.StyledHeading>
        </S.StyledLink>
      </S.StyledOddsTitle>

      <S.StyledOddsSubTitle>
        {!isCustomer && (
          <>
            <S.Label>{t('default source')}</S.Label>
            <S.SourceName>{event.mainSource}</S.SourceName>
          </>
        )}

        <div />
        <RoleGuards
          roles={[
            UserGroupEnum.ADMIN,
            UserGroupEnum.OPERATOR,
            UserGroupEnum.SUPERVISOR,
          ]}
        >
          <Button color="secondary" onClick={showReturnDialog}>
            {t('match return')}
          </Button>
          {showAutoCalculation && (
            <Button color="secondary" onClick={showAutocalculateModal}>
              {t('auto-calculation')}
            </Button>
          )}
        </RoleGuards>
      </S.StyledOddsSubTitle>

      <S.StyledOddsTableContainer>
        <ResultCategoriesTable
          event={event}
          resultTab={resultTab}
          selectResultTab={selectResultTab}
          selectCategoryId={selectCategoryId}
          selectedCategory={selectedCategory}
        />
        {!!selectedCategory && (
          <S.StyledOddsTable>
            <S.StyledOddsTableHeader>
              <Span fontWeight={500} fontSize={18}>
                {selectedCategory?.name}
              </Span>
            </S.StyledOddsTableHeader>
            <ResultsTable
              event={event}
              results={filteredResults}
              mappedResults={mappedResults}
              types={types}
              isCustomer={isCustomer}
              sorting={sorting}
              filters={filters}
              setSorting={setSorting}
              setFilters={setFilters}
            />
          </S.StyledOddsTable>
        )}
      </S.StyledOddsTableContainer>
      {isReturnDialogVisible && (
        <Dialog
          text={t('returnMatchDialog', { id: event.id })}
          onClose={hideReturnDialog}
          onConfirm={handleReturnMatch}
        />
      )}
      {isAutocalculateModalVisible && (
        <AutocalculateModal
          closeModal={hideAutocalculateModal}
          eventId={event.id}
        />
      )}
    </S.StyledOddsContainer>
  );
};
