import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityType, Tag } from 'sportsbook-openapi-react';

import { FETCH_ALL_LIMIT } from 'consts';

import { Select } from 'app/components/forms';

import { actionsNT } from 'app/providers/EntitiesProvider';
import {
  selectTagsByCustomer,
  selectTagsItems,
} from 'app/providers/EntitiesProvider/tags';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

interface Props {
  entityId: number;
  entityType: EntityType;
}

export const TagsSelect: FC<Props> = ({ entityId, entityType }) => {
  const dispatch = useDispatch();
  const { isCustomer, customerId } = useUserStore(getIsCustomer);
  const { itemsToTags, entriesByTagId } = useSelector(selectTagsByCustomer);
  const tagItems = useSelector(selectTagsItems);

  useEffect(() => {
    dispatch(actionsNT.tagsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  useEffect(() => {
    if (isCustomer && customerId) {
      dispatch(actionsNT.tagsByCustomerReset());
      dispatch(
        actionsNT.tagsByCustomerFetchItems({
          customerId: customerId,
          entityId,
          entityType,
        }),
      );
    }
  }, [customerId, dispatch, entityId, entityType, isCustomer]);

  const handleTagsChange = (previousTags: Tag[]) => (currentTags: Tag[]) => {
    const addedTags = currentTags.filter(
      tag => !previousTags.find(prevTag => tag?.id === prevTag?.id),
    );
    const removedTags = previousTags.filter(
      tag => !currentTags.find(curTag => tag?.id === curTag?.id),
    );

    if (customerId) {
      addedTags.forEach(tag => {
        dispatch(
          actionsNT.tagsByCustomerUpdate({
            customerId,
            entityId,
            entityType,
            entityTag: {
              tagId: tag.id,
              entityId,
              entityType,
            },
          }),
        );
      });
      removedTags.forEach(tag => {
        dispatch(
          actionsNT.tagsByCustomerDelete({
            customerId,
            tagId: entriesByTagId[tag.id].id!,
          }),
        );
      });
    }
  };

  return (
    <Select
      isMulti
      options={tagItems}
      value={itemsToTags}
      onChange={value => handleTagsChange(itemsToTags)(value as Tag[])}
    />
  );
};
