import * as yup from 'yup';

export { yup };

export const emailValidationSchema = yup
  .string()
  .trim()
  .required('Email is required')
  .email('Invalid Email')
  .max(256, 'validations.max-length-256-symbols');

export const passwordValidationSchema = yup
  .string()
  .required('Password is required')
  .min(8, 'validations.min-length-8-symbols')
  .max(256, 'validations.max-length-256-symbols');

export const requiredStringValidationSchema = yup
  .string()
  .trim()
  .required('Required field');

export const stringValidationSchema = yup.string().trim();

export const requiredIntegerValidationSchema = yup
  .number()
  .integer()
  .typeError('Field must be a valid number')
  .required('Required field');

export const optionValidationSchema = yup
  .object()
  .shape({})
  .required('Required field');

export const codeValidationSchema = yup
  .number()
  .integer()
  .typeError('Code must be a number')
  .required('Code is required');

export const numberValidationSchema = yup.number().required();

export const requiredStringArraySchema = yup.array().of(yup.string());

export const requiredDateValidationSchema = yup
  .date()
  .required('Required field');

export const moreOrEqOne = value => {
  return value >= 1;
};
export const moreOrEqZero = value => {
  return value >= 0;
};
