import { PayloadAction } from '@reduxjs/toolkit';
import { SportEventStats } from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';

const reducer = {
  eventStatsFetchItem(state) {
    state.eventStats.fetchItem.error = null;
    state.eventStats.fetchItem.loading = true;
  },
  eventStatsFetchItemSuccess(state, action: PayloadAction<SportEventStats>) {
    state.eventStats.entries[action.payload.eventId] = action.payload;
    state.eventStats.fetchItem.loading = false;
  },
  eventStatsFetchItemError(state, action: PayloadAction<EntitiesErrorType>) {
    state.eventStats.fetchItem.error = action.payload;
    state.eventStats.fetchItem.loading = false;
  },
};

export default reducer;
