import { TagWithOutId } from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
});

export const createInitialValues: TagWithOutId = {
  name: '',
};
