import styled from 'styled-components';

interface RadioSubformProps {
  slick?: boolean;
}

export const StyledFormBlock = styled.div`
  display: grid;
  grid-gap: 40px;
`;

export const RadioSubform = styled.div<RadioSubformProps>`
  display: grid;
  grid-template-columns: ${p =>
    p.slick ? 'min-content min-content' : '1fr 1fr'};
  grid-column-gap: 30px;
`;

export const RadioButtonWrapper = styled.div`
  display: grid;
`;

export const TableWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  grid-row-gap: 16px;
`;

export const ErrorMessage = styled.div`
  grid-column: 1/3;
  color: ${p => p.theme.colors.red[500]};
`;
