import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';

import { RootState } from 'types';

import { Entities } from 'app/providers/EntitiesProvider/types';

const getOutcomeCategorySourceMappings = (state: RootState) =>
  state.entities[Entities.OUTCOMECATEGORYSOURCEMAPPINGS];

const getOutcomeCategorySourceMappingsEntries = (state: RootState) =>
  getOutcomeCategorySourceMappings(state).entries;

export const selectOutcomeCategorySourceMappingsByCategory = createSelector(
  [getOutcomeCategorySourceMappingsEntries],
  outcomeCategorySourceMappings =>
    keyBy(outcomeCategorySourceMappings, 'categoryId'),
);
