import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { eventsApi } from 'app/api';
import {
  AutoCalculationStats,
  FootballLiveParametersRequest,
  ModelMatchPartStatus,
  StatType,
} from 'sportsbook-openapi-react';

import { Dialog, Text } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';

import * as S from './AutocalculateModal.styles';

const footballState: FootballLiveParametersRequest = {
  match: {
    score: {
      team1: 0,
      team2: 0,
    },
    status: ModelMatchPartStatus.FINALIZING,
  },
  time1: {
    score: {
      team1: 0,
      team2: 0,
    },
    status: ModelMatchPartStatus.FINALIZING,
  },
  time2: {
    score: {
      team1: 0,
      team2: 0,
    },
    status: ModelMatchPartStatus.FINALIZING,
  },
  isExtraTime: false,
};

interface Props {
  eventId: number;
  closeModal: () => void;
}

export const AutocalculateModal = ({ eventId, closeModal }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useRef(footballState);

  const onConfirm = async () => {
    if (state.current) {
      const matchState = state.current;

      // Precalculate 2nd time scores
      matchState.time2!.score!.team1 =
        matchState.match!.score!.team1! - matchState.time1!.score!.team1!;
      matchState.time2!.score!.team2 =
        matchState.match!.score!.team2! - matchState.time1!.score!.team2!;

      // Aggregate scores for convenient use
      const team1Scores = {
        match: state.current.match?.score?.team1,
        time1: state.current.time1?.score?.team1,
        time2: state.current.time2?.score?.team1,
      };

      const team2Scores = {
        match: state.current.match?.score?.team2,
        time1: state.current.time1?.score?.team2,
        time2: state.current.time2?.score?.team2,
      };

      // Initialize stats object
      const autoCalculationStats: AutoCalculationStats = {
        homeStats: { stats: [], periodStats: [] },
        awayStats: { stats: [], periodStats: [] },
        commonStats: { stats: [] },
      };

      // Enrich stats object with filled and calculated above scores from form
      // Home stats
      if (state.current.match!.score!.team1 !== undefined) {
        // Match score
        autoCalculationStats.homeStats.stats.push({
          type: StatType.SCORE,
          value: {
            numberValue: team1Scores.match,
          },
        });
        if (state.current.time1!.score!.team1 !== undefined) {
          // Time 1 score
          autoCalculationStats.homeStats.periodStats.push({
            periodNumber: 1,
            stat: {
              type: StatType.SCORE,
              value: {
                numberValue: team1Scores.time1,
              },
            },
          });

          // Time 2 score
          autoCalculationStats.homeStats.periodStats.push({
            periodNumber: 2,
            stat: {
              type: StatType.SCORE,
              value: {
                numberValue: team1Scores.time2,
              },
            },
          });
        }
      }

      // Away stats
      if (state.current.match!.score!.team2 !== undefined) {
        // Match score
        autoCalculationStats.awayStats.stats.push({
          type: StatType.SCORE,
          value: {
            numberValue: team2Scores.match,
          },
        });
        if (state.current.time1!.score!.team2 !== undefined) {
          // Time 1 score
          autoCalculationStats.awayStats.periodStats.push({
            periodNumber: 1,
            stat: {
              type: StatType.SCORE,
              value: {
                numberValue: team2Scores.time1,
              },
            },
          });

          // Time 2 score
          autoCalculationStats.awayStats.periodStats.push({
            periodNumber: 2,
            stat: {
              type: StatType.SCORE,
              value: {
                numberValue: team2Scores.time2,
              },
            },
          });
        }
      }

      const sportEventResult = await eventsApi.autoCalculateEventResults({
        eventId,
        autoCalculationStats,
      });

      await eventsApi.applyAutoCalculatedResults({
        eventId,
        sportEventResult,
      });

      dispatch(actionsNT.eventsResultsFetchItem({ eventId }));
    }
  };

  return (
    <Dialog
      text={t('auto-calculation')}
      onClose={closeModal}
      onConfirm={onConfirm}
    >
      <S.Wrapper>
        <S.StyledCheckboxWrapper>
          <input
            type="checkbox"
            onChange={event => {
              state.current.isExtraTime = event.target.checked;
            }}
          />
          <Text>{t('is extra time')}</Text>
        </S.StyledCheckboxWrapper>
        <S.PeriodWrapper>
          <Text weight={600}>{t('match')}:</Text>
        </S.PeriodWrapper>
        <S.InputWraper>
          <Text>{t('team score')} 1</Text>
          <S.StyledInput
            defaultValue={state.current.match!.score!.team1}
            onChange={event => {
              state.current.match!.score!.team1 = Number(event.target.value);
            }}
          />
        </S.InputWraper>
        <S.InputWraper>
          <Text>{t('team score')} 2</Text>
          <S.StyledInput
            defaultValue={state.current.match!.score!.team2}
            onChange={event => {
              state.current.match!.score!.team2 = Number(event.target.value);
            }}
          />
        </S.InputWraper>
        <S.PeriodWrapper>
          <Text weight={600}>{t('football time')} 1:</Text>
        </S.PeriodWrapper>
        <S.InputWraper>
          <Text>{t('team score')} 1</Text>
          <S.StyledInput
            defaultValue={state.current.time1!.score!.team1}
            onChange={event => {
              state.current.time1!.score!.team1 = Number(event.target.value);
            }}
          />
        </S.InputWraper>
        <S.InputWraper>
          <Text>{t('team score')} 2</Text>
          <S.StyledInput
            defaultValue={state.current.time1!.score!.team2}
            onChange={event => {
              state.current.time1!.score!.team2 = Number(event.target.value);
            }}
          />
        </S.InputWraper>
      </S.Wrapper>
    </Dialog>
  );
};
