import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getAsyncLoadOptions } from 'utils/select';

import { AsyncSelect, DatePicker, Select } from 'app/components/forms';
import { SelectEventFilters } from 'app/components/modals/SelectEventModal/SelectEventModal.types';
import { SelectEventFiltersProps } from 'app/components/modals/SelectEventModal/SelectEventModalContent';
import { Button, LoadingIndicator, Text } from 'app/components/ui';

import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';

import * as S from './SelectEventFiltersBlock.styles';

export const SelectEventFiltersBlock: FC<SelectEventFiltersProps> = ({
  loadEvents,
  isLoading,
  initialFilters,
}) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<SelectEventFilters>(initialFilters);

  const handleFilterChange = useCallback(
    (key: keyof typeof filters) => value => {
      setFilters(prevState => ({
        ...prevState,
        [key]: value,
      }));
    },
    [setFilters],
  );

  const sports = useSelector(selectSportsItems);

  return (
    <S.FiltersWrapper>
      <S.FilterBlock>
        <Text>{t('sport')}</Text>
        <Select
          isClearable
          options={sports}
          value={filters.sport}
          onChange={handleFilterChange('sport')}
        />
      </S.FilterBlock>
      <S.FilterBlock>
        <Text>{t('group')}</Text>
        <AsyncSelect
          isClearable
          value={filters.group}
          onChange={handleFilterChange('group')}
          cacheUniqs={[
            filters.sport,
            filters.scheduledFrom,
            filters.scheduledTo,
          ]}
          loadOptions={getAsyncLoadOptions({
            entity: 'groups',
            sportId: filters.sport?.id,
            scheduledFrom: filters.scheduledFrom,
            scheduledTo: filters.scheduledTo,
          })}
        />
      </S.FilterBlock>
      <S.FilterBlock>
        <Text>{t('tournaments')}</Text>
        <AsyncSelect
          isClearable
          value={filters.tournament}
          onChange={handleFilterChange('tournament')}
          cacheUniqs={[
            filters.sport,
            filters.group,
            filters.scheduledFrom,
            filters.scheduledTo,
          ]}
          loadOptions={getAsyncLoadOptions({
            entity: 'tournaments',
            sportId: filters.sport?.id,
            groupId: filters.group?.id,
            scheduledFrom: filters.scheduledFrom,
            scheduledTo: filters.scheduledTo,
          })}
        />
      </S.FilterBlock>
      <S.FilterBlock>
        <Text>{t('homeCompetitor')}</Text>
        <AsyncSelect
          isMulti
          isClearable
          value={filters.homeCompetitors}
          onChange={handleFilterChange('homeCompetitors')}
          cacheUniqs={[
            filters.sport,
            filters.group,
            filters.scheduledFrom,
            filters.scheduledTo,
          ]}
          loadOptions={getAsyncLoadOptions({
            entity: 'competitors',
            sportId: filters.sport?.id,
            groupId: filters.group?.id,
            scheduledFrom: filters.scheduledFrom,
            scheduledTo: filters.scheduledTo,
          })}
        />
      </S.FilterBlock>
      <S.FilterBlock>
        <Text>{t('awayCompetitor')}</Text>
        <AsyncSelect
          isMulti
          isClearable
          value={filters.awayCompetitors}
          onChange={handleFilterChange('awayCompetitors')}
          cacheUniqs={[
            filters.sport,
            filters.group,
            filters.scheduledFrom,
            filters.scheduledTo,
          ]}
          loadOptions={getAsyncLoadOptions({
            entity: 'competitors',
            sportId: filters.sport?.id,
            groupId: filters.group?.id,
            scheduledFrom: filters.scheduledFrom,
            scheduledTo: filters.scheduledTo,
          })}
        />
      </S.FilterBlock>
      <S.FilterBlock>
        <Text>{t('live')}</Text>
        <S.StyledCheckbox
          label={''}
          checked={filters.live}
          onChange={handleFilterChange('live')}
        />
      </S.FilterBlock>
      <S.FilterBlock>
        <Text>{t('scheduledFrom')}</Text>
        <DatePicker
          isClearable
          value={filters.scheduledFrom?.toISOString()}
          onChange={handleFilterChange('scheduledFrom')}
        />
      </S.FilterBlock>
      <S.FilterBlock>
        <Text>{t('scheduledTo')}</Text>
        <DatePicker
          isClearable
          value={filters.scheduledTo?.toISOString()}
          onChange={handleFilterChange('scheduledFrom')}
        />
      </S.FilterBlock>
      <S.FilterBlock>
        <div />
        <Button onClick={() => loadEvents(filters)}>
          {isLoading ? <LoadingIndicator small /> : t('search')}
        </Button>
      </S.FilterBlock>
    </S.FiltersWrapper>
  );
};
