import { FC } from 'react';
import styled from 'styled-components';

import { Button } from 'app/components/ui';

interface Props {
  submitButtonText: string;
  disabled?: boolean;
  onLeftButtonClick?: () => void;
  leftButtonDisabled?: boolean;
  leftButtonText?: string;
}

export const PanelFooter: FC<Props> = ({
  submitButtonText,
  leftButtonText,
  onLeftButtonClick,
  leftButtonDisabled,
  disabled,
}) => (
  <Footer alignRight={!leftButtonText}>
    {!!leftButtonText && (
      <Button
        color="link"
        type="button"
        onClick={onLeftButtonClick}
        disabled={leftButtonDisabled}
      >
        {leftButtonText}
      </Button>
    )}
    <Button disabled={disabled} type="submit" size="s">
      {submitButtonText}
    </Button>
  </Footer>
);

const Footer = styled.div<{
  alignRight: boolean;
}>`
  flex-grow: 1;
  display: flex;
  justify-content: ${({ alignRight }) =>
    alignRight ? 'flex-end' : 'space-between'};
  align-items: center;
`;
