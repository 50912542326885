import { typesApi } from 'app/api';

const api = {
  endpoint: typesApi,
  methods: {
    fetchItems: 'getTypes',
    fetchItem: 'getType',
    create: 'createType',
    update: 'updateType',
    delete: 'deleteType',
  },
};

export default api;
