import { keyBy } from 'lodash-es';

import {
  Entity,
  EntityWithStringId,
} from 'app/providers/EntitiesProvider/types';

export const toIdsArray = <T extends string | number>(
  items: { id?: T }[],
  existingIds: any[] = [],
  idFieldName: string = 'id',
) => {
  const result: Array<T> = [];
  items.forEach(item => {
    if (item[idFieldName]) {
      const id = item[idFieldName];
      if (!existingIds.includes(id)) {
        result.push(id);
      }
    }
  });
  return result;
};

export const toIdsMap = <T>(items: T[], idFieldName: string = 'id') =>
  keyBy(items, idFieldName);

export const getEntityItems = <T>(entity: Entity<T>): T[] =>
  entity.sortedIds.map((id: number | string) => entity.entries[id]);

export const getEntityWithStringIdItems = <T>(
  entity: EntityWithStringId<T>,
): T[] => entity.sortedIds.map((id: string) => entity.entries[id]);

export const populateWithItems = <T>(
  entity: Entity<T>,
): Entity<T> & { items: T[] } => {
  return {
    ...entity,
    items: getEntityItems(entity),
  };
};
