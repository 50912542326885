import { FC, useEffect, useMemo, useState } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CommentsSelect } from 'app/pages/Dictionary/Directory/components/CommentsSelect';
import { TagsSelect } from 'app/pages/Dictionary/Directory/components/TagsSelect';
import { EventOperator } from 'app/pages/Events/components/LiveEventOperator';
import { ShowSourcesButton } from 'app/pages/Events/components/ShowSourcesButton';
import * as CommonS from 'app/pages/Events/components/styles';
import { getHomeAwayCompetitors } from 'app/pages/Events/utils';
import dayjs from 'dayjs';
import { EntityType, Event, UserGroupEnum } from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';
import { RootState } from 'types';
import { TestIds } from 'types/testIds.types';

import { EventDependencyPanel, RelationPanel } from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button } from 'app/components/ui';

import { adapterActions } from 'app/providers/AdaptersProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCommentsByEntityTypeAndEntityId } from 'app/providers/EntitiesProvider/commentsByEntity';
import { selectCountry } from 'app/providers/EntitiesProvider/countries';
import { getEventLinkedIds } from 'app/providers/EntitiesProvider/eventLinkedIds';
import { selectGroup } from 'app/providers/EntitiesProvider/groups';
import { getMatchType } from 'app/providers/EntitiesProvider/matchTypes';
import { getSportById } from 'app/providers/EntitiesProvider/sports';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

import * as S from './EventInfo.styles';

type OwnProps = {
  event: Event;
};

export const EventInfo: FC<OwnProps> = ({ event }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [editRelationPanel, setEditRelationPanel] = useState<null | {
    eventId: number;
    selectedEvents: number[];
  }>(null);
  const [isEventDependencyVisible, showEventDependency, hideEventDependency] =
    useBooleanState();

  const { isCustomer } = useUserStore(getIsCustomer);

  const group = useSelector((state: RootState) =>
    selectGroup(state, event.groupId),
  );
  const sport = useSelector(state => getSportById(state, group?.sportId));
  const matchType = useSelector(state =>
    getMatchType(state, event.matchTypeId),
  );
  const country = useSelector((state: RootState) =>
    selectCountry(state, event.tournament?.countryId!),
  );
  const tournament = event.tournament;
  const { homeCompetitor, awayCompetitor } = getHomeAwayCompetitors(event);
  const entityComments = useSelector(
    getCommentsByEntityTypeAndEntityId(
      EntityType.TOURNAMENT,
      event.tournament!.id,
    ),
  );

  const getLinkedIds = useCallback(() => {
    dispatch(actionsNT.eventLinkedIdsFetchItem({ eventId: event.id }));
  }, [dispatch, event.id]);

  useEffect(() => {
    getLinkedIds();
  }, [dispatch, getLinkedIds]);
  const linkedIds = useSelector(state => getEventLinkedIds(state, event.id));

  useEffect(() => {
    dispatch(actionsNT.groupsFetchItem({ groupId: event.groupId }));
  }, [dispatch, event.groupId]);

  useEffect(() => {
    dispatch(
      actionsNT.countriesFetchItem({ countryId: event.tournament?.countryId! }),
    );
  }, [dispatch, event.tournament?.countryId]);

  useEffect(() => {
    dispatch(
      actionsNT.commentsByEntityFetchItems({
        entityType: EntityType.TOURNAMENT,
        entityId: event.tournament?.id,
      }),
    );
  }, [dispatch, event.tournament?.id]);

  useEffect(() => {
    dispatch(actionsNT.matchTypesFetchItem({ matchTypeId: event.matchTypeId }));
  }, [dispatch, event.matchTypeId]);

  useEffect(() => {
    if (!isCustomer) {
      dispatch(adapterActions.fetchAdapters());
    }
  }, [dispatch, isCustomer]);

  useEffect(() => {
    if (!isCustomer) {
      dispatch(
        adapterActions.mappedEventsFetch({
          ids: [event.id],
        }),
      );
    }
  }, [dispatch, event.id, isCustomer]);

  useEffect(() => {
    if (group) {
      dispatch(actionsNT.sportsFetchItem({ sportId: group.sportId }));
    }
  }, [group, dispatch]);

  const eventDependenciesButtonText = useMemo(() => {
    let result = '';
    const tournamentsCount =
      event.dependentTournaments?.length! +
      event.tournament?.dependentTournaments?.length!;

    if (!!tournamentsCount) {
      result += `${t('tournament')} (${tournamentsCount})`;
    }
    if (!!event.dependentEvents?.length) {
      result += ` ${t('match')} (${event.dependentEvents.length})`;
    }
    if (result === '') {
      result = t('add dependency');
    }
    return result.trim();
  }, [event.dependentEvents, event.dependentTournaments, event.tournament, t]);

  const linkedEventsLength = linkedIds.length + Number(!!event.baseEventId);

  return (
    <S.EventInfoContainer>
      <S.InfoBlock data-test-id={TestIds.EventPageInfo}>
        <S.EventFields>
          <CommonS.EventFieldLabel
            data-test-id={`${TestIds.EventPageInfoSport}-label`}
          >
            {t('sport type')}
          </CommonS.EventFieldLabel>
          {!!sport && (
            <CommonS.EventFieldValue
              data-test-id={`${TestIds.EventPageInfoSport}-value`}
            >
              {sport.name}
            </CommonS.EventFieldValue>
          )}

          <CommonS.EventFieldLabel
            data-test-id={`${TestIds.EventPageInfoCountry}-label`}
          >
            {t('country')}
          </CommonS.EventFieldLabel>
          {!!country && (
            <CommonS.EventFieldValue
              data-test-id={`${TestIds.EventPageInfoCountry}-value`}
            >
              {country.name}
            </CommonS.EventFieldValue>
          )}

          <CommonS.EventFieldLabel
            data-test-id={`${TestIds.EventPageInfoGroup}-label`}
          >
            {t('group')}
          </CommonS.EventFieldLabel>
          {!!group && (
            <CommonS.EventFieldValue
              data-test-id={`${TestIds.EventPageInfoGroup}-value`}
            >
              {group.name}
            </CommonS.EventFieldValue>
          )}

          <CommonS.EventFieldLabel
            data-test-id={`${TestIds.EventPageInfoDatetime}-label`}
          >
            {t('datetime')}
          </CommonS.EventFieldLabel>
          <CommonS.EventFieldValue
            data-test-id={`${TestIds.EventPageInfoDatetime}-value`}
          >
            {dayjs(event.eventDate).format('HH:mm DD.MM.YYYY')}
          </CommonS.EventFieldValue>

          <CommonS.EventFieldLabel
            data-test-id={`${TestIds.EventPageInfoTournament}-label`}
          >
            {t('tournament')}
          </CommonS.EventFieldLabel>
          {!!tournament && (
            <CommonS.EventFieldValue
              data-test-id={`${TestIds.EventPageInfoTournament}-value`}
            >
              {tournament.name}
            </CommonS.EventFieldValue>
          )}

          <CommonS.EventFieldLabel
            data-test-id={`${TestIds.EventPageInfoCompetitors}-label`}
          >
            {t('competitors')}
          </CommonS.EventFieldLabel>
          <CommonS.EventFieldValue
            data-test-id={`${TestIds.EventPageInfoCompetitors}-value`}
          >
            {[homeCompetitor?.name, awayCompetitor?.name]
              .filter(c => !!c)
              .join(' : ')}
          </CommonS.EventFieldValue>

          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.OPERATOR,
              UserGroupEnum.SUPERVISOR,
            ]}
          >
            {event.live && <EventOperator event={event} />}
          </RoleGuards>
        </S.EventFields>
        <S.EventFields>
          <CommonS.EventFieldLabel
            data-test-id={`${TestIds.EventPageInfoMatchType}-label`}
          >
            {t('match type')}
          </CommonS.EventFieldLabel>
          <Button
            color="link"
            testId={TestIds.EventPageInfoMatchType}
            onClick={() => {
              const selectedEvents = [event.id];
              if (linkedIds) selectedEvents.push(...linkedIds);
              if (event.baseEventId) selectedEvents.push(event.baseEventId);
              setEditRelationPanel({ eventId: event.id, selectedEvents });
            }}
          >
            {matchType?.name}{' '}
            {!!linkedEventsLength &&
              `(${t('linked events', { count: linkedEventsLength })})`}
          </Button>
          <CommonS.EventFieldLabel
            data-test-id={`${TestIds.EventPageInfoDependencies}-label`}
          >
            {t('dependencies')}
          </CommonS.EventFieldLabel>
          <Button
            color="link"
            testId={TestIds.EventPageInfoDependencies}
            onClick={showEventDependency}
          >
            {eventDependenciesButtonText}
          </Button>
          <CommonS.EventFieldLabel
            data-test-id={`${TestIds.EventPageInfoNote}-label`}
          >
            {t('note')}
          </CommonS.EventFieldLabel>
          <CommonS.EventFieldValue
            data-test-id={`${TestIds.EventPageInfoNote}-value`}
          >
            {event.note ? event.note : t('no note')}
          </CommonS.EventFieldValue>
          {!isCustomer && (
            <>
              <CommonS.EventFieldLabel>
                {t('source events')}
              </CommonS.EventFieldLabel>
              <ShowSourcesButton eventId={event.id} />
            </>
          )}
          <RoleGuards roles={[UserGroupEnum.CUSTOMER]}>
            <CommonS.EventFieldLabel
              data-test-id={`${TestIds.EventPageInfoTags}-label`}
            >
              {t('tags')}
            </CommonS.EventFieldLabel>
            <TagsSelect
              entityId={event.id}
              entityType={EntityType.SPORT_EVENT}
            />
          </RoleGuards>
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.SUPERVISOR,
              UserGroupEnum.OPERATOR,
            ]}
          >
            <CommonS.EventFieldLabel
              data-test-id={`${TestIds.EventPageInfoComments}-label`}
            >
              {t('comments')}
            </CommonS.EventFieldLabel>
            <CommentsSelect
              entityId={event.id}
              entityType={EntityType.SPORT_EVENT}
            />
          </RoleGuards>
          <CommonS.EventFieldLabel>
            {t('tournament comment')}
          </CommonS.EventFieldLabel>
          <CommonS.EventFieldValue>
            {entityComments.map(e => e?.name).join(', ')}
          </CommonS.EventFieldValue>
          <CommonS.EventFieldLabel
            data-test-id={`${TestIds.EventPageInfoType}-label`}
          >
            {t('type')}
          </CommonS.EventFieldLabel>
          <CommonS.EventFieldValue
            data-test-id={`${TestIds.EventPageInfoType}-value`}
          >
            {event.kind}
          </CommonS.EventFieldValue>
        </S.EventFields>
      </S.InfoBlock>
      {!!editRelationPanel && (
        <RelationPanel
          onClose={() => {
            setEditRelationPanel(null);
          }}
          actionType="edit"
          selectedEvents={editRelationPanel.selectedEvents}
          id={editRelationPanel.eventId}
          onSuccess={getLinkedIds}
        />
      )}
      {isEventDependencyVisible && (
        <EventDependencyPanel id={event.id} onClose={hideEventDependency} />
      )}
    </S.EventInfoContainer>
  );
};
