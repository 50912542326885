import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  code: Yup.string().required('Required'),
});

export const initialCreateValues = {
  name: '',
  code: '',
};
