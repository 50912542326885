import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from 'app/pages/Sources/components/EntitiesTables/styles';
import { FormikProvider, useFormik } from 'formik';
import { AdapterPlayer, Player } from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import useBooleanState from 'hooks/useBooleanState';

import { AsyncSelectField } from 'app/components/forms';
import { PlayerCreatePanel } from 'app/components/panels';
import { Button, TextLoader } from 'app/components/ui';
import LargePlus from 'assets/images/large-plus.svg?react';

import { adapterActions } from 'app/providers/AdaptersProvider';

type Values = {
  sportsbookPlayer?: Player;
};

interface Props {
  player: AdapterPlayer;
  players: Dictionary<Player>;
  mapped: boolean;
  adapter: string;
  isEditing: boolean;
  isFetched: boolean;
  setEditingRow: (editingId: string | undefined) => void;
}

export const PlayerRow = ({
  mapped,
  adapter,
  player,
  players,
  isEditing,
  isFetched,
  setEditingRow,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [
    isCreatePlayerPanelVisible,
    showCreatePlayerPanel,
    hideCreatePlayerPanel,
  ] = useBooleanState();

  const handleDeleteMapping = () => {
    if (!player.id) return;
    dispatch(adapterActions.playersDeleteMapping({ id: player.id, adapter }));
    setEditingRow(undefined);
  };

  const handleEdit = () => {
    setEditingRow(player.id);
  };

  const formik = useFormik<Values>({
    initialValues: {
      sportsbookPlayer: mapped ? players[player.sportsbookId!] : undefined,
    },
    onSubmit: ({ sportsbookPlayer }) => {
      dispatch(
        adapterActions.playersMap({
          id: player.id!,
          adapter,
          mappingRequest: {
            sportsbookId: sportsbookPlayer?.id!,
          },
          withDeletion: !mapped,
        }),
      );
      setEditingRow(undefined);
    },
    enableReinitialize: true,
  });

  const { values, submitForm, setFieldValue } = formik;

  const onPlayerCreated = entity => {
    setFieldValue('sportsbookPlayer', entity);
  };

  const playerSelect = (
    <AsyncSelectField
      name="sportsbookPlayer"
      loadOptions={getAsyncLoadOptions({
        entity: 'players',
        disabled: false,
      })}
      debounceTimeout={200}
    />
  );

  return (
    <FormikProvider value={formik}>
      {mapped ? (
        <S.Tr>
          <S.Td>{player.name}</S.Td>
          {isEditing ? (
            <S.SelectTd>
              {playerSelect}
              <Button color="action" onClick={showCreatePlayerPanel}>
                <LargePlus />
              </Button>
            </S.SelectTd>
          ) : (
            <S.Td>
              {!isFetched ? <TextLoader /> : values.sportsbookPlayer?.name}
            </S.Td>
          )}
          <S.Td>
            {isEditing ? (
              <S.ButtonsBlock key="editing">
                <S.ButtonsWrapper>
                  <Button
                    color="action"
                    onClick={submitForm}
                    disabled={!values.sportsbookPlayer}
                  >
                    <FontAwesomeIcon icon={faSave} />
                    {t('save')}
                  </Button>
                  <Button
                    color="action"
                    onClick={handleDeleteMapping}
                    disabled={!values.sportsbookPlayer}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    {t('delete')}
                  </Button>
                </S.ButtonsWrapper>
              </S.ButtonsBlock>
            ) : (
              <S.ButtonsBlock key="normal">
                <Button color="action" onClick={handleEdit}>
                  <FontAwesomeIcon icon={faEdit} />
                  {t('edit')}
                </Button>
              </S.ButtonsBlock>
            )}
          </S.Td>
        </S.Tr>
      ) : (
        <S.Tr>
          <S.Td weight={45}>{player.name}</S.Td>
          <S.SelectTd weight={45}>
            {playerSelect}
            <Button color="action" onClick={showCreatePlayerPanel}>
              <LargePlus />
            </Button>
          </S.SelectTd>
          <S.Td weight={10} textAlign={'right'}>
            <Button onClick={submitForm} disabled={!values.sportsbookPlayer}>
              {t('map')}
            </Button>
          </S.Td>
        </S.Tr>
      )}
      {isCreatePlayerPanelVisible && (
        <PlayerCreatePanel
          onClose={hideCreatePlayerPanel}
          onSuccess={onPlayerCreated}
        />
      )}
    </FormikProvider>
  );
};
