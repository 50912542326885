import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash-es';
import { Event } from 'sportsbook-openapi-react';

import { useUpdateEvent } from 'hooks/events';

import { getEventsEntries } from 'app/providers/EntitiesProvider/events';

import { getDependenciesLists } from './getDependenciesLists';

export const useEventDependenciesUpdate = () => {
  const events = useSelector(getEventsEntries);
  const updateEvent = useUpdateEvent();

  const updateEventDependencies = useCallback(
    (
      eventId: Event['id'],
      {
        idsToAdd = [],
        idsToRemove = [],
        dependentTournaments = [],
      }: {
        idsToRemove?: Event['dependentEvents'];
        idsToAdd?: Event['dependentEvents'];
        dependentTournaments?: Event['dependentTournaments'];
      },
    ) => {
      const event = events[eventId];

      const dependentEvents = uniq([...event.dependentEvents, ...idsToAdd])
        .filter(id => id !== eventId)
        .filter(id => !idsToRemove.includes(id));

      updateEvent({
        ...event,
        dependentEvents,
        dependentTournaments: uniq([...dependentTournaments]),
      });
    },
    [events, updateEvent],
  );

  return useCallback(
    (
      currentEvent: Event,
      dependentEvents: Event['id'][],
      dependentTournaments: Event['id'][],
    ) => {
      const { removedIds, dependentIds } = getDependenciesLists(
        currentEvent.dependentEvents,
        dependentEvents,
      );

      /**
       * Remove current eventId from other tournaments
       */
      removedIds.forEach(dependentEventId => {
        updateEventDependencies(dependentEventId, {
          idsToRemove: [currentEvent.id],
        });
      });

      /**
       * Update currently selected events
       * Add current event id and avoid dependency of event to itself
       */
      dependentIds.forEach(dependentEventId => {
        updateEventDependencies(dependentEventId, {
          idsToAdd: [currentEvent.id, ...dependentIds],
        });
      });

      /**
       * Update the main event
       */
      updateEventDependencies(currentEvent.id, {
        idsToRemove: removedIds,
        idsToAdd: dependentIds,
        dependentTournaments,
      });
    },
    [updateEventDependencies],
  );
};
