import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Player as PlayerType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';
import { getCompetitorsEntries } from 'app/providers/EntitiesProvider/competitors';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import { FormValues, validationSchema } from './constants';
import { PlayerForm } from './PlayerForm';

interface Props {
  id: number;
  onClose: () => void;
}

export const PlayerEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const player: PlayerType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.PLAYERS, id),
  );
  const competitors = useSelector(getCompetitorsEntries);
  const sports = useSelector(getSportsEntries);

  useEffect(() => {
    dispatch(
      actionsNT.competitorsFetchItems({
        ids: player.competitors,
        limit: player.competitors.length,
        withPagination: true,
      }),
    );
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch, player]);

  useRequestState(Entities.PLAYERS, 'update', onClose);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.playersUpdate({
        playerId: player.id!,
        playerWithOutId: {
          name: values.name,
          competitors: values.competitors.map(competitor => competitor.id),
          translations: [],
        },
      }),
    );
  };

  const changePlayerStatus = () => {
    dispatch(
      actionsNT.playersUpdate({
        playerId: player?.id!,
        playerWithOutId: {
          ...player!,
          disabled: !player?.disabled,
        },
      }),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={{
        name: player.name!,
        competitors: player.competitors
          .filter(competitor => !!competitors[competitor])
          .map(competitorId => {
            const competitor = competitors[competitorId];
            const sportName = sports[competitor?.sportId]
              ? ` (${sports[competitor?.sportId].name})`
              : '';
            return {
              ...competitor,
              name: `${competitor?.name}${sportName}`,
            };
          }),
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.player')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
              leftButtonText={player?.disabled ? t('enable') : t('disable')}
              onLeftButtonClick={changePlayerStatus}
            />
          }
        >
          <PlayerForm id={id} actionType="edit" />
        </Panel>
      )}
    </Formik>
  );
};
