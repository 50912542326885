import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 56px 40px 56px minmax(0, 1fr);
`;

export const Heading = styled.span`
  display: grid;
  grid-template-columns: auto 1fr 200px;
  grid-column-gap: 15px;
  padding: 0 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 56px;
  align-items: center;
  color: #000000;
`;

export const BackButton = styled.div`
  cursor: pointer;
`;

export const ControlsWrapper = styled.div`
  padding: 12px 20px;
  display: grid;
  align-items: center;
  grid-template-columns: min-content 150px 300px 200px 200px;
  grid-column-gap: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  white-space: nowrap;
  border-bottom: 1px solid ${p => p.theme.colors.primary[40]};
`;

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  column-gap: 10px;

  > input {
    cursor: pointer;
  }
`;
