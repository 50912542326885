import styled from 'styled-components';

import { StyledSpanProps } from './Span.types';

export const StyledSpan = styled.span<StyledSpanProps>`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize}px;
  line-height: ${props => props.lineHeight};
  color: ${props => props.color};
`;
