import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { MatchType, MatchTypeWithOutId } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';

import { validationSchema } from './constants';
import { MatchTypeForm } from './MatchTypeForm';

interface Props {
  onClose: () => void;
  onSuccess?: (sport: MatchType) => void;
  sports?: number[];
}

export const MatchTypeCreatePanel = ({
  onClose,
  onSuccess,
  sports = [],
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleMatchTypeCreated = (result: MatchType) => {
    if (onSuccess) onSuccess(result);
    onClose();
  };

  useRequestState(Entities.MATCH_TYPES, 'create', handleMatchTypeCreated);

  const onSubmit = (values: MatchTypeWithOutId) => {
    dispatch(
      actionsNT.matchTypesCreate({
        matchTypeWithOutId: {
          name: values.name,
          sports: values.sports,
          translations: [],
          code: values.code!,
        },
      }),
    );
  };

  return (
    <Formik<MatchTypeWithOutId>
      initialValues={{
        name: '',
        code: '',
        sports,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.create.matchType')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <MatchTypeForm />
        </Panel>
      )}
    </Formik>
  );
};
