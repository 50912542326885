import styled from 'styled-components';

export const TablesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 2fr;
  grid-template-rows: 1fr;
  overflow: hidden;
`;

export const TableWrapper = styled.div`
  padding: 20px;
  display: grid;
  grid-row-gap: 16px;
  max-height: 100%;
  overflow: auto;
  grid-auto-rows: min-content;

  &:nth-of-type(1) {
    padding-left: 0;
  }
  &:nth-of-type(3) {
    padding-right: 0;
  }
`;

export const TableTitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
`;

export const Spacer = styled.div`
  border-right: 1px solid ${p => p.theme.colors.primary[40]};
`;

export const NoWrapLabel = styled.label`
  white-space: nowrap;
  display: block;
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
  box-sizing: content-box;
`;

export const ThinCell = styled.div`
  padding: 12px 8px;
`;

export const ThinHeader = styled.div`
  padding: 0 8px;
`;

export const MappingValue = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const PlayersMappingCell = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 5px;
`;

export const StyledSelect = styled.div`
  width: 150px;
`;
