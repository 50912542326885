import { getHomeAwayCompetitors } from 'app/pages/Events/utils';
import { Event } from 'sportsbook-openapi-react';

export interface FormValues {
  events: Event[];
  mainEvent: Event;
}

export const getEventName = (event: Event) => {
  const { homeCompetitor, awayCompetitor } = getHomeAwayCompetitors(event);
  return `${homeCompetitor?.name} - ${awayCompetitor?.name}`;
};
