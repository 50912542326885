/**
 *
 * Outcomes
 *
 */

import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

import { Panel } from './components/Panel';

export function Outcomes() {
  return (
    <>
      <Helmet>
        <title>Outcomes</title>
        <meta name="description" content="Description of Outcomes" />
      </Helmet>
      <Panel />
      <Outlet />
    </>
  );
}
