import styled from 'styled-components';

export const Wrapper = styled.div`
  grid-column: 1/3;

  display: grid;
  grid-template-rows: 56px minmax(0, 1fr);
`;

export const DropdownWrapper = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  border-bottom: 1px solid ${p => p.theme.colors.primary[40]};
`;

export const ContentWrapper = styled.div`
  padding: 20px;
  display: grid;
  grid-template-rows: 32px minmax(0, 1fr);
  grid-row-gap: 20px;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: max-content 300px;
  grid-column-gap: 20px;
`;
