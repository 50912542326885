import { StyledSpan } from './Text.styles';
import { TextProps } from './Text.types';

export function Text({
  size = 'm',
  weight = 400,
  height = 1.3,
  textColor = 100,
  ...props
}: TextProps) {
  return (
    <StyledSpan
      size={size}
      weight={weight}
      height={height}
      textColor={textColor}
      {...props}
    />
  );
}
