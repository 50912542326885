import { keyBy } from 'lodash-es';

import { AdapterEntity } from './types';

export const toIdsArray = <T extends { id?: any }>(items: T[]) =>
  items.map(item => item.id);
export const toIdsMap = <T extends { id?: any }>(items: T[]) =>
  keyBy(items, 'id');

export const getAdapterEntityItems = <T>(entity: AdapterEntity<T>): T[] =>
  entity.sortedIds.map(id => entity.entries[id]);
