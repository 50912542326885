import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { Entities } from 'app/providers/EntitiesProvider/types';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getMarketsSubstate = (state: RootState) =>
  getEntities(state)[Entities.MARKETS];

export const getMarketsEntries = (state: RootState) =>
  getMarketsSubstate(state).entries;

export const getMarketsHasMore = (state: RootState) =>
  getMarketsSubstate(state).hasMore;

export const selectMarketsItems = createSelector(getMarketsSubstate, markets =>
  getEntityItems(markets),
);
