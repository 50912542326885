import { createElement, FunctionComponent, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { Audit } from './AuditPanel';
import { getAuditPanel } from './selectors';

export const AuditProvider = ({ children }: PropsWithChildren) => {
  const panel = useSelector(getAuditPanel);

  return (
    <>
      {!!panel &&
        createElement(Audit as FunctionComponent<any>, {
          ...panel,
        })}
      {children}
    </>
  );
};
