import { PayloadAction } from '@reduxjs/toolkit';
import { EventStatus } from 'sportsbook-openapi-react';

const reducer = {
  eventStatusByCustomerFetchItem() {},
  eventStatusByCustomerFetchItemSuccess(
    state,
    action: PayloadAction<{
      status: EventStatus;
      eventId: number;
    }>,
  ) {
    const { status, eventId } = action.payload;
    state.events.entries[eventId].status = status;
  },
  eventStatusByCustomerFetchItemError() {},
  eventStatusByCustomerUpdate() {},
  eventStatusByCustomerUpdateSuccess(
    state,
    action: PayloadAction<{
      status: EventStatus;
      eventId: number;
    }>,
  ) {
    const { status, eventId } = action.payload;
    state.events.entries[eventId].status = status;
  },
  eventStatusByCustomerUpdateError() {},
};

export default reducer;
