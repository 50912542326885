/**
 *
 * CrossMappings
 *
 */

import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

import { CrossMappingsPanel } from './components/Panel';

export function CrossMapping() {
  return (
    <>
      <Helmet>
        <title>Cross mapping</title>
        <meta name="cross-mappings" content="Cross-mappings" />
      </Helmet>
      <CrossMappingsPanel />
      <Outlet />
    </>
  );
}
