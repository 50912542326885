import { matchTypesApi } from 'app/api';

const api = {
  endpoint: matchTypesApi,
  methods: {
    fetchItems: 'getMatchTypes',
    fetchItem: 'getMatchType',
    create: 'createMatchType',
    update: 'updateMatchType',
    delete: 'deleteMatchType',
  },
};

export default api;
