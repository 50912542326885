import { FC, useRef } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useBooleanState from 'hooks/useBooleanState';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import * as S from './SelectCell.styles';
import { SelectCellProps } from './SelectCell.types';
import { getPopupPosition } from './SelectCell.utils';

export const SelectCell: FC<SelectCellProps> = ({
  value,
  options,
  onChange,
}) => {
  const [isOpen, , close, toggle] = useBooleanState();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const selectedOption = options.find(option => option.id === value);

  const popupPosition = getPopupPosition(wrapperRef);

  useOnClickOutside(wrapperRef, close);

  return (
    <S.Wrapper ref={wrapperRef}>
      <S.LabelWrapper onClick={toggle}>
        <div>{selectedOption?.name}</div>
        <S.IconWrapper>
          <FontAwesomeIcon icon={faChevronDown} size="xs" />
        </S.IconWrapper>
      </S.LabelWrapper>

      {isOpen && (
        <S.OptionsWrapper extraStyles={popupPosition}>
          {options.map(option => (
            <S.OptionWrapper
              key={option.id}
              onClick={ev => {
                ev.stopPropagation();
                onChange(option.id);
                close();
              }}
            >
              {option.name}
            </S.OptionWrapper>
          ))}
        </S.OptionsWrapper>
      )}
    </S.Wrapper>
  );
};
