import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Event, EventStatus } from 'sportsbook-openapi-react';

import { useUpdateEvent } from 'hooks/events';
import { TestIds } from 'types/testIds.types';

import { Button, Popup } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

import * as S from './StatusPopup.styles';

interface Props {
  event: Event;
}

const adminStatuses = [
  EventStatus.OPEN,
  EventStatus.CLOSED,
  EventStatus.SUSPENDED,
  EventStatus.PLANNING,
  EventStatus.CANCELLED,
  EventStatus.TRADING,
  EventStatus.ENDED,
  EventStatus.RETIRED,
  EventStatus.INTERRUPTED,
];

const customerStatuses = [
  EventStatus.OPEN,
  EventStatus.SUSPENDED,
  EventStatus.TRADING,
];

export const StatusPopup: FC<Props> = ({ event }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isCustomer, customerId } = useUserStore(getIsCustomer);
  const updateEvent = useUpdateEvent();
  const { status } = event;

  useEffect(() => {
    if (isCustomer && customerId) {
      dispatch(
        actionsNT.eventStatusByCustomerFetchItem({
          eventId: event.id,
          customerId,
        }),
      );
    }
  }, [customerId, dispatch, event.id, isCustomer]);

  const handleSuspendClick = () => {
    if (isCustomer && customerId) {
      dispatch(
        actionsNT.eventStatusByCustomerUpdate({
          eventId: event.id,
          customerId,
          eventStatusWrapper: { status: EventStatus.SUSPENDED },
        }),
      );
    } else {
      updateEvent({
        ...event,
        status: EventStatus.SUSPENDED,
      });
    }
  };

  const handleOpenClick = () => {
    if (isCustomer && customerId) {
      dispatch(
        actionsNT.eventStatusByCustomerUpdate({
          eventId: event.id,
          customerId,
          eventStatusWrapper: { status: EventStatus.OPEN },
        }),
      );
    } else {
      updateEvent({
        ...event,
        status: EventStatus.OPEN,
      });
    }
  };

  const handleTradingClick = () => {
    if (isCustomer && customerId) {
      dispatch(
        actionsNT.eventStatusByCustomerUpdate({
          eventId: event.id,
          customerId,
          eventStatusWrapper: { status: EventStatus.TRADING },
        }),
      );
    } else {
      updateEvent({
        ...event,
        status: EventStatus.TRADING,
      });
    }
  };

  const handleCloseClick = () => {
    if (isCustomer && customerId) {
      dispatch(
        actionsNT.eventStatusByCustomerUpdate({
          eventId: event.id,
          customerId,
          eventStatusWrapper: { status: EventStatus.CLOSED },
        }),
      );
    } else {
      updateEvent({
        ...event,
        status: EventStatus.CLOSED,
      });
    }
  };

  const handleStatusClick = (status: EventStatus) => {
    switch (status) {
      case EventStatus.OPEN: {
        handleOpenClick();
        break;
      }
      case EventStatus.CLOSED: {
        handleCloseClick();
        break;
      }
      case EventStatus.SUSPENDED: {
        handleSuspendClick();
        break;
      }
      case EventStatus.TRADING: {
        handleTradingClick();
        break;
      }
      default: {
        updateEvent({
          ...event,
          status,
        });
      }
    }
  };

  return (
    <Popup
      button={
        <Button
          color="action"
          testId={TestIds.EventPageStatusButton}
          disabled={isCustomer && status === EventStatus.CLOSED}
        >
          {t(`statuses.labels.${status}`)}
          <FontAwesomeIcon icon={faAngleDown} color="000000" />
        </Button>
      }
      body={
        <S.Column>
          {(isCustomer ? customerStatuses : adminStatuses)
            .filter(s => s !== status)
            .map(s => (
              <S.Span
                key={s}
                data-test-id={`${TestIds.EventPageStatusOption}--${s}`}
                onClick={() => {
                  handleStatusClick(s);
                }}
              >
                {t(`statuses.actions.${s}`)}
              </S.Span>
            ))}
        </S.Column>
      }
    />
  );
};
