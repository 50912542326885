import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  Currency as CurrencyType,
  CurrencyWithOutId,
} from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { IS_DELETION_DISABLED } from 'consts';

import { TextInputField } from 'app/components/forms';
import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Span } from 'app/components/ui';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';

import { validationSchema } from './constants';
import * as S from './styles';

interface Props {
  id: number;
  onClose: () => void;
}

export const CurrencyEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currency: CurrencyType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.CURRENCIES, id),
  );

  useRequestState(Entities.CURRENCIES, 'update', onClose);

  const onSubmit = (values: CurrencyWithOutId) => {
    dispatch(
      actionsNT.currenciesUpdate({
        currencyId: currency.id,
        currencyWithOutId: {
          name: values.name,
          translations: [],
          symbol: values.symbol!,
          code: values.code!,
          numericCode: values.numericCode,
        },
      }),
    );
  };

  return (
    <Formik<CurrencyWithOutId>
      initialValues={{
        name: currency.name,
        symbol: currency.symbol,
        code: currency.code,
        numericCode: currency.numericCode,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.currency')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
              leftButtonText={t('delete')}
              leftButtonDisabled={IS_DELETION_DISABLED}
            />
          }
        >
          <S.FormBlock>
            <Span>{t('name')}</Span>
            <TextInputField name="name" readOnly />
            <Span>{t('symbol')}</Span>
            <TextInputField name="symbol" />
          </S.FormBlock>
        </Panel>
      )}
    </Formik>
  );
};
