import { customersApi } from 'app/api';

const api = {
  endpoint: customersApi,
  methods: {
    fetchItems: 'getCustomers',
    fetchItem: 'getCustomer',
    create: 'createCustomer',
    update: 'updateCustomer',
    delete: 'deleteCustomer',
  },
};

export default api;
