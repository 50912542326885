import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { MapEventForm } from 'app/pages/Sources/components';
import { getHomeAwayCompetitors } from 'app/pages/Sources/utils';
import dayjs from 'dayjs';
import { get, isEqual } from 'lodash-es';
import {
  AdapterCountry,
  AdapterEvent,
  AdapterSport,
  AdapterTournament,
  GetAdapterSportEventsSortDirectionEnum,
} from 'sportsbook-openapi-react';

import { getAdapterAsyncLoadOptions } from 'utils/select';
import { useLazyLoading } from 'hooks/useLazyLoading';

import { Checkbox } from 'app/components/forms';
import { AdapterOutcomesPanel } from 'app/components/panels';
import { Button, Dialog, LoadingIndicator, Table } from 'app/components/ui';
import {
  AsyncSelectTableFilter,
  SelectTableFilter,
} from 'app/components/ui/Table/components';

import {
  adapterActions,
  getAdapterCountriesEntries,
  getAdapterEventsHasMore,
  getAdapterEventsLoading,
  getAdapterEventsSortedIds,
  getAdapterSportsEntries,
  selectAdapterCountriesItems,
  selectAdapterEventsItems,
} from 'app/providers/AdaptersProvider';

import { useUserStore } from 'store';
import { getIsOperator } from 'store/user';

import * as S from './AdaptersEventsList.styles';

interface Props {
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
  tableState: SourcesTableState;
  setTableState: Dispatch<SetStateAction<SourcesTableState>>;
  selectedAdapterEvent: Maybe<AdapterEvent>;
  setAdapterEvent: Dispatch<SetStateAction<Maybe<AdapterEvent>>>;
  isExcluded: boolean;
  isMapped: boolean;
  tab: string;
}

type MatchParams = {
  adapter: string;
};
enum SourceTabsEnum {
  UNMAPPED = 'tabs.unmapped',
  MAPPED = 'tabs.mapped',
  EXCLUDED = 'tabs.excluded',
}

interface SourcesTableState {
  selectedEvents: string[];
}

interface SourcesTableState {
  selectedEvents: string[];
}

const sourceTabToParams = {
  [SourceTabsEnum.MAPPED]: {
    mapped: true,
  },
  [SourceTabsEnum.UNMAPPED]: {
    mapped: false,
  },
  [SourceTabsEnum.EXCLUDED]: {
    excluded: true,
  },
};

interface Filters {
  sport?: Required<AdapterSport>;
  tournament?: AdapterTournament;
  scheduledFrom?: Date;
  scheduledTo?: Date;
  notBegan?: boolean;
  probsReceived?: boolean;
  country?: AdapterCountry;
  isOutright: boolean;
}

export const AdaptersEventsList: FC<Props> = ({
  filters,
  setFilters,
  tableState,
  setTableState,
  selectedAdapterEvent,
  setAdapterEvent,
  isExcluded,
  isMapped,
  tab,
}) => {
  const adapterSportsEntries = useSelector(getAdapterSportsEntries);
  const dispatch = useDispatch();
  const { adapter } = useParams<MatchParams>();
  const [excludeId, setExcludeId] = useState<string | undefined>();
  const [adapterOutcomesPanel, setAdapterOutcomesPanel] = useState<null | {
    adapterEventId: string;
    title: string;
  }>(null);

  if (!adapter) {
    throw new Error('Provide adapter parameter');
  }
  const { t } = useTranslation();
  const countriesOptions = useSelector(selectAdapterCountriesItems);
  const entries = useSelector(getAdapterCountriesEntries);

  const data = useSelector(selectAdapterEventsItems);
  const loading = useSelector(getAdapterEventsLoading);
  const hasMore = useSelector(getAdapterEventsHasMore);
  const sortedIds = useSelector(getAdapterEventsSortedIds);
  const isOperator = useUserStore(getIsOperator);

  useEffect(() => {
    if (!isOperator) {
      dispatch(adapterActions.parametersFetch({ adapter }));
    }
    dispatch(adapterActions.sportsReset());
  }, [adapter, dispatch, isOperator]);

  useEffect(() => {
    dispatch(adapterActions.sportsFetch({ adapter }));
    dispatch(
      adapterActions.countriesFetch({
        adapter,
        limit: 1000,
        offset: 0,
      }),
    );
  }, [dispatch, adapter]);

  const handleCheckboxClick = useCallback(
    (id?: string) => (value: boolean) => {
      if (id) {
        if (value) {
          setTableState(prev => ({
            ...prev,
            selectedEvents: [...prev.selectedEvents, id],
          }));
        } else {
          setTableState(prev => ({
            ...prev,
            selectedEvents: prev.selectedEvents.filter(item => item !== id),
          }));
        }
      }
    },
    [setTableState],
  );

  const handleCheckAllClick = useCallback(() => {
    if (tableState.selectedEvents.length !== sortedIds.length) {
      setTableState({
        selectedEvents: sortedIds,
      });
    } else {
      setTableState({
        selectedEvents: [],
      });
    }
  }, [sortedIds, setTableState, tableState.selectedEvents.length]);

  const updateExcludedStatus = (id: string) => {
    dispatch(
      adapterActions.eventsUpdate({
        id,
        adapter,
        excludingRequest: {
          excluded: !isExcluded,
        },
      }),
    );
  };

  const handleExclude = () => {
    if (tableState.selectedEvents.length) {
      tableState.selectedEvents.forEach(updateExcludedStatus);
      setTableState({ selectedEvents: [] });
    } else if (excludeId) {
      updateExcludedStatus(excludeId!);
    }
  };

  const columns: Column<AdapterEvent>[] = useMemo(
    () => [
      {
        Header: () => (
          <Checkbox
            onChange={handleCheckAllClick}
            checked={tableState.selectedEvents.length === sortedIds.length}
            label={
              <>
                {t('id')}({tableState.selectedEvents.length})
              </>
            }
          />
        ),
        accessor: 'id',
        Cell: ({ value }) => (
          <Checkbox
            onChange={handleCheckboxClick(value)}
            checked={tableState.selectedEvents.includes(value!)}
            label={value}
          />
        ),
      },
      {
        Header: t('sport type').toString(),
        id: 'sportType',
        accessor: 'tournament',
        Cell: ({ value }) => (
          <>{get(adapterSportsEntries, `[${value?.sportId}].name`)}</>
        ),
      },
      {
        Header: t('start date').toString(),
        accessor: 'scheduled',
        Cell: ({ value }) => <>{dayjs(value).format('HH:mm DD.MM.YYYY')}</>,
      },
      {
        Header: props => (
          <AsyncSelectTableFilter
            loadOptions={getAdapterAsyncLoadOptions({
              entity: 'tournaments',
              adapter,
              sportId: filters.sport?.id,
            })}
            {...props}
          />
        ),
        accessor: 'tournament',
        Cell: ({ value }) => <>{value?.name}</>,
      },
      {
        Header: props => (
          <SelectTableFilter options={countriesOptions} {...props} />
        ),
        id: 'country',
        accessor: 'tournament',
        Cell: ({ value }) => (
          <>
            {(value?.countryId && entries[value.countryId]?.name) ??
              value?.countryId}
          </>
        ),
      },
      {
        Header: `${t('competitor')} 1`,
        accessor: 'competitors',
        id: 'homeCompetitor',
        Cell: ({ row: { original: data } }) => {
          const { homeCompetitor } = getHomeAwayCompetitors(data);
          return <>{homeCompetitor?.name}</>;
        },
      },
      {
        Header: `${t('competitor')} 2`,
        accessor: 'competitors',
        id: 'awayCompetitor',
        Cell: ({ row: { original: data } }) => {
          const { awayCompetitor } = getHomeAwayCompetitors(data);
          return <>{awayCompetitor?.name}</>;
        },
      },
      {
        Header: t('outcomes').toString(),
        id: 'outcomes',
        Cell: ({ row: { original: data } }) => {
          const { homeCompetitor, awayCompetitor } =
            getHomeAwayCompetitors(data);
          return (
            <Button
              color="link"
              onClick={event => {
                event.stopPropagation();
                setAdapterOutcomesPanel({
                  adapterEventId: data.id!,
                  title: `${t(
                    'event',
                  )} ${homeCompetitor?.name} ${awayCompetitor?.name}`,
                });
              }}
            >
              {t('outcomes')}
            </Button>
          );
        },
      },
      ...(isMapped
        ? [
            {
              Header: '',
              id: 'actions',
              Cell: ({ row: { original: data } }) => (
                <Button
                  color="link"
                  onClick={event => {
                    event.stopPropagation();
                    dispatch(
                      adapterActions.eventsDeleteMapping({
                        id: data.id!,
                        adapter,
                      }),
                    );
                  }}
                >
                  {t('remove mapping')}
                </Button>
              ),
            },
          ]
        : []),
      {
        Header: '',
        id: 'exclude_actions',
        accessor: 'id',
        Cell: ({ value }) => {
          const isDisabled =
            tableState.selectedEvents.length > 0 &&
            !tableState.selectedEvents.includes(value!);
          return (
            <Button
              color="link"
              disabled={isDisabled}
              onClick={event => {
                event.stopPropagation();
                setExcludeId(value);
              }}
            >
              {t(isExcluded ? 'return' : 'exclude')}
            </Button>
          );
        },
      },
    ],
    [
      t,
      isMapped,
      handleCheckAllClick,
      tableState.selectedEvents,
      sortedIds.length,
      handleCheckboxClick,
      adapterSportsEntries,
      adapter,
      filters.sport?.id,
      countriesOptions,
      entries,
      dispatch,
      isExcluded,
    ],
  );

  const onLazyLoad = useCallback(
    ({ limit, offset }) => {
      dispatch(
        adapterActions.eventsFetch({
          limit,
          offset,
          adapter,
          ...sourceTabToParams[tab],
          ...filters,
          sportId: filters.sport?.id ? String(filters.sport?.id) : undefined,
          tournamentId: filters.tournament?.id
            ? String(filters.tournament?.id)
            : undefined,
          countryId: filters.country?.id
            ? String(filters.country?.id)
            : undefined,
          sortDirection: GetAdapterSportEventsSortDirectionEnum.ASC,
        }),
      );
    },
    [dispatch, adapter, tab, filters],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore: hasMore && !loading,
    extraDeps: [filters, tab],
    onPaginationReset: () => {
      dispatch(adapterActions.eventsReset());
    },
  });

  const handleRowClick = useCallback(
    event => {
      if (isEqual(event, selectedAdapterEvent)) {
        setAdapterEvent(null);
      } else {
        setAdapterEvent(event);
      }
    },
    [selectedAdapterEvent, setAdapterEvent],
  );

  return !data?.length && loading ? (
    <S.SourcesListLoading>
      <LoadingIndicator />
    </S.SourcesListLoading>
  ) : (
    <>
      <S.TableWrapper>
        <Table
          data={data}
          columns={columns}
          onBottom={onBottom}
          onRowClick={handleRowClick}
          isSelected={cell => {
            return cell.id === selectedAdapterEvent?.id;
          }}
          filters={{
            value: filters,
            onFilterChange: setFilters,
          }}
          columnsWidth={[10, 15, 10, 15, 10, 15, 15, 'fit-content']}
        />
        {loading && (
          <S.TableLoading>
            <LoadingIndicator />
          </S.TableLoading>
        )}
      </S.TableWrapper>
      {selectedAdapterEvent && (
        <MapEventForm
          mapped={isMapped}
          adapter={adapter}
          adapterEvent={selectedAdapterEvent}
          onMap={() => {
            setAdapterEvent(null);
          }}
        />
      )}
      {excludeId && (
        <Dialog
          text={t('updateExclusionDialog', {
            action: t(isExcluded ? 'include' : 'exclude').toLowerCase(),
          })}
          onClose={() => setExcludeId(undefined)}
          onConfirm={handleExclude}
        />
      )}
      {!!adapterOutcomesPanel && (
        <AdapterOutcomesPanel
          adapter={adapter}
          adapterEventId={adapterOutcomesPanel.adapterEventId}
          title={adapterOutcomesPanel.title}
          onClose={() => {
            setAdapterOutcomesPanel(null);
          }}
        />
      )}
    </>
  );
};
