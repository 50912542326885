import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Formik, FormikProps } from 'formik';
import {
  Competitor,
  Country,
  Event,
  EventKind,
  EventSource,
  EventStatus,
  Group,
  MatchType,
  Sport,
  Tournament,
} from 'sportsbook-openapi-react';

import { getDateFromDateAndTime } from 'utils/datetime';
import { getAsyncLoadOptions } from 'utils/select';
import {
  optionValidationSchema,
  requiredDateValidationSchema,
  stringValidationSchema,
  yup,
} from 'utils/validation';
import { MAIN_MATCH_TYPE_ID } from 'hooks/events';
import useBooleanState from 'hooks/useBooleanState';
import { FETCH_ALL_LIMIT } from 'consts';
import { TestIds } from 'types/testIds.types';

import {
  AsyncSelectField,
  DatePicker,
  SelectField,
  TextInput,
} from 'app/components/forms';
import {
  CompetitorCreatePanel,
  GroupCreatePanel,
  MatchTypeCreatePanel,
  SportCreatePanel,
  TournamentCreatePanel,
} from 'app/components/panels';
import { Button, LoadingIndicator, TextArea } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import {
  getEventsCreate,
  getEventsUpdate,
} from 'app/providers/EntitiesProvider/events';
import {
  getGroupsEntries,
  selectGroupsItems,
} from 'app/providers/EntitiesProvider/groups';
import { selectMatchTypesItems } from 'app/providers/EntitiesProvider/matchTypes';
import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';

import { useUserStore } from 'store';
import { getIsCustomer, getIsOperator } from 'store/user';

import * as S from './EventFormik.styles';

const initialValues = {
  sport: null as Sport | null,
  country: null as Country | null,
  group: null as Group | null,
  date: null as Date | null,
  time: null as Date | null,
  location: '',
  tournament: null as Tournament | null,
  competitorA: null as Competitor | null,
  competitorB: null as Competitor | null,
  note: '',
  status: { id: EventStatus.OPEN, name: EventStatus.OPEN },
  matchType: null as MatchType | null,
  kind: { id: EventKind.GENERAL, name: EventKind.GENERAL },
};
type Values = typeof initialValues & {
  date: Date | null;
  time: Date | null;
};

const eventsListFiltersValidationSchema = yup.object().shape({
  sport: optionValidationSchema,
  group: optionValidationSchema,
  date: requiredDateValidationSchema,
  time: requiredDateValidationSchema,
  tournament: optionValidationSchema,
  competitorA: optionValidationSchema,
  competitorB: optionValidationSchema,
  note: stringValidationSchema,
  kind: optionValidationSchema,
});

type EventFormikProps = {
  event?: Event;
  initialEvent?: Partial<Values>;
  type?: 'create' | 'edit';
};

export const WITHOUT_COMPETITOR_OPTION_ID = -1;

export const EventFormik: FC<EventFormikProps> = ({
  event,
  type,
  initialEvent = {},
}) => {
  const [isEventStateReset, resetEventState] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    loading: createLoading,
    error: createError,
    result: createdEvent,
  } = useSelector(getEventsCreate);

  const {
    loading: updateLoading,
    error: updateError,
    result: updatedEvent,
  } = useSelector(getEventsUpdate);

  useEffect(() => {
    dispatch(actionsNT.eventsUpdateReset());
    dispatch(actionsNT.eventsCreateReset());
    resetEventState(true);
  }, [dispatch]);

  useEffect(() => {
    if (
      isEventStateReset &&
      !createLoading &&
      !updateLoading &&
      !createError &&
      !updateError &&
      (createdEvent || updatedEvent)
    ) {
      const { id } = createdEvent || updatedEvent;

      navigate(`/events/${id}`);
    }
  }, [
    createLoading,
    createError,
    updateLoading,
    updateError,
    createdEvent,
    updatedEvent,
    isEventStateReset,
    navigate,
  ]);

  return (
    <Formik<Values>
      enableReinitialize
      initialValues={{ ...initialValues, ...initialEvent }}
      validationSchema={eventsListFiltersValidationSchema}
      onSubmit={(
        {
          tournament,
          group,
          competitorA,
          competitorB,
          date,
          time,
          note,
          status,
          matchType,
          kind,
        },
        { setStatus, setSubmitting },
      ) => {
        setSubmitting(true);
        setStatus({ errorMessage: undefined });

        const competitors = [competitorA!.id];
        if (competitorB!.id !== WITHOUT_COMPETITOR_OPTION_ID) {
          competitors.push(competitorB!.id);
        }

        const eventData = {
          ...(event
            ? { ...event, scheduledLive: new Date(event.eventDate) }
            : { mainSource: EventSource.MODEL }),
          tournamentId: +tournament?.id!,
          groupId: +group?.id!,
          live: !!event?.live,
          eventDate: getDateFromDateAndTime(date, time),
          competitors,
          note,
          status: status.id,
          matchTypeId: matchType?.id!,
          translations: [],
          homeCompetitors: [competitorA!.id],
          dependentEvents: event?.dependentEvents ?? [],
          dependentTournaments: event?.dependentTournaments ?? [],
          resulted: false,
          kind: kind.id,
        };

        if (type === 'edit' && event) {
          dispatch(
            actionsNT.eventsUpdate({
              eventId: event.id,
              eventWithOutId: eventData,
            }),
          );
        } else {
          dispatch(
            actionsNT.eventsCreate({
              eventWithOutId: eventData,
            }),
          );
        }

        setSubmitting(false);
      }}
    >
      {formikProps => (
        <EventForm
          {...formikProps}
          type={type}
          loading={createLoading || updateLoading}
        />
      )}
    </Formik>
  );
};

type EventFormProps = {
  type?: 'create' | 'edit';
  loading?: boolean;
};

const statusOptions = Object.values(EventStatus).map(status => ({
  name: status,
  id: status,
}));

const kindOptions = Object.values(EventKind).map(kind => ({
  name: kind,
  id: kind,
}));

export const EventForm: FC<FormikProps<Values> & EventFormProps> = ({
  touched,
  setFieldTouched,
  values,
  errors,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  resetForm,
  type,
  loading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isCustomer } = useUserStore(getIsCustomer);
  const isOperator = useUserStore(getIsOperator);
  const [
    isCreateTournamentVisible,
    showCreateTournament,
    hideCreateTournament,
  ] = useBooleanState();
  const [isCreateMatchTypeVisible, showCreateMatchType, hideCreateMatchType] =
    useBooleanState();

  const [isCreateGroupVisible, showCreateGroup, hideCreateGroup] =
    useBooleanState();

  const [
    isCreateCompetitorAVisible,
    showCreateCompetitorA,
    hideCreateCompetitorA,
  ] = useBooleanState();

  const [
    isCreateCompetitorBVisible,
    showCreateCompetitorB,
    hideCreateCompetitorB,
  ] = useBooleanState();

  const [
    isCreateSportPanelVisible,
    showCreateSportPanel,
    hideCreateSportPanel,
  ] = useBooleanState();

  const sportItems = useSelector(selectSportsItems);
  const groupItems = useSelector(selectGroupsItems);
  const groupEntries = useSelector(getGroupsEntries);
  const matchTypeItems = useSelector(selectMatchTypesItems);

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.countriesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  useEffect(() => {
    if (values.sport?.id) {
      dispatch(
        actionsNT.groupsFetchItems({
          sportId: +values.sport.id,
          limit: FETCH_ALL_LIMIT,
        }),
      );
      dispatch(
        actionsNT.matchTypesFetchItems({
          sportId: +values.sport.id,
          limit: FETCH_ALL_LIMIT,
        }),
      );
    }
  }, [values.sport, dispatch, setFieldValue]);

  useEffect(() => {
    if (values.tournament?.id) {
      setFieldValue('group', groupEntries[values.tournament.groupId]);
    }
  }, [values.tournament, setFieldValue, groupEntries]);

  useEffect(() => {
    if (values.sport?.id) {
      if (values.matchType?.sports.includes(values.sport.id)) {
      } else {
        const matchType = matchTypeItems[MAIN_MATCH_TYPE_ID];
        if (matchType) {
          setFieldValue('matchType', matchTypeItems[matchType.id]);
        }
      }
    }
  }, [setFieldValue, values.sport, values.matchType, matchTypeItems]);

  const handleSportSelect = (selectedOption: Sport) => {
    setFieldValue('sport', selectedOption);
  };

  const handleGroupSelect = (selectedOption: Group) => {
    setFieldValue('group', selectedOption);
  };

  const handleTournamentSelect = (selectedOption: Tournament) => {
    setFieldValue('tournament', selectedOption);
  };

  const handleCompetitorASelect = (selectedOption: Competitor) => {
    setFieldValue('competitorA', selectedOption);
  };

  const handleCompetitorBSelect = (selectedOption: Competitor) => {
    setFieldValue('competitorB', selectedOption);
  };

  const handleMatchTypeSelect = (selectedOption: MatchType) => {
    setFieldValue('matchType', selectedOption);
  };

  const createSportOption: any = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreateSportPanel,
  };

  const createGroupOption: any = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreateGroup,
  };

  const createTournamentOption = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreateTournament,
  };

  const createCompetitorAOption = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreateCompetitorA,
  };

  const createCompetitorBOption = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreateCompetitorB,
  };

  const createMatchTypeOption: any = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreateMatchType,
  };

  const sportsOptions =
    isCustomer || isOperator ? sportItems : [createSportOption, ...sportItems];

  const groupsOptions =
    isCustomer || isOperator ? groupItems : [createGroupOption, ...groupItems];

  const matchTypeOptions = isCustomer
    ? matchTypeItems
    : [createMatchTypeOption, ...matchTypeItems];

  const withoutCompetitorOption = {
    name: t('without competitor'),
    id: WITHOUT_COMPETITOR_OPTION_ID,
  };

  return (
    <S.EventForm onSubmit={handleSubmit}>
      <S.EventFormBlock area={'sport'}>
        <S.CreateEventSpan>{t('sport type')}</S.CreateEventSpan>
        <SelectField
          name="sport"
          options={sportsOptions}
          size="m"
          testId={TestIds.EventFormSportSelect}
        />
      </S.EventFormBlock>

      <S.EventFormBlock area={'group'}>
        <S.CreateEventSpan>{t('group')}</S.CreateEventSpan>
        <SelectField
          name="group"
          options={groupsOptions}
          disabled={!values.sport?.id}
          size="m"
          testId={TestIds.EventFormGroupSelect}
        />
      </S.EventFormBlock>

      <S.CreateEventDateTime>
        <S.EventFormBlock>
          <S.CreateEventSpan>{t('date')}</S.CreateEventSpan>
          <DatePicker
            onChange={value => setFieldValue('date', value)}
            selected={values.date}
            dateFormat="dd/MM/yyyy"
            customInput={
              <TextInput
                size="m"
                icon={<FontAwesomeIcon icon={faCalendar} />}
                invalid={!!touched.date && !!errors.date}
              />
            }
            onBlur={() => setFieldTouched('date')}
            testId={TestIds.EventFormDateDatepicker}
          />
        </S.EventFormBlock>

        <S.EventFormBlock>
          <S.CreateEventSpan>{t('time')}</S.CreateEventSpan>
          <DatePicker
            onChange={value => setFieldValue('time', value)}
            selected={values.time}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={t('time')}
            dateFormat="HH:mm"
            customInput={
              <TextInput
                size="m"
                icon={<FontAwesomeIcon icon={faCalendar} />}
                invalid={!!touched.time && !!errors.time}
              />
            }
            onBlur={() => setFieldTouched('time')}
            testId={TestIds.EventFormTimeDatepicker}
          />
        </S.EventFormBlock>
      </S.CreateEventDateTime>

      <S.EventFormBlock area={'tournament'}>
        <S.CreateEventSpan>{t('tournament')}</S.CreateEventSpan>
        <AsyncSelectField
          name="tournament"
          loadOptions={getAsyncLoadOptions({
            entity: 'tournaments',
            sportId: values.sport?.id,
            groupId: values.group?.id,
            disabled: false,
            createOption: isCustomer ? undefined : createTournamentOption,
          })}
          cacheUniqs={[values.sport, values.group]}
          disabled={!values.sport?.id}
          size="m"
          testId={TestIds.EventFormTournamentSelect}
        />
      </S.EventFormBlock>

      <S.EventFormBlock area={'competitorA'}>
        <S.CreateEventSpan>{t('homeCompetitor')}</S.CreateEventSpan>
        <AsyncSelectField
          name="competitorA"
          cacheUniqs={[values.competitorB]}
          loadOptions={getAsyncLoadOptions({
            entity: 'competitors',
            sportId: values.sport?.id,
            createOption: isCustomer ? undefined : createCompetitorAOption,
            excludeOptions: [values.competitorB],
            disabled: false,
          })}
          disabled={!values.sport}
          size="m"
          testId={TestIds.EventFormHomeCompetitorSelect}
        />
      </S.EventFormBlock>

      <S.EventFormBlock area={'competitorB'}>
        <S.CreateEventSpan>{t('awayCompetitor')}</S.CreateEventSpan>
        <AsyncSelectField
          name="competitorB"
          cacheUniqs={[values.competitorA]}
          loadOptions={getAsyncLoadOptions({
            entity: 'competitors',
            sportId: values.sport?.id,
            disabled: false,
            createOption: isCustomer ? undefined : createCompetitorBOption,
            emptyOption: withoutCompetitorOption,
            excludeOptions: [values.competitorA],
          })}
          disabled={!values.sport?.id}
          size="m"
          testId={TestIds.EventFormAwayCompetitorSelect}
        />
      </S.EventFormBlock>

      <S.EventFormBlock area={'status'}>
        <S.CreateEventSpan>{t('status')}</S.CreateEventSpan>
        <SelectField
          name="status"
          options={statusOptions}
          size="m"
          testId={TestIds.EventFormStatusSelect}
        />
      </S.EventFormBlock>

      <S.EventFormBlock area={'matchType'}>
        <S.CreateEventSpan>{t('match type')}</S.CreateEventSpan>
        <SelectField
          name="matchType"
          options={matchTypeOptions}
          disabled={!values.sport?.id}
          size="m"
          testId={TestIds.EventFormMatchTypeSelect}
        />
      </S.EventFormBlock>

      <S.EventFormBlock area={'kind'}>
        <S.CreateEventSpan>{t('match kind')}</S.CreateEventSpan>
        <SelectField
          name="kind"
          options={kindOptions}
          size="m"
          testId={TestIds.EventFormKindSelect}
        />
      </S.EventFormBlock>

      <S.EventFormBlock area={'note'}>
        <S.CreateEventSpan>{t('note')}</S.CreateEventSpan>
        <Field
          name={'note'}
          as={TextArea}
          size="m"
          data-test-id={TestIds.EventFormNoteField}
        />
      </S.EventFormBlock>

      <S.EventActions>
        {type !== 'edit' && (
          <Button
            color="secondary"
            type={'button'}
            disabled={isSubmitting || loading}
            onClick={() => resetForm()}
            testId={TestIds.EventFormCancelButton}
          >
            {t('cancel')}
          </Button>
        )}
        <S.StyledButton
          type="submit"
          disabled={isSubmitting || loading}
          testId={TestIds.EventFormSubmitButton}
        >
          {loading ? <LoadingIndicator small /> : <span>{t('save')}</span>}
        </S.StyledButton>
      </S.EventActions>
      {isCreateTournamentVisible && (
        <TournamentCreatePanel
          onClose={hideCreateTournament}
          sportId={values.sport?.id}
          groupId={values.group?.id}
          onSuccess={handleTournamentSelect}
        />
      )}
      {isCreateGroupVisible && (
        <GroupCreatePanel
          onClose={hideCreateGroup}
          sportId={values.sport?.id}
          onSuccess={handleGroupSelect}
        />
      )}
      {isCreateMatchTypeVisible && (
        <MatchTypeCreatePanel
          onClose={hideCreateMatchType}
          sports={values.sport?.id ? [values.sport?.id] : []}
          onSuccess={handleMatchTypeSelect}
        />
      )}
      {isCreateCompetitorAVisible && (
        <CompetitorCreatePanel
          onClose={hideCreateCompetitorA}
          sportId={values.sport?.id}
          onSuccess={handleCompetitorASelect}
        />
      )}
      {isCreateCompetitorBVisible && (
        <CompetitorCreatePanel
          onClose={hideCreateCompetitorB}
          sportId={values.sport?.id}
          onSuccess={handleCompetitorBSelect}
        />
      )}
      {isCreateSportPanelVisible && (
        <SportCreatePanel
          onClose={hideCreateSportPanel}
          onSuccess={handleSportSelect}
        />
      )}
    </S.EventForm>
  );
};
