import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { Entities } from 'app/providers/EntitiesProvider/types';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getGroupsSubstate = (state: RootState) =>
  getEntities(state)[Entities.GROUPS];

export const getGroupsEntries = (state: RootState) =>
  getEntities(state)[Entities.GROUPS].entries;

export const selectGroupsItems = createSelector([getGroupsSubstate], groups =>
  getEntityItems(groups),
);

export const selectGroup = (state: RootState, id: number) =>
  getGroupsSubstate(state).entries[id];
