import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  height: 24px;
  width: 24px;
  border: none;
  outline: none;
  border-radius: 12px;
  transition:
    background-color 0.2s ease,
    transform 0.2s ease;
  background-color: ${p => p.theme.colors.primary[700]};
  color: ${p => p.theme.colors.primary[0]};

  &:hover {
    background-color: ${p => p.theme.colors.primary[800]};
  }

  &:active {
    transform: scale(0.96);
    background-color: ${p => p.theme.colors.primary[900]};
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin: auto;
`;
