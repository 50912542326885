import { Competitor as CompetitorType } from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  competitors: Yup.array().of(Yup.object()).required('Required'),
});

export interface FormValues {
  name: string;
  competitors: CompetitorType[];
}

export const initialCreateValues: FormValues = {
  name: '',
  competitors: [] as CompetitorType[],
};
