import { PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerSportEventOdds,
  SportEventOdds,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';

const reducer = {
  eventsOddsFetchItem(state) {
    state.eventsOdds.fetchItem.error = null;
    state.eventsOdds.fetchItem.loading = true;
  },
  eventsOddsFetchItemSuccess(
    state,
    action: PayloadAction<{ odds: SportEventOdds; eventId: number }>,
  ) {
    const { odds, eventId } = action.payload;
    state.eventsOdds.entries[eventId] = odds;
    state.eventsOdds.fetchItem.loading = false;
  },
  eventsOddsFetchItemError(state, action: PayloadAction<EntitiesErrorType>) {
    state.eventsOdds.fetchItem.error = action.payload;
    state.eventsOdds.fetchItem.loading = false;
  },
  eventsOddsUpdate(state) {
    state.eventsOdds.update.error = null;
    state.eventsOdds.update.loading = true;
  },
  eventsOddsUpdateSuccess(state) {
    state.eventsOdds.update.loading = false;
  },
  eventsOddsUpdateError(state, action: PayloadAction<EntitiesErrorType>) {
    state.eventsOdds.update.error = action.payload;
    state.eventsOdds.update.loading = false;
  },
  eventsOddsByCustomerFetchItem(state) {
    state.eventsOdds.fetchItem.error = null;
    state.eventsOdds.fetchItem.loading = true;
  },
  eventsOddsByCustomerFetchItemSuccess(
    state,
    action: PayloadAction<{ odds: CustomerSportEventOdds; eventId: number }>,
  ) {
    const { odds, eventId } = action.payload;
    state.eventsOdds.entries[eventId] = odds;
    state.eventsOdds.fetchItem.loading = false;
  },
  eventsOddsByCustomerFetchItemError(
    state,
    action: PayloadAction<EntitiesErrorType>,
  ) {
    state.eventsOdds.fetchItem.error = action.payload;
    state.eventsOdds.fetchItem.loading = false;
  },
};

export default reducer;
