import { rgba } from 'polished';
import styled from 'styled-components';

interface PanelProps {
  panelWidth?: number;
}

export const SlidePanelWrapper = styled.div<PanelProps>`
  position: fixed;
  z-index: 12;
  width: ${p => (p.panelWidth ? `${100 - p.panelWidth}vw` : '50vw')};
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${rgba('#000000', 0.2)};

  transition: background-color 0.2s ease;
`;

export const FormPanel = styled.form<PanelProps>`
  position: fixed;
  z-index: 12;
  width: ${p => (p.panelWidth ? `${p.panelWidth}vw` : '50vw')};
  height: 100vh;
  right: 0;
  top: 0;
  background-color: white;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
`;

export const BlockPanel = styled.div<PanelProps>`
  position: fixed;
  z-index: 12;
  width: ${p => (p.panelWidth ? `${p.panelWidth}vw` : '50vw')};
  height: 100vh;
  right: 0;
  top: 0;
  background-color: white;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
`;

export const Header = styled.div`
  padding: 20px 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const H1 = styled.h1`
  font-size: 24px;
  line-height: 130%;
`;

export const Close = styled.div`
  cursor: pointer;
`;

export const Content = styled.div`
  flex-grow: 1;
  padding: 10px 20px;
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 10px;
  align-content: flex-start;
  overflow: auto;
`;

export const Footer = styled.footer`
  border-top: 1px solid #e5e5e5;
  height: 56px;
  padding: 12px 20px;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
`;
