import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from 'app/pages/Sources/components/EntitiesTables/styles';
import { Formik, useFormikContext } from 'formik';
import _values from 'lodash-es/values';
import { AdapterSport, Sport } from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';

import { SelectField } from 'app/components/forms';
import { SportCreatePanel } from 'app/components/panels';
import { Button } from 'app/components/ui';
import LargePlus from 'assets/images/large-plus.svg?react';

import { adapterActions } from 'app/providers/AdaptersProvider';

interface Values {
  sportsbookSport?: Sport;
}

interface Props {
  mapped: boolean;
  adapter: string;
  sport: AdapterSport;
  sports: Dictionary<Sport>;
  isEditing: boolean;
  setEditingRow: (editingId: string | undefined) => void;
}

const Form = ({
  mapped,
  sports,
  sport,
  adapter,
  isEditing,
  setEditingRow,
}: Props) => {
  const { values, setFieldValue, submitForm } = useFormikContext<Values>();
  const [
    isCreateSportPanelVisible,
    showCreateSportPanel,
    hideCreateSportPanel,
  ] = useBooleanState();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sportsArray = _values(sports);

  const onSportCreated = (newSport: Sport) => {
    setFieldValue('sportsbookSport', newSport);
  };

  const handleDeleteMapping = () => {
    if (!sport.id) return;
    dispatch(adapterActions.sportsDeleteMapping({ id: sport.id, adapter }));
    setEditingRow(undefined);
  };

  const handleEdit = () => {
    setEditingRow(sport.id);
  };

  if (!mapped) {
    return (
      <S.Tr>
        <S.Td weight={45}>{sport.name}</S.Td>
        <S.SelectTd weight={45}>
          <SelectField name="sportsbookSport" options={sportsArray} />
          <Button color="action" onClick={showCreateSportPanel}>
            <LargePlus />
          </Button>
        </S.SelectTd>
        <S.Td weight={10} textAlign={'right'}>
          <Button onClick={submitForm} disabled={!values.sportsbookSport}>
            {t('map')}
          </Button>
        </S.Td>
        {isCreateSportPanelVisible && (
          <SportCreatePanel
            onClose={hideCreateSportPanel}
            onSuccess={onSportCreated}
          />
        )}
      </S.Tr>
    );
  }

  if (isEditing) {
    return (
      <S.Tr>
        <S.Td>{sport.name}</S.Td>
        <S.SelectTd>
          <SelectField name="sportsbookSport" options={sportsArray} />
          <S.AddButton onClick={showCreateSportPanel}>
            <LargePlus />
          </S.AddButton>
        </S.SelectTd>
        <S.Td>
          <S.ButtonsBlock>
            <S.ButtonsWrapper>
              <Button
                color="action"
                onClick={submitForm}
                disabled={!values.sportsbookSport}
              >
                <FontAwesomeIcon icon={faSave} />
                {t('save')}
              </Button>
              <Button
                color="action"
                onClick={handleDeleteMapping}
                disabled={!values.sportsbookSport}
              >
                <FontAwesomeIcon icon={faTrash} />
                {t('delete')}
              </Button>
            </S.ButtonsWrapper>
          </S.ButtonsBlock>
        </S.Td>
        {isCreateSportPanelVisible && (
          <SportCreatePanel
            onClose={hideCreateSportPanel}
            onSuccess={onSportCreated}
          />
        )}
      </S.Tr>
    );
  }
  return (
    <S.Tr>
      <S.Td>{sport.name}</S.Td>
      <S.Td>{values.sportsbookSport?.name}</S.Td>
      <S.Td>
        <S.ButtonsBlock>
          <Button color="action" onClick={handleEdit}>
            <FontAwesomeIcon icon={faEdit} />
            {t('edit')}
          </Button>
        </S.ButtonsBlock>
      </S.Td>
    </S.Tr>
  );
};

export const SportRow = (props: Props) => {
  const { mapped, sports, sport, adapter, setEditingRow } = props;
  const dispatch = useDispatch();

  const submit = ({ sportsbookSport }: Values) => {
    dispatch(
      adapterActions.sportsMap({
        id: sport.id!,
        adapter,
        mappingRequest: { sportsbookId: sportsbookSport?.id },
        withDeletion: !mapped,
      }),
    );
    setEditingRow(undefined);
  };

  return (
    <Formik<Values>
      enableReinitialize
      initialValues={{
        sportsbookSport: mapped ? sports[sport.sportsbookId!] : undefined,
      }}
      onSubmit={submit}
    >
      <Form {...props} />
    </Formik>
  );
};
