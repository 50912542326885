import { RootState } from 'types';

import {
  DirectoryEntitiesEnum,
  Entities,
  EntitiesState,
  EntityMethods,
  RequestState,
} from './types';

export const getEntities = (state: RootState) => state.entities;

const getEntitiesByType = (state: RootState, type: keyof EntitiesState) =>
  getEntities(state)[type];

export const getRequestsState = (
  state: RootState,
  type: keyof EntitiesState,
  method: EntityMethods,
): RequestState => getEntitiesByType(state, type)[method];

export const getDirectoryEntityByTypeAndId = (
  state: RootState,
  type: DirectoryEntitiesEnum,
  id: number,
) => getEntities(state)[type].entries[id];

export const getEntityByTypeAndId = (
  state,
  type: Entities,
  id: number | string,
) => getEntities(state)[type].entries[id] as any;

export const getEntityHasMoreByType = (state, type: Entities) =>
  getEntities(state)[type].hasMore;
