import { SportEventResultType } from 'sportsbook-openapi-react';

export const resultToColor: Record<SportEventResultType, string> = {
  [SportEventResultType.LOSE]: '#EB5757',
  [SportEventResultType.LOSE_RETURN]: '#C17C7C',
  [SportEventResultType.NA]: '#B3BAC5',
  [SportEventResultType.RETURN]: '#7A869A',
  [SportEventResultType.UNKNOWN]: '#B3BAC5',
  [SportEventResultType.WIN]: '#27AE60',
  [SportEventResultType.WIN_RETURN]: '#8DA194',
};

export const resultToCode: Record<SportEventResultType, string> = {
  [SportEventResultType.LOSE]: 'L',
  [SportEventResultType.LOSE_RETURN]: 'LR',
  [SportEventResultType.NA]: 'NA',
  [SportEventResultType.RETURN]: 'R',
  [SportEventResultType.UNKNOWN]: 'U',
  [SportEventResultType.WIN]: 'W',
  [SportEventResultType.WIN_RETURN]: 'WR',
};
