import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import { Tournament, UserGroupEnum } from 'sportsbook-openapi-react';
import styled from 'styled-components';

import { getAsyncLoadOptions } from 'utils/select';

import { AsyncSelect } from 'app/components/forms';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';

export const SelectTournament = ({
  name,
  excludedTournament,
}: {
  name: string;
  excludedTournament: Tournament;
}) => {
  const dispatch = useDispatch();
  const [tournament, setTournament] = useState<Maybe<Tournament>>(null);
  const { t } = useTranslation();
  const [field, , helpers] = useField<number[]>(name);
  const tournamentIds = field.value;

  const handleAddTournament = () => {
    if (tournament) {
      helpers.setValue([...tournamentIds, tournament.id]);
      dispatch(actionsNT.tournamentsFetchItem({ tournamentId: tournament.id }));
      setTournament(null);
    }
  };
  return (
    <RoleGuards
      roles={[
        UserGroupEnum.ADMIN,
        UserGroupEnum.OPERATOR,
        UserGroupEnum.SUPERVISOR,
      ]}
    >
      <AddBlockWrapper>
        <AsyncSelect
          loadOptions={getAsyncLoadOptions({
            entity: 'tournaments',
            excludeOptions: [excludedTournament],
          })}
          value={tournament}
          onChange={value => setTournament(value as Tournament)}
          placeholder={t('tournament')}
        />
        <Button color="action" type="button" onClick={handleAddTournament}>
          <FontAwesomeIcon icon={faPlus} />
          {t('add dependency')}
        </Button>
      </AddBlockWrapper>
    </RoleGuards>
  );
};

const AddBlockWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px min-content;
  grid-column-gap: 16px;
`;
