import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getCrossMappingsSubstate = (state: RootState) =>
  getEntities(state).crossMappings;

export const getCrossMappingsEntries = (state: RootState) =>
  getCrossMappingsSubstate(state).entries;

export const getCrossMappingsByCategoryId = (state: RootState, id: number) => {
  const items = getEntityItems(getCrossMappingsSubstate(state));

  return items.filter(item => item.outcomeType?.categoryId === id);
};
