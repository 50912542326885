/**
 *
 * Dropdown
 *
 */
import { FC, MouseEvent, useCallback, useRef, useState } from 'react';

import Chevron from './assets/images/chevron.svg?react';
import { useClickOutsideOld } from './Dropdown.hooks';
import * as S from './Dropdown.styles';
import { DropdownProps } from './Dropdown.types';

export const Dropdown: FC<DropdownProps> = ({ component, options }) => {
  const [show, setShow] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const toggle = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setShow(prevState => !prevState);
  }, []);

  useClickOutsideOld(wrapperRef, () => setShow(false));

  return (
    <S.DropdownWrapper onClick={toggle} ref={wrapperRef} data-testid="Dropdown">
      {component}
      <Chevron />
      {show && (
        <S.List>
          {options.map((option, i) => {
            return (
              <S.ListItem key={i} onClick={toggle}>
                {option}
              </S.ListItem>
            );
          })}
        </S.List>
      )}
    </S.DropdownWrapper>
  );
};
