import { PayloadAction } from '@reduxjs/toolkit';
import uniq from 'lodash-es/uniq';

import {
  entityInitialState,
  idFieldByEntity,
} from 'app/providers/EntitiesProvider/constants';
import {
  CreateDefaultData,
  CreateListDefaultData,
  DeleteDefaultData,
  FetchItemDefaultData,
  FetchItemsDefaultData,
  UpdateDefaultData,
} from 'app/providers/EntitiesProvider/types';

import { toIdsArray, toIdsMap } from './utils';

/*
Reducers naming policy

<Entity Name>FetchItems
<Entity Name>FetchItem
<Entity Name>Create
<Entity Name>Update
<Entity Name>Delete
postfixes: ['' means Load, Success, Error]

 */

/*
L - Entity List Type
I - Entity Item Type
D - Entity Delete Type
E - Entity Error Type
 */
const entityReducersGenerator = <L, I, D, E>({
  entity,
  idFieldName = 'id',
}) => ({
  /*
  Fetch Items
   */
  [`${entity}FetchItems`]: function (state, action) {
    state[entity].fetchItems.error = null;
    state[entity].fetchItems.loading = true;
    state[entity].withPagination = action.payload?.withPagination;
    state[entity].onlyEntries = action.payload?.onlyEntries;
  },
  [`${entity}FetchItemsSuccess`]: function (
    state,
    action: PayloadAction<L & FetchItemsDefaultData>,
  ) {
    const { items, hasMore } = action.payload;
    if (!state[entity].onlyEntries) {
      if (state[entity].withPagination) {
        state[entity].sortedIds = uniq([
          ...state[entity].sortedIds,
          ...toIdsArray(items, [], idFieldName),
        ]);
      } else {
        state[entity].sortedIds = toIdsArray(items, [], idFieldName);
      }
    }
    state[entity].entries = {
      ...state[entity].entries,
      ...toIdsMap(items, idFieldName),
    };
    state[entity].fetchItems.loading = false;
    state[entity].hasMore = hasMore;
  },
  [`${entity}FetchItemsError`]: function (state, action: PayloadAction<E>) {
    state[entity].fetchItems.error = action.payload;
    state[entity].fetchItems.loading = false;
  },
  /*
  Fetch Item
    */
  [`${entity}FetchItem`]: function (state, action) {
    state[entity].fetchItem.error = null;
    if (!action.payload.ignoreLoading) {
      state[entity].fetchItem.loading = true;
    }
  },
  [`${entity}FetchItemSuccess`]: function (
    state,
    action: PayloadAction<I & FetchItemDefaultData>,
  ) {
    const entry = action.payload;
    if (!state[entity].sortedIds.includes(entry[idFieldName])) {
      state[entity].sortedIds.push(entry[idFieldName]);
    }
    state[entity].entries[entry[idFieldName]] = entry;
    state[entity].fetchItem.loading = false;
    state[entity].fetchItem.error = null;
  },
  [`${entity}FetchItemError`]: function (state, action: PayloadAction<E>) {
    state[entity].fetchItem.error = action.payload;
    state[entity].fetchItem.loading = false;
  },
  /*
  Create
   */
  [`${entity}Create`]: function (state) {
    state[entity].create.error = null;
    state[entity].create[idFieldName] = null;
    state[entity].create.result = null;
    state[entity].create.loading = true;
  },
  [`${entity}CreateSuccess`]: function (
    state,
    action: PayloadAction<I & CreateDefaultData>,
  ) {
    const entry = action.payload;
    state[entity].entries[entry[idFieldName]] = entry;
    state[entity].sortedIds.push(entry[idFieldName]);
    state[entity].create.loading = false;
    state[entity].create[idFieldName] = entry[idFieldName];
    state[entity].create.result = entry;
    state[entity].create.error = null;
  },
  [`${entity}CreateError`]: function (state, action: PayloadAction<E>) {
    state[entity].create.error = action.payload;
    state[entity].create.loading = false;
  },
  [`${entity}CreateReset`]: function (state) {
    state[entity].create.error = null;
    state[entity].create.loading = false;
    state[entity].create[idFieldName] = null;
    state[entity].create.result = null;
  },
  /*
  Create List
   */
  [`${entity}CreateList`]: function (state) {
    state[entity].createList.error = null;
    state[entity].createList[idFieldName] = null;
    state[entity].createList.result = null;
    state[entity].createList.loading = true;
  },
  [`${entity}CreateListSuccess`]: function (
    state,
    action: PayloadAction<CreateListDefaultData<I>>,
  ) {
    const { items } = action.payload;
    if (items) {
      state[entity].entries = {
        ...state[entity].entries,
        ...toIdsMap(items, idFieldName),
      };
      state[entity].sortedIds = uniq([
        ...state[entity].sortedIds,
        ...toIdsArray(items, [], idFieldName),
      ]);
    }
    state[entity].createList.loading = false;
  },
  [`${entity}CreateListError`]: function (state, action: PayloadAction<E>) {
    state[entity].createList.error = action.payload;
    state[entity].createList.loading = false;
  },
  /*
  Update
   */
  [`${entity}Update`]: function (state) {
    state[entity].update.error = null;
    state[entity].update[idFieldName] = null;
    state[entity].update.result = null;
    state[entity].update.loading = true;
  },
  [`${entity}UpdateSuccess`]: function (
    state,
    action: PayloadAction<I & UpdateDefaultData>,
  ) {
    const entry = action.payload;
    state[entity].entries[entry[idFieldName]] = entry;
    if (!state[entity].sortedIds.includes(entry[idFieldName])) {
      state[entity].sortedIds.push(entry[idFieldName]);
    }
    state[entity].update.loading = false;
    state[entity].update[idFieldName] = entry[idFieldName];
    state[entity].update.result = entry;
    state[entity].update.error = null;
  },
  [`${entity}UpdateError`]: function (state, action: PayloadAction<E>) {
    state[entity].update.error = action.payload;
    state[entity].update.loading = false;
  },
  [`${entity}UpdateReset`]: function (state) {
    state[entity].update.error = null;
    state[entity].update.loading = false;
    state[entity].update[idFieldName] = null;
    state[entity].update.result = null;
  },
  /*
  Delete
   */
  [`${entity}Delete`]: function (
    state,
    action: PayloadAction<D & DeleteDefaultData>,
  ) {
    state[entity].delete.error = null;
    state[entity].delete.loading = true;
    const id = action.payload[idFieldByEntity[entity]];
    delete state[entity].entries[id];
    state[entity].sortedIds = state[entity].sortedIds.filter(
      sortedId => sortedId !== id,
    );
  },
  [`${entity}DeleteSuccess`]: function (state) {
    state[entity].delete.loading = false;
  },
  [`${entity}DeleteError`]: function (state, action: PayloadAction<E>) {
    state[entity].delete.error = action.payload;
    state[entity].delete.loading = false;
  },
  [`${entity}Reset`]: function (state) {
    state[entity] = entityInitialState;
  },
});

export default entityReducersGenerator;
