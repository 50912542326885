import { Column } from 'react-table';
import { BetPayMax } from 'sportsbook-openapi-react';

import BetpaymaxCell from './BetpaymaxCell';

interface Props {
  header: string;
  accessor: string;
  betPayMaxes: Dictionary<BetPayMax>;
  updateBetPayMaxValue: (props: {
    outcomeCategoryId: number;
    betPayMax?: BetPayMax;
    maxPay?: boolean;
    value?: number;
  }) => void;
}

export const BetpaymaxColumn = <T extends object>({
  header,
  accessor,
  betPayMaxes,
  updateBetPayMaxValue,
}: Props): Column<T> => {
  return {
    Header: header,
    id: 'betPayMax',
    accessor: accessor as keyof T,
    Cell: ({ value: categoryId }) => {
      const betPayMax = betPayMaxes[categoryId];
      return (
        <BetpaymaxCell
          betPayMax={betPayMax}
          outcomeCategoryId={categoryId}
          handleCellChange={updateBetPayMaxValue}
        />
      );
    },
  };
};
