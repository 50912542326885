import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getEntityWithStringIdItems } from 'app/providers/EntitiesProvider/utils';

const getUsersSubstate = (state: RootState) => getEntities(state).users;

export const getUsersEntries = (state: RootState) =>
  getUsersSubstate(state).entries;
export const getUsersPaginationToken = (state: RootState) =>
  getUsersSubstate(state).paginationToken;
export const getUsersHasMore = (state: RootState) =>
  getUsersSubstate(state).hasMore;

export const selectUsersItems = createSelector([getUsersSubstate], users =>
  getEntityWithStringIdItems(users),
);
