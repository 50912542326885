import {
  Player,
  PlayerList,
  PlayersApiDeletePlayerRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    PlayerList,
    Player,
    PlayersApiDeletePlayerRequest,
    EntitiesErrorType
  >({
    entity: 'players',
  }),
};

export default reducer;
