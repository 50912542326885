import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 4px 24px 16px;
`;

export const Loader = styled.div`
  height: 53px;
  border-radius: 4px;
  background-color: ${p => p.theme.colors.primary[20]};
`;

export const EventInfo = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(7, auto);
  grid-column-gap: 32px;
  justify-content: flex-start;
  align-items: center;

  padding: 12px 16px;
  border-radius: 4px;
  background-color: ${p => p.theme.colors.primary[20]};
`;

export const InfoBlock = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: 2px;
  column-gap: 5px;
`;

export const Label = styled.span`
  color: ${p => p.theme.colors.primary[80]};
  font-weight: ${p => p.theme.fonts.typography['H90'].weight};
  font-size: ${p => p.theme.fonts.typography['H90'].size};
  line-height: 1;
`;

export const Value = styled.span`
  color: ${p => p.theme.colors.primary[800]};
  font-weight: 700;
  font-size: ${p => p.theme.fonts.typography['H400'].size};
  line-height: 1;
`;

export const CompetitorsBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 8px;
  align-items: center;
`;

export const CompetitorName = styled.span`
  color: ${p => p.theme.colors.primary[800]};
  font-weight: 700;
  font-size: ${p => p.theme.fonts.typography['H200'].size};
`;

export const ScoreBlock = styled.div`
  height: 29px;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: minmax(20px, 1fr) 6px minmax(20px, 1fr);

  padding: 4px;
  background-color: ${p => p.theme.colors.primary[600]};
  border-radius: 4px;
`;

export const InfoScore = styled.span`
  background-color: white;
  font-weight: 700;
  padding: 0 4px;
  border-radius: 4px;
  margin-right: 2px;
  font-size: ${p => p.theme.fonts.typography['H200'].size};
  display: inline-block;
`;

export const ScoreValue = styled(Value)`
  color: ${p => p.theme.colors.primary[0]};
`;

export const Separator = styled(Value)`
  color: ${p => p.theme.colors.primary[80]};
`;
