import { matchPath, useNavigate } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Event } from 'sportsbook-openapi-react';

import { TestIds } from 'types/testIds.types';

import * as S from './BackButton.styles';

interface Props {
  event: Event;
}

export function BackButton({ event }: Props) {
  const navigate = useNavigate();

  const isResult = matchPath(
    {
      path: '/results/:id',
      end: false,
      caseSensitive: true,
    },
    '/results/:id',
  );

  const handleBackClick = () => {
    if (isResult) {
      navigate('/results');
      return;
    }
    if (event.live) {
      navigate('/live');
    } else {
      navigate('/events');
    }
  };

  return (
    <S.Button
      onClick={handleBackClick}
      data-test-id={TestIds.EventPageBackButton}
    >
      <S.StyledFontAwesomeIcon icon={faChevronLeft} />
    </S.Button>
  );
}
