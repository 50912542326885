import styled from 'styled-components';

interface StyledCheckboxProps {
  checked?: boolean;
}

export const ChechboxWrapper = styled.div`
  cursor: pointer;
  width: fit-content;
  display: grid;
  grid-template-columns: 14px 1fr;
  grid-column-gap: 8px;

  align-items: center;
`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 6px;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  border: 1px solid;
  border-color: ${p => p.theme.colors.primary[60]};
  transition: border-color 0.2s ease;

  &:hover {
    border-color: ${p => p.theme.colors.primary[100]};
    ${p =>
      p.checked &&
      `
    border-color: ${p.theme.colors.blue[300]};
  `}
  }

  ${p =>
    p.checked &&
    `
    background-color: ${p.theme.colors.blue[300]};
    border-color: ${p.theme.colors.blue[300]};
  `}
`;
