import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { ShowSourcesButton } from 'app/pages/Events/components/ShowSourcesButton';
import {
  getAdapterFromSource,
  getHomeAwayCompetitors as getEventHomeAwayCompetitors,
} from 'app/pages/Events/utils';
import { getHomeAwayCompetitors } from 'app/pages/Sources/utils';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { AdapterEvent, Event } from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';
import { RootState } from 'types';

import { Button, Dialog, Table } from 'app/components/ui';

import {
  adapterActions,
  selectMappedEventsItemsById,
} from 'app/providers/AdaptersProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectEventsItems } from 'app/providers/EntitiesProvider/events';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import * as S from './MappedEventsTable.styles';

interface Props {
  mapped?: boolean;
  adapter: string;
  adapterEvent: AdapterEvent;
  searchParams: {
    sportId?: number;
    tournamentId?: number;
    competitors?: (number | undefined)[];
  };
  onMap: () => void;
}

export const MappedEventsTable: FC<Props> = ({
  mapped,
  adapter,
  adapterEvent,
  searchParams,
  onMap,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const events = useSelector(selectEventsItems);
  const sports = useSelector(getSportsEntries);
  const { sportsbookId } = adapterEvent;
  const [selectedEvent, setSelectedEvent] = useState<Maybe<Event>>();
  const [isMappingDialogVisible, showMappingDialog, hideMappingDialog] =
    useBooleanState();

  useEffect(() => {
    if (sportsbookId) {
      dispatch(actionsNT.eventsFetchItem({ eventId: sportsbookId }));
    }
  }, [dispatch, sportsbookId]);

  useEffect(() => {
    dispatch(actionsNT.eventsCreateReset());
    setSelectedEvent(null);
  }, [searchParams, setSelectedEvent, dispatch]);

  const data = useMemo(() => {
    const competitorsSet = new Set(searchParams.competitors?.filter(Boolean));

    return sportsbookId
      ? events.filter(event => event.id === sportsbookId)
      : events.filter(
          event =>
            event.tournament?.id === searchParams.tournamentId &&
            isEqual(
              new Set(event.competitors.map(competitor => competitor.id)),
              competitorsSet,
            ),
        );
  }, [
    searchParams.competitors,
    searchParams.tournamentId,
    sportsbookId,
    events,
  ]);

  useEffect(() => {
    setSelectedEvent(data[data.length - 1]);
  }, [data, setSelectedEvent]);

  const columns = useMemo<Column<Event>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ value }) => (
          <S.StyledLink target="_blank" to={`/events/${value}`}>
            {value}
          </S.StyledLink>
        ),
      },
      {
        Header: t('sport type').toString(),
        id: 'sport',
        accessor: 'tournament',
        Cell: ({ value }) => {
          if (!value?.sportId) return '';
          return sports[value.sportId].name;
        },
      },
      {
        Header: t('source').toString(),
        accessor: 'mainSource',
        Cell: ({ row: { original: data } }) => (
          <ShowSourcesButton eventId={data.id} />
        ),
      },
      {
        Header: t('start date').toString(),
        accessor: 'eventDate',
        Cell: ({ value }) => <>{dayjs(value).format('HH:mm DD:MM:YYYY')}</>,
      },
      {
        Header: t('tournament').toString(),
        accessor: 'tournament',
        Cell: ({ value }) => <>{value?.name}</>,
      },
      {
        Header: `${t('competitor')} 1`,
        id: 'homeCompetitor',
        Cell: ({ row: { original: data } }) => {
          const { homeCompetitor } = getEventHomeAwayCompetitors(data);
          return <>{homeCompetitor?.name}</>;
        },
      },
      {
        Header: `${t('competitor')} 2`,
        id: 'awayCompetitor',
        Cell: ({ row: { original: data } }) => {
          const { awayCompetitor } = getEventHomeAwayCompetitors(data);
          return <>{awayCompetitor?.name}</>;
        },
      },
      {
        Header: t('status').toString(),
        accessor: 'status',
        Cell: ({ value }) => <>{t(`statuses.labels.${value}`)}</>,
      },
    ],
    [t, sports],
  );

  const mapEvent = (cross: boolean) => {
    if (selectedEvent) {
      dispatch(
        adapterActions.eventsMap({
          id: adapterEvent.id!,
          adapter,
          eventMappingRequest: {
            sportsbookId: selectedEvent.id,
            crossMapped: cross,
          },
          withDeletion: true,
        }),
      );
      if (adapterEvent.tournament) {
        if (
          adapterEvent.tournament?.sportsbookId !== selectedEvent.tournament?.id
        ) {
          dispatch(
            adapterActions.tournamentsMap({
              adapter,
              id: adapterEvent.tournament.id!,
              mappingRequest: {
                sportsbookId: selectedEvent.tournament?.id,
              },
            }),
          );
        }
        // TODO fix this check
        if (
          adapterEvent.tournament.sportId !== selectedEvent.tournament?.sportId
        ) {
          dispatch(
            adapterActions.sportsMap({
              adapter,
              id: adapterEvent.tournament.sportId!,
              mappingRequest: {
                sportsbookId: selectedEvent.tournament?.sportId,
              },
            }),
          );
        }
      }

      const { homeCompetitor, awayCompetitor } =
        getHomeAwayCompetitors(adapterEvent);
      const {
        homeCompetitor: sbHomeCompetitor,
        awayCompetitor: sbAwayCompetitor,
      } = getEventHomeAwayCompetitors(selectedEvent);

      if (homeCompetitor) {
        const competitorIdToMap = cross
          ? sbAwayCompetitor?.id!
          : sbHomeCompetitor?.id!;

        if (homeCompetitor.sportsbookId !== competitorIdToMap) {
          dispatch(
            adapterActions.competitorsMap({
              adapter,
              id: homeCompetitor.id!,
              mappingRequest: {
                sportsbookId: competitorIdToMap,
              },
            }),
          );
        }
      }
      if (awayCompetitor) {
        const competitorIdToMap = cross
          ? sbHomeCompetitor?.id!
          : sbAwayCompetitor?.id!;

        if (awayCompetitor.sportsbookId !== competitorIdToMap) {
          dispatch(
            adapterActions.competitorsMap({
              adapter,
              id: awayCompetitor.id!,
              mappingRequest: {
                sportsbookId: competitorIdToMap,
              },
            }),
          );
        }
      }

      onMap();
    }
  };

  const eventSourcesItems = useSelector((state: RootState) =>
    selectMappedEventsItemsById(state, selectedEvent?.id ?? 0),
  );

  const disabled =
    !data.length ||
    eventSourcesItems.some(
      item => getAdapterFromSource(item.source!) === adapter,
    );

  return (
    <S.Wrapper>
      <S.TableWrapper>
        <Table
          data={data}
          columns={columns}
          onRowClick={setSelectedEvent}
          isSelected={(data: any) => !mapped && data.id === selectedEvent?.id}
          columnsWidth={[5, 10, 10, 10, 15, 20, 20, 10]}
        />
      </S.TableWrapper>
      <S.Footer>
        {!mapped && (
          <>
            <Button
              color="secondary"
              onClick={showMappingDialog}
              disabled={disabled}
            >
              {t('map to event cross')}
            </Button>

            <Button onClick={() => mapEvent(false)} disabled={disabled}>
              {t('map to event')}
            </Button>
          </>
        )}
      </S.Footer>
      {isMappingDialogVisible && (
        <Dialog
          text={t('crossMappingDialog')}
          onClose={hideMappingDialog}
          onConfirm={() => mapEvent(true)}
        />
      )}
    </S.Wrapper>
  );
};
