import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  padding: 0 20px;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  grid-column-gap: 20px;

  z-index: 2;
`;
