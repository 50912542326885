import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { useFormikContext } from 'formik';
import { pick, values } from 'lodash-es';
import { Event, Tournament } from 'sportsbook-openapi-react';

import { RadioButtonField } from 'app/components/forms';
import { getEventName } from 'app/components/panels/dependency/utils';
import { Span, Table } from 'app/components/ui';

import * as S from './styles';
import { CreateDependencyFormValues, DependencyEnum } from './utils';

interface Props {
  events: Dictionary<Event>;
  selectedEvents: number[];
  event: Event;
  tournament: Tournament;
  tournaments: Tournament[];
  error: string;
}

export const DependencyForm = ({
  events,
  selectedEvents,
  event,
  tournament,
  tournaments,
  error,
}: Props) => {
  const { t } = useTranslation();

  const {
    values: { type, dependentType },
    setFieldValue,
  } = useFormikContext<CreateDependencyFormValues>();

  const eventName = getEventName(event);

  const tournamentsColumns: Column<Tournament>[] = [
    {
      Header: t('name').toString(),
      accessor: 'name',
    },
  ];

  const eventsColumns: Column<Event>[] = [
    {
      Header: t('name').toString(),
      id: 'name',
      Cell: ({ row: { original } }) => <>{getEventName(original)}</>,
    },
  ];

  const eventsData = values(pick(events, selectedEvents))
    .map(event => event!)
    .filter(item => item.id !== event.id);

  return (
    <S.StyledFormBlock>
      <S.RadioSubform>
        <S.RadioButtonWrapper>
          <RadioButtonField
            name="type"
            value={DependencyEnum.TOURNAMENT}
            label={t('tournament')}
            onActivate={() => {
              setFieldValue('dependentType', DependencyEnum.TOURNAMENT);
            }}
          />
          <Span>{tournament.name}</Span>
        </S.RadioButtonWrapper>
        <S.RadioButtonWrapper>
          <RadioButtonField
            name="type"
            value={DependencyEnum.EVENT}
            label={t('match')}
          />
          <Span>{eventName}</Span>
        </S.RadioButtonWrapper>
      </S.RadioSubform>
      <S.TableWrapper>
        <Span>{t('map with')}</Span>
        <S.RadioSubform slick>
          <RadioButtonField
            name="dependentType"
            value={DependencyEnum.TOURNAMENT}
            label={t('with tournament')}
          />
          <RadioButtonField
            disabled={type === DependencyEnum.TOURNAMENT}
            name="dependentType"
            value={DependencyEnum.EVENT}
            label={t('with match')}
          />
        </S.RadioSubform>
        {dependentType === DependencyEnum.EVENT ? (
          <Table columns={eventsColumns} data={eventsData} />
        ) : (
          <Table columns={tournamentsColumns} data={tournaments} />
        )}
      </S.TableWrapper>
      {!!error && <S.ErrorMessage>{error}</S.ErrorMessage>}
    </S.StyledFormBlock>
  );
};
