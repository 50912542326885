import { marketsApi } from 'app/api';

const api = {
  endpoint: marketsApi,
  methods: {
    fetchItems: 'getMarkets',
    fetchItem: 'getMarket',
    create: 'createMarket',
    update: 'updateMarket',
    delete: 'deleteMarket',
  },
};

export default api;
