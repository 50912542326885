import styled from 'styled-components';

import { Size, StyledButtonProps } from './Button.types';

const sizeToHeight: Record<Size, string> = {
  s: '32px',
  m: '40px',
};

export const StyledButton = styled.button<StyledButtonProps>`
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;

  height: ${p => {
    switch (p.color) {
      case 'link':
        return 'fit-content';
      default:
        return sizeToHeight[p.size];
    }
  }};
  width: ${p => {
    switch (p.color) {
      case 'link':
        return 'fit-content';
      default:
        return 'auto';
    }
  }};
  padding: ${p => {
    switch (p.color) {
      case 'link':
        return '0';
      default:
        return '0 12px';
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 7px;

  border: none;
  border-radius: 4px;

  transition:
    background-color 0.2s ease,
    color 0.2s ease;
  outline: none;

  font-family: ${p => p.theme.fonts.family};
  font-size: ${p => {
    switch (p.color) {
      case 'link':
        return p.theme.fonts.typography['H100'].size;
      case 'action':
        return p.theme.fonts.typography['H100'].size;
      default:
        return p.theme.fonts.typography['H400'].size;
    }
  }};
  font-weight: ${p => {
    switch (p.color) {
      case 'link':
        return p.theme.fonts.typography['H100'].weight;
      case 'action':
        return p.theme.fonts.typography['H100'].weight;
      default:
        return p.theme.fonts.typography['H400'].weight;
    }
  }};

  background-color: ${p => {
    switch (p.color) {
      case 'primary':
        return p.theme.colors.blue[400];
      case 'secondary':
        return p.theme.colors.primary[300];
      case 'action':
        return p.theme.colors.primary[30];
      default:
        return 'transparent';
    }
  }};

  color: ${p => {
    switch (p.color) {
      case 'tretiary':
        return p.theme.colors.primary[300];
      case 'action':
        return p.theme.colors.primary[500];
      case 'link':
        return p.theme.colors.blue[300];
      default:
        return p.theme.colors.primary[0];
    }
  }};

  &:hover {
    background-color: ${p => {
      switch (p.color) {
        case 'primary':
          return p.theme.colors.blue[300];
        case 'secondary':
          return p.theme.colors.primary[400];
        case 'action':
          return p.theme.colors.primary[40];
        case 'tretiary':
          return p.theme.colors.primary[20];
        default:
          return 'transparent';
      }
    }};

    color: ${p => {
      switch (p.color) {
        case 'tretiary':
          return p.theme.colors.primary[300];
        case 'action':
          return p.theme.colors.primary[500];
        case 'link':
          return p.theme.colors.blue[400];
        default:
          return p.theme.colors.primary[0];
      }
    }};
  }

  &:active {
    background-color: ${p => {
      switch (p.color) {
        case 'primary':
          return p.theme.colors.blue[400];
        case 'secondary':
          return p.theme.colors.primary[500];
        case 'action':
          return p.theme.colors.primary[50];
        case 'tretiary':
          return p.theme.colors.primary[40];
        default:
          return 'transparent';
      }
    }};

    color: ${p => {
      switch (p.color) {
        case 'tretiary':
          return p.theme.colors.primary[500];
        case 'action':
          return p.theme.colors.primary[500];
        case 'link':
          return p.theme.colors.blue[500];
        default:
          return p.theme.colors.primary[0];
      }
    }};

    box-shadow: inset 0 0 3px 1px
      ${p => {
        switch (p.color) {
          case 'primary':
            return p.theme.colors.blue[500];
          case 'secondary':
            return p.theme.colors.primary[700];
          case 'action':
            return p.theme.colors.primary[70];
          case 'tretiary':
            return p.theme.colors.primary[60];
          default:
            return 'transparent';
        }
      }};
  }

  &:disabled {
    background-color: ${p => {
      switch (p.color) {
        case 'primary':
          return p.theme.colors.primary[40];
        case 'secondary':
          return p.theme.colors.primary[40];
        case 'action':
          return p.theme.colors.primary[40];
        default:
          return 'transparent';
      }
    }};

    color: ${p => {
      switch (p.color) {
        case 'tretiary':
          return p.theme.colors.primary[40];
        case 'action':
          return p.theme.colors.primary[80];
        case 'link':
          return p.theme.colors.blue[75];
        default:
          return p.theme.colors.primary[0];
      }
    }};
  }
`;
