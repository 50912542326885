import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { sortSportEventOdds } from 'app/pages/Events/utils';
import { paramsToColumns } from 'app/pages/Events/utils/params';
import { round, uniq } from 'lodash-es';
import {
  Event,
  OutcomeMarketLink,
  SportEventOdd,
  SportEventOddSource,
  TradingStatus,
} from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { LoadingIndicator, Table } from 'app/components/ui';
import { ColumnWidth } from 'app/components/ui/Table/Table.types';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getTypesEntries } from 'app/providers/EntitiesProvider/type';

import * as S from './OddsTable.styles';

interface Props {
  event: Event;
  selectedSource: Maybe<SportEventOddSource>;
}

export const OddsTable: FC<Props> = ({ event, selectedSource }) => {
  const { t } = useTranslation();
  const types = useSelector(getTypesEntries);
  const { isLoading } = useRequestState('eventsOdds', 'fetchItem');

  const { isLoading: mappingsLoading } = useRequestState(
    'eventsMappings',
    'fetchItem',
  );
  const dispatch = useDispatch();

  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState({});

  const filteredProbabilities = useMemo(
    () =>
      [
        ...(selectedSource
          ? selectedSource.probabilities.filter(
              probability =>
                probability.tradingStatus === TradingStatus.TRADING,
            )
          : []),
      ].filter(odd => {
        return odd.parameters?.every(parameter => {
          if (!filters[`parameters.${parameter.type}`]) {
            return true;
          }
          return (
            String(filters[`parameters.${parameter.type}`].id) ===
            parameter.value
          );
        });
      }),
    [filters, selectedSource],
  );

  const sortedProbabilities = useMemo(
    () => [...filteredProbabilities].sort(sortSportEventOdds(types, sorting)),
    [filteredProbabilities, sorting, types],
  );

  useEffect(() => {
    const ids = uniq(filteredProbabilities.map(p => p.outcomeTypeId));
    if (ids.length) {
      dispatch(actionsNT.typesFetchItems({ ids, withPagination: true }));
    }
  }, [dispatch, filteredProbabilities]);

  const paramsColumns = useMemo(
    () => paramsToColumns(t, event, filteredProbabilities),
    [event, filteredProbabilities, t],
  );

  const oddsColumns: Column<SportEventOdd>[] = useMemo(
    () => [
      {
        Header: t('outcome').toString(),
        accessor: 'outcomeTypeId',
        Cell: ({ value }) => <>{types[value]?.name || value}</>,
      },
      {
        Header: t('market type').toString(),
        id: 'marketLink',
        accessor: 'outcomeTypeId',
        Cell: ({ value }) => {
          return types[value]?.marketLink === OutcomeMarketLink.ANY ||
            !types[value]?.marketLink
            ? null
            : t(`marketLinkNames.${types[value]?.marketLink}`);
        },
      },
      ...paramsColumns,
      {
        Header: t('coefficient').toString(),
        accessor: 'value',
        Cell: ({ value }) => <>{round(value, 2)}</>,
      },
    ],
    [paramsColumns, t, types],
  );

  const columnsWidth = useMemo<ColumnWidth[]>(
    () => [
      'fit-content',
      'auto',
      ...Array(paramsColumns.length).fill(20),
      'fit-content',
    ],
    [paramsColumns.length],
  );

  if (isLoading || mappingsLoading) {
    return (
      <S.OddsLoading>
        <LoadingIndicator />
      </S.OddsLoading>
    );
  }

  return (
    filteredProbabilities && (
      <S.OddsTableWrapper>
        <Table
          columns={oddsColumns}
          data={sortedProbabilities}
          columnsWidth={columnsWidth}
          sorting={{
            value: sorting,
            onSortingChange: setSorting,
          }}
          filters={{
            value: filters,
            onFilterChange: setFilters,
          }}
        />
      </S.OddsTableWrapper>
    )
  );
};
