import { SortableHeaderProps } from 'app/components/ui/Table/Table.types';

import {
  AsyncSelectTableFilter,
  AsyncSelectTableFilterProps,
  SortableHeader,
} from '..';

export function SortableAsyncSelectTableFilter<D extends object = {}>({
  loadOptions,
  ...props
}: SortableHeaderProps<D> & AsyncSelectTableFilterProps<D>) {
  return (
    <SortableHeader {...props}>
      <AsyncSelectTableFilter loadOptions={loadOptions} {...props} />
    </SortableHeader>
  );
}
