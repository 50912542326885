import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getCategories = (state: RootState) => getEntities(state).categories;

export const getCategoriesEntries = (state: RootState) =>
  getCategories(state).entries;

export const selectCategoriesItems = createSelector(
  [getCategories],
  categories => getEntityItems(categories),
);

export const getCategoryById = (state: RootState, id: number) =>
  getCategoriesEntries(state)[id];
