import { PayloadAction } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import { Player, Source, SourceList } from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<SourceList, Player, Source, EntitiesErrorType>({
    entity: 'sources',
  }),
  sourcesFetchItemsSuccess(state, action: PayloadAction<Required<SourceList>>) {
    const { items } = action.payload;
    state.sources.entries = keyBy(items, 'code');
    state.sources.sortedIds = items.map(item => item.code);
    state.sources.fetchItems.loading = false;
  },
};

export default reducer;
