import { usersApi } from 'app/api';

const api = {
  endpoint: usersApi,
  methods: {
    fetchItems: 'getEventFavoriteList',
    create: 'createEventFavoriteMapping',
    delete: 'deleteEventFavoriteMapping',
    deleteList: 'deleteAllEventFavoriteMapping',
  },
};

export default api;
