import { Margin } from 'sportsbook-openapi-react';

import { parseFloatFormatted } from 'utils/number';
import { moreOrEqOne } from 'utils/validation';

import { TextInputCell } from 'app/components/forms';

import { DEFAULT_MARGIN_VALUE } from 'app/providers/EntitiesProvider/hooks';

interface Props {
  outcomeCategoryId: number;
  handleCellChange: (props: {
    margin?: Margin;
    value: number;
    outcomeCategoryId: number;
  }) => void;
  margin: Margin;
  isOperator: boolean;
  isEditable: boolean;
}

const MarginCell = ({
  outcomeCategoryId,
  handleCellChange,
  margin,
  isOperator,
  isEditable,
}: Props) => {
  return isOperator && !isEditable ? (
    <>{margin?.margin}</>
  ) : (
    <div onClick={event => event.stopPropagation()}>
      <TextInputCell
        // @ts-ignore
        step={0.01}
        min={1.0}
        defaultValue={margin?.margin ?? DEFAULT_MARGIN_VALUE}
        onSave={newValue => {
          handleCellChange({
            margin,
            value: parseFloatFormatted(newValue),
            outcomeCategoryId,
          });
        }}
        type="number"
        validate={moreOrEqOne}
      />
    </div>
  );
};

export default MarginCell;
