import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import uniq from 'lodash-es/uniq';
import { Event } from 'sportsbook-openapi-react';

import { MAIN_MATCH_TYPE_ID, useUpdateEvent } from 'hooks/events';
import { useCompleted, useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { LoadingIndicator, Panel, Span } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getEventsEntries } from 'app/providers/EntitiesProvider/events';

import EventsTable from './EventsTable';
import * as S from './styles';
import { FormValues, getEventName } from './utils';

interface Props {
  onClose: () => void;
  id: number;
  actionType: 'create' | 'edit';
  selectedEvents: number[];
  onSuccess?: () => void;
}

export const RelationPanel = ({
  onClose,
  onSuccess,
  id,
  selectedEvents,
  actionType,
}: Props) => {
  const [submitted, setSubmitted] = useState(false);

  const { isLoading } = useRequestState(Entities.EVENTS, 'fetchItems');
  const completed = useCompleted(isLoading);

  const { error } = useRequestState(Entities.EVENTS, 'update', () => {
    if (onSuccess) onSuccess();
    onClose();
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const events = useSelector(getEventsEntries);

  useEffect(() => {
    dispatch(
      actionsNT.eventsFetchItems({
        ids: selectedEvents,
        limit: selectedEvents!.length,
        withPagination: true,
      }),
    );
  }, [selectedEvents, dispatch]);

  useEffect(() => {
    dispatch(actionsNT.matchTypesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const updateEvent = useUpdateEvent();

  const eventsData = useMemo(() => {
    const ids = uniq([id, ...selectedEvents]);
    const result: Event[] = [];
    ids.forEach(eventId => {
      if (events[eventId]) result.push(events[eventId]);
    });
    return result;
  }, [id, selectedEvents, events]);

  const mainEvent = useMemo(() => {
    let baseEventIds: number[] = [];
    eventsData.forEach(event => {
      if (event.baseEventId) baseEventIds.push(event.baseEventId);
    });
    baseEventIds = uniq(baseEventIds);

    if (baseEventIds.length) {
      return eventsData.find(event => event.id === baseEventIds[0])!;
    } else {
      const candidate = eventsData.find(event => event.id === id);
      if (
        candidate?.matchTypeId === MAIN_MATCH_TYPE_ID &&
        !candidate.baseEventId
      ) {
        return candidate;
      }
      return eventsData.find(
        event => event.matchTypeId === MAIN_MATCH_TYPE_ID,
      )!;
    }
  }, [id, eventsData]);

  const saveLinkedEvents = (
    candidateEvents: Event[],
    mainEvent: Event,
    remove?: boolean,
  ) => {
    const linkedEvents = candidateEvents.filter(
      event => event.id !== mainEvent.id,
    );
    const removedEvents = eventsData.filter(
      event =>
        !candidateEvents.find(linkedEvent => event.id === linkedEvent.id),
    );

    linkedEvents.forEach(event => {
      updateEvent({
        ...event,
        baseEventId: remove ? undefined : mainEvent.id,
      });
    });

    removedEvents.forEach(event => {
      updateEvent({
        ...event,
        baseEventId: undefined,
      });
    });
  };

  const isButtonDisabled = submitted || (actionType === 'create' && !mainEvent);

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        events: eventsData,
        mainEvent,
      }}
      onSubmit={values => {
        saveLinkedEvents(values.events, values.mainEvent);
        setSubmitted(true);
      }}
    >
      {({ handleSubmit, values }) => (
        <Panel
          title={t('panels.create.linked')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={actionType === 'create' ? t('add') : t('save')}
              disabled={isButtonDisabled}
              leftButtonText={actionType === 'edit' ? t('delete') : undefined}
              onLeftButtonClick={() =>
                saveLinkedEvents(values.events, values.mainEvent, true)
              }
            />
          }
        >
          <S.StyledFormBlock>
            {!completed ? (
              <LoadingIndicator type="full" />
            ) : (
              <S.TableWrapper>
                <Span>
                  {t('link with event')} {getEventName(values.mainEvent)}
                </Span>
                <EventsTable />
              </S.TableWrapper>
            )}
            {!!error && <S.ErrorMessage>{error}</S.ErrorMessage>}
          </S.StyledFormBlock>
        </Panel>
      )}
    </Formik>
  );
};
