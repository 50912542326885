import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 200px auto;
`;

export const TableWrapper = styled.div`
  padding: 20px 20px 0;
  overflow: auto;
`;

export const Footer = styled.div`
  display: grid;
  justify-content: flex-end;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  padding: 20px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${p => p.theme.colors.blue[300]};
  &:visited {
    color: ${p => p.theme.colors.blue[300]};
  }
`;
