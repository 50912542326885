import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Tag as TagType, TagWithOutId } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';

import { validationSchema } from './constants';
import { TagForm } from './TagForm';

interface Props {
  id: number;
  onClose: () => void;
}

export const TagEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tag: TagType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.TAGS, id),
  );

  useRequestState('tags', 'update', onClose);

  const onSubmit = (values: TagWithOutId) => {
    dispatch(
      actionsNT.tagsUpdate({
        tagId: tag.id,
        tagWithOutId: values,
      }),
    );
  };

  return (
    <Formik<TagWithOutId>
      initialValues={{
        name: tag.name,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.tag')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
              leftButtonText={t('delete')}
            />
          }
        >
          <TagForm />
        </Panel>
      )}
    </Formik>
  );
};
