import { useContext } from 'react';
import ReactSelect, { components, SingleValueProps, Theme } from 'react-select';
import { ThemeContext } from 'styled-components';

import { Button } from 'app/components/ui';

import { getStyles, SelectWrapper } from './Select.styles';
import { BaseOptionType, SelectProps, Size } from './Select.types';

const sizeToHeight: Record<Size, number> = {
  s: 32,
  m: 40,
};

const CustomOption = props => {
  const { data } = props;

  return data.isButton ? (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: data.onClick,
      }}
    >
      <Button
        color="link"
        type="button"
        onClick={event => {
          event.stopPropagation();
          data.onClick();
        }}
      >
        {data.label}
      </Button>
    </components.Option>
  ) : (
    <components.Option {...props} />
  );
};

const SingleValueRenderer = <OptionType extends BaseOptionType>({
  children,
  ...props
}: SingleValueProps<OptionType>) => (
  <components.SingleValue {...props}>
    <span
      style={{
        width: 'fit-content',
      }}
      onMouseDown={event => {
        event.stopPropagation();
      }}
    >
      {children}
    </span>
  </components.SingleValue>
);

export const Select = <
  OptionType extends BaseOptionType = BaseOptionType,
  isMulti extends boolean = false,
>({
  invalid,
  success,
  disabled,
  readOnly,
  size = 's',
  className,
  ...props
}: SelectProps<OptionType, isMulti>) => {
  const theme = useContext(ThemeContext);

  return (
    <SelectWrapper data-test-id={props.testId} className={className}>
      <ReactSelect
        {...props}
        styles={getStyles({ invalid, success, disabled, readOnly }, theme)}
        isDisabled={disabled}
        isSearchable={props.isSearchable ?? !readOnly}
        menuIsOpen={readOnly ? false : undefined}
        components={{
          IndicatorSeparator: null,
          Option: CustomOption,
          SingleValue: SingleValueRenderer,
          ...(props.components || {}),
        }}
        getOptionLabel={option => option.name}
        getOptionValue={option => String(option.id)}
        maxMenuHeight={150}
        theme={(selectTheme: Theme) => {
          return {
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              primary: theme.colors.primary[40],
              primary25: theme.colors.primary[20],
              primary50: theme.colors.primary[40],
              neutral5: theme.colors.primary[20],
              neutral0: theme.colors.primary[0],
            },
            borderRadius: 4,
            spacing: {
              ...selectTheme.spacing,
              controlHeight: sizeToHeight[size],
            },
          };
        }}
      />
    </SelectWrapper>
  );
};
