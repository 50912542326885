import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Competitor, CompetitorType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import { CompetitorForm } from './CompetitorForm';
import { FormValues, validationSchema } from './constants';

const typesOptions = Object.values(CompetitorType).map(value => ({
  id: value,
  name: value,
}));

interface Props {
  onClose: () => void;
  onSuccess?: (competitor: Competitor) => void;
  sportId?: number;
}

export const CompetitorCreatePanel = ({
  onClose,
  onSuccess,
  sportId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.countriesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const sports = useSelector(getSportsEntries);

  const handleCompetitorCreated = (result: Competitor) => {
    if (onSuccess) onSuccess(result);
    onClose();
  };

  useRequestState(Entities.COMPETITORS, 'create', handleCompetitorCreated);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.competitorsCreate({
        competitorWithOutId: {
          name: values.name,
          sportId: values.sport?.id!,
          countryId: values.country?.id!,
          translations: [],
          type: values.type?.id!,
          referenceIds: values.player ? [values.player.id] : [],
        },
      }),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={{
        name: '',
        sport: sportId ? sports[sportId] : undefined,
        type: typesOptions.find(item => item.id === CompetitorType.TEAM),
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.create.competitor')}
          onClose={onClose}
          onSubmit={handleSubmit}
          panelWidth={25}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <CompetitorForm actionType="create" sportId={sportId} />
        </Panel>
      )}
    </Formik>
  );
};
