import {
  PeriodStatV2,
  SportEventStatsV2,
  StatType,
} from 'sportsbook-openapi-react';

export const getStats = (stats?: SportEventStatsV2) => {
  if (!stats) return undefined;
  let awayScore: number | undefined;
  let homeScore: number | undefined;
  let matchPhaseId: number | undefined;
  let currentTime: number | undefined;
  let remainingTime: number | undefined;
  let homeGameScore: number | undefined;
  let awayGameScore: number | undefined;
  let currentServer: string | undefined;
  let counterTerrorists: number | undefined;
  let homePeriodStats: PeriodStatV2[] = [];
  let awayPeriodStats: PeriodStatV2[] = [];

  stats.awayStats.stats.forEach(el => {
    if (el.type === StatType.SCORE) awayScore = el.value.numberValue;
    if (el.type === StatType.GAME_SCORE) awayGameScore = el.value.numberValue;
  });

  stats.homeStats.stats.forEach(el => {
    if (el.type === StatType.SCORE) homeScore = el.value.numberValue;
    if (el.type === StatType.GAME_SCORE) homeGameScore = el.value.numberValue;
  });

  stats.commonStats.stats.forEach(el => {
    if (el.type === StatType.EVENT_PHASE_ID)
      matchPhaseId = el.value.numberValue;
    if (el.type === StatType.CURRENT_TIME) currentTime = el.value.timeValue;
    if (el.type === StatType.REMAINING_TIME_IN_PERIOD)
      remainingTime = el.value.timeValue;
    if (el.type === StatType.CURRENT_SERVER) currentServer = el.value.sideValue;
    if (el.type === StatType.CURRENT_CT_TEAM)
      counterTerrorists = el.value.numberValue;
  });

  if (stats.homeStats.periodStats) {
    homePeriodStats = stats.homeStats.periodStats.filter(
      periodStat => periodStat.stat.type === StatType.SCORE,
    );
  }

  if (stats.awayStats.periodStats) {
    awayPeriodStats = stats.awayStats.periodStats.filter(
      periodStat => periodStat.stat.type === StatType.SCORE,
    );
  }

  homePeriodStats.sort((a, b) => {
    if (a.periodNumber && b.periodNumber) {
      return a.periodNumber - b.periodNumber;
    }
    return 0;
  });
  awayPeriodStats.sort((a, b) => {
    if (a.periodNumber && b.periodNumber) {
      return a.periodNumber - b.periodNumber;
    }
    return 0;
  });

  return {
    awayScore,
    homeScore,
    matchPhaseId,
    currentTime,
    remainingTime,
    homeGameScore,
    awayGameScore,
    currentServer,
    counterTerrorists,
    homePeriodStats,
    awayPeriodStats,
  };
};
