import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getCompetitors = (state: RootState) => getEntities(state).competitors;

export const getCompetitorsEntries = (state: RootState) =>
  getCompetitors(state).entries;

export const getCompetitorsHasMore = (state: RootState) =>
  getCompetitors(state).hasMore;

export const selectCompetitorsItems = createSelector(
  [getCompetitors],
  competitors => getEntityItems(competitors),
);

export const getCompetitorEntry = (state, id) =>
  id ? getCompetitorsEntries(state)[id] : undefined;
