import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FilterContext } from 'app/components/ui/Table';

import * as S from './TextInputTableFilter.styles';
import { TextInputTableFilterProps } from './TextInputTableFilter.types';

export function TextInputTableFilter<D extends object = {}>({
  dataTest,
  column: { id },
}: TextInputTableFilterProps<D>) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const { filters, handleFilterChange } = useContext(FilterContext);

  if (!filters) {
    throw Error('Provide `filters` prop to Table');
  }

  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      handleFilterChange(id)(event.target.value);
      inputRef.current?.blur();
    }
  };

  const handleClearFilter = () => {
    handleFilterChange(id)('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    inputRef.current?.blur();
  };

  return (
    <S.InputWrapper>
      <S.StyledInput
        data-test={dataTest}
        placeholder={t(id)}
        ref={inputRef}
        onKeyUp={handleKeyUp}
        defaultValue={filters[id]}
      />

      <S.StyledIcon onClick={handleClearFilter}>
        {!!filters[id] ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faSearch} />
        )}
      </S.StyledIcon>
    </S.InputWrapper>
  );
}
