import { PayloadAction } from '@reduxjs/toolkit';
import uniq from 'lodash-es/uniq';
import {
  OutcomeCategorySourceMapping,
  OutcomeCategorySourceMappingApiDeleteOutcomeCategorySourceMappingRequest,
  OutcomeCategorySourceMappingApiGetOutcomeCategorySourceMappingsRequest,
  OutcomeCategorySourceMappingsList,
} from 'sportsbook-openapi-react';

import {
  EntitiesErrorType,
  PaginatedGetRequest,
} from 'app/providers/EntitiesProvider/types';
import {
  entityReducersGenerator,
  toIdsArray,
  toIdsMap,
} from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    OutcomeCategorySourceMappingsList,
    OutcomeCategorySourceMapping,
    OutcomeCategorySourceMappingApiDeleteOutcomeCategorySourceMappingRequest,
    EntitiesErrorType
  >({
    entity: 'outcomeCategorySourceMappings',
  }),
  outcomeCategorySourceMappingsFetchItems(
    state,
    action: PayloadAction<
      PaginatedGetRequest<OutcomeCategorySourceMappingApiGetOutcomeCategorySourceMappingsRequest>
    >,
  ) {
    state.outcomeCategorySourceMappings.fetchItems.error = null;
    state.outcomeCategorySourceMappings.fetchItems.loading = true;
    state.outcomeCategorySourceMappings.withPagination =
      action.payload?.withPagination;
    state.outcomeCategorySourceMappings.onlyEntries =
      action.payload?.onlyEntries;
  },
  outcomeCategorySourceMappingsFetchItemsSuccess(
    state,
    action: PayloadAction<OutcomeCategorySourceMappingsList>,
  ) {
    const { items, hasMore } = action.payload;
    if (!state.outcomeCategorySourceMappings.onlyEntries) {
      if (state.outcomeCategorySourceMappings.withPagination) {
        state.outcomeCategorySourceMappings.sortedIds = uniq([
          ...state.outcomeCategorySourceMappings.sortedIds,
          ...toIdsArray(items),
        ]);
      } else {
        state.outcomeCategorySourceMappings.sortedIds = toIdsArray(items);
      }
    }
    state.outcomeCategorySourceMappings.entries = {
      ...state.outcomeCategorySourceMappings.entries,
      ...toIdsMap(items),
    };
    state.outcomeCategorySourceMappings.fetchItems.loading = false;
    state.outcomeCategorySourceMappings.hasMore = hasMore;
  },
  outcomeCategorySourceMappingsFetchItemsError(
    state,
    action: PayloadAction<Error>,
  ) {
    state.outcomeCategorySourceMappings.fetchItems.error = action.payload;
    state.outcomeCategorySourceMappings.fetchItems.loading = false;
  },
};

export default reducer;
