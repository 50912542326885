import { AdaptersApi } from 'sportsbook-openapi-react';

import { AdaptersProviderState, ContainerState } from './types';

export const adapterEntityInitialState = {
  entries: {},
  sortedIds: [],
  hasMore: true,
  fetch: { loading: false },
  map: { loading: false },
  delete: { loading: false },
};

const adapterInitialState = {
  competitors: adapterEntityInitialState,
  markets: adapterEntityInitialState,
  types: adapterEntityInitialState,
  players: adapterEntityInitialState,
  events: adapterEntityInitialState,
  eventProbabilities: adapterEntityInitialState,
  sports: adapterEntityInitialState,
  tournaments: adapterEntityInitialState,
  countries: adapterEntityInitialState,
  parameters: adapterEntityInitialState,
  matchPhases: adapterEntityInitialState,
  mappedEvents: {},
};

export const initialState: ContainerState = {
  adapters: {
    entries: [],
    fetch: { loading: false },
  },
  entities: adapterInitialState,
};

export const apiMap: Record<
  keyof AdaptersProviderState['entities'],
  {
    Fetch: keyof AdaptersApi;
    Map?: keyof AdaptersApi;
    Update?: keyof AdaptersApi;
    DeleteMapping?: keyof AdaptersApi;
  }
> = {
  competitors: {
    Fetch: 'getAdapterCompetitors',
    Map: 'mapCompetitor',
    DeleteMapping: 'deleteCompetitorMapping',
  },
  countries: {
    Fetch: 'getAdapterCountries',
  },
  eventProbabilities: {
    Fetch: 'getAdapterSportEventProbabilities',
  },
  events: {
    Fetch: 'getAdapterSportEvents',
    Map: 'mapSportEvent',
    Update: 'excludeSportEvent',
    DeleteMapping: 'deleteSportEventMapping',
  },
  markets: {
    Fetch: 'getAdapterMarkets',
    Map: 'mapMarket',
    DeleteMapping: 'deleteMarketMapping',
  },
  matchPhases: {
    Fetch: 'getAdapterMatchPhases',
    Map: 'mapMatchPhase',
    DeleteMapping: 'deleteMatchPhaseMapping',
  },
  parameters: {
    Fetch: 'getAdapterParameters',
  },
  players: {
    Fetch: 'getAdapterPlayers',
    Map: 'mapPlayer',
    DeleteMapping: 'deletePlayerMapping',
  },
  sports: {
    Fetch: 'getAdapterSports',
    Map: 'mapSport',
    DeleteMapping: 'deleteSportMapping',
  },
  tournaments: {
    Fetch: 'getAdapterTournaments',
    Map: 'mapTournaments',
    DeleteMapping: 'deleteTournamentMapping',
  },
  types: {
    Fetch: 'getAdapterOutcomeTypes',
    Map: 'mapOutcomeType',
    DeleteMapping: 'deleteOutcomeTypeMapping',
  },
  mappedEvents: {
    Fetch: 'getSportEventsByIds',
  },
};
