import { FC } from 'react';

import { StyledButton } from './Button.styles';
import { ButtonProps } from './Button.types';

export const Button: FC<ButtonProps> = ({
  color = 'primary',
  size = 's',
  children,
  icon,
  testId,
  ...props
}) => {
  if (icon) {
    return (
      <StyledButton size={size} color={color} {...props} data-test-id={testId}>
        {icon}
        {children}
      </StyledButton>
    );
  } else {
    return (
      <StyledButton
        size={size}
        color={color}
        children={children}
        {...props}
        data-test-id={testId}
      />
    );
  }
};
