import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { Tournament, TournamentKind } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getCountriesEntries } from 'app/providers/EntitiesProvider/countries';
import { getGroupsEntries } from 'app/providers/EntitiesProvider/groups';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import { FormValues, validationSchema } from './constants';
import { TournamentForm } from './TournamentForm';

interface Props {
  onClose: () => void;
  sportId?: number;
  groupId?: number;
  onSuccess?: (tournament: Tournament) => void;
}

export const TournamentCreatePanel = ({
  onClose,
  sportId,
  groupId,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sports = useSelector(getSportsEntries);
  const groups = useSelector(getGroupsEntries);
  const countries = useSelector(getCountriesEntries);

  const handleTournamentCreated = (result: Tournament) => {
    if (onSuccess) onSuccess(result);
    onClose();
  };

  useRequestState(Entities.TOURNAMENTS, 'create', handleTournamentCreated);

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      sport: sportId ? sports[sportId] : undefined,
      group: groupId ? groups[groupId] : undefined,
      country: undefined,
      kind: { id: TournamentKind.GENERAL, name: TournamentKind.GENERAL },
    },
    validationSchema,
    onSubmit: values => {
      dispatch(
        actionsNT.tournamentsCreate({
          tournamentWithOutId: {
            name: values.name,
            sportId: values.sport?.id!,
            groupId: values.group?.id!,
            countryId: values.country?.id!,
            translations: [],
            dependentTournaments: [],
            kind: values.kind!.id,
            baseTournamentId: values.baseTournament?.id,
          },
        }),
      );
    },
  });

  const { values, setFieldValue, handleSubmit, isValid, dirty } = formik;

  useEffect(() => {
    if (values.sport?.id && values.group?.sportId !== values.sport.id) {
      setFieldValue('group', null);
    }
  }, [setFieldValue, values.group?.sportId, values.sport?.id]);

  return (
    <FormikProvider value={formik}>
      <Panel
        title={t('panels.create.tournament')}
        onClose={onClose}
        onSubmit={handleSubmit}
        footer={
          <PanelFooter
            submitButtonText={t('create')}
            disabled={!dirty || !isValid}
          />
        }
      >
        <TournamentForm
          countries={countries}
          sports={sports}
          groups={groups}
          actionType="create"
          sportId={sportId}
          groupId={groupId}
        />
      </Panel>
    </FormikProvider>
  );
};
