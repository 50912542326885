import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import { useField } from 'formik';
import { Tournament, UserGroupEnum } from 'sportsbook-openapi-react';

import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Table } from 'app/components/ui';

import { selectTournamentsItems } from 'app/providers/EntitiesProvider/tournaments';

export const TournamentsTable = ({ name }: { name: string }) => {
  const { t } = useTranslation();
  const tournaments = useSelector(selectTournamentsItems);
  const [field, , helpers] = useField<number[]>(name);
  const tournamentIds = field.value;

  const columns: Column<Tournament>[] = [
    {
      Header: t('dependent tournaments').toString(),
      accessor: 'name',
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ value }) => (
        <RoleGuards
          roles={[
            UserGroupEnum.ADMIN,
            UserGroupEnum.OPERATOR,
            UserGroupEnum.SUPERVISOR,
          ]}
        >
          <Button
            color="link"
            onClick={() => {
              helpers.setValue(
                tournamentIds.filter(tournamentId => tournamentId !== value),
              );
            }}
          >
            {t('delete')}
          </Button>
        </RoleGuards>
      ),
    },
  ];

  const data = useMemo(
    () => tournaments.filter(item => tournamentIds.includes(item.id)),
    [tournamentIds, tournaments],
  );

  return <Table columns={columns} data={data} />;
};
