import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { Entities } from 'app/providers/EntitiesProvider/types';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getEvents = (state: RootState) => getEntities(state)[Entities.EVENTS];

export const getEventsEntries = (state: RootState) => getEvents(state).entries;
export const getEventsHasMore = (state: RootState) => getEvents(state).hasMore;
export const getEventsSortedIds = (state: RootState) =>
  getEvents(state).sortedIds;

export const selectEventsItems = createSelector([getEvents], events =>
  getEntityItems(events),
);

export const getEvent = (state, eventId: number) =>
  getEventsEntries(state)[eventId];

export const getEventsCreate = (state: RootState) => getEvents(state).create;
export const getEventsUpdate = (state: RootState) => getEvents(state).update;
