import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';

import { RootState } from 'types';

import { Entities } from 'app/providers/EntitiesProvider/types';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getMargins = (state: RootState) => state.entities[Entities.MARGINS];
const getMarginsEntries = (state: RootState) => getMargins(state).entries;

export const selectMarginItems = createSelector([getMargins], margins =>
  getEntityItems(margins),
);

export const selectMarginsByCategory = createSelector(
  [getMarginsEntries],
  margins => keyBy(margins, 'outcomeCategoryId'),
);
