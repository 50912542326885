import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const StyledOddsLoading = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledResultlWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const StyledLabel = styled.div`
  border-radius: 2px;
  width: 28px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
`;

export const StyledOddsContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
`;

export const StyledOddsTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 24px;
`;

export const StyledOddsSubTitle = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  grid-column-gap: 8px;
  align-items: center;
  padding: 12px 16px 12px 24px;
`;

export const StyledOddsTableContainer = styled.div`
  display: grid;
  grid-template-columns: 481px 1fr;
  grid-template-rows: minmax(0, 1fr);
  border-top: 1px solid ${p => p.theme.colors.primary[40]};
`;

export const StyledOddsTable = styled.div`
  display: grid;
  grid-template-rows: 40px minmax(0, 1fr);
`;

export const StyledOddsTableHeader = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

export const StyledOddsTableWrapper = styled.div`
  padding: 0 20px;
`;

export const StyledHeading = styled.span`
  color: ${p => p.theme.colors.primary[800]};
  font-size: ${p => p.theme.fonts.typography['H500'].size};
  font-weight: ${p => p.theme.fonts.typography['H500'].weight};
`;

export const Label = styled.span`
  color: ${p => p.theme.colors.primary[80]};
  font-size: ${p => p.theme.fonts.typography['H90'].size};
  font-weight: ${p => p.theme.fonts.typography['H90'].weight};
`;

export const SourceName = styled.span`
  font-size: ${p => p.theme.fonts.typography['H200'].size};
  font-weight: ${p => p.theme.fonts.typography['H200'].weight};
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
`;
