import { FC, useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faEdit, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from 'app/pages/Sources/components/EntitiesTables/styles';
import { AdapterSport } from 'sportsbook-openapi-react';

import { useBottomScroll } from 'hooks/useBottomScroll';
import { useLazyLoading } from 'hooks/useLazyLoading';

import { AdapterOutcomesMappingPanel } from 'app/components/panels';
import { LoadingIndicator, TextLoader } from 'app/components/ui';

import {
  adapterActions,
  getAdapterMarketEntries,
  getAdapterTypesHasMore,
  getAdapterTypesLoading,
  selectAdapterTypesItems,
} from 'app/providers/AdaptersProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { getTypesEntries } from 'app/providers/EntitiesProvider/type';

interface Props {
  mapped: boolean;
  adapter: string;
  searchQuery: string;
  sport?: Maybe<AdapterSport>;
}

export const OutcomesTable: FC<Props> = ({
  mapped,
  adapter,
  searchQuery,
  sport,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [fetchedIds, setFetchedIds] = useState<number[]>([]);
  const [outcomesPanel, setOutcomesPanel] = useState<null | {
    adapterOutcomeTypeId: string;
    marketName?: string;
  }>(null);

  useEffect(() => {
    dispatch(adapterActions.marketsFetch({ adapter }));
  }, [dispatch, adapter]);

  useEffect(() => {
    dispatch(adapterActions.typesReset());
  }, [dispatch, mapped, searchQuery, sport]);

  const entries = useSelector(getTypesEntries);

  const loading = useSelector(getAdapterTypesLoading);
  const hasMore = useSelector(getAdapterTypesHasMore);
  const items = useSelector(selectAdapterTypesItems);

  const adapterMarkets = useSelector(getAdapterMarketEntries);

  useEffect(() => {
    if (mapped && items.length) {
      const ids = items
        .filter(
          dataEntry =>
            !!dataEntry.sportsbookId &&
            !fetchedIds.includes(dataEntry.sportsbookId),
        )
        .map(dataEntry => dataEntry.sportsbookId!);
      if (ids.length) {
        dispatch(
          actionsNT.typesFetchItems({
            ids,
            limit: ids.length,
            withPagination: true,
          }),
        );
        setFetchedIds(prev => [...prev, ...ids]);
      }
    }
  }, [dispatch, mapped, items, fetchedIds]);

  const onLazyLoad = useCallback(
    ({ limit, offset }) => {
      dispatch(
        adapterActions.typesFetch({
          adapter,
          mapped,
          limit,
          offset,
          sportId: sport?.id,
          marketName: searchQuery.length
            ? searchQuery.toLowerCase()
            : undefined,
        }),
      );
    },
    [dispatch, adapter, mapped, searchQuery, sport],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [searchQuery, mapped, sport],
    initialPagination: {
      offset: 0,
      limit: 30,
    },
  });

  const handleBottomScroll = useCallback(() => {
    onBottom();
  }, [onBottom]);

  const scrollRef = useBottomScroll<HTMLDivElement>(handleBottomScroll);

  if (loading && !items.length) {
    return <LoadingIndicator type="full" />;
  }

  return (
    <S.TableWrapper ref={scrollRef}>
      <S.StyledTable key="mapped">
        {mapped && (
          <S.THead>
            <S.Tr>
              <S.Th weight={40}>От источника</S.Th>
              <S.Th weight={35}>В справочнике</S.Th>
              <S.Th weight={25} />
            </S.Tr>
          </S.THead>
        )}
        <S.TBody>
          {items.map(row => (
            <S.Tr key={row.id}>
              <S.Td>
                {row.marketId && adapterMarkets[row.marketId]?.name}{' '}
                {row.description}
              </S.Td>
              <S.Td>
                {mapped ? (
                  !fetchedIds.includes(row.sportsbookId!) ? (
                    <TextLoader />
                  ) : (
                    entries[row.sportsbookId!]?.name
                  )
                ) : null}
              </S.Td>
              <S.Td weight={15}>
                <S.StyledButton
                  key={'save'}
                  color="action"
                  onClick={() => {
                    setOutcomesPanel({
                      adapterOutcomeTypeId: row.id!,
                      marketName:
                        (row.marketId && adapterMarkets[row.marketId]?.name) ??
                        '',
                    });
                  }}
                >
                  {mapped ? (
                    <>
                      <FontAwesomeIcon icon={faEdit} />
                      {t('edit')}
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faLink} />
                      {t('map')}
                    </>
                  )}
                </S.StyledButton>
              </S.Td>
            </S.Tr>
          ))}
        </S.TBody>
      </S.StyledTable>
      {loading && <LoadingIndicator type="absolute" />}
      {!!outcomesPanel && (
        <AdapterOutcomesMappingPanel
          adapter={adapter}
          adapterOutcomeTypeId={outcomesPanel.adapterOutcomeTypeId}
          marketName={outcomesPanel.marketName}
          onClose={() => {
            setOutcomesPanel(null);
          }}
        />
      )}
    </S.TableWrapper>
  );
};
