import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SelectField, TextInputField } from 'app/components/forms';
import { Span } from 'app/components/ui';

import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';

import * as S from './styles';

export const SportForm = () => {
  const { t } = useTranslation();

  const sportItems = useSelector(selectSportsItems);

  return (
    <S.FormBlock>
      <Span>{t('name')}</Span>
      <TextInputField name="name" />
      <Span>{t('code')}</Span>
      <TextInputField name="code" />
      <Span>{t('main sport')}</Span>
      <S.StyledSelect>
        <SelectField name="genericSport" options={sportItems} isClearable />
      </S.StyledSelect>
    </S.FormBlock>
  );
};
