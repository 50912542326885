import { Sport } from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  sport: Yup.object().required('Required'),
});

export interface FormValues {
  name: string;
  sport?: Sport;
}
