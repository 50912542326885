import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { OutcomeMarket as OutcomeMarketType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';
import { getCategoriesEntries } from 'app/providers/EntitiesProvider/categories';

import { FormValues, validationSchema } from './constants';
import { MarketForm } from './MarketForm';

interface Props {
  id: number;
  onClose: () => void;
}

export const MarketEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const market: OutcomeMarketType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.MARKETS, id),
  );
  const categories = useSelector(getCategoriesEntries);

  useRequestState(Entities.MARKETS, 'update', onClose);

  useEffect(() => {
    if (market?.categoryId) {
      dispatch(
        actionsNT.categoriesFetchItem({ categoryId: market.categoryId }),
      );
    }
  }, [dispatch, market?.categoryId]);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.marketsUpdate({
        marketId: +id!,
        outcomeMarketWithOutId: {
          name: values.name,
          type: values.type?.id!,
          params: values.params.map(param => param.id),
          translations: market?.translations ?? [],
          categoryId: values.outcomeCategory?.id,
        },
      }),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={{
        name: market.name,
        params: market.params.map(param => ({
          id: param,
          name: param,
        })),
        outcomeCategory: market.categoryId
          ? categories[market.categoryId!]
          : undefined,
        type: {
          id: market.type,
          name: market.type,
        },
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.market')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <MarketForm />
        </Panel>
      )}
    </Formik>
  );
};
