import { createSelector } from '@reduxjs/toolkit';
import { EntityType } from 'sportsbook-openapi-react';

import { RootState } from 'types';

const getComments = (state: RootState) => state.entities.commentsByEntity;
const getCommentsSortedIds = (state: RootState) => getComments(state).sortedIds;
const getCommentsEntries = (state: RootState) => getComments(state).entries;

export const getCommentsByEntityTypeAndEntityId = (
  entityType: EntityType,
  entityId: number,
) =>
  createSelector(
    [getCommentsSortedIds, getCommentsEntries],
    (sortedIds, entries) =>
      sortedIds[`${entityType}_${entityId}`]?.map(id => entries[id]) ?? [],
  );
