import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Event, EventStatus } from 'sportsbook-openapi-react';

import { DatePicker, Select } from 'app/components/forms';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

import * as S from './EvenyResultForm.styles';

const statusOptions = Object.values(EventStatus).map(status => ({
  name: status,
  id: status,
}));

interface Props {
  event: Event;
}

export function EventResultForm({ event }: Props) {
  const { t } = useTranslation();
  const { isCustomer } = useUserStore(getIsCustomer);

  return (
    <S.Wrapper>
      {isCustomer ? (
        <>
          <S.FormField>
            <S.Label>{t('date and time of start')}:</S.Label>
            {dayjs(event.eventDate).format('HH:mm DD.MM.YYYY')}
          </S.FormField>
          <S.FormField>
            <S.Label>{t('status')}:</S.Label>
            {event.status}
          </S.FormField>
          <S.FormField>
            <S.Label>{t('note')}:</S.Label>
            <S.Label>{event.note ? event.note : t('no note')}</S.Label>
          </S.FormField>
        </>
      ) : (
        <>
          <S.FormField>
            <S.Label>{t('date and time of start')}</S.Label>
            <DatePicker
              size="m"
              showTimeSelect
              selected={new Date(event.eventDate)}
              timeFormat="p"
              dateFormat="dd/MM/yyyy HH:mm"
              onChange={() => {}}
            />
          </S.FormField>
          <S.FormField>
            <S.Label>{t('status')}</S.Label>
            <Select
              value={{ name: event.status, id: event.status }}
              size="m"
              options={statusOptions}
            />
          </S.FormField>
          <S.FormField>
            <S.Label>{t('note')}:</S.Label>
            <S.Label>{event.note ? event.note : t('no note')}</S.Label>
          </S.FormField>
        </>
      )}
    </S.Wrapper>
  );
}
