import { eventsApi } from 'app/api';

const api = {
  endpoint: eventsApi,
  methods: {
    create: 'suspendProbabilities',
  },
};

export default api;
