import { EntityType } from 'sportsbook-openapi-react';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider';

const getExclusionsSubstate = (state: RootState) =>
  getEntities(state).exclusions;

export const getExclusion = (state, type: EntityType, id: number) =>
  getExclusionsSubstate(state).entries[`${type}_${id}`];
