import { ThemeContext } from 'app/components/ui/ThemeProvider';

function getBorderColor(theme: ThemeContext, isFocused?: boolean) {
  if (isFocused) {
    return theme.colors.primary[700];
  }
  return theme.colors.primary[0];
}

function getHoverBorderColor(theme: ThemeContext, isFocused?: boolean) {
  if (isFocused) {
    return theme.colors.primary[700];
  }
  return theme.colors.primary[60];
}

function getBoxShadow(theme: ThemeContext, isFocused?: boolean) {
  if (isFocused) {
    return `0 0 1px 1px ${theme.colors.primary[700]}`;
  }
  return 'none';
}

export function getSelectFilterStyles(theme: ThemeContext) {
  return {
    container: provided => ({
      ...provided,
      marginLeft: '-6px',
      width: '100%',
      fontFamily: theme.fonts.family,
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      borderColor: getBorderColor(theme, isFocused),
      boxShadow: getBoxShadow(theme, isFocused),
      transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
      backgroundColor: theme.colors.primary[0],
      color: theme.colors.primary[500],
      height: 24,
      '&:hover': {
        borderColor: getHoverBorderColor(theme, isFocused),
      },
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: theme.fonts.typography['H200'].size,
      color: theme.colors.primary[200],
      lineHeight: '19px',
      whiteSpace: 'nowrap',
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: theme.fonts.typography['H200'].size,
      lineHeight: '19px',
    }),
    valueContainer: provided => ({
      ...provided,
      fontSize: theme.fonts.typography['H200'].size,
      padding: '1px 4px',
      height: 22,
    }),
    clearIndicator: provided => ({
      ...provided,
      cursor: 'pointer',
      padding: '4px 2px 4px 4px',
      color: theme.colors.primary[200],
      transition: 'color 0.2s ease',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      cursor: 'pointer',
      padding: '4px 4px 4px 2px',
      color: theme.colors.primary[200],
      transition: 'color 0.2s ease',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      padding: '1px',
      height: 22,
    }),
    menu: provided => ({
      ...provided,
      zIndex: 2,
    }),
    input: provided => ({
      ...provided,
      padding: 0,
      margin: '0 2px',
    }),
  };
}
