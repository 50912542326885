import { FC } from 'react';
import { useField } from 'formik';

import { TextInput } from 'app/components/forms';

import { TextInpupFieldProps } from './TextInputField.types';

export const TextInputField: FC<TextInpupFieldProps> = ({
  name,
  readOnly,
  disabled,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <TextInput
      {...field}
      {...props}
      invalid={!!meta.error}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};
