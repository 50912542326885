import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities, populateWithItems } from 'app/providers/EntitiesProvider';

const getCurrenciesSubstate = (state: RootState) =>
  getEntities(state).currencies;

export const selectCurrencies = createSelector(
  getCurrenciesSubstate,
  currencies => populateWithItems(currencies),
);
