import {
  SelectTableFilterProps,
  SortableHeaderProps,
} from 'app/components/ui/Table/Table.types';

import { SelectTableFilter, SortableHeader } from '..';

export function SortableSelectTableFilter<D extends object = {}>({
  options,
  ...props
}: SortableHeaderProps<D> & SelectTableFilterProps<D>) {
  return (
    <SortableHeader {...props}>
      <SelectTableFilter options={options} {...props} />
    </SortableHeader>
  );
}
