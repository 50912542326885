import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { USERS_FETCH_LIMIT } from 'app/pages/Users';
import { User, UserGroupEnum } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import { useRequestState } from 'hooks/useRequestState';

import { UserEditPanel } from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Dialog, LoadingIndicator, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import {
  getUsersHasMore,
  getUsersPaginationToken,
  selectUsersItems,
} from 'app/providers/EntitiesProvider/users';

import * as S from './UserList.styles';

interface Props {
  roleName?: string;
  searchQuery?: string;
}

export const UsersList: FC<Props> = ({ roleName, searchQuery }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [usernameToBeDeleted, setUsername] = useState('');
  const [editUserId, setEditUserId] = useState<null | string>(null);

  const { isLoading } = useRequestState(Entities.USERS, 'fetchItems');
  const items = useSelector(selectUsersItems);
  const hasMore = useSelector(getUsersHasMore);
  const paginationToken = useSelector(getUsersPaginationToken);

  useEffect(() => {
    dispatch(actionsNT.usersReset());
  }, [dispatch, roleName, searchQuery]);

  useEffect(() => {
    dispatch(
      actionsNT.usersFetchItems({
        ...(roleName !== '' && { roleName }),
        limit: USERS_FETCH_LIMIT,
        name: searchQuery?.length ? searchQuery.toLowerCase() : undefined,
      }),
    );
  }, [dispatch, roleName, searchQuery]);

  const data = useMemo(
    () => items.filter(user => (roleName ? user.group === roleName : true)),
    [items, roleName],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.USER_ACTIONS,
  });

  const columns: Column<User>[] = [
    {
      Header: t('username').toString(),
      accessor: 'name',
      Cell: ({ row: { original: data } }) => (
        <S.LowerCaseSpan>{data.name}</S.LowerCaseSpan>
      ),
    },
    {
      Header: t('access').toString(),
      accessor: 'group',
      Cell: ({ value }) => <S.LowerCaseSpan>{value}</S.LowerCaseSpan>,
    },
    {
      Header: t('actions').toString(),
      id: 'action',
      accessor: 'name',
      Cell: ({ row: { original: cell }, value }) => (
        <S.UserActions>
          <Button
            color="link"
            onClick={() => {
              openAudit({ id: String(value) });
            }}
          >
            {t('audit')}
          </Button>
          <RoleGuards roles={[UserGroupEnum.ADMIN]}>
            <Button
              color="link"
              onClick={() => {
                setEditUserId(cell.id);
              }}
            >
              {t('edit')}
            </Button>
            <Button
              color="link"
              onClick={() => {
                setUsername(value);
              }}
            >
              {t('delete')}
            </Button>
          </RoleGuards>
        </S.UserActions>
      ),
    },
  ];

  const onBottom = () => {
    if (hasMore) {
      dispatch(
        actionsNT.usersFetchItems({
          ...(roleName !== '' && { roleName }),
          limit: USERS_FETCH_LIMIT,
          ...(paginationToken !== '' && { paginationToken }),
        }),
      );
    }
  };

  return (
    <S.Wrapper>
      <Table
        columns={columns}
        data={data}
        columnsWidth={['auto', 'auto', 'fit-content']}
        onBottom={onBottom}
      />
      {isLoading && <LoadingIndicator type="absolute" />}
      {usernameToBeDeleted && (
        <Dialog
          text={t('deleteDialog', { name: usernameToBeDeleted })}
          onClose={() => setUsername('')}
          onConfirm={() =>
            dispatch(actionsNT.usersDelete({ username: usernameToBeDeleted }))
          }
        />
      )}
      {!!editUserId && (
        <UserEditPanel
          onClose={() => {
            setEditUserId(null);
          }}
          id={editUserId}
        />
      )}
    </S.Wrapper>
  );
};
