import ky from 'ky';
import {
  AdaptersApi,
  AuditApi,
  CashoutBlocksApi,
  CategoriesApi,
  CommentsApi,
  CompetitorsApi,
  Configuration,
  CountriesApi,
  CurrencyApi,
  CurrentUserApi,
  CustomersApi,
  DelaysApi,
  EventsApi,
  ExclusionsApi,
  FootballApi,
  GroupsApi,
  LanguagesApi,
  LogosApi,
  MarginsApi,
  MarketsApi,
  MatchPhasesApi,
  MatchTypesApi,
  MaxOddsApi,
  OutcomeCategorySourceMappingApi,
  PlayersApi,
  SearchApi,
  SourcesApi,
  SportsApi,
  TagsApi,
  TournamentsApi,
  TypesApi,
  UsersApi,
} from 'sportsbook-openapi-react';

import { API_URL } from 'consts';

const configuration = new Configuration({
  fetchApi: ky,
  basePath: API_URL,
  credentials: 'include',
});

export const currentUserApi = new CurrentUserApi(configuration);
export const customersApi = new CustomersApi(configuration);
export const usersApi = new UsersApi(configuration);
export const typesApi = new TypesApi(configuration);
export const tournamentsApi = new TournamentsApi(configuration);
export const tagsApi = new TagsApi(configuration);
export const sportsApi = new SportsApi(configuration);
export const sourcesApi = new SourcesApi(configuration);
export const searchApi = new SearchApi(configuration);
export const playersApi = new PlayersApi(configuration);
export const matchTypesApi = new MatchTypesApi(configuration);
export const matchPhasesApi = new MatchPhasesApi(configuration);
export const marketsApi = new MarketsApi(configuration);
export const marginsApi = new MarginsApi(configuration);
export const logosApi = new LogosApi(configuration);
export const languagesApi = new LanguagesApi(configuration);
export const groupsApi = new GroupsApi(configuration);
export const exclusionsApi = new ExclusionsApi(configuration);
export const outcomeCategorySourceMappingApi =
  new OutcomeCategorySourceMappingApi(configuration);
export const eventsApi = new EventsApi(configuration);
export const delaysApi = new DelaysApi(configuration);
export const currencyApi = new CurrencyApi(configuration);
export const countriesApi = new CountriesApi(configuration);
export const competitorsApi = new CompetitorsApi(configuration);
export const commentsApi = new CommentsApi(configuration);
export const cashoutBlocksApi = new CashoutBlocksApi(configuration);
export const categoriesApi = new CategoriesApi(configuration);
export const auditApi = new AuditApi(configuration);
export const adaptersApi = new AdaptersApi(configuration);
export const footballApi = new FootballApi(configuration);
export const maxOddsApi = new MaxOddsApi(configuration);

export { request } from './request';
