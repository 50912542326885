import { useCallback, useEffect, useState } from 'react';

const defaultPagination = {
  limit: 100,
  offset: 0,
};

type Pagination = typeof defaultPagination;

type useLazyLoadingProps = {
  onLazyLoad: (pagination: Pagination) => void;
  hasMore: boolean;
  initialPagination?: Pagination;
  extraDeps?: Array<any>;
  onPaginationReset?: () => void;
};

export const useLazyLoading = ({
  onLazyLoad,
  hasMore,
  initialPagination = defaultPagination,
  extraDeps = [],
  onPaginationReset = () => {},
}: useLazyLoadingProps) => {
  const [pagination, setPagination] = useState(initialPagination);

  useEffect(() => {
    setPagination(initialPagination);
    onPaginationReset();
    // TODO: solve extraDeps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...extraDeps]);

  useEffect(() => {
    onLazyLoad(pagination);
  }, [pagination, onLazyLoad]);

  return useCallback(() => {
    if (hasMore) {
      setPagination(pagination => ({
        ...pagination,
        offset: pagination.offset + pagination.limit,
      }));
    }
  }, [hasMore, setPagination]);
};
