import { PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash-es/keyBy';
import { EventMapping } from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';

const reducer = {
  eventsMappingsFetchItem(state) {
    state.eventsMappings.fetchItem.error = null;
    state.eventsMappings.fetchItem.loading = true;
  },
  eventsMappingsFetchItemSuccess(
    state,
    action: PayloadAction<{ mappings: Array<EventMapping>; eventId: number }>,
  ) {
    const { mappings, eventId } = action.payload;
    state.eventsMappings.entries[eventId] = keyBy(mappings, 'categoryId');
    state.eventsMappings.fetchItem.loading = false;
  },
  eventsMappingsFetchItemError(
    state,
    action: PayloadAction<EntitiesErrorType>,
  ) {
    state.eventsMappings.fetchItem.error = action.payload;
    state.eventsMappings.fetchItem.loading = false;
  },
  eventsMappingsCreate(state) {
    state.eventsMappings.create.error = null;
    state.eventsMappings.create.loading = true;
  },
  eventsMappingsCreateSuccess(
    state,
    action: PayloadAction<{
      mappings: EventMapping;
      eventId: number;
    }>,
  ) {
    const { mappings, eventId } = action.payload;
    if (state.eventsMappings.entries[eventId]) {
      state.eventsMappings.entries[eventId][mappings.categoryId] = mappings;
    } else {
      state.eventsMappings.entries[eventId] = {
        [mappings.categoryId]: mappings,
      };
    }
    state.eventsMappings.create.loading = false;
  },
  eventsMappingsCreateError(state, action: PayloadAction<EntitiesErrorType>) {
    state.eventsMappings.create.error = action.payload;
    state.eventsMappings.create.loading = false;
  },
  eventsMappingsUpdate(state) {
    state.eventsMappings.update.error = null;
    state.eventsMappings.update.loading = true;
  },
  eventsMappingsUpdateSuccess(
    state,
    action: PayloadAction<{
      mappings: EventMapping;
      eventId: number;
    }>,
  ) {
    const { mappings, eventId } = action.payload;
    if (state.eventsMappings.entries[eventId]) {
      state.eventsMappings.entries[eventId][mappings.categoryId] = mappings;
    } else {
      state.eventsMappings.entries[eventId] = {
        [mappings.categoryId]: mappings,
      };
    }
    state.eventsMappings.update.loading = false;
  },
  eventsMappingsUpdateError(state, action: PayloadAction<EntitiesErrorType>) {
    state.eventsMappings.update.error = action.payload;
    state.eventsMappings.update.loading = false;
  },
};

export default reducer;
