import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getEventSourceName } from 'app/pages/Events/utils/eventSource';
import { uniq } from 'lodash-es';
import {
  Event,
  EventSource,
  SportEventOddsLineType,
} from 'sportsbook-openapi-react';
import styled from 'styled-components';

import { RootState } from 'types';

import { Select } from 'app/components/forms';
import { Text } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getEventOddsByEventId } from 'app/providers/EntitiesProvider/eventsOdds';
import { selectSourcesItems } from 'app/providers/EntitiesProvider/sources';

export interface EventSourceOption {
  id: EventSource;
  name: string;
}

interface Props {
  eventId: Event['id'];
  mainSource: EventSource;
  onChange: (value: EventSourceOption) => void;
}

export const ChangeLiveSource: FC<Props> = ({
  eventId,
  mainSource,
  onChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const odds = useSelector((state: RootState) =>
    getEventOddsByEventId(state, eventId),
  );

  const eventMainSourceOptions = useMemo(() => {
    return uniq(
      odds?.categories.map(c => c.sources.map(s => s.source)).flat(),
    ).map(source => ({ name: getEventSourceName(source), id: source }));
  }, [odds]);
  const sources = useSelector(selectSourcesItems);

  useEffect(() => {
    if (!sources.length) {
      dispatch(actionsNT.sourcesFetchItems());
    }
  }, [dispatch, sources.length]);

  useEffect(() => {
    if (odds === undefined) {
      dispatch(
        actionsNT.eventsOddsFetchItem({
          eventId,
          lineType: SportEventOddsLineType.PRE,
        }),
      );
    }
  }, [dispatch, eventId, odds]);

  if (eventMainSourceOptions.length === 0) return null;

  return (
    <Wrapper>
      <Text>
        {t('current')}: {getEventSourceName(mainSource)}
      </Text>
      <Select
        options={eventMainSourceOptions}
        onChange={value => onChange(value as EventSourceOption)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 5px;
`;
