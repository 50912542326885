import {
  CountriesApiDeleteCountryRequest,
  Country,
  CountryList,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    CountryList,
    Country,
    CountriesApiDeleteCountryRequest,
    EntitiesErrorType
  >({
    entity: 'countries',
  }),
};

export default reducer;
