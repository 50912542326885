import { Action, PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { entityInitialState, initialState } from './constants';
import {
  AuditedEntities,
  AuditProviderState,
  GetAuditError,
  GetAuditPayload,
  GetAuditResponse,
  OpenPanelProps,
} from './types';

const auditProviderSlice = createSlice({
  name: 'auditProvider',
  initialState,
  reducers: {
    getAudit: <T extends AuditedEntities>(
      state: AuditProviderState,
      action: PayloadAction<GetAuditPayload<T>>,
    ) => {
      if (!state.entities[action.payload.entity]) {
        state.entities[action.payload.entity] = {
          ...entityInitialState,
          isLoading: true,
        };
      } else {
        state.entities[action.payload.entity].isLoading = true;
      }
    },
    getAuditSuccess: (state, action: PayloadAction<GetAuditResponse>) => {
      const { items, paginationToken } = action.payload.response;

      state.entities[action.payload.entity].isLoading = false;
      if (items) {
        state.entities[action.payload.entity].items.push(...items);
      }
      if (paginationToken) {
        state.entities[action.payload.entity].paginationToken = paginationToken;
      }
    },
    getAuditError: (state, action: PayloadAction<GetAuditError>) => {
      state.entities[action.payload.entity].isLoading = false;
    },
    resetAuditEntity: (
      state,
      action: PayloadAction<{ entity: AuditedEntities }>,
    ) => {
      state.entities[action.payload.entity] = entityInitialState;
    },
    someAction() {},
    openPanel: <T extends AuditedEntities>(
      state,
      action: PayloadAction<OpenPanelProps<T>>,
    ) => {
      state.panel = action.payload;
    },
    removePanel(state) {
      state.panel = null;
    },
  },
});

export const { reducer, name: sliceKey } = auditProviderSlice;

export const actions: Omit<typeof auditProviderSlice.actions, 'getAudit'> & {
  getAudit: <T extends AuditedEntities>(payload: GetAuditPayload<T>) => Action;
  openPanel: <T extends AuditedEntities>(
    panelProps: OpenPanelProps<T>,
  ) => Action;
} = auditProviderSlice.actions;
