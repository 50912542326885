import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EventFormik } from 'app/pages/Events/components/EventFormik';
import { getHomeAwayCompetitors } from 'app/pages/Events/utils';
import { EventKind, Group, MatchType, Sport } from 'sportsbook-openapi-react';

import { IS_DELETION_DISABLED } from 'consts';
import { RootState } from 'types';

import { LoadingIndicator } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { useEvent } from 'app/providers/EntitiesProvider/events';
import { selectGroup } from 'app/providers/EntitiesProvider/groups';
import { getMatchType } from 'app/providers/EntitiesProvider/matchTypes';
import { getSportById } from 'app/providers/EntitiesProvider/sports';

import * as S from './EditEvent.styles';

export const EditEvent: FC = () => {
  const [lockedGroup, setLockedGroup] = useState<Group | null>(null);
  const [lockedSport, setLockedSport] = useState<Sport | null>(null);
  const [lockedMatchType, setLockedMatchType] = useState<MatchType | null>(
    null,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { event, loading, error } = useEvent();

  const group = useSelector((state: RootState) =>
    event?.groupId ? selectGroup(state, event.groupId) : undefined,
  );
  const sport = useSelector(state =>
    getSportById(state, event?.tournament?.sportId!),
  );
  const matchType = useSelector(state =>
    getMatchType(state, event?.matchTypeId),
  );

  useEffect(() => {
    if (event?.groupId) {
      dispatch(actionsNT.groupsFetchItem({ groupId: event.groupId }));
    }
  }, [dispatch, event?.groupId]);

  useEffect(() => {
    if (event?.matchTypeId) {
      dispatch(
        actionsNT.matchTypesFetchItem({ matchTypeId: event.matchTypeId }),
      );
    }
  }, [dispatch, event?.matchTypeId]);

  useEffect(() => {
    if (event?.tournament?.sportId) {
      dispatch(
        actionsNT.sportsFetchItem({ sportId: event.tournament?.sportId }),
      );
    }
  }, [dispatch, event?.tournament?.sportId]);

  useEffect(() => {
    if (group) {
      setLockedGroup(group);
    }
  }, [group]);

  useEffect(() => {
    if (sport) {
      setLockedSport(sport);
    }
  }, [sport]);

  useEffect(() => {
    if (matchType) {
      setLockedMatchType(matchType);
    }
  }, [matchType]);

  const handleDelete = () => {
    if (event) {
      dispatch(actionsNT.eventsDelete({ eventId: event.id }));
      navigate('/events');
    }
  };

  const initialValues = useMemo(() => {
    if (event && lockedGroup && lockedSport) {
      const { homeCompetitor, awayCompetitor } = getHomeAwayCompetitors(event);
      return {
        sport: lockedSport,
        group: lockedGroup,
        matchType: lockedMatchType,
        date: new Date(event.eventDate),
        time: new Date(event.eventDate),
        tournament: event.tournament,
        competitorA: homeCompetitor,
        competitorB: awayCompetitor,
        status: { id: event.status, name: event.status },
        kind: event.kind
          ? { id: event.kind, name: event.kind }
          : { id: EventKind.GENERAL, name: EventKind.GENERAL },
        note: event.note,
      };
    }
  }, [event, lockedGroup, lockedMatchType, lockedSport]);

  return (
    <S.Wrapper>
      <S.EditEventHeader>
        <S.StyledPageHeader>{t('event editing')}</S.StyledPageHeader>
        <S.EditEventdButton
          color="link"
          onClick={handleDelete}
          disabled={IS_DELETION_DISABLED}
        >
          {t('delete')}
        </S.EditEventdButton>
      </S.EditEventHeader>
      <S.EditEventContainer>
        {!event || !initialValues || loading ? (
          <S.EditEventLoading>
            <LoadingIndicator />
          </S.EditEventLoading>
        ) : error ? (
          <S.EditEventError>{t('event error')}</S.EditEventError>
        ) : (
          <EventFormik initialEvent={initialValues} type="edit" event={event} />
        )}
      </S.EditEventContainer>
    </S.Wrapper>
  );
};
