import { useTranslation } from 'react-i18next';

import { TextInputField } from 'app/components/forms';
import { Span } from 'app/components/ui';

import * as S from './styles';

export const TagForm = () => {
  const { t } = useTranslation();

  return (
    <S.FormBlock>
      <Span>{t('name')}</Span>
      <TextInputField name="name" />
    </S.FormBlock>
  );
};
