import { matchPhasesApi } from 'app/api';

const api = {
  endpoint: matchPhasesApi,
  methods: {
    fetchItems: 'getMatchPhases',
    fetchItem: 'getMatchPhase',
    create: 'createMatchPhase',
    update: 'updateMatchPhase',
    delete: 'deleteMatchPhase',
  },
};

export default api;
