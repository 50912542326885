import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Event, UserGroupEnum } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import {
  useEventDependenciesUpdate,
  useTournamentsDependenciesUpdate,
} from 'app/components/panels/dependency/hooks';
import { PanelFooter } from 'app/components/panels/PanelFooter';
import { RoleGuards } from 'app/components/RoleGuards';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';

import { EditForm } from './EditForm';
import type { EventDependencyFormValues } from './types';

interface Props {
  onClose: () => void;
  id: number;
}

export const EventDependencyPanel = ({ id, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const event: Event = useSelector(state =>
    getEntityByTypeAndId(state, Entities.EVENTS, id),
  );

  if (!event) {
    throw new Error('Not valid eventId provided');
  }
  const tournament = event.tournament!;

  useEffect(() => {
    const tournamentIds = [
      ...event.dependentTournaments,
      ...tournament.dependentTournaments,
      tournament.id,
    ];

    dispatch(
      actionsNT.eventsFetchItems({
        ids: event.dependentEvents,
        withPagination: true,
        limit: event.dependentEvents.length,
      }),
    );
    dispatch(
      actionsNT.tournamentsFetchItems({
        ids: tournamentIds,
        limit: tournamentIds.length,
      }),
    );
  }, [
    dispatch,
    event.dependentEvents,
    event.dependentTournaments,
    tournament.dependentTournaments,
    tournament.id,
  ]);

  const { isLoading: isEventsUpdateLoading } = useRequestState(
    Entities.EVENTS,
    'update',
    onClose,
  );

  const { isLoading: isTournamentsUpdateLoading } = useRequestState(
    Entities.TOURNAMENTS,
    'update',
    onClose,
  );

  const updateTournaments = useTournamentsDependenciesUpdate();
  const updateEvents = useEventDependenciesUpdate();
  const onSubmit = (values: EventDependencyFormValues) => {
    updateTournaments(tournament, values.tournament.dependentTournaments);
    updateEvents(
      event,
      values.event.dependentEvents,
      values.event.dependentTournaments,
    );
  };

  return (
    <Formik<EventDependencyFormValues>
      initialValues={{
        event,
        tournament,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Panel
          title={t('dependencies')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <RoleGuards
              roles={[
                UserGroupEnum.ADMIN,
                UserGroupEnum.OPERATOR,
                UserGroupEnum.SUPERVISOR,
              ]}
            >
              <PanelFooter
                submitButtonText={t('save')}
                disabled={isEventsUpdateLoading || isTournamentsUpdateLoading}
              />
            </RoleGuards>
          }
        >
          <EditForm event={event} tournament={tournament} />
        </Panel>
      )}
    </Formik>
  );
};
