import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const EventsList = styled.div`
  display: grid;
  grid-template-rows: 56px minmax(0, 1fr);
  grid-gap: 16px;
  height: 100%;
  padding-bottom: 25px;
`;

export const EventsTableWrapper = styled.div`
  padding: 0 25px;
  position: relative;
`;

export const EventsListError = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${p => p.theme.colors.blue[300]};
  &:visited {
    color: ${p => p.theme.colors.blue[300]};
  }
`;
