import styled from 'styled-components';

export const Panel = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 56px) minmax(0, 1fr);

  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
`;

export const DropdownWrapper = styled.div`
  padding: 0 20px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #e5e5e5;
`;

export const PanelHeading = styled.div`
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  align-items: center;
`;

export const PanelBlock = styled.div`
  padding: 10px 20px;
`;
