/**
 *
 * DateRangePicker
 *
 */
import { FC } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

import { useLocale } from 'utils/locale';

import { DateRangePickerWrapper, StyledIcon } from './DateRangePicker.styles';
import { DateRangePickerProps } from './DateRangePicker.types';

import 'react-datepicker/dist/react-datepicker.css';

export const DateRangePicker: FC<DateRangePickerProps> = ({
  size = 's',
  testId,
  onStartDateChange,
  onEndDateChange,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const handleStartDateChange = date => {
    onStartDateChange(date);
  };

  const handleEndDateChange = date => {
    onEndDateChange(date);
  };

  const commonProps = {
    dateFormat: 'dd.MM.yy HH:mm',
    timeIntervals: 15,
    placeholderText: t('date time placeholder'),
    timeFormat: 'p',
    showTimeSelect: true,
    locale,
  };

  return (
    <DateRangePickerWrapper data-test-id={testId} size={size}>
      <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        {...commonProps}
      />
      –
      <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        selectsEnd
        {...commonProps}
      />
      <StyledIcon icon={faCalendar} />
    </DateRangePickerWrapper>
  );
};
