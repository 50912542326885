import { FC, ReactNode, useState } from 'react';
import { PLACEMENT_TYPES, useLayer } from 'react-laag';

import * as S from './Popup.styles';
interface Props {
  button: ReactNode;
  body: ReactNode;
  placement?: ArrayElement<typeof PLACEMENT_TYPES>;
}

export const Popup: FC<Props> = ({
  button,
  body,
  placement = 'bottom-start',
}) => {
  const [isOpen, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen,
    placement,
    onOutsideClick: close,
    triggerOffset: 10,
    auto: true,
  });

  const handleToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(prev => !prev);
  };

  return (
    <>
      <S.IconWrapper {...triggerProps} onClick={handleToggle}>
        {button}
      </S.IconWrapper>
      {isOpen &&
        renderLayer(
          <S.Wrapper {...layerProps} onClick={handleToggle}>
            {body}
          </S.Wrapper>,
        )}
    </>
  );
};
