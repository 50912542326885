import { adaptersApi } from 'app/api';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Adapter } from 'sportsbook-openapi-react';

import { apiMap } from './constants';
import { adapterActions } from './slice';
import { AdaptersProviderState } from './types';

function* handleFetchAdapters() {
  try {
    const response: Adapter[] = yield call(function () {
      return adaptersApi.getAdapters();
    });

    yield put(adapterActions.fetchAdaptersSuccess(response));
  } catch (e) {
    yield put(adapterActions.competitorsFetchFailure(e));
  }
}

const methodHandleGenerator = (
  entity: keyof AdaptersProviderState['entities'],
  method,
  options?: {
    wrapItems?: boolean;
    extraData?: {
      req?: string;
      res?: string;
    };
  },
) =>
  function* (action) {
    try {
      const data = yield call(function () {
        return adaptersApi[apiMap[entity][method]!](action.payload);
      });

      const response = options?.wrapItems ? { items: data } : data;
      if (options?.extraData?.req) {
        response[options.extraData.req] = action.payload[options.extraData.req];
      }

      if (options?.extraData?.res) {
        response[options.extraData.res] = data;
      }

      const successAction = `${entity}${method}Success`;

      if (adapterActions[successAction]) {
        yield put(adapterActions[successAction](response));
      }
    } catch (e) {
      const result = { error: e };
      if (options?.extraData?.req) {
        result[options.extraData.req] = action.payload[options.extraData.req];
      }
      const failureAction = `${entity}${method}Failure`;

      if (adapterActions[failureAction]) {
        yield put(adapterActions[failureAction](result));
      }
    }
  };

const entitySagasGenerator = (
  entity: keyof AdaptersProviderState['entities'],
  options?: {
    wrapItems?: boolean;
    extraData?: {
      req?: string;
      res?: string;
    };
  },
) =>
  all(
    Object.keys(apiMap[entity]).map(methodKey =>
      takeEvery(
        adapterActions[`${entity}${methodKey}`].type,
        methodHandleGenerator(entity, methodKey, options),
      ),
    ),
  );

export function* adaptersProviderSaga() {
  return yield all([
    takeEvery(adapterActions.fetchAdapters.type, handleFetchAdapters),
    entitySagasGenerator('competitors'),
    entitySagasGenerator('markets', { wrapItems: true }),
    entitySagasGenerator('players'),
    entitySagasGenerator('types'),
    entitySagasGenerator('events'),
    entitySagasGenerator('eventProbabilities'),
    entitySagasGenerator('sports', { wrapItems: true }),
    entitySagasGenerator('tournaments'),
    entitySagasGenerator('countries'),
    entitySagasGenerator('parameters', { wrapItems: true }),
    entitySagasGenerator('matchPhases'),
    entitySagasGenerator('mappedEvents', {
      extraData: {
        req: 'ids',
      },
    }),
  ]);
}
