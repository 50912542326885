import { Event } from 'sportsbook-openapi-react';

import { UpdateRequestState } from 'app/providers/EntitiesProvider';

export const isUpdated = (update: UpdateRequestState<Event>): boolean =>
  !update.loading && !update.error && !!update.id;

export enum DependencyEnum {
  EVENT = 'EVENT_DEPENDENCY',
  TOURNAMENT = 'TOURNAMENT_DEPENDENCY',
}

export interface CreateDependencyFormValues {
  type: DependencyEnum;
  dependentType: DependencyEnum;
  dependentId?: number;
}
