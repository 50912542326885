import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Competitor,
  CompetitorType,
  LogoEntityType,
} from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import useBooleanState from 'hooks/useBooleanState';

import {
  AsyncSelectField,
  SelectField,
  TextInputField,
} from 'app/components/forms';
import { LogoUploader } from 'app/components/LogoUploader';
import { PlayerCreatePanel } from 'app/components/panels/player';
import { Span } from 'app/components/ui';

import { getCountriesEntries } from 'app/providers/EntitiesProvider/countries';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import * as S from './styles';

interface Props {
  actionType: 'create' | 'edit';
  sportId?: number;
  competitor?: Competitor;
}

const typesOptions = Object.values(CompetitorType).map(value => ({
  id: value,
  name: value,
}));

export const CompetitorForm = ({ actionType, sportId, competitor }: Props) => {
  const { t } = useTranslation();

  const sports = useSelector(getSportsEntries);
  const countries = useSelector(getCountriesEntries);

  const [isCreatePlayerVisible, showCreatePlayer, hideCreatePlayer] =
    useBooleanState();

  const createPlayerOption = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreatePlayer,
  };

  return (
    <>
      <S.FormBlock>
        <Span>{t('name')}</Span>
        <TextInputField name="name" />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('sport')}</Span>
        <SelectField
          name="sport"
          options={Object.values(sports)}
          readOnly={!!(sportId && actionType === 'create')}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('country')}</Span>
        <SelectField name="country" options={Object.values(countries)} />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('type')}</Span>
        <SelectField name="type" options={typesOptions} />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('player')}</Span>
        <AsyncSelectField
          isClearable
          name="player"
          loadOptions={getAsyncLoadOptions({
            entity: 'players',
            createOption: createPlayerOption,
          })}
        ></AsyncSelectField>
      </S.FormBlock>
      {actionType === 'edit' && !!competitor?.id && (
        <LogoUploader
          entityType={LogoEntityType.COMPETITOR}
          entityId={competitor.id}
        />
      )}
      {isCreatePlayerVisible && (
        <PlayerCreatePanel onClose={hideCreatePlayer} />
      )}
    </>
  );
};
