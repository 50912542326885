// eslint-disable-next-line simple-import-sort/imports
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'sanitize.css/sanitize.css';
import './locales/i18n';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { App } from 'app';

import { GlobalStyle } from 'styles/global-styles';
import { configureAppStore } from 'store/configureStore';
import { ThemeProvider } from './app/components/ui';

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

const store = configureAppStore();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<>{'An error has occurred'}</>}>
      <ReduxProvider store={store}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </QueryClientProvider>
          <GlobalStyle />
        </HelmetProvider>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
);

const getEnvironment = () => {
  const host = window.location.host;
  if (host.includes('almabook.net')) return 'production';
  if (host.includes('stage')) return 'stage';
  return 'sandbox';
};

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    environment: getEnvironment(),
    dsn: 'https://f338a94f549748cfae8040ea250e74a3@o495997.ingest.sentry.io/5689171',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
  });
}
