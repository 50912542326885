import { competitorsApi } from 'app/api';

const api = {
  endpoint: competitorsApi,
  methods: {
    fetchItems: 'getCompetitors',
    fetchItem: 'getCompetitor',
    create: 'createCompetitor',
    update: 'updateCompetitor',
    delete: 'deleteCompetitor',
  },
};

export default api;
