import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import { Adapter } from 'sportsbook-openapi-react';

import { Panel, Table } from 'app/components/ui';

import { selectAdapters } from 'app/providers/AdaptersProvider';

import { useAdapterCompetitors } from './useAdapterCompetitors';

interface Props {
  id?: number;
  onClose: () => void;
}

export const AdapterCompetitorsNamesPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const adapters = useSelector(selectAdapters);
  const adapterCompetitors = useAdapterCompetitors({ adapters, id });

  const columns: Column<Adapter>[] = [
    {
      Header: t('source').toString(),
      accessor: 'name',
      id: 'adapterName',
      Cell: ({ value }) => <>{value}</>,
    },
    {
      Header: t('adapter translation').toString(),
      accessor: 'name',
      id: 'competitorName',
      Cell: ({ value }) => <>{adapterCompetitors[value!]}</>,
    },
  ];

  return (
    <Panel
      title={t(`panels.show.adapterCompetitorsNames`)}
      onClose={onClose}
      panelWidth={30}
    >
      <Table
        data={adapters}
        columns={columns}
        columnsWidth={['auto', 'auto']}
      />
    </Panel>
  );
};
