import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniq } from 'lodash-es';
import { Tournament } from 'sportsbook-openapi-react';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getTournamentsEntries } from 'app/providers/EntitiesProvider/tournaments';

import { getDependenciesLists } from './getDependenciesLists';

export const useTournamentsDependenciesUpdate = () => {
  const dispatch = useDispatch();
  const tournaments = useSelector(getTournamentsEntries);

  const updateTournamentDependencies = useCallback(
    (
      tournamentId: Tournament['id'],
      {
        idsToAdd = [],
        idsToRemove = [],
      }: {
        idsToRemove?: Tournament['id'][];
        idsToAdd?: Tournament['id'][];
      },
    ) => {
      const tournament = tournaments[tournamentId];

      const dependentTournaments = uniq([
        ...tournament.dependentTournaments,
        ...idsToAdd,
      ]).filter(id => !idsToRemove.includes(id));

      dispatch(
        actionsNT.tournamentsUpdate({
          tournamentId: tournament.id,
          tournamentWithOutId: {
            ...tournament,
            dependentTournaments,
          },
        }),
      );
    },
    [dispatch, tournaments],
  );

  return useCallback(
    (currentTournament: Tournament, dependentTournaments: number[]) => {
      // Get list of removed ids and currently selected ones
      const { removedIds, dependentIds } = getDependenciesLists(
        currentTournament.dependentTournaments,
        dependentTournaments,
      );

      /**
       * Remove current tournamentId from other tournaments
       */
      removedIds.forEach(dependentTournamentId => {
        updateTournamentDependencies(dependentTournamentId, {
          idsToRemove: [currentTournament.id],
        });
      });

      /**
       * Update currently selected tournaments
       * Add current tournament id and avoid dependency of tournament to itself
       */
      dependentIds.forEach(dependentTournamentId => {
        updateTournamentDependencies(dependentTournamentId, {
          idsToAdd: [
            currentTournament.id,
            ...dependentIds.filter(id => id !== dependentTournamentId),
          ],
        });
      });

      /**
       * Update the main tournament
       */
      updateTournamentDependencies(currentTournament.id, {
        idsToRemove: removedIds,
        idsToAdd: dependentIds,
      });
    },
    [updateTournamentDependencies],
  );
};
