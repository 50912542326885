import styled from 'styled-components';

import { Header, Navigation } from './components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 56px minmax(0, 1fr);
  grid-template-columns: 56px 1fr;
  grid-template-areas:
    'header header'
    'navigation content';
  color: ${p => p.theme.colors.primary[700]};
`;

export const StyledNavigation = styled(Navigation)`
  grid-area: navigation;
`;

export const StyledHeader = styled(Header)`
  grid-area: header;
`;

export const Content = styled.div`
  grid-area: content;
  border-top: 1px solid ${p => p.theme.colors.primary[40]};
`;
