import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { OutcomeCategory, Sport } from 'sportsbook-openapi-react';

import { useSearch } from 'hooks/useSearch';
import { FETCH_ALL_LIMIT } from 'consts';
import { useTheme } from 'styles';

import { Select, TextInput } from 'app/components/forms';
import { Table } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectCategoriesItems } from 'app/providers/EntitiesProvider/categories';
import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';

import * as S from './Panel.styles';

export const CrossMappingsPanel: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const navigate = useNavigate();
  const allSportsOption = { id: 0, name: t('all'), code: '' };
  const [sport, setSport] = useState<Sport>(allSportsOption);
  const sports = useSelector(selectSportsItems);
  const categories = useSelector(selectCategoriesItems);

  useEffect(() => {
    dispatch(
      actionsNT.categoriesFetchItems({
        limit: FETCH_ALL_LIMIT,
        name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
      }),
    );
  }, [dispatch, searchQuery]);

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.marketsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const theme = useTheme();

  const filterOptions = [allSportsOption, ...sports];

  const columns: Column<OutcomeCategory>[] = [
    { Header: t('name').toString(), accessor: 'name' },
  ];

  const filteredData = useMemo(
    () =>
      categories.filter(category => {
        if (sport?.id) {
          return category.sports.includes(sport.id);
        }
        return true;
      }),
    [categories, sport],
  );

  return (
    <S.Panel>
      <S.DropdownWrapper>
        <DictionaryDropdown value={t('cross-mapping')} />
      </S.DropdownWrapper>
      <S.PanelHeading>
        <TextInput
          placeholder={t('search')}
          onChange={e => setSearchQueryDebounced(e.target.value)}
          icon={<FontAwesomeIcon icon={faSearch} />}
        />
        <Select
          placeholder={t('sport type')}
          value={sport}
          options={filterOptions}
          onChange={element => setSport(element as Sport)}
        />
      </S.PanelHeading>
      <S.PanelBlock>
        <Table
          columns={columns}
          data={filteredData}
          columnsWidth={['auto', 'fit-content']}
          onRowClick={data => {
            navigate(`/dictionary/cross-mappings/${data.id}`, {
              replace: true,
            });
          }}
          rowStyles={row => {
            return row.disabled
              ? `background-color: ${theme.colors.primary[50]} !important;
              &:hover {
                background-color: ${theme.colors.primary[50]} !important;
              }
              `
              : '';
          }}
        />
      </S.PanelBlock>
    </S.Panel>
  );
};
