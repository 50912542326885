import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import { Event, UserGroupEnum } from 'sportsbook-openapi-react';
import styled from 'styled-components';

import { RoleGuards } from 'app/components/RoleGuards';
import { Button } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';

import { EventSelect } from './EventSelect';

export const SelectEvent = ({
  name,
  excludedEvents,
}: {
  name: string;
  excludedEvents: Event[];
}) => {
  const dispatch = useDispatch();
  const [event, setEvent] = useState<Maybe<Event>>(null);
  const { t } = useTranslation();
  const [field, , helpers] = useField<number[]>(name);
  const eventIds = field.value;

  const handleAddEvent = () => {
    if (event) {
      helpers.setValue([...eventIds, event.id]);
      dispatch(
        actionsNT.eventsFetchItem({ eventId: event.id, ignoreLoading: true }),
      );
      setEvent(null);
    }
  };
  return (
    <RoleGuards
      roles={[
        UserGroupEnum.ADMIN,
        UserGroupEnum.OPERATOR,
        UserGroupEnum.SUPERVISOR,
      ]}
    >
      <AddBlockWrapper>
        <EventSelect
          value={event}
          onChange={setEvent}
          excludedEvents={excludedEvents}
        />
        <Button color="action" type="button" onClick={handleAddEvent}>
          <FontAwesomeIcon icon={faPlus} />
          {t('add dependency')}
        </Button>
      </AddBlockWrapper>
    </RoleGuards>
  );
};

const AddBlockWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-column-gap: 16px;
`;
