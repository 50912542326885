import { Column } from 'react-table';
import { CategoryCashoutBlock } from 'sportsbook-openapi-react';

import CashoutCell from './CashoutCell';

interface Props {
  header: string;
  accessor: string;
  categoryCashoutBlocks: Dictionary<CategoryCashoutBlock>;
  updateCategoryCashoutBlock: (
    categoryId: number,
    cashoutBlocked: boolean,
  ) => void;
}

export const CashoutColumn = <T extends object>({
  header,
  accessor,
  categoryCashoutBlocks,
  updateCategoryCashoutBlock,
}: Props): Column<T> => {
  return {
    Header: header,
    id: 'categoryCashoutBlock',
    accessor: accessor as keyof T,
    Cell: ({ value: categoryId }) => (
      <CashoutCell
        blocked={categoryCashoutBlocks[categoryId]?.cashoutBlocked}
        categoryId={categoryId}
        updateCategoryCashoutBlock={updateCategoryCashoutBlock}
      />
    ),
  };
};
