import { PayloadAction } from '@reduxjs/toolkit';
import uniq from 'lodash-es/uniq';
import {
  Margin,
  MarginList,
  MarginsApiDeleteMarginRequest,
  MarginsApiGetMarginEventListRequest,
} from 'sportsbook-openapi-react';

import {
  EntitiesErrorType,
  PaginatedGetRequest,
} from 'app/providers/EntitiesProvider/types';
import {
  entityReducersGenerator,
  toIdsArray,
  toIdsMap,
} from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    MarginList,
    Margin,
    MarginsApiDeleteMarginRequest,
    EntitiesErrorType
  >({
    entity: 'margins',
  }),
  marginsByEventFetchItems(
    state,
    action: PayloadAction<
      PaginatedGetRequest<MarginsApiGetMarginEventListRequest>
    >,
  ) {
    state.margins.fetchItems.error = null;
    state.margins.fetchItems.loading = true;
    state.margins.withPagination = action.payload?.withPagination;
    state.margins.onlyEntries = action.payload?.onlyEntries;
  },
  marginsByEventFetchItemsSuccess(state, action: PayloadAction<MarginList>) {
    const { items, hasMore } = action.payload;
    if (!state.margins.onlyEntries) {
      if (state.margins.withPagination) {
        state.margins.sortedIds = uniq([
          ...state.margins.sortedIds,
          ...toIdsArray(items),
        ]);
      } else {
        state.margins.sortedIds = toIdsArray(items);
      }
    }
    state.margins.entries = {
      ...state.margins.entries,
      ...toIdsMap(items),
    };
    state.margins.fetchItems.loading = false;
    state.margins.hasMore = hasMore;
  },
  marginsByEventFetchItemsError(state, action: PayloadAction<Error>) {
    state.margins.fetchItems.error = action.payload;
    state.margins.fetchItems.loading = false;
  },
};

export default reducer;
