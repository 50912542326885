import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { UserGroupEnum } from 'sportsbook-openapi-react';

import { ls } from 'utils/ls';
import useBooleanState from 'hooks/useBooleanState';
import { IS_DELETION_DISABLED, IS_DELETION_DISABLED_KEY } from 'consts';

import ApiProvider from 'app/providers/ApiProvider';
import { AuditProvider } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import UserProvider from 'app/providers/UserProvider';

import { useUserStore } from 'store';
import { getUser } from 'store/user';

import { Layout } from './components/Layout/Layout';
import { RoleGuards } from './components/RoleGuards';
import { Dialog } from './components/ui';
import { Comments } from './pages/Dictionary/Comments';
import { Competitors } from './pages/Dictionary/Competitors';
import { Countries } from './pages/Dictionary/Countries';
import { CrossMapping } from './pages/Dictionary/CrossMapping';
import { CrossMappingsContent } from './pages/Dictionary/CrossMapping/components/Content';
import { Currencies } from './pages/Dictionary/Currencies';
import { Directory } from './pages/Dictionary/Directory';
import { DirectoryContent } from './pages/Dictionary/Directory/components/Content';
import { Languages } from './pages/Dictionary/Languages';
import { Markets } from './pages/Dictionary/Markets';
import { MatchPhases } from './pages/Dictionary/MatchPhases';
import { MatchTypes } from './pages/Dictionary/MatchTypes';
import { Outcomes } from './pages/Dictionary/Outcomes';
import { OutcomesContent } from './pages/Dictionary/Outcomes/components/Content';
import { Players } from './pages/Dictionary/Players';
import { Tags } from './pages/Dictionary/Tags';
import {
  Dictionary,
  Events,
  Live,
  Results,
  Sources,
  Translations,
  Users,
} from './pages';

const AppProviders = ({ children }) => (
  <ApiProvider>
    <UserProvider>
      <AuditProvider>{children}</AuditProvider>
    </UserProvider>
  </ApiProvider>
);

export const App = () => {
  const user = useUserStore(getUser);
  const dispatch = useDispatch();
  const [isNotificationVisible, showNotification, hideNotification] =
    useBooleanState();

  useEffect(() => {
    const isDeletionDialogShowed = ls.getItem(IS_DELETION_DISABLED_KEY);
    if (IS_DELETION_DISABLED && isDeletionDialogShowed !== '1') {
      showNotification();
    }
  }, [showNotification]);

  useEffect(() => {
    if (user?.id) {
      dispatch(
        actionsNT.eventFavoritesFetchItems({
          userId: user.id,
        }),
      );
    }
  }, [dispatch, user.id]);

  return (
    <AppProviders>
      <Helmet titleTemplate="%s - SportsBook" defaultTitle="SportsBook">
        <meta name="description" content="SportsBook application" />
      </Helmet>
      <Router>
        <Layout user={user}>
          <Routes>
            <Route path="/dictionary" element={<Dictionary />}>
              <Route
                path="outcomes"
                element={
                  <RoleGuards
                    roles={[
                      UserGroupEnum.ADMIN,
                      UserGroupEnum.SUPERVISOR,
                      UserGroupEnum.TRANSLATOR,
                      UserGroupEnum.OPERATOR,
                    ]}
                    withRedirect={true}
                  >
                    <Outcomes />
                  </RoleGuards>
                }
              >
                <Route path={':id/:outcomeId?'} element={<OutcomesContent />} />
              </Route>
              <Route
                path="countries"
                element={
                  <RoleGuards
                    roles={[
                      UserGroupEnum.ADMIN,
                      UserGroupEnum.SUPERVISOR,
                      UserGroupEnum.TRANSLATOR,
                      UserGroupEnum.OPERATOR,
                    ]}
                    withRedirect={true}
                  >
                    <Countries />
                  </RoleGuards>
                }
              />

              <Route
                path="competitors"
                element={
                  <RoleGuards
                    roles={[
                      UserGroupEnum.ADMIN,
                      UserGroupEnum.SUPERVISOR,
                      UserGroupEnum.TRANSLATOR,
                      UserGroupEnum.OPERATOR,
                    ]}
                    withRedirect={true}
                  >
                    <Competitors />
                  </RoleGuards>
                }
              />

              <Route
                path="players"
                element={
                  <RoleGuards
                    roles={[
                      UserGroupEnum.ADMIN,
                      UserGroupEnum.SUPERVISOR,
                      UserGroupEnum.TRANSLATOR,
                      UserGroupEnum.OPERATOR,
                    ]}
                    withRedirect={true}
                  >
                    <Players />
                  </RoleGuards>
                }
              />

              <Route
                path="markets"
                element={
                  <RoleGuards
                    roles={[UserGroupEnum.ADMIN, UserGroupEnum.SUPERVISOR]}
                    withRedirect={true}
                  >
                    <Markets />
                  </RoleGuards>
                }
              />

              <Route
                path="languages"
                element={
                  <RoleGuards
                    roles={[
                      UserGroupEnum.ADMIN,
                      UserGroupEnum.SUPERVISOR,
                      UserGroupEnum.TRANSLATOR,
                      UserGroupEnum.OPERATOR,
                    ]}
                    withRedirect={true}
                  >
                    <Languages />
                  </RoleGuards>
                }
              />

              <Route
                path="matchTypes"
                element={
                  <RoleGuards
                    roles={[
                      UserGroupEnum.ADMIN,
                      UserGroupEnum.SUPERVISOR,
                      UserGroupEnum.TRANSLATOR,
                    ]}
                    withRedirect={true}
                  >
                    <MatchTypes />
                  </RoleGuards>
                }
              />

              <Route
                path="matchPhases"
                element={
                  <RoleGuards
                    roles={[
                      UserGroupEnum.ADMIN,
                      UserGroupEnum.SUPERVISOR,
                      UserGroupEnum.TRANSLATOR,
                    ]}
                    withRedirect={true}
                  >
                    <MatchPhases />
                  </RoleGuards>
                }
              />

              <Route
                path="tags"
                element={
                  <RoleGuards
                    roles={[
                      UserGroupEnum.ADMIN,
                      UserGroupEnum.CUSTOMER,
                      UserGroupEnum.SUPERVISOR,
                    ]}
                    withRedirect={true}
                  >
                    <Tags />
                  </RoleGuards>
                }
              />

              <Route
                path="comments"
                element={
                  <RoleGuards
                    roles={[
                      UserGroupEnum.ADMIN,
                      UserGroupEnum.SUPERVISOR,
                      UserGroupEnum.TRANSLATOR,
                      UserGroupEnum.OPERATOR,
                    ]}
                    withRedirect={true}
                  >
                    <Comments />
                  </RoleGuards>
                }
              />

              <Route
                path="cross-mappings"
                element={
                  <RoleGuards
                    roles={[UserGroupEnum.ADMIN, UserGroupEnum.SUPERVISOR]}
                    withRedirect={true}
                  >
                    <CrossMapping />
                  </RoleGuards>
                }
              >
                <Route
                  path={':id/:typeId?'}
                  element={<CrossMappingsContent />}
                />
              </Route>

              <Route
                path="currencies"
                element={
                  <RoleGuards
                    roles={[
                      UserGroupEnum.ADMIN,
                      UserGroupEnum.SUPERVISOR,
                      UserGroupEnum.TRANSLATOR,
                    ]}
                    withRedirect={true}
                  >
                    <Currencies />
                  </RoleGuards>
                }
              />

              <Route path="/dictionary" element={<Directory />}>
                <Route path={':type/:id'} element={<DirectoryContent />} />
              </Route>
            </Route>

            <Route
              path="/events/*"
              element={
                <RoleGuards
                  roles={[
                    UserGroupEnum.ADMIN,
                    UserGroupEnum.CUSTOMER,
                    UserGroupEnum.SUPERVISOR,
                    UserGroupEnum.OPERATOR,
                  ]}
                  withRedirect={true}
                >
                  <Events />
                </RoleGuards>
              }
            />

            <Route
              path="/sources/:adapter/*"
              element={
                <RoleGuards
                  roles={[
                    UserGroupEnum.ADMIN,
                    UserGroupEnum.OPERATOR,
                    UserGroupEnum.SUPERVISOR,
                  ]}
                  withRedirect={true}
                >
                  <Sources />
                </RoleGuards>
              }
            />

            <Route path="/live/*" element={<Live />} />

            <Route
              path="/results/*"
              element={
                <RoleGuards
                  roles={[
                    UserGroupEnum.ADMIN,
                    UserGroupEnum.OPERATOR,
                    UserGroupEnum.CUSTOMER,
                    UserGroupEnum.SUPERVISOR,
                    UserGroupEnum.OPERATOR,
                  ]}
                  withRedirect={true}
                >
                  <Results />
                </RoleGuards>
              }
            />

            <Route
              path="/users"
              element={
                <RoleGuards
                  roles={[UserGroupEnum.ADMIN, UserGroupEnum.SUPERVISOR]}
                  withRedirect={true}
                >
                  <Users />
                </RoleGuards>
              }
            />

            <Route
              path="/translations/*"
              element={
                <RoleGuards
                  roles={[
                    UserGroupEnum.ADMIN,
                    UserGroupEnum.SUPERVISOR,
                    UserGroupEnum.TRANSLATOR,
                    UserGroupEnum.OPERATOR,
                  ]}
                  withRedirect={true}
                >
                  <Translations />
                </RoleGuards>
              }
            />

            <Route
              path="/"
              element={
                <RoleGuards
                  roles={[
                    UserGroupEnum.ADMIN,
                    UserGroupEnum.OPERATOR,
                    UserGroupEnum.CUSTOMER,
                    UserGroupEnum.SUPERVISOR,
                    UserGroupEnum.TRANSLATOR,
                  ]}
                >
                  <Navigate to="/dictionary" />
                </RoleGuards>
              }
            />
          </Routes>
          {isNotificationVisible && (
            <Dialog
              text={`Внимание! На данный момент удаление всех типов данных было отключено.`}
              onClose={() => {
                ls.setItem(IS_DELETION_DISABLED_KEY, '1');
                hideNotification();
              }}
              withoutActions
            />
          )}
        </Layout>
      </Router>
    </AppProviders>
  );
};
