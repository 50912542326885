import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import get from 'lodash-es/get';
import { Competitor, LogoEntityType } from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import useBooleanState from 'hooks/useBooleanState';

import { AsyncSelectField, TextInputField } from 'app/components/forms';
import { LogoUploader } from 'app/components/LogoUploader';
import { CompetitorCreatePanel } from 'app/components/panels/competitor';
import { Span } from 'app/components/ui';

import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import { FormValues } from './constants';
import * as S from './styles';

interface Props {
  actionType: 'create' | 'edit';
  id?: number;
}

export const PlayerForm = ({ actionType, id }: Props) => {
  const { t } = useTranslation();

  const sports = useSelector(getSportsEntries);
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const [
    isCreateCompetitorVisible,
    showCreateCompetitor,
    hideCreateCompetitor,
  ] = useBooleanState();

  const handleCompetitorSelect = (competitor: Competitor) => {
    setFieldValue('competitors', [...values.competitors, competitor]);
  };

  const createCompetitorOption = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreateCompetitor,
  };

  return (
    <>
      <S.FormBlock>
        <Span>{t('name')}</Span>
        <TextInputField name="name" />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('competitor')}</Span>
        <AsyncSelectField
          isMulti
          name="competitors"
          loadOptions={getAsyncLoadOptions({
            entity: 'competitors',
            createOption: createCompetitorOption,
            transformOptions: options =>
              options.map(option => ({
                ...option,
                name: `${option.name} (${get(
                  sports,
                  `[${option.sportId}].name`,
                )})`,
              })),
          })}
        />
      </S.FormBlock>
      {isCreateCompetitorVisible && (
        <CompetitorCreatePanel
          onClose={hideCreateCompetitor}
          onSuccess={handleCompetitorSelect}
        />
      )}
      {actionType === 'edit' && !!id && (
        <LogoUploader entityType={LogoEntityType.PLAYER} entityId={id} />
      )}
    </>
  );
};
