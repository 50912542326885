import { FC, PropsWithChildren } from 'react';
import useCollapse from 'react-collapsed';

interface CollapseProps {
  isExpanded: boolean;
}

export const Collapse: FC<PropsWithChildren<CollapseProps>> = ({
  isExpanded,
  children,
}) => {
  const { getCollapseProps } = useCollapse({
    isExpanded,
    easing: 'ease-out',
    duration: 300,
  });

  return <div {...getCollapseProps()}>{children}</div>;
};
