import styled from 'styled-components';

export const IconWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  padding: 8px;
  background: white;
  box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.2);
  border-radius: 3px;
  min-width: 200px;
  z-index: 10;
`;
