import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 16px;
`;

export const MappingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 6px 16px;
  align-items: center;
`;
