/**
 *
 * Markets
 *
 */

import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { omit } from 'lodash-es';
import { OutcomeMarket } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { useLazyLoading } from 'hooks/useLazyLoading';
import { useRequestState } from 'hooks/useRequestState';
import { useSearch } from 'hooks/useSearch';

import { TextInput, TextInputCell } from 'app/components/forms';
import { MarketCreatePanel, MarketEditPanel } from 'app/components/panels';
import { Button, LoadingIndicator, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import {
  getMarketsHasMore,
  selectMarketsItems,
} from 'app/providers/EntitiesProvider/markets';

import * as S from './Markets.styles';

export function Markets() {
  const items = useSelector(selectMarketsItems);
  const hasMore = useSelector(getMarketsHasMore);
  const { isLoading } = useRequestState(Entities.MARKETS, 'fetchItems');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { searchQuery, setSearchQueryDebounced } = useSearch();

  const [isCreateMarketVisible, showCreateMarket, hideCreateMarket] =
    useBooleanState();
  const [editMarketId, setEditMarketId] = useState<null | number>(null);

  const onLazyLoad = useCallback(
    ({ limit, offset }) =>
      dispatch(
        actionsNT.marketsFetchItems({
          limit,
          offset,
          withPagination: true,
          name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
        }),
      ),
    [dispatch, searchQuery],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [searchQuery],
    onPaginationReset: () => {
      dispatch(actionsNT.marketsReset());
    },
  });

  const handleUpdateMarket = useCallback(
    (market: OutcomeMarket) => {
      dispatch(
        actionsNT.marketsUpdate({
          marketId: market.id,
          outcomeMarketWithOutId: {
            ...omit(market, 'id'),
          },
        }),
      );
    },
    [dispatch],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.OUTCOME_MARKETS,
  });

  const columns: Column<OutcomeMarket>[] = useMemo(
    () => [
      {
        Header: t('name').toString(),
        accessor: 'name',
        Cell: ({ value, row: { original: data } }) => (
          <TextInputCell
            defaultValue={value}
            onSave={newValue => {
              handleUpdateMarket({
                ...data,
                name: newValue,
              });
            }}
            validate={value => value.length > 3}
          />
        ),
      },
      {
        Header: '',
        id: 'action',
        Cell: ({ row: { original: data } }) => (
          <S.ButtonsWrapper>
            <Button
              color="action"
              onClick={() => {
                setEditMarketId(data.id);
              }}
            >
              {t('edit')}
            </Button>
            <Button
              color="action"
              onClick={() => {
                openAudit({ id: String(data.id), includeRelated: false });
              }}
            >
              {t('audit')}
            </Button>
          </S.ButtonsWrapper>
        ),
      },
    ],
    [t, handleUpdateMarket, openAudit],
  );

  return (
    <S.Wrapper>
      <S.DropdownWrapper>
        <DictionaryDropdown value={'markets'} />
      </S.DropdownWrapper>
      <S.ContentWrapper>
        <S.Header>
          <Button onClick={showCreateMarket}>{t('add market')}</Button>
          <TextInput
            placeholder={t('search')}
            onChange={e => setSearchQueryDebounced(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />
        </S.Header>
        <Table
          columns={columns}
          data={items}
          onBottom={onBottom}
          columnsWidth={['auto', 'fit-content']}
        />
        {isLoading && <LoadingIndicator type="absolute" />}
      </S.ContentWrapper>
      {isCreateMarketVisible && (
        <MarketCreatePanel onClose={hideCreateMarket} />
      )}
      {!!editMarketId && (
        <MarketEditPanel
          onClose={() => {
            setEditMarketId(null);
          }}
          id={editMarketId}
        />
      )}
    </S.Wrapper>
  );
}
