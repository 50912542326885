import { eventsApi } from 'app/api';

const api = {
  endpoint: eventsApi,
  methods: {
    fetchItem: 'getEventStatusByCustomer',
    update: 'upsertEventStatusByCustomer',
  },
};

export default api;
