import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as S from 'app/pages/Sources/components/EntitiesTables/styles';
import get from 'lodash-es/get';
import { AdapterSport, Sport } from 'sportsbook-openapi-react';

import { useBottomScroll } from 'hooks/useBottomScroll';
import { useLazyLoading } from 'hooks/useLazyLoading';

import { LoadingIndicator } from 'app/components/ui';

import {
  adapterActions,
  getAdapterCompetitorsHasMore,
  getAdapterCompetitorsLoading,
  getAdapterSportsEntries,
  selectAdapterCompetitorsItems,
} from 'app/providers/AdaptersProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCompetitorsEntries } from 'app/providers/EntitiesProvider/competitors';

import { CompetitorRow } from './CompetitorRow';

interface Props {
  mapped: boolean;
  adapter: string;
  searchQuery: string;
  sportsbookSport: Maybe<Sport>;
  sport?: Maybe<AdapterSport>;
}

export const CompetitorsTable: FC<Props> = ({
  mapped,
  adapter,
  searchQuery,
  sport,
  sportsbookSport,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editingRow, setEditingRow] =
    useState<Maybe<number | string>>(undefined);
  const [fetchedIds, setFetchedIds] = useState<number[]>([]);
  const adapterSportsEntries = useSelector(getAdapterSportsEntries);

  const competitors = useSelector(getCompetitorsEntries);
  const hasMore = useSelector(getAdapterCompetitorsHasMore);
  const loading = useSelector(getAdapterCompetitorsLoading);
  const items = useSelector(selectAdapterCompetitorsItems);

  useEffect(() => {
    if (mapped && items.length) {
      const ids = items
        .filter(
          dataEntry =>
            !!dataEntry.sportsbookId &&
            !fetchedIds.includes(dataEntry.sportsbookId),
        )
        .map(dataEntry => dataEntry.sportsbookId!);
      if (ids.length) {
        dispatch(
          actionsNT.competitorsFetchItems({
            ids,
            limit: ids.length,
            withPagination: true,
          }),
        );
        setFetchedIds(prev => [...prev, ...ids]);
      }
    }
  }, [dispatch, mapped, items, fetchedIds]);

  useEffect(() => {
    dispatch(adapterActions.sportsFetch({ adapter }));
  }, [dispatch, adapter]);

  const onLazyLoad = useCallback(
    ({ limit, offset }) =>
      dispatch(
        adapterActions.competitorsFetch({
          adapter,
          mapped,
          limit,
          offset,
          name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
          sportId: sport?.id,
        }),
      ),
    [dispatch, adapter, mapped, searchQuery, sport],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [searchQuery, mapped, sport],
    initialPagination: {
      offset: 0,
      limit: 30,
    },
    onPaginationReset: () => {
      dispatch(adapterActions.competitorsReset());
    },
  });

  const handleBottomScroll = useCallback(() => {
    onBottom();
  }, [onBottom]);

  const scrollRef = useBottomScroll<HTMLDivElement>(handleBottomScroll);

  if (loading && !items.length) {
    return <LoadingIndicator type="full" />;
  }

  return (
    <S.TableWrapper ref={scrollRef}>
      <S.StyledTable key="mapped">
        {mapped && (
          <S.THead>
            <S.Tr>
              <S.Th weight={25}>{t('from source')}</S.Th>
              <S.Th weight={25}>{t('in dictionary')}</S.Th>
              <S.Th weight={35}>{t('sport type')}</S.Th>
              <S.Th weight={15} />
            </S.Tr>
          </S.THead>
        )}
        <S.TBody>
          {items.map(row => (
            <CompetitorRow
              key={row.id}
              competitor={row}
              mapped={mapped}
              adapter={adapter}
              sportName={
                get(
                  adapterSportsEntries,
                  `[${row.sportId}].name`,
                ) as unknown as string
              }
              sportsbookSport={sportsbookSport}
              competitors={competitors}
              isEditing={editingRow === row.id}
              isFetched={fetchedIds.includes(row.sportsbookId!)}
              setEditingRow={setEditingRow}
            />
          ))}
        </S.TBody>
      </S.StyledTable>
      {loading && <LoadingIndicator type="absolute" />}
    </S.TableWrapper>
  );
};
