import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface StyledLinkProps {
  selected: boolean;
}

export const Wrapper = styled.div`
  grid-area: navigation;
  width: 56px;

  background-color: ${p => p.theme.colors.primary[900]};
  display: grid;
  grid-auto-rows: auto;
  align-content: flex-start;
  justify-items: center;
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  padding: 8px;
  margin: 8px;
  border-radius: 4px;

  svg,
  path {
    fill: ${p => p.theme.colors.primary[80]};
    transition: fill 0.2s ease;
  }

  &:hover {
    svg,
    path {
      fill: ${p => p.theme.colors.primary[0]};
    }
  }

  ${p =>
    p.selected &&
    `
    svg,
    path {
      fill: ${p.theme.colors.primary[0]};
    }
    background-color: ${p.theme.colors.primary[800]};
  `}
`;
