import { parseDate } from './datetime';

export const cache = (key: string, filters: Dictionary<any>) =>
  sessionStorage.setItem(key, JSON.stringify(filters));

export const uncache = <T extends object>(key: string, dateKeys?: string[]) => {
  const value = sessionStorage.getItem(key);
  if (!value) return;
  const parsedValue = JSON.parse(value) as T;
  dateKeys?.forEach(dateKey => {
    parsedValue[dateKey] = parseDate(parsedValue[dateKey]);
  });
  return parsedValue;
};

export const pruneCache = (key?: string) => {
  if (key) {
    sessionStorage.removeItem(key);
  } else {
    sessionStorage.clear();
  }
};
