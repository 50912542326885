import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'types';

import {
  EntitiesState,
  EntityMethods,
  getRequestsState,
} from 'app/providers/EntitiesProvider';

import { usePrevious } from './usePrevious';

export const useRequestState = (
  entity: keyof EntitiesState,
  method: EntityMethods,
  onComplete?: (result: any) => void,
) => {
  const { loading, error, result } = useSelector((state: RootState) =>
    getRequestsState(state, entity, method),
  );
  const prevLoading = usePrevious(loading);

  useEffect(() => {
    if (onComplete && prevLoading && !loading && !error) {
      onComplete(result);
    }
  });

  return useMemo(() => ({ isLoading: loading, error }), [loading, error]);
};

export const useCompleted = (loading: boolean) => {
  const [completed, setCompleted] = useState(false);

  const prevLoading = usePrevious(loading);

  useEffect(() => {
    if (prevLoading && !loading) {
      setCompleted(true);
    }
    if (!prevLoading && loading) {
      setCompleted(false);
    }
  }, [loading, prevLoading]);

  return completed;
};
