import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityType, Margin, OutcomeCategory } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { selectMarginsByCategory } from 'app/providers/EntitiesProvider/margins';

import { useUserStore } from 'store';
import { getIsCustomer, getIsTranslator } from 'store/user';

interface UseMarginMethodsProps {
  live: boolean;
  entityId: number;
  entityType: EntityType;
}

export const DEFAULT_MARGIN_VALUE = 1.08;

export const useMarginMethods = ({
  live = false,
  entityId,
  entityType,
}: UseMarginMethodsProps) => {
  const dispatch = useDispatch();
  const { customerId } = useUserStore(getIsCustomer);
  const isTranslator = useUserStore(getIsTranslator);

  const fetchMargins = useCallback(() => {
    if (!isTranslator) {
      dispatch(actionsNT.marginsReset());
      dispatch(
        actionsNT.marginsFetchItems({
          live,
          customerId,
          entityId,
          entityType,
          limit: FETCH_ALL_LIMIT,
        }),
      );
    }
  }, [customerId, dispatch, entityId, entityType, isTranslator, live]);

  const fetchMargin = useCallback(
    ({ marginId }: { marginId: number }) => {
      if (!isTranslator) {
        dispatch(
          actionsNT.marginsFetchItem({
            marginId,
          }),
        );
      }
    },
    [dispatch, isTranslator],
  );

  const createMargin = useCallback(
    ({
      outcomeCategoryId,
      margin,
    }: {
      outcomeCategoryId: number;
      margin: number;
    }) => {
      dispatch(
        actionsNT.marginsCreate({
          marginWithOutId: {
            customerId,
            outcomeCategoryId,
            live,
            margin,
            entityId,
            entityType,
          },
        }),
      );
    },
    [customerId, dispatch, entityId, entityType, live],
  );

  const createMarginsList = useCallback(
    (items: { outcomeCategoryId: number; margin: number }[]) => {
      dispatch(
        actionsNT.marginsCreateList({
          marginListWithOutId: {
            items: items.map(item => ({
              customerId,
              live: live,
              entityId,
              entityType,
              ...item,
            })),
          },
        }),
      );
    },
    [customerId, dispatch, entityId, entityType, live],
  );

  const updateMargin = useCallback(
    (
      oldMargin: Margin,
      {
        margin,
      }: {
        margin: number;
      },
    ) => {
      dispatch(
        actionsNT.marginsUpdate({
          marginId: oldMargin.id,
          marginWithOutId: {
            ...oldMargin,
            margin,
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteMargin = useCallback(
    ({ marginId }: { marginId: number }) => {
      dispatch(
        actionsNT.marginsDelete({
          marginId,
        }),
      );
    },
    [dispatch],
  );

  return {
    fetchMargins,
    fetchMargin,
    createMargin,
    createMarginsList,
    updateMargin,
    deleteMargin,
  };
};

export const useMargin = (props: UseMarginMethodsProps) => {
  const {
    fetchMargins,
    createMargin,
    createMarginsList,
    updateMargin,
    deleteMargin,
  } = useMarginMethods(props);

  useEffect(() => {
    fetchMargins();
  }, [fetchMargins]);

  const marginsByCategory = useSelector(selectMarginsByCategory);

  const deleteMarginByCategory = useCallback(
    ({ outcomeCategoryId }: { outcomeCategoryId: number }) => {
      const margin = marginsByCategory[outcomeCategoryId];
      if (margin) {
        deleteMargin({ marginId: margin.id });
      }
    },
    [deleteMargin, marginsByCategory],
  );

  const { isLoading: isMarginsLoading } = useRequestState(
    Entities.MARGINS,
    'fetchItems',
  );

  const updateMarginValue = useCallback(
    ({
      margin,
      value,
      outcomeCategoryId,
    }: {
      margin?: Margin;
      value: number;
      outcomeCategoryId: number;
    }) => {
      if (margin?.entityType === props.entityType) {
        updateMargin(margin, {
          margin: value,
        });
      } else {
        createMargin({
          outcomeCategoryId,
          margin: value,
        });
      }
    },
    [createMargin, props.entityType, updateMargin],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.ENTITY_MARGINS,
  });

  const openMarginAudit = useCallback<
    (outcomeCategoryId: OutcomeCategory['id']) => void
  >(
    outcomeCategoryId => {
      const margin = marginsByCategory[outcomeCategoryId];

      if (margin) {
        openAudit({ id: String(margin.id), includeRelated: false });
      }
    },
    [marginsByCategory, openAudit],
  );

  return {
    createMarginsList,
    deleteMarginByCategory,
    isMarginsLoading,
    openMarginAudit,
    updateMarginValue,
    margins: marginsByCategory,
  };
};
