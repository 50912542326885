/**
 *
 * Span
 *
 */
import { FC } from 'react';

import { StyledSpan } from './Span.styles';
import { SpanProps } from './Span.types';

export const Span: FC<SpanProps> = ({
  children,
  fontSize = 14,
  fontWeight = 400,
  lineHeight = 1.3,
  color = '#000000',
  ...rest
}) => {
  return (
    <StyledSpan
      data-testid="Span"
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      color={color}
      {...rest}
    >
      {children}
    </StyledSpan>
  );
};
