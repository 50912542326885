import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PopulatedEvent, useUpdateEvent } from 'hooks/events';

import { Dialog } from 'app/components/ui';

import { ChangeLiveSource, EventSourceOption } from './ChangeLiveSource';

interface Props {
  events: PopulatedEvent[];
  withDeletion?: boolean;
  closeModal: () => void;
}

export const ChangeEventLiveModal = ({
  events,
  withDeletion = false,
  closeModal,
}: Props) => {
  const { t } = useTranslation();
  const [source, setSource] = useState<EventSourceOption>();
  const updateEvent = useUpdateEvent();

  if (events.length === 0) return null;
  const currentLive = events[0].live;
  const live = !currentLive;
  const mainSource = events[0].mainSource;
  const id = events.map(e => e.id).join(', ');

  const onConfirm = () => {
    events.forEach((eventToUpdate: PopulatedEvent) => {
      window.open(`/events/${eventToUpdate.id}`, `window.${eventToUpdate.id}`);
      updateEvent(
        {
          ...eventToUpdate,
          live,
          scheduledLive: new Date(eventToUpdate.eventDate),
          mainSource: source?.id ?? eventToUpdate.mainSource,
        },
        withDeletion,
      );
    });
  };

  return (
    <Dialog
      text={live ? t('toLiveDialog', { id }) : t('toLineDialog', { id })}
      onClose={closeModal}
      onConfirm={onConfirm}
    >
      {live && events.length === 1 && (
        <ChangeLiveSource
          eventId={events[0].id}
          mainSource={mainSource!}
          onChange={setSource}
        />
      )}
    </Dialog>
  );
};
