import { useContext } from 'react';
import { components, GroupBase, SingleValueProps, Theme } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { ThemeContext } from 'styled-components';

import { getStyles } from 'app/components/forms/Select/Select.styles';
import { BaseOptionType } from 'app/components/forms/Select/Select.types';
import { Button } from 'app/components/ui';

import { AsyncSelectProps, Size } from './AsyncSelect.types';

const sizeToHeight: Record<Size, number> = {
  s: 32,
  m: 40,
};

const CustomOption = props => {
  const { data } = props;
  return data.isButton ? (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: data.onClick,
      }}
    >
      <Button
        color="link"
        type="button"
        onClick={() => {
          data.onClick();
        }}
      >
        {data.label}
      </Button>
    </components.Option>
  ) : (
    <components.Option {...props} />
  );
};

const SingleValueRenderer = <OptionType extends BaseOptionType>({
  children,
  ...props
}: SingleValueProps<OptionType>) => (
  <components.SingleValue {...props}>
    <span
      style={{
        width: 'fit-content',
      }}
      onMouseDown={event => {
        event.stopPropagation();
      }}
    >
      {children}
    </span>
  </components.SingleValue>
);

export const AsyncSelect = <OptionsType extends BaseOptionType>({
  invalid,
  success,
  disabled,
  readOnly,
  size = 's',
  getOptionLabel = option => option.name,
  getOptionValue = option => String(option.id),
  ...props
}: AsyncSelectProps<OptionsType>) => {
  const theme = useContext(ThemeContext);

  return (
    <AsyncPaginate<OptionsType, GroupBase<OptionsType>, unknown, boolean>
      {...props}
      value={props.value ?? null}
      styles={getStyles({ invalid, success, disabled, readOnly }, theme)}
      isDisabled={disabled}
      isSearchable={!readOnly}
      menuIsOpen={readOnly ? false : undefined}
      components={{
        IndicatorSeparator: null,
        SingleValue: SingleValueRenderer,
        Option: CustomOption,
      }}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      maxMenuHeight={150}
      theme={(selectTheme: Theme) => {
        return {
          ...selectTheme,
          colors: {
            ...selectTheme.colors,
            primary: theme.colors.primary[40],
            primary25: theme.colors.primary[20],
            primary50: theme.colors.primary[40],
            neutral5: theme.colors.primary[20],
            neutral0: theme.colors.primary[0],
          },
          borderRadius: 4,
          spacing: {
            ...selectTheme.spacing,
            controlHeight: sizeToHeight[size],
          },
        };
      }}
    />
  );
};
