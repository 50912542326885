import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { OutcomeTypeCrossMappingWithOutcomeTypes } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT, IS_DELETION_DISABLED } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';
import { getMarketsEntries } from 'app/providers/EntitiesProvider/markets';

import { validationSchema } from './constants';
import { CrossMappingForm } from './CrossMappingForm';

interface Props {
  id: number;
  onClose: () => void;
  categoryId: number;
}

export const CrossMappingEditPanel = ({ onClose, id, categoryId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsNT.marketsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.categoriesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const crossMapping: OutcomeTypeCrossMappingWithOutcomeTypes = useSelector(
    state => getEntityByTypeAndId(state, Entities.CROSS_MAPPINGS, id),
  );
  const markets = useSelector(getMarketsEntries);

  useRequestState(Entities.CROSS_MAPPINGS, 'update', onClose);

  const onSubmit = (values: OutcomeTypeCrossMappingWithOutcomeTypes) => {
    dispatch(
      actionsNT.crossMappingsUpdate({
        typeId: values.outcomeType?.id!,
        outcomeTypeCrossMappingWithOutId: {
          outcomeTypeIdCross: values.crossOutcomeType?.id!,
          crossParams: values.crossParams,
        },
      }),
    );
  };

  const onDelete = () => {
    dispatch(
      actionsNT.crossMappingsDelete({
        typeId: id!,
      }),
    );
    onClose();
  };

  if (!crossMapping) return null;

  return (
    <Formik<OutcomeTypeCrossMappingWithOutcomeTypes>
      initialValues={crossMapping}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.crossMapping')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
              leftButtonText={t('delete')}
              leftButtonDisabled={IS_DELETION_DISABLED}
              onLeftButtonClick={onDelete}
            />
          }
        >
          <CrossMappingForm id={id} categoryId={categoryId} markets={markets} />
        </Panel>
      )}
    </Formik>
  );
};
