import { PayloadAction } from '@reduxjs/toolkit';
import uniq from 'lodash-es/uniq';
import {
  BetPayMax,
  BetPayMaxList,
  CustomersApiDeleteBetPayMaxRequest,
  CustomersApiGetBetPayMaxEventListRequest,
} from 'sportsbook-openapi-react';

import {
  EntitiesErrorType,
  PaginatedGetRequest,
} from 'app/providers/EntitiesProvider/types';
import {
  entityReducersGenerator,
  toIdsArray,
  toIdsMap,
} from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    BetPayMaxList,
    BetPayMax,
    CustomersApiDeleteBetPayMaxRequest,
    EntitiesErrorType
  >({
    entity: 'betPayMax',
  }),
  betPayMaxByEventFetchItems(
    state,
    action: PayloadAction<
      PaginatedGetRequest<CustomersApiGetBetPayMaxEventListRequest>
    >,
  ) {
    state.betPayMax.fetchItems.error = null;
    state.betPayMax.fetchItems.loading = true;
    state.betPayMax.withPagination = action.payload?.withPagination;
    state.betPayMax.onlyEntries = action.payload?.onlyEntries;
  },
  betPayMaxByEventFetchItemsSuccess(
    state,
    action: PayloadAction<BetPayMaxList>,
  ) {
    const { items, hasMore } = action.payload;
    if (!state.betPayMax.onlyEntries) {
      if (state.betPayMax.withPagination) {
        state.betPayMax.sortedIds = uniq([
          ...state.betPayMax.sortedIds,
          ...toIdsArray(items),
        ]);
      } else {
        state.betPayMax.sortedIds = toIdsArray(items);
      }
    }
    state.betPayMax.entries = {
      ...state.betPayMax.entries,
      ...toIdsMap(items),
    };
    state.betPayMax.fetchItems.loading = false;
    state.betPayMax.hasMore = hasMore;
  },
  betPayMaxByEventFetchItemsError(state, action: PayloadAction<Error>) {
    state.betPayMax.fetchItems.error = action.payload;
    state.betPayMax.fetchItems.loading = false;
  },
};

export default reducer;
