import { EventFavoriteMapping } from 'sportsbook-openapi-react';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';

const getEventFavoritesSubstate = (state: RootState) =>
  getEntities(state).eventFavorites;

export const getFavoriteEvents = (state: RootState) =>
  getEventFavoritesSubstate(state).entries;

export const getFavoriteByEventAndUserId = (
  state: RootState,
  eventId: number,
  userId: string,
): EventFavoriteMapping | undefined =>
  getFavoriteEvents(state)[`${userId}_${eventId}`];
