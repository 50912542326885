import { marginsApi } from 'app/api';

const api = {
  endpoint: marginsApi,
  methods: {
    fetchItems: 'getMargins',
    fetchItem: 'getMargin',
    create: 'createMargin',
    createList: 'createMarginList',
    update: 'updateMargin',
    delete: 'deleteMargin',
  },
};

export default api;

export const marginsByEventApi = {
  endpoint: marginsApi,
  methods: {
    fetchItems: 'getMarginEventList',
  },
};
