import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FieldArray, useFormikContext } from 'formik';
import { MatchTypeWithOutId } from 'sportsbook-openapi-react';

import { Checkbox, TextInputField } from 'app/components/forms';
import { Span, Table } from 'app/components/ui';

import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';

import * as S from './styles';

const columns = ['sport'];

export const MatchTypeForm = () => {
  const { t } = useTranslation();
  const items = useSelector(selectSportsItems);
  const {
    values: { sports },
  } = useFormikContext<MatchTypeWithOutId>();

  return (
    <>
      <S.FormBlock>
        <Span>{t('name')}</Span>
        <TextInputField name="name" />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('matchType code')}</Span>
        <TextInputField name="code" />
      </S.FormBlock>
      <S.TableWrapper>
        <FieldArray name={'sports'}>
          {({ push, remove }) => (
            <Table<{ id: number }>
              columns={columns}
              data={items.map(sport => ({
                sport: (
                  <Checkbox
                    checked={sports.includes(sport.id!)}
                    label={sport.name}
                    onChange={checked => {
                      if (checked) {
                        push(sport.id);
                      } else {
                        remove(sports.indexOf(sport.id));
                      }
                    }}
                  />
                ),
                id: sport.id,
              }))}
              onRowClick={({ id }) => {
                const sportIndex = sports.indexOf(id);
                if (sportIndex < 0) {
                  push(id);
                } else {
                  remove(sportIndex);
                }
              }}
              rowStyles={'cursor: pointer;'}
            />
          )}
        </FieldArray>
      </S.TableWrapper>
    </>
  );
};
