import { FC } from 'react';
import { useField } from 'formik';

import { AsyncSelect } from 'app/components/forms';
import { SelectWrapper } from 'app/components/forms/Select/Select.styles';

import { AsyncSelectFieldProps } from './AsyncSelectField.types';

export const AsyncSelectField: FC<AsyncSelectFieldProps> = ({
  name,
  readOnly,
  disabled,
  testId,
  className,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <SelectWrapper data-test-id={testId} className={className}>
      <AsyncSelect
        {...field}
        {...props}
        value={field.value ?? null}
        invalid={!!meta.error}
        readOnly={readOnly}
        disabled={disabled}
        onChange={value => helpers.setValue(value)}
      />
    </SelectWrapper>
  );
};
