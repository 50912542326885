import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BetPayMax,
  EntityType,
  OutcomeCategory,
} from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectBetPayMaxesByCategory } from 'app/providers/EntitiesProvider/betPayMax';

import { useUserStore } from 'store';
import { getCustomer } from 'store/user';

export const BETPAYMAX_TRANSMISSION_COEFFICIENT = 100;

interface UseBetPayMaxMethodsProps {
  live: boolean;
  entityId: number;
  entityType: EntityType;
}

export const useBetPayMaxMethods = ({
  live = false,
  entityId,
  entityType,
}: UseBetPayMaxMethodsProps) => {
  const dispatch = useDispatch();
  const customer = useUserStore(getCustomer);

  const fetchBetPayMaxes = useCallback(() => {
    if (customer) {
      dispatch(actionsNT.betPayMaxReset());
      dispatch(
        actionsNT.betPayMaxFetchItems({
          live,
          customerId: customer.id,
          entityId,
          entityType,
          currencyId: customer.defaultCurrencyId,
          limit: FETCH_ALL_LIMIT,
        }),
      );
    }
  }, [customer, dispatch, entityId, entityType, live]);

  const fetchBetPayMax = useCallback(
    ({ betpaymaxId }: { betpaymaxId: number }) => {
      if (customer) {
        dispatch(
          actionsNT.betPayMaxFetchItem({
            betpaymaxId,
            customerId: customer.id,
          }),
        );
      }
    },
    [customer, dispatch],
  );

  const createBetPayMax = useCallback(
    ({
      outcomeCategoryId,
      value,
      maxPay,
    }: {
      outcomeCategoryId: number;
      value: number;
      maxPay: boolean;
    }) => {
      if (customer) {
        dispatch(
          actionsNT.betPayMaxCreate({
            customerId: customer.id,
            betPayMaxWithOutId: {
              maxPay,
              outcomeCategoryId,
              live,
              value,
              entityId,
              entityType,
              currencyId: customer.defaultCurrencyId,
            },
          }),
        );
      }
    },
    [customer, dispatch, entityId, entityType, live],
  );

  const createBetPayMaxList = useCallback(
    (
      items: {
        outcomeCategoryId: number;
        value: number;
        maxPay: boolean;
      }[],
    ) => {
      if (customer) {
        dispatch(
          actionsNT.betPayMaxCreateList({
            customerId: customer.id,
            betPayMaxListWithOutId: {
              items: items.map(item => ({
                live,
                entityId,
                entityType,
                currencyId: customer.defaultCurrencyId,
                ...item,
              })),
            },
          }),
        );
      }
    },
    [customer, dispatch, entityId, entityType, live],
  );

  const updateBetPayMax = useCallback(
    (
      betPayMax: BetPayMax,
      { value, maxPay }: { value?: number; maxPay?: boolean },
    ) => {
      if (customer) {
        dispatch(
          actionsNT.betPayMaxUpdate({
            betpaymaxId: betPayMax.id,
            customerId: customer.id,
            betPayMaxWithOutId: {
              ...betPayMax,
              maxPay: maxPay ?? betPayMax.maxPay,
              value: value ?? betPayMax.value,
            },
          }),
        );
      }
    },
    [customer, dispatch],
  );

  const deleteBetPayMax = useCallback(
    ({ betpaymaxId }: { betpaymaxId: number }) => {
      if (customer) {
        dispatch(
          actionsNT.betPayMaxDelete({
            betpaymaxId,
            customerId: customer.id,
          }),
        );
      }
    },
    [customer, dispatch],
  );

  return {
    fetchBetPayMaxes,
    fetchBetPayMax,
    createBetPayMax,
    createBetPayMaxList,
    updateBetPayMax,
    deleteBetPayMax,
  };
};

export const useBetPayMax = (props: UseBetPayMaxMethodsProps) => {
  const {
    fetchBetPayMaxes,
    fetchBetPayMax,
    createBetPayMax,
    createBetPayMaxList,
    updateBetPayMax,
    deleteBetPayMax,
  } = useBetPayMaxMethods(props);

  useEffect(() => {
    fetchBetPayMaxes();
  }, [fetchBetPayMaxes]);

  const betPayMaxesByCategory = useSelector(selectBetPayMaxesByCategory);

  const deleteBetPayMaxByCategory = useCallback(
    ({ outcomeCategoryId }: { outcomeCategoryId: number }) => {
      const betPayMax = betPayMaxesByCategory[outcomeCategoryId];
      if (betPayMax) {
        deleteBetPayMax({ betpaymaxId: betPayMax.id });
      }
    },
    [betPayMaxesByCategory, deleteBetPayMax],
  );

  const { isLoading: isBetPayMaxesLoading } = useRequestState(
    'betPayMax',
    'fetchItems',
  );

  const updateBetPayMaxValue = useCallback(
    ({
      betPayMax,
      value,
      maxPay,
      outcomeCategoryId,
    }: {
      betPayMax?: BetPayMax;
      value?: number;
      maxPay?: boolean;
      outcomeCategoryId: number;
    }) => {
      if (betPayMax?.entityType === props.entityType) {
        updateBetPayMax(betPayMax, {
          maxPay,
          value: value ? value * BETPAYMAX_TRANSMISSION_COEFFICIENT : undefined,
        });
      } else {
        createBetPayMax({
          outcomeCategoryId,
          maxPay: maxPay ?? betPayMax?.maxPay ?? false,
          value: value
            ? value * BETPAYMAX_TRANSMISSION_COEFFICIENT
            : betPayMax?.value ?? 1000,
        });
      }
    },
    [createBetPayMax, props.entityType, updateBetPayMax],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.ENTITY_BETPAYMAXES,
  });

  const openBetPayMaxAudit = useCallback<
    (outcomeCategoryId: OutcomeCategory['id']) => void
  >(
    outcomeCategoryId => {
      const betPayMax = betPayMaxesByCategory[outcomeCategoryId];

      if (betPayMax) {
        openAudit({ id: String(betPayMax.id), includeRelated: false });
      }
    },
    [betPayMaxesByCategory, openAudit],
  );

  return {
    fetchBetPayMax,
    createBetPayMaxList,
    deleteBetPayMaxByCategory,
    isBetPayMaxesLoading,
    openBetPayMaxAudit,
    updateBetPayMaxValue,
    betPayMaxes: betPayMaxesByCategory,
  };
};
