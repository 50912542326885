import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Event } from 'sportsbook-openapi-react';

import { Dialog } from 'app/components/ui';

import { SelectEventFilters } from './SelectEventModal.types';
import { SelectEventModalContent } from './SelectEventModalContent';

interface Props {
  event: Maybe<Event>;
  initialFilters?: SelectEventFilters;
  excludedEvents: Event[];
  onChange: (event: Event) => void;
  closeModal: () => void;
}

export const SelectEventModal = ({
  event,
  onChange,
  excludedEvents,
  initialFilters = {},
  closeModal,
}: Props) => {
  const internalValue = useRef<Maybe<Event>>(event);
  const { t } = useTranslation();

  const onConfirm = () => {
    if (internalValue.current) {
      onChange(internalValue.current);
    }
  };

  return (
    <Dialog
      text={t('select event')}
      onClose={closeModal}
      strictWidth={false}
      onConfirm={onConfirm}
    >
      <SelectEventModalContent
        initialFilters={initialFilters}
        excludedEvents={excludedEvents}
        onChange={newValue => {
          internalValue.current = newValue;
        }}
      />
    </Dialog>
  );
};
