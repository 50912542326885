import { commentsApi } from 'app/api';

const api = {
  endpoint: commentsApi,
  methods: {
    fetchItems: 'getEntityComments',
    create: 'createEntityComment',
    delete: 'deleteEntityComment',
  },
};

export default api;
