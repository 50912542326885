import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 3;
  padding: 20px 24px;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-column-gap: 16px;
`;

export const FormField = styled.div`
  display: grid;
  grid-template-columns: auto 240px;
  align-items: center;
  grid-column-gap: 8px;
`;

export const Label = styled.span`
  color: ${p => p.theme.colors.primary[700]};
  font-weight: ${p => p.theme.fonts.typography['H200'].weight};
  font-size: ${p => p.theme.fonts.typography['H200'].size};
`;
