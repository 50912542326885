import { useTranslation } from 'react-i18next';
import { Event, Tournament } from 'sportsbook-openapi-react';

import {
  EventsTable,
  SelectEvent,
  SelectTournament,
  TournamentsTable,
} from 'app/components/panels/dependency/components';
import { getEventName } from 'app/components/panels/dependency/utils';
import { Span } from 'app/components/ui';

import * as S from './styles';

interface Props {
  event: Event;
  tournament: Tournament;
}

export const EditForm = ({ event, tournament }: Props) => {
  const { t } = useTranslation();

  const eventName = getEventName(event);
  return (
    <S.EditWrapper>
      <S.TablesBlockWrapper>
        <Span size={18}>
          {t('tournament')}: {tournament.name}
        </Span>
        <Span size={18}>
          {t('match')}: {eventName}
        </Span>
      </S.TablesBlockWrapper>
      <S.Divider />
      <S.TablesBlockWrapper>
        <S.StyledBlockHeader>
          <S.StyledBlockTitle>
            {t('tournament')}-{t('tournament')}
          </S.StyledBlockTitle>
          <SelectTournament
            name="tournament.dependentTournaments"
            excludedTournament={tournament}
          />
        </S.StyledBlockHeader>
        <TournamentsTable name="tournament.dependentTournaments" />
      </S.TablesBlockWrapper>
      <S.Divider />
      <S.TablesBlockWrapper>
        <S.StyledBlockHeader>
          <S.StyledBlockTitle>
            {t('match')}-{t('tournament')}
          </S.StyledBlockTitle>
          <SelectTournament
            name="event.dependentTournaments"
            excludedTournament={tournament}
          />
        </S.StyledBlockHeader>
        <TournamentsTable name="event.dependentTournaments" />
      </S.TablesBlockWrapper>
      <S.Divider />
      <S.TablesBlockWrapper>
        <S.StyledBlockHeader>
          <S.StyledBlockTitle>
            {t('match')}-{t('match')}
          </S.StyledBlockTitle>
          <SelectEvent name="event.dependentEvents" excludedEvents={[event]} />
        </S.StyledBlockHeader>
        <EventsTable name="event.dependentEvents" />
      </S.TablesBlockWrapper>
    </S.EditWrapper>
  );
};
