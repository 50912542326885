import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  AdapterOutcomeParameterMapping,
  RelationType,
} from 'sportsbook-openapi-react';

import { ID } from 'utils/id';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  adapterActions,
  getAdapterTypesEntries,
  selectAdapterParametersItems,
} from 'app/providers/AdaptersProvider';
import { getTypesEntries } from 'app/providers/EntitiesProvider/type';

import { Form } from './Form';
import {
  FormValues,
  transformAdditionalParams,
  transformForwardedRawParams,
  transformRawAdditionalParams,
  transformType,
} from './utils';

interface Props {
  adapter: string;
  adapterOutcomeTypeId: string;
  marketName?: string;
  rawParams?: string[];
  onClose: () => void;
}

export const AdapterOutcomesMappingPanel = ({
  onClose,
  adapterOutcomeTypeId,
  adapter,
  marketName,
  rawParams,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const adapterTypes = useSelector(getAdapterTypesEntries);
  const adapterType = adapterTypes[adapterOutcomeTypeId];

  const parameterItems = useSelector(selectAdapterParametersItems);
  const types = useSelector(getTypesEntries);

  const initialValues = useMemo(() => {
    const sportsbookType =
      adapterType?.sportsbookId && types[adapterType.sportsbookId]
        ? transformType(types[adapterType.sportsbookId!])
        : undefined;

    const additionalParams =
      rawParams !== undefined
        ? transformForwardedRawParams(
            t,
            rawParams,
            adapterType?.additionalParams,
          )
        : transformAdditionalParams(
            t,
            adapterType?.additionalParams?.filter(param =>
              parameterItems.some(p => p.name === param.name),
            ),
          ) ?? [{ id: ID() }];
    const customParams = transformAdditionalParams(
      t,
      adapterType?.additionalParams?.filter(param =>
        parameterItems.every(p => p.name !== param.name),
      ),
    ) ?? [
      {
        id: ID(),
        relationType: {
          id: RelationType.NORMAL,
          name: t(`relationTypes.${RelationType.NORMAL}`),
        },
      },
    ];

    return {
      sportsbookType,
      additionalParams,
      customParams,
    };
  }, [
    adapterType?.additionalParams,
    adapterType.sportsbookId,
    types,
    parameterItems,
    rawParams,
    t,
  ]);

  const onSubmit = (values: FormValues) => {
    const additionalParams: AdapterOutcomeParameterMapping[] = [
      ...transformRawAdditionalParams(values.additionalParams),
      ...transformRawAdditionalParams(values.customParams),
    ];
    dispatch(
      adapterActions.typesMap({
        adapter,
        id: adapterOutcomeTypeId,
        outcomeTypeMappingRequest: {
          sportsbookId: values.sportsbookType?.id,
          additionalParams,
        },
        withDeletion: true,
      }),
    );
    onClose();
  };

  const handleDelete = () => {
    dispatch(
      adapterActions.typesDeleteMapping({
        id: adapterOutcomeTypeId,
        adapter,
      }),
    );
    onClose();
  };

  return (
    <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Panel
          title={t('map outcome')}
          onClose={onClose}
          onSubmit={handleSubmit}
          panelWidth={50}
          footer={
            <PanelFooter
              submitButtonText={t('map')}
              leftButtonText={
                initialValues.sportsbookType ? t('delete') : undefined
              }
              onLeftButtonClick={handleDelete}
            />
          }
        >
          <Form
            adapterOutcomeTypeId={adapterOutcomeTypeId}
            marketName={marketName}
          />
        </Panel>
      )}
    </Formik>
  );
};
