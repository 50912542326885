import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from 'app/pages/Sources/components/EntitiesTables/styles';
import { AdapterCompetitor } from 'sportsbook-openapi-react';

import { useBottomScroll } from 'hooks/useBottomScroll';
import { useLazyLoading } from 'hooks/useLazyLoading';

import { LoadingIndicator } from 'app/components/ui';

import {
  adapterActions,
  getAdapterPlayersHasMore,
  getAdapterPlayersLoading,
  selectAdapterPlayersItems,
} from 'app/providers/AdaptersProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { getPlayersEntries } from 'app/providers/EntitiesProvider/players';

import { PlayerRow } from './PlayerRow';

interface Props {
  mapped: boolean;
  adapter: string;
  searchQuery: string;
  competitor?: Maybe<AdapterCompetitor>;
}

export const PlayersTable: FC<Props> = ({
  mapped,
  adapter,
  searchQuery,
  competitor,
}) => {
  const dispatch = useDispatch();
  const [editingRow, setEditingRow] =
    useState<Maybe<number | string>>(undefined);
  const [fetchedIds, setFetchedIds] = useState<number[]>([]);

  const players = useSelector(getPlayersEntries);
  const hasMore = useSelector(getAdapterPlayersHasMore);
  const loading = useSelector(getAdapterPlayersLoading);
  const items = useSelector(selectAdapterPlayersItems);

  useEffect(() => {
    if (mapped && items.length) {
      const ids = items
        .filter(
          dataEntry =>
            !!dataEntry.sportsbookId &&
            !fetchedIds.includes(dataEntry.sportsbookId),
        )
        .map(dataEntry => dataEntry.sportsbookId!);
      if (ids.length) {
        dispatch(
          actionsNT.playersFetchItems({
            ids,
            limit: ids.length,
            withPagination: true,
          }),
        );
        setFetchedIds(prev => [...prev, ...ids]);
      }
    }
  }, [dispatch, mapped, items, fetchedIds]);

  const onLazyLoad = useCallback(
    ({ limit, offset }) =>
      dispatch(
        adapterActions.playersFetch({
          adapter,
          mapped,
          limit,
          offset,
          name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
          competitorId: competitor?.id,
        }),
      ),
    [dispatch, adapter, mapped, searchQuery, competitor?.id],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [searchQuery, mapped, competitor],
    initialPagination: {
      offset: 0,
      limit: 30,
    },
    onPaginationReset: () => {
      dispatch(adapterActions.playersReset());
    },
  });

  const handleBottomScroll = useCallback(() => {
    onBottom();
  }, [onBottom]);

  const scrollRef = useBottomScroll<HTMLDivElement>(handleBottomScroll);

  if (loading && !items.length) {
    return <LoadingIndicator type="full" />;
  }

  return (
    <S.TableWrapper ref={scrollRef}>
      <S.StyledTable key="mapped">
        {mapped && (
          <S.THead>
            <S.Tr>
              <S.Th weight={40}>От источника</S.Th>
              <S.Th weight={35}>В справочнике</S.Th>
              <S.Th weight={25} />
            </S.Tr>
          </S.THead>
        )}
        <S.TBody>
          {items.map(row => (
            <PlayerRow
              key={row.id}
              mapped={mapped}
              adapter={adapter}
              player={row}
              players={players}
              isEditing={editingRow === row.id}
              isFetched={fetchedIds.includes(row.sportsbookId!)}
              setEditingRow={setEditingRow}
            />
          ))}
        </S.TBody>
      </S.StyledTable>
      {loading && <LoadingIndicator type="absolute" />}
    </S.TableWrapper>
  );
};
