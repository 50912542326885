import { Column } from 'react-table';
import { Delay } from 'sportsbook-openapi-react';

import DelayCell from './DelayCell';

interface Props {
  header: string;
  accessor: string;
  delays: Dictionary<Delay>;
  updateDelayValue: (props: {
    outcomeCategoryId: number;
    delay?: Delay;
    value: number;
  }) => void;
}

export const DelayColumn = <T extends object>({
  header,
  accessor,
  delays,
  updateDelayValue,
}: Props): Column<T> => {
  return {
    Header: header,
    id: 'delay',
    accessor: accessor as keyof T,
    Cell: ({ value: categoryId }) => {
      const delay = delays[categoryId];
      return (
        <DelayCell
          delay={delay}
          outcomeCategoryId={categoryId}
          handleCellChange={updateDelayValue}
        />
      );
    },
  };
};
