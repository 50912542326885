export enum TestIds {
  SidebarMenuButton = 'sidebar-menu-button',
  EventFormSportSelect = 'event-form-sport-select',
  EventFormGroupSelect = 'event-form-group-select',
  EventFormDateDatepicker = 'event-form-date-datepicker',
  EventFormTimeDatepicker = 'event-form-time-datepicker',
  EventFormTournamentSelect = 'event-form-tournament-select',
  EventFormHomeCompetitorSelect = 'event-form-home-competitor-select',
  EventFormAwayCompetitorSelect = 'event-form-away-competitor-select',
  EventFormStatusSelect = 'event-form-status-select',
  EventFormMatchTypeSelect = 'event-form-match-type-select',
  EventFormKindSelect = 'event-form-kind-select',
  EventFormNoteField = 'event-form-note-field',
  EventFormCancelButton = 'event-form-cancel-field',
  EventFormSubmitButton = 'event-form-submit-field',

  EventPageHeader = 'event-page-header',
  EventPageEventId = 'event-page-event-id',
  EventPageStatusButton = 'event-page-status-button',
  EventPageStatusOption = 'event-page-status-option',
  EventPageBackButton = 'event-page-back-button',
  EventPageEditButton = 'event-page-edit-button',
  EventPageFavoriteButton = 'event-page-favorite-button',
  EventPageLiveButton = 'event-page-live-button',
  EventPageAuditButton = 'event-page-audit-button',
  EventPageResultsButton = 'event-page-results-button',
  EventPageUpdateButton = 'event-page-update-button',
  EventPageModelsCalculateSelect = 'event-page-models-calculate-select',

  EventPageLiveInfo = 'event-page-live-info',
  EventPageLiveInfoCompetitors = 'event-page-live-info-competitors',
  EventPageLiveInfoMatchPhase = 'event-page-live-info-match-phase',
  EventPageLiveInfoMatchTime = 'event-page-live-info-match-time',
  EventPageLiveInfoPeriodScore = 'event-page-live-info-period-score',
  EventPageLiveInfoGameScore = 'event-page-live-info-game-score',
  EventPageLiveInfoRemainingTime = 'event-page-live-info-remaining-time',
  EventPageLiveInfoCurrentServer = 'event-page-live-info-current-server',
  EventPageLiveInfoCT = 'event-page-live-info-CT',

  EventPageInfo = 'event-page-info',
  EventPageInfoSport = 'event-page-info-sport',
  EventPageInfoCountry = 'event-page-info-country',
  EventPageInfoGroup = 'event-page-info-group',
  EventPageInfoDatetime = 'event-page-info-datetime',
  EventPageInfoTournament = 'event-page-info-tournament',
  EventPageInfoCompetitors = 'event-page-info-competitors',
  EventPageInfoMatchType = 'event-page-info-match-type',
  EventPageInfoDependencies = 'event-page-info-dependencies',
  EventPageInfoNote = 'event-page-info-note',
  EventPageInfoTags = 'event-page-info-tags',
  EventPageInfoComments = 'event-page-info-comments',
  EventPageInfoType = 'event-page-info-type',

  EventsPageDateFilter = 'events-page-date-filter',
  EventsPageNotBeganCheckbox = 'events-page-not-began-checkbox',
  EventsPageFavoriteCheckbox = 'events-page-favorite-checkbox',
  EventsPageResetFiltersButton = 'events-page-reset-filters-button',
  EventsPageTable = 'events-page-table',
  ResultsPageNotResultedCheckbox = 'events-page-not-resulted-checkbox',
}
