import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';

import { RootState } from 'types';

const getBetPayMaxes = (state: RootState) => state.entities.betPayMax;
const getBetPayMaxesEntries = (state: RootState) =>
  getBetPayMaxes(state).entries;

export const selectBetPayMaxesByCategory = createSelector(
  [getBetPayMaxesEntries],
  betPayMaxes => keyBy(betPayMaxes, 'outcomeCategoryId'),
);
