import {
  Competitor,
  CompetitorList,
  CompetitorsApiDeleteCompetitorRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    CompetitorList,
    Competitor,
    CompetitorsApiDeleteCompetitorRequest,
    EntitiesErrorType
  >({
    entity: 'competitors',
  }),
};

export default reducer;
