import styled from 'styled-components';

export const EventContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
`;

export const EventLoading = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EventError = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
