import styled from 'styled-components';

import { Span } from '..';

export const TabsWrapper = styled.div`
  display: flex;
  padding: 0 20px;
  height: 40px;
  border-bottom: 1px solid #e5e5e5;
`;

type ItemProps = {
  selected: boolean;
  withDots?: boolean;
  withNumber?: boolean;
};

export const Item = styled.div<ItemProps>`
  cursor: pointer;
  width: min-content;
  padding: 0 10px;

  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;

  font-size: 14px;
  color: black;

  ${props =>
    props.selected &&
    `
    background: #e6e6e6;
    border-bottom: 2px solid black;
  `}
  ${({ withDots, withNumber, selected }) => {
    if (withDots && withNumber && selected)
      return 'grid-template-columns: repeat(3, auto);';
    if ((withDots && selected) || withNumber)
      return 'grid-template-columns: repeat(2, auto);';
  }}
`;

export const Point = styled.span`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000000;
`;

export const Label = styled(Span)`
  width: fit-content;
  white-space: nowrap;
`;

export const Count = styled.span`
  white-space: nowrap;
  font-size: 14px;
  color: #808080;
  width: fit-content;
`;
