import { useCallback } from 'react';
import { PropsValue } from 'react-select';

import { BaseOptionType } from 'app/components/forms/Select/Select.types';

import { Filters, Sorting } from './Table.types';

export function useSorting<SortingType extends Sorting>(
  sorting: SortingType,
  onSortingChange: (sorting: SortingType) => void,
) {
  const handleSortingChange = useCallback(
    (id: string) => (asc: boolean | undefined) => {
      onSortingChange({ ...sorting, [id]: asc });
    },
    [onSortingChange, sorting],
  );

  return { sorting, handleSortingChange };
}

export function useFilters<FiltersType extends Filters>(
  filters: FiltersType,
  onFilterChange: (filters: FiltersType) => void,
) {
  const handleFilterChange = useCallback(
    (id: string) => (value: string | PropsValue<BaseOptionType>) => {
      onFilterChange({ ...filters, [id]: value });
    },
    [filters, onFilterChange],
  );

  return { filters, handleFilterChange };
}
