import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Event } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { RootState } from 'types';
import { TestIds } from 'types/testIds.types';

import { Button } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getFavoriteByEventAndUserId } from 'app/providers/EntitiesProvider/eventFavorites';

import { useUserStore } from 'store';
import { getUser } from 'store/user';

interface Props {
  event: Event;
}

export function FavoriteButton({ event }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useUserStore(getUser);
  const favorite = useSelector((state: RootState) =>
    getFavoriteByEventAndUserId(state, event.id, user.id),
  );

  const handleEventFavoriteClick = () => {
    if (!user) return;
    if (favorite) {
      dispatch(
        actionsNT.eventFavoritesDelete({
          eventId: event.id,
          userId: user.id,
        }),
      );
    } else {
      dispatch(
        actionsNT.eventFavoritesCreate({
          userId: user.id,
          eventFavoriteMapping: {
            userId: user.id,
            eventId: event.id,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    dispatch(
      actionsNT.eventFavoritesFetchItems({
        userId: user?.id,
      }),
    );
  }, [dispatch, user, user?.id]);

  const { isLoading: createLoading } = useRequestState(
    'eventFavorites',
    'create',
  );
  const { isLoading: deleteLoading } = useRequestState(
    'eventFavorites',
    'delete',
  );

  const isLoading = createLoading || deleteLoading;

  return (
    <>
      {favorite ? (
        <Button
          color="secondary"
          onClick={handleEventFavoriteClick}
          testId={TestIds.EventPageFavoriteButton}
          disabled={isLoading}
        >
          <FontAwesomeIcon icon={faStar} />
          {t('delete from favorite')}
        </Button>
      ) : (
        <Button
          testId={TestIds.EventPageFavoriteButton}
          color="action"
          onClick={handleEventFavoriteClick}
        >
          <FontAwesomeIcon icon={faStarEmpty} />
          {t('add to favorite')}
        </Button>
      )}
    </>
  );
}
