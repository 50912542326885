import { PayloadAction } from '@reduxjs/toolkit';

import {
  AdapterState,
  ContainerState,
} from 'app/providers/AdaptersProvider/types';

const defaultDelete =
  <T extends { id: string }>(entity: keyof AdapterState) =>
  (state: ContainerState, action: PayloadAction<T>) => {
    state.entities[entity].delete.loading = true;
    state.entities[entity].delete.error = null;
    state.entities[entity].sortedIds = state.entities[entity].sortedIds.filter(
      id => id !== action.payload.id,
    );
    delete state.entities[entity].entries[action.payload.id];
  };

const defaultDeleteSuccess =
  (entity: keyof AdapterState) => (state: ContainerState) => {
    state.entities[entity].delete.loading = false;
  };

const defaultDeleteFailure =
  <T extends Error = Error>(entity: keyof AdapterState) =>
  (state: ContainerState, action: PayloadAction<T>) => {
    state.entities[entity].delete.loading = false;
    state.entities[entity].delete.error = action.payload.message;
  };

export const deleteReducersGenerator = (entityName: keyof AdapterState) => ({
  [`${entityName}DeleteMapping`]: defaultDelete(entityName),
  [`${entityName}DeleteMappingSuccess`]: defaultDeleteSuccess(entityName),
  [`${entityName}DeleteMappingFailure`]: defaultDeleteFailure(entityName),
});
