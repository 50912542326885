import styled from 'styled-components';

import { TooltipPositionEnum, TooltipWrapperProps } from './types';

export const getTooltipPositions = (
  position: TooltipPositionEnum,
  color: string,
) => {
  switch (position) {
    case TooltipPositionEnum.RIGHT: {
      return {
        wrapper: `
          left: 100%;
          top: 50%;
          transform: translate3d(10px, -50%, 0);
        `,
        arrow: `
          right: 100%;
          top: 50%;
          transform: translate3d(0, -50%, 0);
          border-right-color: ${color};
        `,
      };
    }
    case TooltipPositionEnum.LEFT: {
      return {
        wrapper: `
          right: 100%;
          top: 50%;
          transform: translate3d(-10px, -50%, 0);
        `,
        arrow: `
          left: 100%;
          top: 50%;
          transform: translate3d(0, -50%, 0);
          border-left-color: ${color};
        `,
      };
    }
    case TooltipPositionEnum.TOP: {
      return {
        wrapper: `
          bottom: 100%;
          left: 50%;
          transform: translate3d(-50%, -10px, 0);
        `,
        arrow: `
          top: 100%;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          border-top-color: ${color};
        `,
      };
    }
    case TooltipPositionEnum.BOTTOM: {
      return {
        wrapper: `
          top: 100%;
          left: 50%;
          transform: translate3d(-50%, 10px, 0);
        `,
        arrow: `
          bottom: 100%;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          border-bottom-color: ${color};
        `,
      };
    }
  }
};

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

export const TooltipWrapper = styled.div<TooltipWrapperProps>`
  z-index: 1000;

  position: absolute;
  ${p => getTooltipPositions(p.position, p.theme.colors.primary[40])?.wrapper}

  width: fit-content;
  white-space: nowrap;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${p => p.theme.colors.primary[40]};
  background-color: ${p => p.theme.colors.primary[0]};

  &:before {
    content: '';
    position: absolute;
    border-width: 10px;
    border-style: solid;
    border-color: transparent;
    ${p => getTooltipPositions(p.position, p.theme.colors.primary[40])?.arrow}
  }
`;
