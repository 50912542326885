import styled from 'styled-components';

export const SourcesListLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableWrapper = styled.div`
  overflow: auto;
  padding: 20px 20px 0;
  flex-grow: 1;
  position: relative;
`;

export const TableLoading = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50);
  bottom: 50px;
`;
