import {
  Customer,
  CustomerList,
  CustomersApiDeleteCustomerRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    CustomerList,
    Customer,
    CustomersApiDeleteCustomerRequest,
    EntitiesErrorType
  >({
    entity: 'customers',
  }),
};

export default reducer;
