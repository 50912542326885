import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  MatchPhase as MatchPhaseType,
  MatchPhaseWithoutId,
} from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { IS_DELETION_DISABLED } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';

import { validationSchema } from './constants';
import { MatchPhaseForm } from './MatchPhaseForm';

interface Props {
  id: number;
  onClose: () => void;
}

export const MatchPhaseEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const matchPhase: MatchPhaseType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.MATCH_PHASES, id),
  );

  useRequestState(Entities.MATCH_PHASES, 'update', onClose);

  const onSubmit = (matchPhaseWithoutId: MatchPhaseWithoutId) => {
    dispatch(
      actionsNT.matchPhasesUpdate({
        matchphaseId: matchPhase.id,
        matchPhaseWithoutId,
      }),
    );
  };

  const onDelete = () => {
    dispatch(actionsNT.matchPhasesDelete({ matchphaseId: matchPhase.id }));
    onClose();
  };

  return (
    <Formik<MatchPhaseWithoutId>
      initialValues={matchPhase}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.matchPhase')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
              leftButtonText={t('delete')}
              leftButtonDisabled={IS_DELETION_DISABLED}
              onLeftButtonClick={onDelete}
            />
          }
        >
          <MatchPhaseForm />
        </Panel>
      )}
    </Formik>
  );
};
