/*
Custom Hook for debounced Search added
 */

import { useCallback, useState } from 'react';
import { debounce } from 'lodash-es';

export const useSearch = (initialValue: string = '') => {
  const [searchQuery, setSearchQuery] = useState<string>(initialValue);

  const debouncedSearch = debounce((searchQuery: string) => {
    setSearchQuery(searchQuery);
  }, 500);

  const setSearchQueryDebounced = useCallback(debouncedSearch, [
    debouncedSearch,
  ]) as (param: string) => void;

  return { searchQuery, setSearchQuery, setSearchQueryDebounced };
};
