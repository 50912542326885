import { PayloadAction } from '@reduxjs/toolkit';
import { EventLinkedIds } from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';

const reducer = {
  eventLinkedIdsFetchItem(state) {
    state.eventLinkedIds.fetchItem.error = null;
    state.eventLinkedIds.fetchItem.loading = true;
  },
  eventLinkedIdsFetchItemSuccess(
    state,
    action: PayloadAction<{ linkedIds: EventLinkedIds; eventId: number }>,
  ) {
    const { linkedIds, eventId } = action.payload;
    state.eventLinkedIds.entries[eventId] = linkedIds;
    state.eventLinkedIds.fetchItem.loading = false;
  },
  eventLinkedIdsFetchItemError(
    state,
    action: PayloadAction<EntitiesErrorType>,
  ) {
    state.eventLinkedIds.fetchItem.error = action.payload;
    state.eventLinkedIds.fetchItem.loading = false;
  },
};

export default reducer;
