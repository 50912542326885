import { PayloadAction } from '@reduxjs/toolkit';
import { SportEventResult } from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';

const reducer = {
  eventsResultsFetchItem(state) {
    state.eventsResults.fetchItem.error = null;
    state.eventsResults.fetchItem.loading = true;
  },
  eventsResultsFetchItemSuccess(
    state,
    action: PayloadAction<{
      results: Array<SportEventResult>;
      eventId: number;
    }>,
  ) {
    const { results, eventId } = action.payload;
    state.eventsResults.entries[eventId] = results;
    state.eventsResults.fetchItem.loading = false;
  },
  eventsResultsFetchItemError(state, action: PayloadAction<EntitiesErrorType>) {
    state.eventsResults.fetchItem.error = action.payload;
    state.eventsResults.fetchItem.loading = false;
  },
  eventsResultsUpdate(state) {
    state.eventsResults.update.error = null;
    state.eventsResults.update.loading = true;
  },
  eventsResultsUpdateSuccess(
    state,
    action: PayloadAction<{
      results: Array<SportEventResult>;
      eventId: number;
    }>,
  ) {
    const { results, eventId } = action.payload;
    state.eventsResults.entries[eventId] = [
      ...results,
      ...state.eventsResults.entries[eventId],
    ];
    state.eventsResults.update.loading = false;
  },
  eventsResultsUpdateError(state, action: PayloadAction<EntitiesErrorType>) {
    state.eventsResults.update.error = action.payload;
    state.eventsResults.update.loading = false;
  },
};

export default reducer;
