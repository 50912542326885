import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { request } from 'app/api';

import { ls } from 'utils/ls';
import { API_URL, MODELS_UI_URL } from 'consts';

const useLoadApiUrls = () => {
  const {
    mutateAsync: getApi,
    isLoading,
    isError,
    data,
  } = useMutation(
    async () => {
      return await request(`${window.location.origin}/api/apis.json`, {
        cache: 'no-cache',
      });
    },
    {
      onSuccess: response => {
        ls.setItem('API_URL', response['sportsbook-api']);
        ls.setItem('MODELS_UI_URL', response['sportsbook-models-ui']);
        window.location.reload();
      },
    },
  );

  const lsConfigValid = !!API_URL && !!MODELS_UI_URL;

  useEffect(() => {
    if (!lsConfigValid && !isLoading && !data && !isError) {
      getApi();
    }
  }, [lsConfigValid, isLoading, data, isError, getApi]);

  useEffect(() => {
    /*
    In order to disable config data caching
    and cause config data re-fetch on the page reload.
     */
    if (lsConfigValid) {
      ls.removeItem('API_URL');
      ls.removeItem('MODELS_UI_URL');
    }
  }, [lsConfigValid]);

  return {
    isError,
    isLoading: !lsConfigValid && !isError,
  };
};

export default useLoadApiUrls;
