import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import {
  OutcomeCategory,
  Sport,
  UserGroupEnum,
} from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';
import { useSearch } from 'hooks/useSearch';
import { FETCH_ALL_LIMIT } from 'consts';
import { useTheme } from 'styles';

import { Select, TextInput } from 'app/components/forms';
import { CategoryCreatePanel, CategoryEditPanel } from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Table } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectCategoriesItems } from 'app/providers/EntitiesProvider/categories';
import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';

import { useUserStore } from 'store';
import { getIsOperator, getIsTranslator } from 'store/user';

import * as S from './OutcomesPanel.styles';

export const Panel: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const navigate = useNavigate();
  const allSportsOption = { id: 0, name: t('all'), code: '' };
  const [sport, setSport] = useState<Sport>(allSportsOption);
  const isOperator = useUserStore(getIsOperator);
  const isTranslator = useUserStore(getIsTranslator);
  const [isCreateCategoryVisible, showCreateCategory, hideCreateCategory] =
    useBooleanState();
  const [editCategoryId, setEditCategoryId] = useState<null | number>(null);

  const categories = useSelector(selectCategoriesItems);
  const sports = useSelector(selectSportsItems);

  useEffect(() => {
    dispatch(
      actionsNT.categoriesFetchItems({
        limit: FETCH_ALL_LIMIT,
        name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
      }),
    );
  }, [dispatch, searchQuery]);

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
    if (!isTranslator) {
      dispatch(actionsNT.marketsFetchItems({ limit: FETCH_ALL_LIMIT }));
    }
  }, [dispatch, isTranslator]);

  const theme = useTheme();

  const filterOptions = [allSportsOption, ...sports];

  const columns: Column<OutcomeCategory>[] = useMemo(
    () => [
      { Header: t('name').toString(), accessor: 'name' },
      ...(!(isTranslator || isOperator)
        ? [
            {
              Header: '',
              id: 'action',
              Cell: ({ row: { original: data } }) => (
                <Button
                  color="action"
                  onClick={() => {
                    setEditCategoryId(data.id);
                  }}
                >
                  {t('edit')}
                </Button>
              ),
            },
          ]
        : []),
    ],
    [isOperator, isTranslator, t],
  );

  const filteredData = useMemo(
    () =>
      categories.filter(category => {
        if (sport?.id) {
          return category.sports.includes(sport.id);
        }
        return true;
      }),
    [categories, sport],
  );

  return (
    <S.Panel>
      <S.DropdownWrapper>
        <DictionaryDropdown value={'outcomes'} />
        <RoleGuards
          roles={[
            UserGroupEnum.ADMIN,
            UserGroupEnum.CUSTOMER,
            UserGroupEnum.SUPERVISOR,
          ]}
        >
          <Button onClick={showCreateCategory}>{t('create category')}</Button>
        </RoleGuards>
      </S.DropdownWrapper>
      <S.PanelHeading>
        <TextInput
          placeholder={t('search')}
          onChange={e => setSearchQueryDebounced(e.target.value)}
          icon={<FontAwesomeIcon icon={faSearch} />}
        />
        <Select
          placeholder={t('sport type')}
          value={sport}
          options={filterOptions}
          onChange={element => setSport(element as Sport)}
        />
      </S.PanelHeading>
      <S.PanelBlock>
        <Table
          columns={columns}
          data={filteredData}
          columnsWidth={['auto', 'fit-content']}
          onRowClick={data => {
            navigate(`/dictionary/outcomes/${data.id}`, { replace: true });
          }}
          rowStyles={row => {
            return row.disabled
              ? `background-color: ${theme.colors.primary[50]} !important;
              &:hover {
                background-color: ${theme.colors.primary[50]} !important;
              }
              `
              : '';
          }}
        />
      </S.PanelBlock>
      {isCreateCategoryVisible && (
        <CategoryCreatePanel onClose={hideCreateCategory} />
      )}
      {!!editCategoryId && (
        <CategoryEditPanel
          onClose={() => {
            setEditCategoryId(null);
          }}
          id={editCategoryId}
        />
      )}
    </S.Panel>
  );
};
