/**
 *
 * Dictionary
 *
 */

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import * as S from './index.styles';

export function Dictionary() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('dictionary')}</title>
        <meta name="description" content="Description of Dictionary" />
      </Helmet>
      <S.Wrapper>
        <Outlet />
      </S.Wrapper>
    </>
  );
}
