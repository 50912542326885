/**
 *
 * Translations
 *
 */

import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingIndicator, Tabs } from 'app/components/ui';

import { useUserStore } from 'store';
import { getIsOperator } from 'store/user';

import { EntitiesTable } from './components';
import { TranslationFilters } from './components';
import { getFilters } from './selectors';
import { actions } from './slice';
import * as S from './Translations.styles';
import { TranslatedEntities } from './types';

export function Translations() {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const isOperator = useUserStore(getIsOperator);

  const tabs = useMemo(() => {
    if (!isOperator) {
      return [
        {
          label: t('sport'),
          value: TranslatedEntities.SPORTS,
        },
        {
          label: t('group'),
          value: TranslatedEntities.GROUPS,
        },
        {
          label: t('tournament'),
          value: TranslatedEntities.TOURNAMENTS,
        },
        {
          label: t('competitor'),
          value: TranslatedEntities.COMPETITORS,
        },
        {
          label: t('country'),
          value: TranslatedEntities.COUNTRIES,
        },
        {
          label: t('player'),
          value: TranslatedEntities.PLAYERS,
        },
        {
          label: t('category'),
          value: TranslatedEntities.CATEGORIES,
        },
        {
          label: t('outcome'),
          value: TranslatedEntities.TYPES,
        },
        {
          label: t('short name'),
          value: TranslatedEntities.SHORT_NAMES,
        },
        {
          label: t('match type'),
          value: TranslatedEntities.MATCH_TYPES,
        },
        {
          label: t('match phase'),
          value: TranslatedEntities.MATCH_PHASES,
        },
        {
          label: t('comments'),
          value: TranslatedEntities.COMMENTS,
        },
        {
          label: t('currencies'),
          value: TranslatedEntities.CURRENCIES,
        },
      ];
    } else {
      return [
        {
          label: t('sport'),
          value: TranslatedEntities.SPORTS,
        },
        {
          label: t('group'),
          value: TranslatedEntities.GROUPS,
        },
        {
          label: t('tournament'),
          value: TranslatedEntities.TOURNAMENTS,
        },
        {
          label: t('competitor'),
          value: TranslatedEntities.COMPETITORS,
        },
        {
          label: t('country'),
          value: TranslatedEntities.COUNTRIES,
        },
        {
          label: t('player'),
          value: TranslatedEntities.PLAYERS,
        },
        {
          label: t('category'),
          value: TranslatedEntities.CATEGORIES,
        },
        {
          label: t('outcome'),
          value: TranslatedEntities.TYPES,
        },
        {
          label: t('short name'),
          value: TranslatedEntities.SHORT_NAMES,
        },
        {
          label: t('match type'),
          value: TranslatedEntities.MATCH_TYPES,
        },
        {
          label: t('match phase'),
          value: TranslatedEntities.MATCH_PHASES,
        },
        {
          label: t('comments'),
          value: TranslatedEntities.COMMENTS,
        },
      ];
    }
  }, [isOperator, t]);

  const handleChange = (entity: TranslatedEntities) => {
    dispatch(
      actions.setState({
        entity,
        filters: {
          name: '',
        },
      }),
    );
  };

  const filters = useSelector(getFilters);

  return (
    <>
      <Helmet>
        <title>{t('translations')}</title>
        <meta name="description" content="Description of Translations" />
      </Helmet>
      <S.Wrapper>
        <Tabs tabs={tabs} onChange={handleChange} />
        <S.Content>
          <TranslationFilters />
          {filters.langCode ? (
            <EntitiesTable />
          ) : (
            <LoadingIndicator type="full" />
          )}
        </S.Content>
      </S.Wrapper>
    </>
  );
}
