import {
  Currency,
  CurrencyApiDeleteCurrencyRequest,
  CurrencyList,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    CurrencyList,
    Currency,
    CurrencyApiDeleteCurrencyRequest,
    EntitiesErrorType
  >({
    entity: 'currencies',
  }),
};

export default reducer;
