import { categoriesApi } from 'app/api';

const api = {
  endpoint: categoriesApi,
  methods: {
    fetchItems: 'getCategories',
    fetchItem: 'getCategory',
    create: 'createCategory',
    update: 'updateCategory',
    delete: 'deleteCategory',
  },
};

export default api;
