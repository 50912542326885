import { delaysApi } from 'app/api';

const api = {
  endpoint: delaysApi,
  methods: {
    fetchItems: 'getDelays',
    fetchItem: 'getDelay',
    create: 'createDelay',
    createList: 'createDelayList',
    update: 'updateDelay',
    delete: 'deleteDelay',
  },
};

export default api;

export const delaysByEventApi = {
  endpoint: delaysApi,
  methods: {
    fetchItems: 'getDelaysEventList',
  },
};
