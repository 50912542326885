/**
 *
 * Asynchronously loads the component for Live
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Live = lazyLoad(
  () => import('./Live'),
  module => module.Live,
);
