import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Sport } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';

import { createInitialValues, FormValues, SportSchema } from './constants';
import { SportForm } from './SportForm';

interface Props {
  onClose: () => void;
  onSuccess?: (sport: Sport) => void;
}

export const SportCreatePanel = ({ onClose, onSuccess }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSportCreated = (result: Sport) => {
    if (onSuccess) onSuccess(result);
    onClose();
  };

  useRequestState(Entities.SPORTS, 'create', handleSportCreated);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.sportsCreate({
        sportWithOutId: {
          code: values.code,
          name: values.name,
          translations: [],
          genericSportId: values.genericSport?.id,
        },
      }),
    );
  };

  return (
    <Formik
      initialValues={createInitialValues}
      onSubmit={onSubmit}
      validationSchema={SportSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.create.sport')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <SportForm />
        </Panel>
      )}
    </Formik>
  );
};
