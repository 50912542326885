import styled from 'styled-components';

export const Panel = styled.div`
  display: grid;
  grid-template-rows: 56px minmax(0, 1fr);

  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
`;

export const HeaderWrapper = styled.div`
  padding: 0 20px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  border-bottom: 1px solid #e5e5e5;
`;

export const PanelContent = styled.div`
  display: grid;
  grid-template-rows: 56px minmax(0, 1fr);
  position: relative;
`;

export const SearchWrapper = styled(HeaderWrapper)`
  border-bottom: none;
  grid-template-columns: 1fr;
  z-index: 1;
`;

export const ScrollingWrapper = styled.div`
  overflow: auto;
`;
