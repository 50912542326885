export const ls = {
  getItem(key: string) {
    const value = localStorage.getItem(key);
    if (value && value !== 'undefined') {
      return JSON.parse(value);
    } else {
      return undefined;
    }
  },
  setItem(key: string, value: Json) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  removeItem(key: string) {
    localStorage.removeItem(key);
  },
};
