import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';

import { CommentForm } from './CommentForm';
import { createInitialValues, FormValues, validationSchema } from './constants';

interface Props {
  onClose: () => void;
}

export const CommentCreatePanel = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useRequestState('comments', 'create', onClose);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.commentsCreate({
        commentWithoutId: values,
      }),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={createInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.create.comment')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <CommentForm />
        </Panel>
      )}
    </Formik>
  );
};
