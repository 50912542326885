import {
  AdapterState,
  ContainerState,
} from 'app/providers/AdaptersProvider/types';

import { deleteReducersGenerator } from './deleteUtils';
import { fetchReducersGenerator } from './fetchUtils';
import { mapReducersGenerator } from './mapUtils';

interface GeneratorParams {
  reset?: boolean;
  fetch?: boolean;
  map?: boolean;
  remove?: boolean;
  withRewrite?: boolean;
}

const defaultParams: GeneratorParams = {
  reset: false,
  fetch: false,
  map: false,
  remove: false,
  withRewrite: false,
};

const defaultReset =
  (entity: keyof AdapterState) => (state: ContainerState) => {
    state.entities[entity].entries = {};
    state.entities[entity].sortedIds = [];
  };

export const reducersGenerator = (
  entityName: keyof AdapterState,
  generatorParams: GeneratorParams,
) => {
  const params: GeneratorParams = { ...defaultParams, ...generatorParams };
  return {
    ...(params.fetch && fetchReducersGenerator(entityName, params.withRewrite)),
    ...(params.reset && { [`${entityName}Reset`]: defaultReset(entityName) }),
    ...(params.map && mapReducersGenerator(entityName)),
    ...(params.remove && deleteReducersGenerator(entityName)),
  };
};
