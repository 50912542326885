import { all, fork } from 'redux-saga/effects';

import { adaptersProviderSaga } from 'app/providers/AdaptersProvider/saga';
import { auditProviderSaga } from 'app/providers/AuditProvider/saga';
import { entitiesSaga } from 'app/providers/EntitiesProvider/saga';

export default function* rootSaga() {
  return yield all([
    fork(adaptersProviderSaga),
    fork(auditProviderSaga),
    fork(entitiesSaga),
  ]);
}
