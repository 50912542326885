import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
`;

export const H2 = styled.span`
  color: ${p => p.theme.colors.primary[900]};
  font-size: ${p => p.theme.fonts.typography['H700'].size};
  font-weight: ${p => p.theme.fonts.typography['H700'].weight};
`;

export const EventHeader = styled.div`
  padding: 20px 24px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto auto auto 1fr;
  grid-column-gap: 10px;
  align-items: center;
`;
