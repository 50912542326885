import {
  AdapterCountry,
  AdapterSport,
  AdapterTournament,
} from 'sportsbook-openapi-react';

export interface SourcesTableState {
  selectedEvents: string[];
}

export type SourcePageParamsType = {
  adapter: string;
};

export enum SourceTabsEnum {
  UNMAPPED = 'tabs.unmapped',
  MAPPED = 'tabs.mapped',
  EXCLUDED = 'tabs.excluded',
}

export interface Filters {
  sport?: Required<AdapterSport>;
  tournament?: AdapterTournament;
  scheduledFrom?: Date;
  scheduledTo?: Date;
  notBegan?: boolean;
  probsReceived?: boolean;
  country?: AdapterCountry;
  isOutright: boolean;
}
