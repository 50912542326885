import { eventsApi } from 'app/api';

const api = {
  endpoint: eventsApi,
  methods: {
    fetchItem: 'getEventStats',
  },
};

export default api;
