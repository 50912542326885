import styled from 'styled-components';

import { Text } from 'app/components/ui';

export const Content = styled.div`
  display: grid;
  grid-template-rows: auto 32px 40px minmax(0, 1fr);
  grid-template-columns: 1fr;
`;

export const ContentBlock = styled.div`
  padding: 20px;
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-row-gap: 20px;
`;

export const ContentHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 20px;
`;

export const ContentInfoBlockWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
`;

export const ContentInfoBlock = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-auto-rows: 2rem;
  gap: 0.5rem 1rem;
`;

export const InfoLabel = styled(Text)`
  line-height: 2;
  white-space: nowrap;
`;

export const InfoValue = styled(Text)`
  line-height: 2;
  color: #4d4d4d;
`;

export const TabsHeading = styled(Text)`
  padding: 0 20px;
  align-self: center;
`;

export const ContentBottomBlock = styled.div`
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const Spacer = styled.div``;
