import styled from 'styled-components';

export const StyledFormBlock = styled.div`
  display: grid;
  grid-gap: 40px;
`;

export const TableWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  grid-row-gap: 16px;
`;

export const ErrorMessage = styled.div`
  grid-column: 1/3;
  color: ${p => p.theme.colors.red[500]};
`;
