import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pick from 'lodash-es/pick';
import {
  SportEventProbabilitiesMarketOriginal,
  SportEventProbabilityOriginal,
} from 'sportsbook-openapi-react';

import { useSearch } from 'hooks/useSearch';

import { TextInput } from 'app/components/forms';
import { AdapterOutcomesMappingPanel } from 'app/components/panels';
import { Button, LoadingIndicator, Panel, Table } from 'app/components/ui';

import {
  adapterActions,
  getAdapterEntities,
  selectAdapterPlayersItems,
} from 'app/providers/AdaptersProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import {
  getIsTypesLoading,
  getTypesEntries,
} from 'app/providers/EntitiesProvider/type';

import * as S from './styles';
import { adapterToPlayerParam, rawParamsToColumns } from './utils';

interface Props {
  title: string;
  adapterEventId: string;
  adapter: string;
  onClose: () => void;
}

export const AdapterOutcomesPanel = ({
  onClose,
  title,
  adapterEventId,
  adapter,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedMarket, setSelectedMarket] = useState<
    SportEventProbabilitiesMarketOriginal | undefined
  >();
  const [outcomesPanel, setOutcomesPanel] = useState<null | {
    adapterOutcomeTypeId: string;
    marketName?: string;
    rawParams?: string[];
  }>(null);
  const { searchQuery, setSearchQueryDebounced } = useSearch();

  const adapters = useSelector(getAdapterEntities);
  const adapterPlayersItems = useSelector(selectAdapterPlayersItems);

  const markets = adapters.markets.entries;
  const { error, loading } = adapters.eventProbabilities.fetch;
  const probabilities = adapters.eventProbabilities.entries[adapterEventId];

  const adapterTypesIds = useMemo(() => {
    if (!selectedMarket?.probabilities) return [];
    return selectedMarket.probabilities.map(
      probability => probability.outcomeTypeId!,
    );
  }, [selectedMarket]);

  const adapterTypes = adapters.types.entries;
  const adapterTypesLoading = adapters.types.fetch.loading;

  const sportsbookTypesIds = useMemo(() => {
    const result: number[] = [];
    Object.values(pick(adapterTypes, adapterTypesIds)).forEach(type => {
      if (!!type.sportsbookId) result.push(type.sportsbookId);
    });
    return result;
  }, [adapterTypes, adapterTypesIds]);

  const sportsbookTypes = useSelector(getTypesEntries);
  const sportsbookTypesLoading = useSelector(getIsTypesLoading);

  useEffect(() => {
    dispatch(
      adapterActions.eventProbabilitiesFetch({
        id: adapterEventId,
        adapter: adapter!,
      }),
    );
  }, [adapter, adapterEventId, dispatch]);

  useEffect(() => {
    if (probabilities) {
      dispatch(
        adapterActions.marketsFetch({
          adapter: adapter!,
          ids: probabilities?.markets?.map(market => market.marketId!),
        }),
      );
    }
  }, [dispatch, adapterEventId, adapter, probabilities]);

  useEffect(() => {
    if (adapterTypesIds.length) {
      dispatch(
        adapterActions.typesFetch({
          adapter: adapter!,
          ids: adapterTypesIds,
          limit: adapterTypesIds.length,
        }),
      );
    }
  }, [dispatch, adapter, adapterTypesIds]);

  useEffect(() => {
    if (sportsbookTypesIds.length) {
      dispatch(
        actionsNT.typesFetchItems({
          ids: sportsbookTypesIds,
          limit: sportsbookTypesIds.length,
        }),
      );
    }
  }, [sportsbookTypesIds, dispatch]);

  const marketColumns: Column<SportEventProbabilitiesMarketOriginal>[] = [
    {
      Header: t('market').toString(),
      accessor: 'marketId',
      Cell: ({ value }) => <>{value ? markets[value]?.name : value}</>,
    },
  ];

  const marketsData = useMemo(
    () =>
      probabilities && probabilities?.markets
        ? probabilities.markets.filter(
            market =>
              market.marketId &&
              markets[market.marketId]?.name
                ?.toLowerCase()
                ?.includes(searchQuery.toLowerCase()),
          )
        : [],
    [probabilities, markets, searchQuery],
  );

  const marketProbabilities = useMemo(
    () => selectedMarket?.probabilities ?? [],
    [selectedMarket],
  );

  const requestedIds = useRef<{
    sportsbookPlayers: number[];
    adapterPlayers: string[];
  }>({ sportsbookPlayers: [], adapterPlayers: [] });

  useEffect(() => {
    const idsToFetch: string[] = [];
    marketProbabilities.forEach(item => {
      if (item.rawParams && item.rawParams[adapterToPlayerParam[adapter]]) {
        const id = item.rawParams[adapterToPlayerParam[adapter]];
        if (!requestedIds.current.adapterPlayers.includes(id))
          idsToFetch.push(id);
      }
    });
    requestedIds.current.adapterPlayers.push(...idsToFetch);

    if (idsToFetch.length) {
      dispatch(
        adapterActions.playersFetch({
          adapter,
          limit: idsToFetch.length,
          ids: idsToFetch,
        }),
      );
    }
  }, [adapter, dispatch, marketProbabilities]);

  useEffect(() => {
    const playersIdsToFetch: number[] = [];
    adapterPlayersItems.forEach(dataEntry => {
      if (
        dataEntry.sportsbookId &&
        !requestedIds.current.sportsbookPlayers.includes(dataEntry.sportsbookId)
      ) {
        playersIdsToFetch.push(dataEntry.sportsbookId);
      }
    });

    if (playersIdsToFetch.length) {
      dispatch(
        actionsNT.playersFetchItems({
          ids: playersIdsToFetch,
          limit: playersIdsToFetch.length,
          withPagination: true,
        }),
      );
      requestedIds.current.sportsbookPlayers.push(...playersIdsToFetch);
    }
  }, [adapterPlayersItems, dispatch]);

  const paramsColumns = useMemo(
    () => rawParamsToColumns(marketProbabilities, adapter),
    [adapter, marketProbabilities],
  );

  const outcomeColumns = useMemo<Column<SportEventProbabilityOriginal>[]>(
    () => [
      {
        Header: <S.ThinHeader>{t('outcome')}</S.ThinHeader>,
        id: 'outcome',
        Cell: ({ row: { original: data } }) => {
          if (data.outcomeTypeId && adapterTypes[data.outcomeTypeId]) {
            return (
              <S.NoWrapLabel
                title={adapterTypes[data.outcomeTypeId].description}
              >
                {adapterTypes[data.outcomeTypeId].description}
              </S.NoWrapLabel>
            );
          } else {
            return <S.NoWrapLabel title={data.kind}>{data.kind}</S.NoWrapLabel>;
          }
        },
      },
      {
        Header: t('id').toString(),
        accessor: 'outcomeTypeId',
        Cell: ({ value }) => (
          <S.NoWrapLabel title={value}>{value}</S.NoWrapLabel>
        ),
      },
      {
        Header: t('mapping').toString(),
        id: 'mapping',
        accessor: 'outcomeTypeId',
        Cell: ({ row: { original: data } }) => {
          return data.outcomeTypeId ? (
            <S.MappingValue>
              {adapterTypes[data.outcomeTypeId]?.sportsbookId &&
              sportsbookTypes[adapterTypes[data.outcomeTypeId].sportsbookId!]
                ? sportsbookTypes[
                    adapterTypes[data.outcomeTypeId].sportsbookId!
                  ].name
                : ''}
              <Button
                color="link"
                onClick={() => {
                  setOutcomesPanel({
                    adapterOutcomeTypeId: data.outcomeTypeId!,
                    rawParams: Object.keys(data.rawParams ?? {}),
                    marketName: markets[selectedMarket!.marketId!]?.name,
                  });
                }}
              >
                {!adapterTypes[data.outcomeTypeId]?.sportsbookId ? (
                  <>{t('map')}</>
                ) : (
                  <FontAwesomeIcon icon={faPenToSquare} />
                )}
              </Button>
            </S.MappingValue>
          ) : null;
        },
      },
      ...paramsColumns,
      {
        Header: () => <S.ThinHeader>{t('probability')}</S.ThinHeader>,
        accessor: 'probability',
        Cell: ({ value }) => (
          <S.ThinCell>
            {typeof value === 'number' ? value.toFixed(4) : value}
          </S.ThinCell>
        ),
      },
      {
        Header: () => <S.ThinHeader>{t('odd')}</S.ThinHeader>,
        accessor: 'odd',
        Cell: ({ value }) => <S.ThinCell>{value}</S.ThinCell>,
      },
    ],
    [t, paramsColumns, adapterTypes, sportsbookTypes, markets, selectedMarket],
  );

  const columnsWidth = Array(5 + paramsColumns.length).fill('min-content');
  const typesLoading = adapterTypesLoading || sportsbookTypesLoading;

  return (
    <Panel title={title} onClose={onClose} panelWidth={70}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <S.TablesWrapper>
          {probabilities && probabilities.markets ? (
            <>
              <S.TableWrapper>
                <TextInput
                  onChange={event =>
                    setSearchQueryDebounced(event.target.value)
                  }
                />
                <Table
                  columns={marketColumns}
                  data={marketsData}
                  onRowClick={(cell: SportEventProbabilitiesMarketOriginal) =>
                    setSelectedMarket(cell)
                  }
                  isSelected={(cell: SportEventProbabilitiesMarketOriginal) =>
                    cell.marketId === selectedMarket
                  }
                />
              </S.TableWrapper>
              <S.Spacer />
              {selectedMarket?.probabilities &&
                (typesLoading ? (
                  <LoadingIndicator type="full" />
                ) : (
                  <S.TableWrapper>
                    <S.TableTitle>{t('outcomes')}</S.TableTitle>
                    <Table
                      columns={outcomeColumns}
                      data={marketProbabilities}
                      columnsWidth={columnsWidth}
                    />
                  </S.TableWrapper>
                ))}
            </>
          ) : error ? (
            t('error with loading probabilities')
          ) : (
            t('no data')
          )}
          {!!outcomesPanel && (
            <AdapterOutcomesMappingPanel
              adapter={adapter}
              adapterOutcomeTypeId={outcomesPanel.adapterOutcomeTypeId}
              marketName={outcomesPanel.marketName}
              rawParams={outcomesPanel.rawParams}
              onClose={() => {
                setOutcomesPanel(null);
              }}
            />
          )}
        </S.TablesWrapper>
      )}
    </Panel>
  );
};
