import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useUpdateEvent } from 'hooks/events';
import { RootState } from 'types';

import { Button } from 'app/components/ui';

import { getEvent } from 'app/providers/EntitiesProvider/events';

interface Props {
  eventId: number;
}

export const BlockTimeButton = ({ eventId }: Props) => {
  const updateEvent = useUpdateEvent();
  const { t } = useTranslation();

  const event = useSelector((state: RootState) => getEvent(state, eventId));

  const handleBlockTimeClick = () => {
    updateEvent({
      ...event,
      ignoreAdapterTime: !event.ignoreAdapterTime,
    });
  };

  return (
    <div style={{ width: '300px' }}>
      {event.ignoreAdapterTime ? (
        <Button color="secondary" onClick={handleBlockTimeClick}>
          {t('unblock time changing')}
        </Button>
      ) : (
        <Button color="action" onClick={handleBlockTimeClick}>
          {t('block time changing')}
        </Button>
      )}
    </div>
  );
};
