import { PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash-es/keyBy';
import uniq from 'lodash-es/uniq';
import {
  OutcomeTypeCrossMappingList,
  OutcomeTypeCrossMappingWithOutcomeTypes,
  TypesApiDeleteOutcomeTypeCrossMappingRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    any,
    OutcomeTypeCrossMappingWithOutcomeTypes,
    TypesApiDeleteOutcomeTypeCrossMappingRequest,
    EntitiesErrorType
  >({
    entity: 'crossMappings',
  }),
  crossMappingsFetchItemsSuccess(
    state,
    action: PayloadAction<OutcomeTypeCrossMappingList>,
  ) {
    const { items } = action.payload;
    state.crossMappings.entries = {
      ...state.crossMappings.entries,
      ...keyBy(items, item => String(item.outcomeType!.id)),
    };
    state.crossMappings.sortedIds = uniq([
      ...state.crossMappings.sortedIds,
      ...items.map(item => item.outcomeType!.id),
    ]);
    state.crossMappings.fetchItems.loading = false;
  },
  crossMappingsUpdateSuccess(
    state,
    action: PayloadAction<OutcomeTypeCrossMappingWithOutcomeTypes>,
  ) {
    const entry = action.payload;
    const id = entry.outcomeType!.id;
    state.crossMappings.entries[id] = entry;
    if (!state.crossMappings.sortedIds.includes(id)) {
      state.crossMappings.sortedIds.push(id);
    }
    state.crossMappings.update.loading = false;
    state.crossMappings.update.id = id;
    state.crossMappings.update.result = entry;
    state.crossMappings.update.error = null;
  },
  crossMappingsCreateSuccess(
    state,
    action: PayloadAction<OutcomeTypeCrossMappingWithOutcomeTypes>,
  ) {
    const entry = action.payload;
    const id = entry.outcomeType!.id;
    state.crossMappings.entries[id] = entry;
    state.crossMappings.sortedIds.push(id);
    state.crossMappings.create.loading = false;
    state.crossMappings.create.id = id;
    state.crossMappings.create.result = entry;
    state.crossMappings.create.error = null;
  },
  crossMappingsDelete(
    state,
    action: PayloadAction<TypesApiDeleteOutcomeTypeCrossMappingRequest>,
  ) {
    const { typeId } = action.payload;

    delete state.crossMappings.entries[typeId];
    state.crossMappings.sortedIds = state.crossMappings.sortedIds.filter(
      id => id !== typeId,
    );

    state.crossMappings.delete.loading = true;
  },
};

export default reducer;
