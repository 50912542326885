import styled from 'styled-components';

export const ErrorMessage = styled.div`
  grid-column: 1/3;
  color: ${p => p.theme.colors.red[500]};
`;

export const EditWrapper = styled.div`
  display: grid;
  grid-row-gap: 32px;
`;

export const TablesBlockWrapper = styled.div`
  display: grid;
  grid-row-gap: 16px;
`;

export const StyledBlockHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  gap: 30px;
`;

export const StyledBlockTitle = styled.div`
  color: ${p => p.theme.colors.primary[400]};
  font-size: ${p => p.theme.fonts.typography['H500'].size};
`;

export const Divider = styled.div`
  background-color: ${p => p.theme.colors.primary[40]};
  width: 90%;
  height: 2px;
`;
