import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from 'app/pages/Sources/components/EntitiesTables/styles';
import { FormikProvider, useFormik } from 'formik';
import {
  AdapterCountry,
  AdapterSport,
  AdapterTournament,
  Tournament,
} from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import useBooleanState from 'hooks/useBooleanState';

import { AsyncSelectField } from 'app/components/forms';
import { TournamentCreatePanel } from 'app/components/panels';
import { Button } from 'app/components/ui';
import { TextLoader } from 'app/components/ui';
import LargePlus from 'assets/images/large-plus.svg?react';

import { adapterActions } from 'app/providers/AdaptersProvider';

type Values = {
  sportsbookTournament?: Tournament;
};

interface Props {
  mapped: boolean;
  adapter: string;
  tournament: AdapterTournament;
  tournaments: Dictionary<Tournament>;
  countries: Dictionary<AdapterCountry>;
  isEditing: boolean;
  isFetched: boolean;
  setEditingRow: (editingId: string | undefined) => void;
  sport?: Maybe<AdapterSport>;
}

export const TournamentRow: FC<Props> = ({
  mapped,
  adapter,
  sport,
  tournament,
  tournaments,
  countries,
  isEditing,
  setEditingRow,
  isFetched,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [
    isCreateTournamentPanelVisible,
    showCreateTournamentPanel,
    hideCreateTournamentPanel,
  ] = useBooleanState();

  const handleDeleteMapping = () => {
    if (!tournament.id) return;
    dispatch(
      adapterActions.tournamentsDeleteMapping({ id: tournament.id, adapter }),
    );
  };

  const handleEdit = () => {
    setEditingRow(tournament.id);
  };

  const formik = useFormik<Values>({
    initialValues: {
      sportsbookTournament: mapped
        ? tournaments[tournament.sportsbookId!]
        : undefined,
    },
    onSubmit: ({ sportsbookTournament }) => {
      dispatch(
        adapterActions.tournamentsMap({
          id: tournament.id!,
          adapter,
          mappingRequest: {
            sportsbookId: sportsbookTournament?.id!,
          },
          withDeletion: !mapped,
        }),
      );
      setEditingRow(undefined);
    },
    enableReinitialize: true,
  });

  const { values, submitForm, setFieldValue } = formik;

  const onTournamentCreated = entity => {
    setFieldValue('sportsbookTournament', entity);
  };

  const tournamentSelect = (
    <AsyncSelectField
      name="sportsbookTournament"
      loadOptions={getAsyncLoadOptions({
        entity: 'tournaments',
        sportId: sport?.sportsbookId,
        disabled: false,
      })}
      debounceTimeout={200}
    />
  );

  return (
    <FormikProvider value={formik}>
      {mapped ? (
        <S.Tr>
          <S.Td>{tournament.name}</S.Td>
          {isEditing ? (
            <S.SelectTd>
              {tournamentSelect}
              <Button color="action" onClick={showCreateTournamentPanel}>
                <LargePlus />
              </Button>
            </S.SelectTd>
          ) : (
            <S.Td>
              {!isFetched ? <TextLoader /> : values.sportsbookTournament?.name}
            </S.Td>
          )}
          <S.Td>
            {tournament.countryId && countries[tournament.countryId]?.name}
          </S.Td>
          <S.Td>
            {isEditing ? (
              <S.ButtonsBlock>
                <S.ButtonsWrapper>
                  <Button
                    color="action"
                    onClick={submitForm}
                    disabled={!values.sportsbookTournament}
                  >
                    <FontAwesomeIcon icon={faSave} />
                    {t('save')}
                  </Button>
                  <Button
                    color="action"
                    onClick={handleDeleteMapping}
                    disabled={!values.sportsbookTournament}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    {t('delete')}
                  </Button>
                </S.ButtonsWrapper>
              </S.ButtonsBlock>
            ) : (
              <S.ButtonsBlock>
                <Button key={'edit'} color="action" onClick={handleEdit}>
                  <FontAwesomeIcon icon={faEdit} />
                  {t('edit')}
                </Button>
              </S.ButtonsBlock>
            )}
          </S.Td>
        </S.Tr>
      ) : (
        <S.Tr>
          <S.Td weight={35}>{tournament.name}</S.Td>
          <S.Td weight={20}>
            {tournament.countryId && countries[tournament.countryId]?.name}
          </S.Td>
          <S.SelectTd weight={35}>
            {tournamentSelect}
            <Button color="action" onClick={showCreateTournamentPanel}>
              <LargePlus />
            </Button>
          </S.SelectTd>
          <S.Td weight={10} textAlign={'right'}>
            <Button
              disabled={!values.sportsbookTournament}
              onClick={submitForm}
            >
              {t('map')}
            </Button>
          </S.Td>
        </S.Tr>
      )}
      {isCreateTournamentPanelVisible && (
        <TournamentCreatePanel
          onClose={hideCreateTournamentPanel}
          onSuccess={onTournamentCreated}
        />
      )}
    </FormikProvider>
  );
};
