import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { eventsApi } from 'app/api';
import { debounce } from 'lodash-es';
import { Event } from 'sportsbook-openapi-react';

import {
  SelectEventFilters,
  SelectEventModalProps,
} from 'app/components/modals/SelectEventModal/SelectEventModal.types';

import { actionsNT } from 'app/providers/EntitiesProvider';

import { SelectEventFiltersBlock, SelectEventTable } from './components';
import { TableModal } from './SelectEventModalContent.styles';

export const SelectEventModalContent: FC<SelectEventModalProps> = ({
  initialFilters,
  onChange,
  excludedEvents,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<Event>();
  const [state, setState] = useState<{
    items: Event[];
    loaded: boolean;
    isLoading: boolean;
  }>({
    items: [],
    loaded: false,
    isLoading: false,
  });

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems());
  }, [dispatch]);

  const handleChange = (newValue: Event) => {
    setValue(newValue);
    onChange(newValue);
  };

  const loadEvents = useRef(
    debounce(
      ({
        sport,
        group,
        tournament,
        homeCompetitors,
        awayCompetitors,
        ...restFilters
      }: SelectEventFilters) => {
        setState(prevState => ({ ...prevState, isLoading: true }));
        eventsApi
          .getEvents({
            sportId: sport?.id,
            groupId: group?.id,
            tournamentId: tournament?.id,
            homeCompetitorIds: homeCompetitors?.map(c => c.id),
            awayCompetitorIds: awayCompetitors?.map(c => c.id),
            ...restFilters,
          })
          .then(response => {
            setState({ items: response.items, loaded: true, isLoading: false });
          })
          .catch(() => {
            setState({ items: [], loaded: true, isLoading: false });
          });
      },
      1000,
    ),
  );

  return (
    <>
      <SelectEventFiltersBlock
        initialFilters={initialFilters}
        loadEvents={loadEvents.current}
        isLoading={state.isLoading}
      />
      <TableModal>
        <SelectEventTable
          {...state}
          value={value}
          onChange={handleChange}
          excludedEvents={excludedEvents}
        />
      </TableModal>
    </>
  );
};
