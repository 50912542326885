import { MaxOdd } from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = entityReducersGenerator<
  MaxOdd[],
  MaxOdd,
  void,
  EntitiesErrorType
>({
  entity: 'maxOdds',
  idFieldName: 'outcomeCategoryId',
});

export default reducer;
