import { sportsApi } from 'app/api';

const api = {
  endpoint: sportsApi,
  methods: {
    fetchItems: 'getSports',
    fetchItem: 'getSport',
    create: 'createSport',
    update: 'updateSport',
    delete: 'deleteSport',
  },
};

export default api;
