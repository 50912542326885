import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: Roboto, sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  input, select, textarea {
    font-family: inherit;
    font-size: inherit;
  }
  
  button {
    outline: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-out;
  }

  .react-datepicker-popper {
    z-index: 3;
  }
`;
