import { eventsApi } from 'app/api';

const api = {
  endpoint: eventsApi,
  methods: {
    fetchItems: 'getEvents',
    fetchItem: 'getEvent',
    create: 'createEvent',
    update: 'updateEvent',
    delete: 'deleteEvent',
  },
};

export default api;
