import { Column } from 'react-table';
import { Margin } from 'sportsbook-openapi-react';

import MarginCell from './MarginCell';

interface Props {
  header: string;
  accessor: string;
  margins: Dictionary<Margin>;
  updateMarginValue: (props: {
    margin?: Margin;
    value: number;
    outcomeCategoryId: number;
  }) => void;
  isOperator: boolean;
  isEditable: boolean;
}

export const MarginColumn = <T extends object>({
  header,
  accessor,
  margins,
  updateMarginValue,
  isOperator,
  isEditable,
}: Props): Column<T> => {
  return {
    Header: header,
    id: 'margin',
    accessor: accessor as keyof T,
    Cell: ({ value: categoryId }) => {
      const margin = margins[categoryId];
      return (
        <MarginCell
          margin={margin}
          outcomeCategoryId={categoryId}
          handleCellChange={updateMarginValue}
          isOperator={isOperator}
          isEditable={isEditable}
        />
      );
    },
  };
};
