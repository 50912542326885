import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';

const getEventLinkedIdsSubstate = (state: RootState) =>
  getEntities(state).eventLinkedIds;
const getEventLinkedIdsEntries = (state: RootState) =>
  getEventLinkedIdsSubstate(state).entries;

const emptyArr = [];

export const getEventLinkedIds = (state, id: number) =>
  getEventLinkedIdsEntries(state)[id]?.linkedIds ?? emptyArr;
