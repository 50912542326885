/**
 *
 * Layout
 *
 */
import { FC } from 'react';
import { User } from 'sportsbook-openapi-react';

import * as S from './Layout.styles';

interface Props {
  user: Maybe<User>;
  children: React.ReactNode;
}

export const Layout: FC<Props> = ({ children, user }) => {
  return (
    <S.Wrapper>
      <S.StyledHeader user={user} />
      <S.StyledNavigation />
      <S.Content>{children}</S.Content>
    </S.Wrapper>
  );
};
