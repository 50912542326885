import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getEventSourceName } from 'app/pages/Events/utils';
import {
  EntityType,
  EventSource,
  OutcomeCategorySourceMapping,
} from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { adapterActions } from 'app/providers/AdaptersProvider';
import { selectOutcomeCategorySourceMappingsByCategory } from 'app/providers/EntitiesProvider/outcomeCategorySourceMappings';
import { actionsNT } from 'app/providers/EntitiesProvider/slice';
import { Entities } from 'app/providers/EntitiesProvider/types';

import { useUserStore } from 'store';
import { getIsCustomer, getIsTranslator } from 'store/user';
interface UseCategorySourceMappingMethodsProps {
  entityId: number;
  entityType: EntityType;
}
export const useCategorySourceMappingMethods = ({
  entityId,
  entityType,
}: UseCategorySourceMappingMethodsProps) => {
  const dispatch = useDispatch();
  const isTranslator = useUserStore(getIsTranslator);

  const fetchCategorySourceMappings = useCallback(() => {
    if (!isTranslator) {
      dispatch(actionsNT.outcomeCategorySourceMappingsReset());
      dispatch(
        actionsNT.outcomeCategorySourceMappingsFetchItems({
          entityId,
          entityType,
          limit: FETCH_ALL_LIMIT,
        }),
      );
    }
  }, [dispatch, entityId, entityType, isTranslator]);

  const fetchCategorySourceMapping = useCallback(
    ({
      outcomeCategorySourceMappingId,
    }: {
      outcomeCategorySourceMappingId: number;
    }) => {
      if (!isTranslator) {
        dispatch(
          actionsNT.outcomeCategorySourceMappingsFetchItem({
            id: outcomeCategorySourceMappingId,
          }),
        );
      }
    },
    [dispatch, isTranslator],
  );

  const createCategorySourceMapping = useCallback(
    ({
      categoryId,
      categoryMainSource,
    }: {
      categoryId: number;
      categoryMainSource: EventSource;
    }) => {
      dispatch(
        actionsNT.outcomeCategorySourceMappingsCreate({
          outcomeCategorySourceMappingWithoutId: {
            categoryMainSource,
            categoryId,
            entityId,
            entityType,
          },
        }),
      );
    },
    [dispatch, entityId, entityType],
  );

  const createCategorySourceMappingList = useCallback(
    (items: { categoryId: number; categoryMainSource: EventSource }[]) => {
      dispatch(
        actionsNT.outcomeCategorySourceMappingsCreateList({
          outcomeCategorySourceMappingListWithOutId: {
            items: items.map(item => ({
              entityId,
              entityType,
              ...item,
            })),
          },
        }),
      );
    },
    [dispatch, entityId, entityType],
  );

  const updateCategorySourceMapping = useCallback(
    (
      oldOutcomeCategorySourceMapping: OutcomeCategorySourceMapping,
      {
        categoryMainSource,
      }: {
        categoryMainSource: EventSource;
      },
    ) => {
      dispatch(
        actionsNT.outcomeCategorySourceMappingsUpdate({
          id: oldOutcomeCategorySourceMapping.id,
          outcomeCategorySourceMappingWithoutId: {
            ...oldOutcomeCategorySourceMapping,
            categoryMainSource,
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteCategorySourceMapping = useCallback(
    ({ id }: { id: number }) => {
      dispatch(
        actionsNT.outcomeCategorySourceMappingsDelete({
          id,
        }),
      );
    },
    [dispatch],
  );

  return {
    fetchCategorySourceMappings,
    fetchCategorySourceMapping,
    createCategorySourceMapping,
    updateCategorySourceMapping,
    deleteCategorySourceMapping,
    createCategorySourceMappingList,
  };
};

const EXCLUDED_ADAPTERS = [
  EventSource.FORCED_MODEL,
  EventSource.MANUAL,
  EventSource.MODEL,
  EventSource.OLIMP,
  EventSource.UNKNOWN,
];

export const useCategorySourceMapping = (
  props: UseCategorySourceMappingMethodsProps,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isCustomer } = useUserStore(getIsCustomer);
  const isTranslator = useUserStore(getIsTranslator);

  const {
    fetchCategorySourceMappings,
    createCategorySourceMapping,
    updateCategorySourceMapping,
    deleteCategorySourceMapping,
    createCategorySourceMappingList,
  } = useCategorySourceMappingMethods(props);

  useEffect(() => {
    fetchCategorySourceMappings();
  }, [fetchCategorySourceMappings]);

  const categorySourceMappingsByCategory = useSelector(
    selectOutcomeCategorySourceMappingsByCategory,
  );

  const deleteCategorySourceMappingByCategory = useCallback(
    ({ categoryId }: { categoryId: number }) => {
      const outcomeCategorySourceMapping =
        categorySourceMappingsByCategory[categoryId];
      if (outcomeCategorySourceMapping) {
        deleteCategorySourceMapping({
          id: outcomeCategorySourceMapping.id,
        });
      }
    },
    [deleteCategorySourceMapping, categorySourceMappingsByCategory],
  );

  const { isLoading: isCategorySourceMappingsLoading } = useRequestState(
    Entities.OUTCOMECATEGORYSOURCEMAPPINGS,
    'fetchItems',
  );

  const updateCategorySourceMappingValue = useCallback(
    ({
      categorySourceMapping,
      value,
      categoryId,
    }: {
      categorySourceMapping?: OutcomeCategorySourceMapping;
      value: EventSource;
      categoryId: number;
    }) => {
      if (categorySourceMapping?.entityType === props.entityType) {
        updateCategorySourceMapping(categorySourceMapping!, {
          categoryMainSource: value,
        });
      } else {
        createCategorySourceMapping({
          categoryId,
          categoryMainSource: value,
        });
      }
    },
    [
      createCategorySourceMapping,
      props.entityType,
      updateCategorySourceMapping,
    ],
  );

  useEffect(() => {
    if (!(isTranslator || isCustomer)) {
      dispatch(adapterActions.fetchAdapters());
    }
  }, [dispatch, isCustomer, isTranslator]);

  const sourcesOptions = useMemo(
    () => [
      { id: 'delete', name: t('delete') },
      ...Object.values(EventSource)
        .filter(item => !EXCLUDED_ADAPTERS.includes(item))
        .map(source => ({
          name: getEventSourceName(source),
          id: source,
        })),
    ],
    [t],
  );

  return {
    createCategorySourceMappingList,
    deleteCategorySourceMappingByCategory,
    isCategorySourceMappingsLoading,
    categorySourceMappings: categorySourceMappingsByCategory,
    sourcesOptions,
    updateCategorySourceMappingValue,
  };
};
