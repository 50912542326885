import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  position: relative;
`;

export const UserActions = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
`;

export const LowerCaseSpan = styled.span`
  text-transform: lowercase;
`;
