import { PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash-es/keyBy';
import {
  CashoutBlocksApiDeleteCategoryCashoutBlockByCustomerRequest,
  CategoryCashoutBlock,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    CategoryCashoutBlock[],
    CategoryCashoutBlock,
    void,
    EntitiesErrorType
  >({
    entity: 'categoryCashoutBlocks',
  }),
  categoryCashoutBlocksFetchItemsSuccess(
    state,
    action: PayloadAction<CategoryCashoutBlock[]>,
  ) {
    state.categoryCashoutBlocks.entries = {
      ...state.categoryCashoutBlocks.entries,
      ...keyBy(action.payload, 'categoryId'),
    };
    state.categoryCashoutBlocks.fetchItems.loading = false;
  },
  categoryCashoutBlocksFetchItemSuccess(
    state,
    action: PayloadAction<CategoryCashoutBlock>,
  ) {
    const { categoryId } = action.payload;
    state.categoryCashoutBlocks.entries[categoryId] = action.payload;
    state.categoryCashoutBlocks.fetchItem.loading = false;
  },
  categoryCashoutBlocksUpdateSuccess(
    state,
    action: PayloadAction<CategoryCashoutBlock>,
  ) {
    const { categoryId } = action.payload;
    state.categoryCashoutBlocks.entries[categoryId] = action.payload;
    state.categoryCashoutBlocks.update.loading = false;
  },
  categoryCashoutBlocksDelete(
    state,
    action: PayloadAction<CashoutBlocksApiDeleteCategoryCashoutBlockByCustomerRequest>,
  ) {
    const { categoryId } = action.payload;
    delete state.categoryCashoutBlocks.entries[categoryId];
    state.categoryCashoutBlocks.delete.loading = true;
  },
};

export default reducer;
