import styled from 'styled-components';

import { Button } from 'app/components/ui';

export const Wrapper = styled.div`
  display: grid;
`;

export const EditEventContainer = styled.div`
  display: grid;
  padding: 20px;
`;

export const EditEventLoading = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditEventError = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const EditEventHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const StyledPageHeader = styled.div`
  margin: 0;
  padding: 0 20px;
  line-height: 56px;
  font-size: ${p => p.theme.fonts.typography['H800'].size};
  font-weight: ${p => p.theme.fonts.typography['H800'].weight};
`;

export const EditEventdButton = styled(Button)`
  margin-left: 20px;
`;
