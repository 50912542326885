import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import {
  Entities,
  getEntities,
  getEntityItems,
} from 'app/providers/EntitiesProvider';

import { initialState } from './slice';
import { TranslatedEntities } from './types';

const getTranslations = (state: RootState) =>
  state.translations || initialState;

export const getTranslatedEntity = (state: RootState) =>
  getTranslations(state).entity;

export const convertTranslatedEntityToEntity = (entity: TranslatedEntities) => {
  return entity === TranslatedEntities.SHORT_NAMES
    ? Entities.TYPES
    : (entity as unknown as Entities);
};

const getTranslatedEntities = (state: RootState) => {
  const entity = convertTranslatedEntityToEntity(getTranslatedEntity(state));
  return getEntities(state)[entity];
};

export const selectTranslatedEntitiesItems = createSelector(
  [getTranslatedEntities],
  // @ts-ignore
  translatedEntity => getEntityItems(translatedEntity),
);

export const getFilters = (state: RootState) => getTranslations(state).filters;
