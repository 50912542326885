import { FC, useEffect, useRef, useState } from 'react';
import { isFunction } from 'formik';

import { StyledInput } from './TextInputCell.styles';
import { TextInputCellProps } from './TextInputCell.types';

export const TextInputCell: FC<TextInputCellProps> = ({
  dataTest,
  defaultValue,
  onSave,
  saveOnBlur,
  validate,
  placeholder,
  type = 'text',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(defaultValue as string);
  const [error, setError] = useState(false);
  const saved = useRef<boolean>(false);

  const resetValue = () => {
    if (!saved.current) {
      setValue(defaultValue as string);
      setError(false);
    }
  };

  const saveValue = () => {
    if (!isFunction(validate) || validate(value)) {
      onSave(value);
      saved.current = true;
    } else {
      setError(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setError(false);
    saved.current = false;
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!isFunction(validate) || validate(value)) {
        onSave(value);
        saved.current = true;
        inputRef.current?.blur();
      } else {
        setError(true);
      }
    }
  };

  useEffect(resetValue, [defaultValue]);

  useEffect(() => {
    const handleScroll = event => {
      event.stopPropagation();
    };
    const refCopy = inputRef.current;
    if (refCopy) {
      refCopy.addEventListener('wheel', handleScroll);
    }
    return () => {
      if (refCopy) {
        refCopy.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  return (
    <StyledInput
      placeholder={placeholder}
      data-test={dataTest}
      ref={inputRef}
      type={type}
      value={value}
      invalid={error}
      onChange={handleChange}
      onBlur={saveOnBlur ? saveValue : resetValue}
      onKeyUp={handleKeyUp}
      {...rest}
    />
  );
};
