import { tagsApi } from 'app/api';

const api = {
  endpoint: tagsApi,
  methods: {
    fetchItems: 'getTagsByCustomer',
    update: 'upsertTagByCustomer',
    delete: 'deleteCustomerTag',
  },
};

export default api;
