import { createSelector } from '@reduxjs/toolkit';
import keyBy from 'lodash-es/keyBy';
import { Tag } from 'sportsbook-openapi-react';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import {
  getCustomerTagsEntries,
  selectCustomerTagsItems,
} from 'app/providers/EntitiesProvider/tagsByCustomer';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getTagsSubstate = (state: RootState) => getEntities(state).tags;
const getTagsEntries = (state: RootState) => getTagsSubstate(state).entries;

export const getTagsHasMore = (state: RootState) =>
  getTagsSubstate(state).hasMore;

export const selectTagsItems = createSelector([getTagsSubstate], tags =>
  getEntityItems(tags),
);

export const selectTagsByCustomer = createSelector(
  [getCustomerTagsEntries, selectCustomerTagsItems, getTagsEntries],
  (entries, items, tagsEntries) => {
    let itemsToTags: Tag[] = [];
    if (tagsEntries) {
      itemsToTags = items
        .map(entityTag => tagsEntries[entityTag.tagId])
        .filter(tag => !!tag);
    }

    const entriesByTagId = keyBy(Object.values(entries), 'tagId');
    return {
      itemsToTags,
      entriesByTagId,
    };
  },
);
