import styled from 'styled-components';

function escapeRegExp(text: string) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const getHighlightedText = (text: string, highlight: string) => {
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));
  if (highlight === '') return text;

  return (
    <span>
      {parts.map(part =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <HighlightedText key={part}>{part}</HighlightedText>
        ) : (
          part
        ),
      )}
    </span>
  );
};

export const includesText = (word?: string, subWord?: string) =>
  word !== undefined &&
  subWord !== undefined &&
  word.toLowerCase().includes(subWord.toLowerCase());

const HighlightedText = styled.span`
  background-color: #f2c94c;
`;

export const camelToUpperSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`).toUpperCase();

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
