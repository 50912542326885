import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import omit from 'lodash-es/omit';
import {
  User as UserType,
  UserWithOutIdGroupEnum,
} from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';
import { getCustomers } from 'app/providers/EntitiesProvider/customers';

import { FormValues, groupOptions, validationSchema } from './constants';
import { UserForm } from './UserForm';

interface Props {
  id: string;
  onClose: () => void;
}

export const UserEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customers = useSelector(getCustomers);
  const user: UserType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.USERS, id),
  );

  useEffect(() => {
    dispatch(actionsNT.customersFetchItems({ limit: FETCH_ALL_LIMIT }));
    return () => {
      dispatch(actionsNT.usersCreateReset());
    };
  }, [dispatch]);

  useRequestState(Entities.USERS, 'update', onClose);

  const onSubmit = (values: FormValues) => {
    const omittedValues =
      values.group?.id === UserWithOutIdGroupEnum.CUSTOMER
        ? {
            ...values,
            customerId: values.customer?.id,
            group: values.group?.id! as UserWithOutIdGroupEnum,
          }
        : {
            ...omit(values, 'customerId'),
            group: values.group?.id! as UserWithOutIdGroupEnum,
          };
    dispatch(
      actionsNT.usersUpdate({
        username: user.name,
        userWithOutId: omittedValues,
      }),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={{
        name: user.name,
        email: user.email,
        group: groupOptions.find(
          option => (user.group as unknown) === option.id,
        ),
        customer: user.customerId
          ? customers.entries[user.customerId!]
          : undefined,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.user')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <UserForm customers={customers} actionType="edit" />
        </Panel>
      )}
    </Formik>
  );
};
