import { groupsApi } from 'app/api';

const api = {
  endpoint: groupsApi,
  methods: {
    fetchItems: 'getGroups',
    fetchItem: 'getGroup',
    create: 'createGroup',
    update: 'updateGroup',
    delete: 'deleteGroup',
  },
};

export default api;
