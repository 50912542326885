import { all, call, put, race, take, takeEvery } from 'redux-saga/effects';

import { applyTransform } from 'utils/converter';

import { entitiesAPI } from './api';
import { ENTITIES_PROVIDER_REDUCER_KEY } from './constants';
import { actionsNT } from './slice';

const METHODS_TO_ACTIONS = {
  fetchItems: 'FetchItems',
  fetchItem: 'FetchItem',
  create: 'Create',
  createList: 'CreateList',
  update: 'Update',
  delete: 'Delete',
  deleteList: 'DeleteList',
};

const entitiesSagasHandlerGenerator = ({
  entity,
  endpoint,
  method,
  methodKey,
  extraData,
}: any) =>
  function* (data) {
    try {
      const { response, cancel } = yield race({
        response: call(function () {
          return endpoint[`${method}`](data.payload || {});
        }),
        cancel: take(`${ENTITIES_PROVIDER_REDUCER_KEY}/${entity}Reset`),
      });

      if (!cancel) {
        const responsePayload = applyTransform(
          extraData
            ? {
                [extraData.res]: response,
                [extraData.req]: data.payload[extraData.req],
              }
            : response,
        );

        yield put(
          actionsNT[`${entity}${METHODS_TO_ACTIONS[methodKey]}Success`](
            responsePayload,
          ),
        );
        data.payload?.sideEffects?.onSuccess?.(responsePayload);
      }
    } catch (e: any) {
      let err = e;
      try {
        const error = yield e.json();
        err = error.message;
      } catch {}
      console.log('Error occurred while performing API request', err);
      yield put(
        actionsNT[`${entity}${METHODS_TO_ACTIONS[methodKey]}Error`](err),
      );
      data.payload?.sideEffects?.onError?.(err);
    }
  };

const entitiesSagasGenerator = ({ entity, extraData }: any) => {
  const { endpoint, methods } = entitiesAPI[entity];

  return all(
    Object.entries(methods).map(([methodKey, method]) => {
      return takeEvery(
        actionsNT[`${entity}${METHODS_TO_ACTIONS[methodKey]}`].type,
        entitiesSagasHandlerGenerator({
          entity,
          endpoint,
          method,
          methodKey,
          extraData,
        }),
      );
    }),
  );
};

export function* entitiesSaga() {
  return yield all([
    entitiesSagasGenerator({ entity: 'categories' }),
    entitiesSagasGenerator({ entity: 'competitors' }),
    entitiesSagasGenerator({ entity: 'countries' }),
    entitiesSagasGenerator({ entity: 'currencies' }),
    entitiesSagasGenerator({ entity: 'customers' }),
    entitiesSagasGenerator({ entity: 'users' }),
    entitiesSagasGenerator({ entity: 'events' }),
    entitiesSagasGenerator({
      entity: 'eventsOdds',
      extraData: { req: 'eventId', res: 'odds' },
    }),
    entitiesSagasGenerator({ entity: 'eventsOddsReturns' }),
    entitiesSagasGenerator({
      entity: 'eventsOddsByCustomer',
      extraData: { req: 'eventId', res: 'odds' },
    }),
    entitiesSagasGenerator({
      entity: 'eventsMappings',
      extraData: { req: 'eventId', res: 'mappings' },
    }),
    entitiesSagasGenerator({
      entity: 'eventsResults',
      extraData: { req: 'eventId', res: 'results' },
    }),
    entitiesSagasGenerator({
      entity: 'eventStatusByCustomer',
      extraData: { req: 'eventId', res: 'status' },
    }),
    entitiesSagasGenerator({
      entity: 'eventSuspends',
      extraData: { req: 'eventId', res: 'suspends' },
    }),
    entitiesSagasGenerator({
      entity: 'eventLinkedIds',
      extraData: { req: 'eventId', res: 'linkedIds' },
    }),
    entitiesSagasGenerator({ entity: 'eventStats' }),
    entitiesSagasGenerator({
      entity: 'eventFavorites',
    }),
    entitiesSagasGenerator({ entity: 'groups' }),
    entitiesSagasGenerator({ entity: 'margins' }),
    entitiesSagasGenerator({ entity: 'marginsByEvent' }),
    entitiesSagasGenerator({ entity: 'matchTypes' }),
    entitiesSagasGenerator({ entity: 'markets' }),
    entitiesSagasGenerator({ entity: 'sports' }),
    entitiesSagasGenerator({ entity: 'tournaments' }),
    entitiesSagasGenerator({ entity: 'types' }),
    entitiesSagasGenerator({ entity: 'players' }),
    entitiesSagasGenerator({ entity: 'sources' }),
    entitiesSagasGenerator({ entity: 'betPayMax' }),
    entitiesSagasGenerator({ entity: 'betPayMaxByEvent' }),
    entitiesSagasGenerator({ entity: 'customerTokens' }),
    entitiesSagasGenerator({ entity: 'exclusions' }),
    entitiesSagasGenerator({ entity: 'categoryExclusions' }),
    entitiesSagasGenerator({ entity: 'categoryCashoutBlocks' }),
    entitiesSagasGenerator({ entity: 'languages' }),
    entitiesSagasGenerator({ entity: 'matchPhases' }),
    entitiesSagasGenerator({ entity: 'tags' }),
    entitiesSagasGenerator({ entity: 'comments' }),
    entitiesSagasGenerator({ entity: 'commentsByEntity' }),
    entitiesSagasGenerator({ entity: 'tagsByCustomer' }),
    entitiesSagasGenerator({ entity: 'delays' }),
    entitiesSagasGenerator({ entity: 'crossMappings' }),
    entitiesSagasGenerator({ entity: 'delaysByEvent' }),
    entitiesSagasGenerator({ entity: 'search' }),
    entitiesSagasGenerator({ entity: 'outcomeCategorySourceMappings' }),
    entitiesSagasGenerator({ entity: 'logos' }),
    entitiesSagasGenerator({ entity: 'maxOdds' }),
  ]);
}
