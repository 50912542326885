/**
 *
 * Sources
 *
 */

import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { EntitiesWrapper, SourcesPage } from './components';
import * as S from './Sources.styles';

export const IsReadyContext = createContext<{
  isReady: boolean;
  setIsReady: Dispatch<SetStateAction<boolean>>;
}>({
  isReady: false,
  setIsReady: () => {},
});

export function Sources() {
  const { t } = useTranslation();
  const [isReady, setIsReady] = useState(false);

  return (
    <>
      <Helmet>
        <title>{t('sources')}</title>
        <meta name="description" content="Description of Sources" />
      </Helmet>
      <S.Wrapper>
        <IsReadyContext.Provider value={{ isReady, setIsReady }}>
          <Routes>
            <Route path="/*" element={<EntitiesWrapper />} />
            <Route index element={<SourcesPage />} />
          </Routes>
        </IsReadyContext.Provider>
      </S.Wrapper>
    </>
  );
}
