import styled from 'styled-components';

import { InputWrapperProps, StyledInputProps } from './TextInput.types';
import { getInputHeight } from './TextInput.utils';

export const InputWrapper = styled.div<InputWrapperProps>`
  height: ${p => getInputHeight(p.size)};
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

export const StyledInput = styled.input<StyledInputProps>`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0 10px;
  transition:
    border-color 0.2s ease,
    background-color 0.2s ease;

  font-family: ${props => props.theme.fonts.family};
  font-size: ${props => props.theme.fonts.typography['H200'].size};
  font-weight: ${props => props.theme.fonts.typography['H200'].weight};
  color: ${props => props.theme.colors.primary[700]};

  border-style: solid;
  border-color: ${({ invalid, success, theme }) => {
    if (invalid) {
      return theme.colors.red[500];
    }
    if (success) {
      return theme.colors.green[400];
    }
    return theme.colors.primary[60];
  }};
  border-width: 1px;
  border-radius: 4px;

  box-shadow: ${({ success, invalid, theme }) => {
    if (invalid) {
      return 'none';
    }
    if (success) {
      return `0 0 1px 1px ${theme.colors.green[400]}`;
    }
  }};

  background-color: ${({ disabled, readOnly, theme }) => {
    if (disabled || readOnly) {
      return theme.colors.primary[20];
    }
    return theme.colors.primary[0];
  }};

  &:hover {
    border-color: ${p => p.theme.colors.primary[500]};
  }
  &:disabled {
    color: ${props => props.theme.colors.primary[60]};
    &:hover {
      border-color: ${p => p.theme.colors.primary[60]};
    }
  }
  &:read-only {
    color: ${props => props.theme.colors.primary[500]};
    &:hover {
      border-color: ${p => p.theme.colors.primary[60]};
    }
  }
  &:focus {
    border-color: ${p => p.theme.colors.primary[500]};
    box-shadow: 0 0 1px 1px ${p => p.theme.colors.primary[500]};
    outline: none;
    &:hover {
      border-color: ${p => p.theme.colors.primary[500]};
      box-shadow: 0 0 1px 1px ${p => p.theme.colors.primary[500]};
    }
  }
  ::placeholder {
    color: ${p => p.theme.colors.primary[80]};
  }
`;
