import styled from 'styled-components';

import { TextInput } from 'app/components/forms';

export const OddsGeneralTable = styled.div`
  padding: 20px;
  border-right: 1px solid ${p => p.theme.colors.primary[40]};
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: auto minmax(0, 1fr);
`;

export const CategoriesTableControls = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
`;

export const Search = styled(TextInput)`
  width: 100%;
  margin-bottom: 11px;
`;

export const ButtonWrapper = styled.div`
  padding: 0 10px;
`;
