import styled from 'styled-components';

type StyledOddsTableSourcesItemProps = {
  selected?: boolean;
};

export const OddsContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
`;

export const OddsTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 24px;
`;

export const OddsTableContainer = styled.div`
  display: grid;
  grid-template-columns: 700px 1fr;
  grid-template-rows: minmax(0, 1fr);
  border-top: 1px solid ${p => p.theme.colors.primary[40]};
  border-bottom: 1px solid ${p => p.theme.colors.primary[40]};
`;

export const OddsTable = styled.div`
  display: grid;
  grid-template-areas:
    'title'
    'source'
    'actions'
    'tables';
  grid-template-rows: 56px auto auto minmax(0, 1fr);
`;

export const OddsTableHeader = styled.div`
  border-bottom: 1px solid #cccccc;
  padding: 0 20px;
  display: flex;
  align-items: center;
  grid-area: title;
`;

export const OddsTableSources = styled.div`
  border-bottom: 1px solid #cccccc;
  padding: 0 20px;
  display: flex;
  grid-area: source;
`;

export const OddsTableActions = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  grid-area: actions;
`;

export const OddsTableSourcesItem = styled.div<StyledOddsTableSourcesItemProps>`
  border-bottom: 1px solid #cccccc;
  padding: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${p => (p.selected ? p.theme.colors.primary[40] : 'transparent')};
  border-bottom: ${p => (p.selected ? '1px solid #000000' : 'none')};
  gap: 5px;
`;

export const Heading = styled.span`
  color: ${p => p.theme.colors.primary[800]};
  font-size: ${p => p.theme.fonts.typography['H500'].size};
  font-weight: 500;
`;

export const OddsTableWrapper = styled.div`
  grid-area: tables;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
`;
