import { camelToUpperSnakeCase } from './string';

export const transformSortingMap = <T extends string>(sorting) =>
  Object.keys(sorting)
    .filter(key => sorting[key] !== undefined)
    .map(
      element =>
        `${camelToUpperSnakeCase(element)}_${
          sorting[element] ? 'ASC' : 'DESC'
        }`,
    ) as T[];

export enum SortingCacheKeys {
  RESULTS = 'results_sorting',
  LIVE = 'live_sorting',
  LINE = 'line_sorting',
  SOURCE = 'source_sorting',
}
