import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  sports: Yup.array().of(Yup.number()),
  priorityOrder: Yup.number().positive('Must be positive'),
});

export const initialCreateValues = {
  name: '',
  phaseCode: '',
  sportIds: [],
  translations: [],
};
