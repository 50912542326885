import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainSource } from 'app/pages/Events/components/Odds/MainSource';
import { OddsCategoriesTable } from 'app/pages/Events/components/Odds/OddsCategoriesTable';
import { OddsTable } from 'app/pages/Events/components/Odds/OddsTable';
import { getSelectedCategory } from 'app/pages/Events/utils';
import { Event, EventSource } from 'sportsbook-openapi-react';
import {
  SportEventOddsLineType,
  SportEventOddSource,
} from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { RootState } from 'types';

import { Button, Span } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCategoriesEntries } from 'app/providers/EntitiesProvider/categories';
import {
  getEventCreateLoading,
  getEventMappings,
  getEventUpdateLoading,
} from 'app/providers/EntitiesProvider/eventsMappings';
import { getEventOddsByEventId } from 'app/providers/EntitiesProvider/eventsOdds';
import { getSourcesEntries } from 'app/providers/EntitiesProvider/sources';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

import * as S from './EventOdds.styles';

type OwnProps = {
  event: Event;
};

export const EventOdds: FC<OwnProps> = ({ event }) => {
  const [selectedCategoryId, selectCategoryId] = useState(0);
  const [selectedSources, selectSource] = useState({});
  const sourcesRaw = useSelector(getSourcesEntries);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isCustomer, customerId } = useUserStore(getIsCustomer);

  const { isLoading } = useRequestState('eventsOdds', 'fetchItem');
  const odds = useSelector((state: RootState) =>
    getEventOddsByEventId(state, event.id),
  );
  const mappings = useSelector(state => getEventMappings(state, event.id));
  const createMappingsLoading = useSelector(getEventCreateLoading);
  const updateMappingsLoading = useSelector(getEventUpdateLoading);

  const categories = useSelector(getCategoriesEntries);

  const fetchOdds = useCallback(() => {
    if (isCustomer) {
      if (customerId) {
        dispatch(
          actionsNT.eventsOddsByCustomerFetchItem({
            eventId: event.id,
            customerId,
            lineType: event.live
              ? SportEventOddsLineType.LIVE
              : SportEventOddsLineType.PRE,
          }),
        );
      }
    } else {
      dispatch(
        actionsNT.eventsOddsFetchItem({
          eventId: event.id,
          lineType: event.live
            ? SportEventOddsLineType.LIVE
            : SportEventOddsLineType.PRE,
        }),
      );
    }
  }, [isCustomer, customerId, dispatch, event.id, event.live]);

  useEffect(() => {
    dispatch(actionsNT.sourcesFetchItems());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actionsNT.eventsMappingsFetchItem({ eventId: event.id }));
  }, [dispatch, event.id, isCustomer, customerId]);

  useEffect(() => {
    const categoriesIdsToFetch = odds?.categories.map(
      category => category.outcomeCategoryId,
    );
    if (categoriesIdsToFetch?.length > 0) {
      dispatch(
        actionsNT.categoriesFetchItems({
          ids: categoriesIdsToFetch,
          limit: categoriesIdsToFetch?.length,
        }),
      );
    }
  }, [dispatch, odds?.categories]);

  useEffect(() => {
    fetchOdds();
  }, [dispatch, fetchOdds]);

  useEffect(() => {
    if (!isLoading && odds) {
      const selectedCategory = odds.categories[0];
      const selectedCategoryId = selectedCategory?.outcomeCategoryId;
      if (selectedCategory) {
        selectCategoryId(selectedCategoryId);
      }
    }
  }, [odds, isLoading]);

  const selectedCategory = useMemo(
    () => getSelectedCategory(selectedCategoryId, categories, odds),
    [selectedCategoryId, categories, odds],
  );

  const selectedSource: Maybe<SportEventOddSource> = useMemo(() => {
    const selectedSourceName = selectedSources[selectedCategoryId];

    return selectedCategory?.sources?.find(
      source => source.source === selectedSourceName,
    );
  }, [selectedSources, selectedCategoryId, selectedCategory?.sources]);

  const handleSourceClick = ({
    categoryId,
    source,
  }: {
    categoryId: number;
    source: string;
  }) => {
    if (selectedSources[categoryId] !== source) {
      selectSource(selectedSources => ({
        ...selectedSources,
        [categoryId]: source,
      }));
    }
  };

  const handleCategorySourceChange = ({
    categoryId,
    source,
  }: {
    categoryId: number;
    source: string;
  }) => {
    if (!isCustomer) {
      const categoryMappinng = mappings[categoryId];
      dispatch(
        categoryMappinng
          ? actionsNT.eventsMappingsUpdate({
              mappingId: categoryMappinng.id,
              eventId: event.id,
              createEventMapping: {
                categoryId,
                source: source as any,
              },
            })
          : actionsNT.eventsMappingsCreate({
              eventId: event.id,
              createEventMapping: {
                categoryId,
                source: source as EventSource,
              },
            }),
      );
    }
  };

  const selectedCategorySources = useMemo(() => {
    if (!selectedCategory) {
      return [];
    }
    const sources = [...selectedCategory?.sources];

    if (!sources.find(source => source.source === odds.mainSource)) {
      sources.unshift({ source: odds.mainSource, probabilities: [] });
    }

    sources.sort(a => (a.source === odds?.mainSource ? -1 : 1));
    return sources;
  }, [odds?.mainSource, selectedCategory]);

  const activeCategorySource = useMemo(
    () =>
      mappings?.[selectedCategoryId]?.source ??
      event.mainSource ??
      odds?.mainSource,
    [event.mainSource, mappings, odds, selectedCategoryId],
  );

  useEffect(() => {
    if (selectedCategorySources.length) {
      selectSource(selectedSources => {
        if (!!selectedSources[selectedCategoryId]) {
          return selectedSources;
        }
        return {
          ...selectedSources,
          [selectedCategoryId]: activeCategorySource,
        };
      });
    }
  }, [
    activeCategorySource,
    selectedCategoryId,
    selectedCategorySources,
    selectedSources,
  ]);

  return (
    <S.OddsContainer>
      <S.OddsTitle>
        <S.Heading>{t('coefficients')}</S.Heading>
      </S.OddsTitle>
      <MainSource event={event} fetchOdds={fetchOdds} />
      <S.OddsTableContainer>
        <OddsCategoriesTable
          event={event}
          selectedCategory={selectedCategory}
          selectCategoryId={selectCategoryId}
        />
        {!!selectedCategory && (
          <S.OddsTable>
            <S.OddsTableHeader>
              <Span fontWeight={500} fontSize={18}>
                {selectedCategory.name}
              </Span>
            </S.OddsTableHeader>
            {!isCustomer && (
              <S.OddsTableSources>
                {selectedCategorySources.map(source => (
                  <S.OddsTableSourcesItem
                    key={source.source}
                    selected={
                      source.source ===
                      selectedSources[selectedCategory.outcomeCategoryId]
                    }
                    onClick={() =>
                      handleSourceClick({
                        source: source.source,
                        categoryId: selectedCategory.outcomeCategoryId,
                      })
                    }
                  >
                    <Span style={{ padding: '10px 0' }}>
                      {sourcesRaw[source.source]?.name}
                    </Span>
                    <Span color="#808080">{source.probabilities.length}</Span>
                  </S.OddsTableSourcesItem>
                ))}
              </S.OddsTableSources>
            )}
            {!(
              !selectedSources[selectedCategory.outcomeCategoryId] || isCustomer
            ) && (
              <S.OddsTableActions>
                <>
                  {selectedSources[selectedCategory.outcomeCategoryId] ===
                  activeCategorySource ? (
                    <Span>{t('active source')}</Span>
                  ) : (
                    <Button
                      color="link"
                      onClick={() => {
                        handleCategorySourceChange({
                          source:
                            selectedSources[selectedCategory.outcomeCategoryId],
                          categoryId: selectedCategory.outcomeCategoryId,
                        });
                      }}
                      disabled={createMappingsLoading || updateMappingsLoading}
                    >
                      {t('set active source')}
                    </Button>
                  )}
                </>
              </S.OddsTableActions>
            )}
            <S.OddsTableWrapper>
              <OddsTable event={event} selectedSource={selectedSource} />
            </S.OddsTableWrapper>
          </S.OddsTable>
        )}
      </S.OddsTableContainer>
    </S.OddsContainer>
  );
};
