import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  actions,
  AuditedEntities,
  EntityToGetAuditParams,
} from 'app/providers/AuditProvider';

interface UseAuditProps<T extends AuditedEntities> {
  entity: T | T[];
}

export const useAudit = <T extends AuditedEntities>({
  entity,
}: UseAuditProps<T>) => {
  const dispatch = useDispatch();

  return useCallback(
    (payload: EntityToGetAuditParams[T]) => {
      dispatch(
        actions.openPanel({
          entity,
          payload,
        }),
      );
    },
    [dispatch, entity],
  );
};
