import {
  MarketType,
  OutcomeCategory,
  SportEventParameterType,
} from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  type: Yup.object().required('Required'),
  outcomeCategory: Yup.object().required('Required'),
});

export interface FormValues {
  name: string;
  type?: EnumOption<MarketType>;
  params: EnumOption<SportEventParameterType>[];
  outcomeCategory?: OutcomeCategory;
}

export const createInitialValues: FormValues = {
  name: '',
  type: undefined,
  params: [],
  outcomeCategory: undefined,
};
