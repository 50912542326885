import { Sport } from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const SportSchema = Yup.object({
  name: Yup.string().required('Required'),
  code: Yup.string().required('Required'),
});

export interface FormValues {
  name: string;
  code: string;
  genericSport?: Sport;
}

export const createInitialValues: FormValues = {
  name: '',
  code: '',
  genericSport: undefined,
};
