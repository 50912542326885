import { usersApi } from 'app/api';

const api = {
  endpoint: usersApi,
  methods: {
    fetchItems: 'getUsers',
    fetchItem: 'getUser',
    create: 'createUser',
    update: 'updateUser',
    delete: 'deleteUser',
  },
};

export default api;
