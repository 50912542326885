import styled from 'styled-components';

export const FormBlock = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
`;

export const ErrorMessage = styled.div`
  grid-column: 1/3;
  color: ${p => p.theme.colors.red[500]};
`;

export const TableWrapper = styled.div`
  flex-grow: 1;
`;
