import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Event } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getEvent } from 'app/providers/EntitiesProvider/events';

type EventPageParamsType = {
  id: string;
};

const useEvent = (): { event?: Event; loading: boolean; error: boolean } => {
  const dispatch = useDispatch();
  const { id } = useParams<EventPageParamsType>();
  const { isLoading, error } = useRequestState(Entities.EVENTS, 'fetchItem');

  if (!id) {
    throw new Error('Provide id parameter');
  }

  useEffect(() => {
    dispatch(actionsNT.eventsFetchItem({ eventId: Number(id) }));
  }, [dispatch, id]);

  const event = useSelector(state => getEvent(state, +id));

  return useMemo(
    () => ({
      event,
      loading: isLoading,
      error,
    }),
    [event, isLoading, error],
  );
};

export default useEvent;
