import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { getAsyncLoadOptions } from 'utils/select';
import useBooleanState from 'hooks/useBooleanState';

import { AsyncSelectField } from 'app/components/forms';
import { OutcomeCreatePanel } from 'app/components/panels/outcome';
import { Button, Text } from 'app/components/ui';

import { getAdapterTypesEntries } from 'app/providers/AdaptersProvider';

import { ParamMapper } from './ParamMapper';
import * as S from './styles';
import { FormValues, transformType } from './utils';

interface Props {
  adapterOutcomeTypeId: string;
  marketName?: string;
}

const addCodeToTypes = items => items.map(transformType);

export const Form = ({ adapterOutcomeTypeId, marketName }: Props) => {
  const { t } = useTranslation();

  const [isCreateOutcomeVisible, showCreateOutcome, hideCreateOutcome] =
    useBooleanState();

  const adapterTypes = useSelector(getAdapterTypesEntries);
  const { setFieldValue } = useFormikContext<FormValues>();

  const handleOutcomeTypeCreated = outcomeType => {
    setFieldValue('sportsbookType', transformType(outcomeType));
  };

  return (
    <>
      <span>
        {t('from source')} <b>{marketName}</b>{' '}
        {adapterTypes[adapterOutcomeTypeId]?.description ??
          adapterTypes[adapterOutcomeTypeId]?.id}
      </span>
      <S.Wrapper>
        <S.MappingWrapper>
          {t('in system')}
          <AsyncSelectField
            name="sportsbookType"
            loadOptions={getAsyncLoadOptions({
              searchField: 'code',
              entity: 'types',
              transformOptions: addCodeToTypes,
            })}
          />
          <Button type="button" color="action" onClick={showCreateOutcome}>
            {t('add new')}
          </Button>
        </S.MappingWrapper>
        <ParamMapper name="additionalParams">
          <Text weight={600} size="m">
            {t('additional params')}
          </Text>
        </ParamMapper>
        <ParamMapper name="customParams" isCustom>
          <Text weight={600} size="m">
            {t('custom params')}
          </Text>
        </ParamMapper>
      </S.Wrapper>
      {isCreateOutcomeVisible && (
        <OutcomeCreatePanel
          onClose={hideCreateOutcome}
          onSuccess={handleOutcomeTypeCreated}
        />
      )}
    </>
  );
};
