import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { Entities } from 'app/providers/EntitiesProvider/types';

const getTypesSubstate = (state: RootState) =>
  getEntities(state)[Entities.TYPES];

export const getTypesEntries = (state: RootState) =>
  getTypesSubstate(state).entries;

export const getTypesSortedIds = (state: RootState) =>
  getTypesSubstate(state).sortedIds;

export const getIsTypesLoading = (state: RootState) =>
  getTypesSubstate(state).fetchItems.loading;

export const getTypesByCategoryId = (state: RootState, id: number) =>
  getTypesSubstate(state).byCategory[id];

export const getOutcomeTypesByCategoryId = (state: RootState, id: number) => {
  const ids = getTypesSortedIds(state);
  const entries = getTypesByCategoryId(state, id) || {};
  return ids.filter(id => !!entries[id]).map(id => entries[id]);
};
