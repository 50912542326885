import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCategoryCashoutBlocks } from 'app/providers/EntitiesProvider/categoryCashoutBlocks';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

interface UseCategoryCashoutBlockMethodsProps {
  live: boolean;
  entityId: number;
  entityType: EntityType;
}

export const UseCategoryCashoutBlockMethods = ({
  live = false,
  entityId,
  entityType,
}: UseCategoryCashoutBlockMethodsProps) => {
  const dispatch = useDispatch();
  const { isCustomer, customerId } = useUserStore(getIsCustomer);

  const fetchCategoryCashoutBlocks = useCallback(() => {
    if (isCustomer && customerId) {
      dispatch(actionsNT.categoryCashoutBlocksReset());
      dispatch(
        actionsNT.categoryCashoutBlocksFetchItems({
          live,
          customerId,
          entityId,
          entityType,
        }),
      );
    }
  }, [customerId, dispatch, entityId, entityType, isCustomer, live]);

  const fetchCategoryCashoutBlock = useCallback(
    ({ categoryId }: { categoryId: number }) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.categoryCashoutBlocksFetchItem({
            customerId,
            categoryId,
            entityId,
            entityType,
          }),
        );
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer],
  );

  const createCategoryCashoutBlock = useCallback(
    ({
      cashoutBlocked,
      categoryId,
    }: {
      cashoutBlocked: boolean;
      categoryId: number;
    }) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.categoryCashoutBlocksUpdate({
            entityId,
            entityType,
            customerId,
            categoryId,
            categoryCashoutBlockStatus: {
              live,
              cashoutBlocked,
            },
          }),
        );
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer, live],
  );

  const createCategoryCashoutBlockList = useCallback(
    (items: { categoryId: number; cashoutBlocked: boolean }[]) => {
      if (isCustomer && customerId) {
        items.forEach(({ categoryId, cashoutBlocked }) => {
          dispatch(
            actionsNT.categoryCashoutBlocksUpdate({
              entityId,
              entityType,
              customerId,
              categoryId,
              categoryCashoutBlockStatus: {
                live,
                cashoutBlocked,
              },
            }),
          );
        });
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer, live],
  );

  const updateCategoryCashoutBlock = useCallback(
    (categoryId: number, cashoutBlocked: boolean) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.categoryCashoutBlocksUpdate({
            entityId,
            entityType,
            customerId,
            categoryId,
            categoryCashoutBlockStatus: {
              live,
              cashoutBlocked,
            },
          }),
        );
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer, live],
  );

  const deleteCategoryCashoutBlock = useCallback(
    ({ categoryId }: { categoryId: number }) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.categoryCashoutBlocksDelete({
            entityId,
            entityType,
            customerId,
            categoryId,
            live,
          }),
        );
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer, live],
  );

  return {
    fetchCategoryCashoutBlocks,
    fetchCategoryCashoutBlock,
    createCategoryCashoutBlock,
    createCategoryCashoutBlockList,
    updateCategoryCashoutBlock,
    deleteCategoryCashoutBlock,
  };
};

export const useCategoryCashoutBlock = (
  props: UseCategoryCashoutBlockMethodsProps,
) => {
  const {
    fetchCategoryCashoutBlocks,
    updateCategoryCashoutBlock,
    deleteCategoryCashoutBlock,
  } = UseCategoryCashoutBlockMethods(props);

  useEffect(() => {
    fetchCategoryCashoutBlocks();
  }, [fetchCategoryCashoutBlocks]);

  const categoryCashoutBlocks = useSelector(getCategoryCashoutBlocks);

  const { isLoading } = useRequestState('categoryCashoutBlocks', 'fetchItems');

  const deleteCategoryCashoutBlockByCategory = useCallback(
    ({ outcomeCategoryId }: { outcomeCategoryId: number }) => {
      const categoryCashoutBlock = categoryCashoutBlocks[outcomeCategoryId];
      if (categoryCashoutBlock) {
        deleteCategoryCashoutBlock({
          categoryId: categoryCashoutBlock.categoryId,
        });
      }
    },
    [categoryCashoutBlocks, deleteCategoryCashoutBlock],
  );

  return {
    categoryCashoutBlocks,
    updateCategoryCashoutBlock,
    deleteCategoryCashoutBlockByCategory,
    isCategoryCashoutBlockLoading: isLoading,
  };
};
