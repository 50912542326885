import { compareNames } from 'app/pages/Events/utils';
import { sportEventParameterTypesSorted } from 'app/pages/Events/utils/params';
import keyBy from 'lodash-es/keyBy';
import { OutcomeType, SportEventOdd } from 'sportsbook-openapi-react';

export const sortSportEventOdds =
  (types: Dictionary<OutcomeType>, sorting: object) =>
  (a: SportEventOdd, b: SportEventOdd) => {
    const typeA = types[a.outcomeTypeId];
    const typeB = types[b.outcomeTypeId];

    const aPriority = typeA?.priorityOrder ?? Infinity;
    const bPriority = typeB?.priorityOrder ?? Infinity;

    if (aPriority !== bPriority) {
      return aPriority - bPriority;
    }

    const aParameters = keyBy(a.parameters, 'type');
    const bParameters = keyBy(b.parameters, 'type');

    for (const parameterType of sportEventParameterTypesSorted) {
      const parameterKey = `parameters.${parameterType}`;
      if (
        sorting[parameterKey] !== undefined &&
        aParameters[parameterType] &&
        bParameters[parameterType]
      ) {
        if (
          aParameters[parameterType].value === bParameters[parameterType].value
        ) {
          continue;
        }
        if (sorting[parameterKey]) {
          return (
            Number(aParameters[parameterType].value) -
            Number(bParameters[parameterType].value)
          );
        }
        return (
          Number(bParameters[parameterType].value) -
          Number(aParameters[parameterType].value)
        );
      }
    }
    return compareNames(typeA?.name, typeB?.name);
  };
