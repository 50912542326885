import styled from 'styled-components';

import { TextInput } from 'app/components/forms';

export const OddsGeneralTableWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  border-right: 1px solid ${p => p.theme.colors.primary[40]};
`;

export const OddsGeneralTable = styled.div`
  padding: 16px 22px;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
`;

export const Search = styled(TextInput)`
  width: 100%;
`;

export const SearchWrapper = styled.div`
  padding: 12px 16px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
`;
