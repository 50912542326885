import styled from 'styled-components';

export interface Props {
  width?: number;
}

export const Wrapper = styled.div<Props>`
  height: 1.3em;
  width: ${p => p.width}px;
  background-color: ${p => p.theme.colors.primary[30]};
  border-radius: 3px;
`;
