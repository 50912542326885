import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';

import { useAudit } from 'hooks/audit/useAudit';
import { FETCH_ALL_LIMIT } from 'consts';
import { RootState } from 'types';
import { useTheme } from 'styles';

import {
  CrossMappingCreatePanel,
  CrossMappingEditPanel,
} from 'app/components/panels/crossMapping';
import { Button, Span, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCategoryById } from 'app/providers/EntitiesProvider/categories';
import { getCrossMappingsEntries } from 'app/providers/EntitiesProvider/crossMappings';
import { getOutcomeTypesByCategoryId } from 'app/providers/EntitiesProvider/type';

import * as S from './Content.styles';

type Match = {
  id?: string;
  typeId?: string;
};

export const CrossMappingsContent: FC = () => {
  const { t } = useTranslation();
  const params = useParams<Match>();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [editCrossMappingPanel, setEditCrossMappingPanel] = useState<null | {
    categoryId: number;
    id: number;
  }>(null);
  const [createCrossMappingPanel, setCreateCrossMappingPanel] =
    useState<null | {
      categoryId: number;
      id: number;
    }>(null);

  const { id } = params;

  if (!params.id) {
    throw new Error('Provide id and type path parameters');
  }

  useEffect(() => {
    dispatch(actionsNT.crossMappingsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(
      actionsNT.typesFetchItems({ categoryId: +id!, limit: FETCH_ALL_LIMIT }),
    );
    dispatch(actionsNT.marketsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch, id]);

  const category = useSelector((state: RootState) =>
    getCategoryById(state, +id!),
  );
  const outcomeTypes = useSelector((state: RootState) =>
    getOutcomeTypesByCategoryId(state, +id!),
  );
  const crossMappings = useSelector(getCrossMappingsEntries);

  const typesWithCrossMappings = outcomeTypes.map(type => ({
    ...type,
    crossMapping: crossMappings[type.id],
  }));

  const openAudit = useAudit({
    entity: AuditedEntities.CROSS_MAPPINGS,
  });

  const columns = useMemo<
    Column<ArrayElement<typeof typesWithCrossMappings>>[]
  >(
    () => [
      {
        Header: t('name').toString(),
        accessor: 'name',
      },
      {
        Header: t('code').toString(),
        accessor: 'code',
      },
      {
        Header: t('cross outcome').toString(),
        id: 'crossName',
        accessor: 'crossMapping',
        Cell: ({ value }) => <>{value?.crossOutcomeType?.name}</>,
      },
      {
        Header: t('cross code').toString(),
        id: 'crossCode',
        accessor: 'crossMapping',
        Cell: ({ value }) => <>{value?.crossOutcomeType?.code}</>,
      },
      {
        Header: '',
        accessor: 'id',
        id: 'action',
        Cell: ({ row: { original: data } }) =>
          data.crossMapping ? (
            <S.StyledButton
              color="action"
              onClick={() => {
                setEditCrossMappingPanel({
                  categoryId: +id!,
                  id: data.id,
                });
              }}
            >
              {t('edit')}
            </S.StyledButton>
          ) : (
            <S.StyledButton
              color="action"
              onClick={() => {
                setCreateCrossMappingPanel({
                  categoryId: +id!,
                  id: data.id,
                });
              }}
            >
              {t('create cross-mapping')}
            </S.StyledButton>
          ),
      },
      {
        Header: '',
        accessor: 'id',
        id: 'audit',
        Cell: ({ value }) => (
          <Button
            color="action"
            onClick={() => {
              openAudit({ typeId: String(value), includeRelated: false });
            }}
          >
            {t('audit')}
          </Button>
        ),
      },
    ],
    [id, openAudit, t],
  );

  return (
    <S.Content>
      <Span fontSize={18} fontWeight={500}>
        {category && category.name}
      </Span>
      <Table
        columns={columns}
        data={typesWithCrossMappings}
        columnsWidth={[
          'auto',
          'auto',
          'auto',
          'auto',
          'fit-content',
          'fit-content',
        ]}
        rowStyles={() =>
          `&:hover {
              background-color: ${theme.colors.primary[50]} !important;
            }
            `
        }
      />
      {!!editCrossMappingPanel && (
        <CrossMappingEditPanel
          onClose={() => {
            setEditCrossMappingPanel(null);
          }}
          id={editCrossMappingPanel.id}
          categoryId={editCrossMappingPanel.categoryId}
        />
      )}
      {!!createCrossMappingPanel && (
        <CrossMappingCreatePanel
          onClose={() => {
            setCreateCrossMappingPanel(null);
          }}
          id={createCrossMappingPanel.id}
          categoryId={createCrossMappingPanel.categoryId}
        />
      )}
    </S.Content>
  );
};
