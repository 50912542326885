import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityType, OutcomeCategory } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import { useRequestState } from 'hooks/useRequestState';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCategoryExclusions } from 'app/providers/EntitiesProvider/categoryExclusions';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

interface UseCategoryExclusionMethodsProps {
  live: boolean;
  entityId: number;
  entityType: EntityType;
}

export const useCategoryExclusionMethods = ({
  live = false,
  entityId,
  entityType,
}: UseCategoryExclusionMethodsProps) => {
  const dispatch = useDispatch();
  const { isCustomer, customerId } = useUserStore(getIsCustomer);

  const fetchCategoryExclusions = useCallback(() => {
    if (isCustomer && customerId) {
      dispatch(actionsNT.categoryExclusionsReset());
      dispatch(
        actionsNT.categoryExclusionsFetchItems({
          live,
          customerId,
          entityId,
          entityType,
        }),
      );
    }
  }, [customerId, dispatch, entityId, entityType, isCustomer, live]);

  const fetchCategoryExclusion = useCallback(
    ({ categoryId }: { categoryId: number }) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.categoryExclusionsFetchItem({
            customerId,
            categoryId,
            entityId,
            entityType,
          }),
        );
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer],
  );

  const createCategoryExclusion = useCallback(
    ({ excluded, categoryId }: { excluded: boolean; categoryId: number }) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.categoryExclusionsUpdate({
            entityId,
            entityType,
            customerId,
            categoryId,
            categoryExclusionStatus: {
              live,
              excluded,
            },
          }),
        );
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer, live],
  );

  const createCategoryExclusionList = useCallback(
    (items: { categoryId: number; excluded: boolean }[]) => {
      if (isCustomer && customerId) {
        items.forEach(({ categoryId, excluded }) => {
          dispatch(
            actionsNT.categoryExclusionsUpdate({
              entityId,
              entityType,
              customerId,
              categoryId,
              categoryExclusionStatus: {
                live,
                excluded,
              },
            }),
          );
        });
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer, live],
  );

  const updateCategoryExclusion = useCallback(
    (categoryId: number, excluded: boolean) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.categoryExclusionsUpdate({
            entityId,
            entityType,
            customerId,
            categoryId,
            categoryExclusionStatus: {
              live,
              excluded,
            },
          }),
        );
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer, live],
  );

  const deleteCategoryExclusion = useCallback(
    ({ categoryId }: { categoryId: number }) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.categoryExclusionsDelete({
            entityId,
            entityType,
            customerId,
            categoryId,
            live,
          }),
        );
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer, live],
  );

  return {
    fetchCategoryExclusions,
    fetchCategoryExclusion,
    createCategoryExclusion,
    createCategoryExclusionList,
    updateCategoryExclusion,
    deleteCategoryExclusion,
  };
};

export const useCategoryExclusion = (
  props: UseCategoryExclusionMethodsProps,
) => {
  const {
    fetchCategoryExclusions,
    updateCategoryExclusion,
    deleteCategoryExclusion,
  } = useCategoryExclusionMethods(props);

  useEffect(() => {
    fetchCategoryExclusions();
  }, [fetchCategoryExclusions]);

  const categoryExclusions = useSelector(getCategoryExclusions);

  const { isLoading } = useRequestState('categoryExclusions', 'fetchItems');

  const deleteCategoryExclusionByCategory = useCallback(
    ({ outcomeCategoryId }: { outcomeCategoryId: number }) => {
      const categoryExclusion = categoryExclusions[outcomeCategoryId];
      if (categoryExclusion) {
        deleteCategoryExclusion({
          categoryId: categoryExclusion.categoryId,
        });
      }
    },
    [categoryExclusions, deleteCategoryExclusion],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.CUSTOMER_CATEGORY_EXLUSION,
  });

  const openExclusionAudit = useCallback<
    (outcomeCategoryId: OutcomeCategory['id']) => void
  >(
    outcomeCategoryId => {
      openAudit({ id: String(outcomeCategoryId), includeRelated: false });
    },
    [openAudit],
  );

  return {
    categoryExclusions,
    updateCategoryExclusion,
    deleteCategoryExclusionByCategory,
    isCategoryExclusionsLoading: isLoading,
    openExclusionAudit,
  };
};
