import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { EntityType, OutcomeCategory } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { Entities } from 'app/providers/EntitiesProvider';
import { getCategoriesEntries } from 'app/providers/EntitiesProvider/categories';
import {
  BETPAYMAX_TRANSMISSION_COEFFICIENT,
  useBetPayMaxMethods,
  useCategoryExclusionMethods,
  useCategorySourceMappingMethods,
  useDelayMethods,
  useMarginMethods,
} from 'app/providers/EntitiesProvider/hooks';
import { useMaxOddMethods } from 'app/providers/EntitiesProvider/hooks/useMaxOdd';

import {
  FormValues,
  getFilteredCategories,
  initialCreateValues,
  validationSchema,
} from './constants';
import { MarginForm } from './MarginForm';

interface Props {
  onClose: () => void;
  type: EntityType;
  id: number;
  live: boolean;
  existingCategories: number[];
  sportId: number;
}

export const MarginCreatePanel = ({
  onClose,
  type,
  id,
  existingCategories,
  sportId,
  live,
}: Props) => {
  const { t } = useTranslation();

  const categoriesEntries = useSelector(getCategoriesEntries);
  const [categories, setCategories] = useState<Array<OutcomeCategory>>([]);

  useEffect(() => {
    setCategories(
      getFilteredCategories(categoriesEntries, existingCategories, sportId),
    );
  }, [categoriesEntries, existingCategories, sportId]);

  const { createMarginsList } = useMarginMethods({
    entityType: type,
    entityId: id,
    live,
  });
  const { createCategorySourceMappingList } = useCategorySourceMappingMethods({
    entityType: type,
    entityId: id,
  });
  const { createBetPayMaxList } = useBetPayMaxMethods({
    entityType: type,
    entityId: id,
    live,
  });
  const { createMaxOddList } = useMaxOddMethods({
    entityType: type,
    entityId: id,
    live,
  });
  const { createDelaysList } = useDelayMethods({
    entityType: type,
    entityId: id,
  });
  const { createCategoryExclusionList } = useCategoryExclusionMethods({
    entityType: type,
    entityId: id,
    live,
  });

  useRequestState(Entities.MARGINS, 'createList', onClose);

  const onSubmit = (values: FormValues) => {
    createMarginsList(
      values.categories.map(outcomeCategoryId => ({
        margin: values.margin ?? 1.08,
        outcomeCategoryId,
        live,
      })),
    );

    if (values.mainSource?.id) {
      createCategorySourceMappingList(
        values.categories.map(outcomeCategoryId => ({
          categoryId: outcomeCategoryId,
          categoryMainSource: values.mainSource?.id!,
        })),
      );
    }

    createBetPayMaxList(
      values.categories.map(outcomeCategoryId => ({
        maxPay: false,
        value: (values.maxbet ?? 1000) * BETPAYMAX_TRANSMISSION_COEFFICIENT,
        outcomeCategoryId,
      })),
    );

    createDelaysList(
      values.categories.map(outcomeCategoryId => ({
        value: values.delay ?? 0,
        outcomeCategoryId,
      })),
    );

    createCategoryExclusionList(
      values.categories.map(outcomeCategoryId => ({
        categoryId: outcomeCategoryId,
        excluded: false,
      })),
    );
    createMaxOddList(
      values.categories.map(outcomeCategoryId => ({
        value: values.maxOdd ?? 50,
        outcomeCategoryId,
        live,
      })),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={initialCreateValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {({ handleSubmit }) => (
        <Panel
          title={t(`panels.create.margin`)}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={<PanelFooter submitButtonText={t('create')} />}
        >
          <MarginForm categories={categories} live={live} />
        </Panel>
      )}
    </Formik>
  );
};
