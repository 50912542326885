import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getTournamentsSubstate = (state: RootState) =>
  getEntities(state).tournaments;

export const getTournamentsEntries = (state: RootState) =>
  getTournamentsSubstate(state).entries;

export const selectTournamentsItems = createSelector(
  [getTournamentsSubstate],
  tournaments => getEntityItems(tournaments),
);

export const getTournamentsUpdate = (state: RootState) =>
  getTournamentsSubstate(state).update;
