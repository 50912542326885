import { RefObject } from 'react';

export const getPopupPosition = (ref: RefObject<HTMLDivElement>) => {
  let position = '';
  if (ref.current) {
    const wrapperPosition = ref.current.getBoundingClientRect();

    if (window.innerHeight - 180 > wrapperPosition.bottom) {
      position += `
      top: ${wrapperPosition.bottom + 8}px;
      `;
    } else {
      position += `
      bottom: ${window.innerHeight - wrapperPosition.top - 8}px;
      `;
    }

    if (window.innerWidth < wrapperPosition.left + 180) {
      position += `
      right: ${window.innerWidth - wrapperPosition.right}px;
      `;
    } else {
      position += `
      left: ${wrapperPosition.left}px;
      `;
    }
  }

  return position;
};
