import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getHomeAwayCompetitors, getStats } from 'app/pages/Events/utils';
import { Event } from 'sportsbook-openapi-react';

import { getMinutesSecondsFormatted } from 'utils/datetime';
import { useRequestState } from 'hooks/useRequestState';
import { TestIds } from 'types/testIds.types';

import { TextLoader } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getEventStats } from 'app/providers/EntitiesProvider/eventStats';
import { getMatchPhasesEntries } from 'app/providers/EntitiesProvider/matchPhases';

import * as S from './EventLiveStream.styles';

interface Props {
  event: Event;
}

const exists = <T,>(value: T) => {
  return value !== null && value !== undefined;
};

export function EventLiveStream({ event }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const eventStats = useSelector(state => getEventStats(state, event.id));
  const { isLoading: loading } = useRequestState('eventStats', 'fetchItem');

  const entries = useSelector(getMatchPhasesEntries);
  const { isLoading: matchPhaseLoading } = useRequestState(
    Entities.MATCH_PHASES,
    'fetchItem',
  );

  useEffect(() => {
    dispatch(actionsNT.eventStatsFetchItem({ eventId: event.id }));
  }, [dispatch, event.id]);

  const { homeCompetitor, awayCompetitor } = getHomeAwayCompetitors(event);

  const stats = useMemo(() => getStats(eventStats?.v2), [eventStats?.v2]);

  useEffect(() => {
    if (stats?.matchPhaseId) {
      dispatch(
        actionsNT.matchPhasesFetchItem({
          matchphaseId: stats?.matchPhaseId,
        }),
      );
    }
  }, [dispatch, eventStats?.v2.commonStats.stats, stats?.matchPhaseId]);

  if (!eventStats) {
    if (loading) {
      return (
        <S.Wrapper>
          <S.Loader />
        </S.Wrapper>
      );
    } else {
      return null;
    }
  }

  return (
    <S.Wrapper>
      <S.EventInfo data-test-id={TestIds.EventPageLiveInfo}>
        {exists(stats?.awayScore) && exists(stats?.homeScore) && (
          <S.CompetitorsBlock
            data-test-id={TestIds.EventPageLiveInfoCompetitors}
          >
            <S.CompetitorName>{homeCompetitor?.name}</S.CompetitorName>
            <S.ScoreBlock>
              <S.ScoreValue>{stats?.homeScore}</S.ScoreValue>
              <S.Separator>:</S.Separator>
              <S.ScoreValue>{stats?.awayScore}</S.ScoreValue>
            </S.ScoreBlock>
            <S.CompetitorName>{awayCompetitor?.name}</S.CompetitorName>
          </S.CompetitorsBlock>
        )}

        <S.InfoBlock data-test-id={TestIds.EventPageLiveInfoMatchPhase}>
          <S.Label>{t('match phase')}</S.Label>
          <S.Value>
            {matchPhaseLoading ? (
              <TextLoader width={80} />
            ) : stats?.matchPhaseId && entries[stats?.matchPhaseId] ? (
              entries[stats.matchPhaseId].name
            ) : (
              t('not determined')
            )}
          </S.Value>
        </S.InfoBlock>
        {exists(stats?.currentTime) && (
          <S.InfoBlock data-test-id={TestIds.EventPageLiveInfoMatchTime}>
            <S.Label>{t('match time')}</S.Label>
            <S.Value>{getMinutesSecondsFormatted(stats?.currentTime!)}</S.Value>
          </S.InfoBlock>
        )}
        {!!stats?.homePeriodStats.length && !!stats?.awayPeriodStats.length && (
          <S.InfoBlock data-test-id={TestIds.EventPageLiveInfoPeriodScore}>
            <S.Label>{t('period score')}</S.Label>
            <div>
              {stats.homePeriodStats.map((periodStat, index) => (
                <S.InfoScore key={periodStat.periodNumber}>
                  {periodStat.stat.value.numberValue}:
                  {stats.awayPeriodStats[index].stat.value.numberValue}
                </S.InfoScore>
              ))}
            </div>
          </S.InfoBlock>
        )}
        {exists(stats?.remainingTime) && (
          <S.InfoBlock data-test-id={TestIds.EventPageLiveInfoRemainingTime}>
            <S.Label>{t('remaining time')}</S.Label>
            <S.Value>
              {getMinutesSecondsFormatted(stats?.remainingTime!)}
            </S.Value>
          </S.InfoBlock>
        )}
        {exists(stats?.homeGameScore) && (
          <S.InfoBlock data-test-id={TestIds.EventPageLiveInfoGameScore}>
            <S.Label>{t('game score')}</S.Label>
            <S.InfoScore>
              {stats?.homeGameScore}:{stats?.awayGameScore}
            </S.InfoScore>
          </S.InfoBlock>
        )}
        {exists(stats?.currentServer) && (
          <S.InfoBlock data-test-id={TestIds.EventPageLiveInfoCurrentServer}>
            <S.Label>{t('current server')}</S.Label>
            <S.Value>{stats?.currentServer === 'HOME' ? 1 : 2}</S.Value>
          </S.InfoBlock>
        )}
        {exists(stats?.counterTerrorists) && (
          <S.InfoBlock data-test-id={TestIds.EventPageLiveInfoCT}>
            <S.Label>{t('counter-terrorists')}</S.Label>
            <S.Value>{stats?.counterTerrorists}</S.Value>
          </S.InfoBlock>
        )}
        <div />
      </S.EventInfo>
    </S.Wrapper>
  );
}
