import { Size } from './TextInput.types';

export function getInputHeight(size: Size) {
  switch (size) {
    case 's':
      return '32px';
    case 'm':
      return '40px';
  }
}
