import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Sport as SportType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { IS_DELETION_DISABLED } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import { FormValues, SportSchema } from './constants';
import { SportForm } from './SportForm';

interface Props {
  onClose: () => void;
  id: number;
}

export const SportEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useRequestState(Entities.SPORTS, 'update', onClose);

  const sport: SportType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.SPORTS, id),
  );

  const sportsEntries = useSelector(getSportsEntries);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.sportsUpdate({
        sportId: sport.id,
        sportWithOutId: {
          code: values.code,
          name: values.name,
          translations: sport.translations,
          genericSportId: values.genericSport?.id,
        },
      }),
    );
  };

  const onDelete = () => {
    dispatch(actionsNT.sportsDelete({ sportId: id }));
    onClose();
  };

  return (
    <Formik
      initialValues={{
        name: sport.name,
        code: sport.code,
        genericSport: sport.genericSportId
          ? sportsEntries[sport.genericSportId]
          : undefined,
      }}
      onSubmit={onSubmit}
      validationSchema={SportSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.sport')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
              leftButtonText={t('delete')}
              leftButtonDisabled={IS_DELETION_DISABLED}
              onLeftButtonClick={onDelete}
            />
          }
        >
          <SportForm />
        </Panel>
      )}
    </Formik>
  );
};
