import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as CommonS from 'app/pages/Events/components/styles';
import { values } from 'lodash-es';
import { Event, User } from 'sportsbook-openapi-react';

import { useUpdateEvent } from 'hooks/events';

import { Select } from 'app/components/forms';
import { Button, LoadingIndicator } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getEventsUpdate } from 'app/providers/EntitiesProvider/events';
import { getUsersEntries } from 'app/providers/EntitiesProvider/users';

import * as S from './EventOperator.styles';

type OwnProps = {
  event: Event;
};

export const EventOperator: FC<OwnProps> = ({ event }) => {
  const entries = useSelector(getUsersEntries);
  const [selectedUser, selectUser] = useState<Maybe<User>>(
    event.traderId ? entries[event.traderId] : null,
  );
  const [editing, setEditing] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actionsNT.usersFetchItems());
  }, [dispatch]);

  const { loading: eventUpdateLoading } = useSelector(getEventsUpdate);

  const updateEvent = useUpdateEvent();

  const handleOperatorAssign = () => {
    if (
      event.traderId !== selectedUser?.id &&
      selectedUser?.id &&
      !eventUpdateLoading
    ) {
      updateEvent({ ...event, traderId: selectedUser.id });
    }
    toggle();
  };

  const toggle = () => {
    setEditing(prevValue => !prevValue);
  };

  return (
    <>
      <CommonS.EventFieldLabel>{t('live is held by')}</CommonS.EventFieldLabel>
      <S.ValueWrapper>
        <CommonS.EventFieldValue>
          {editing ? (
            <Select
              options={values(entries)}
              onChange={option => selectUser(option as User)}
              value={selectedUser}
            />
          ) : (
            selectedUser?.name ?? t('not assigned')
          )}
        </CommonS.EventFieldValue>

        {editing ? (
          <Button color={'link'} onClick={handleOperatorAssign}>
            <span>{t('save')}</span>
          </Button>
        ) : (
          <Button color={'link'} onClick={toggle}>
            {eventUpdateLoading ? (
              <LoadingIndicator small />
            ) : (
              <span>{t('reassign')}</span>
            )}
          </Button>
        )}
      </S.ValueWrapper>
    </>
  );
};
