import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getParameterName } from 'app/pages/Events/utils';
import { FieldArray, useField } from 'formik';
import {
  RelationType,
  SportEventParameterType,
} from 'sportsbook-openapi-react';
import styled from 'styled-components';

import { ID } from 'utils/id';

import {
  CheckboxField,
  SelectField,
  TextInputField,
} from 'app/components/forms';
import { Button } from 'app/components/ui';

import { selectAdapterParametersItems } from 'app/providers/AdaptersProvider';

interface Props {
  name: string;
  isCustom?: boolean;
  children: ReactNode;
}

export interface RawAdditionalParam {
  name?: {
    id: string;
    name: string;
  };
  constValue?: string;
  sportsbookParamType?: {
    id: SportEventParameterType;
    name: SportEventParameterType;
  };
  invertValue?: boolean;
  id: string;
  relationType?: {
    id: RelationType;
    name: string;
  };
}

const sportsbookParamOptions = Object.values(SportEventParameterType).map(
  value => ({
    name: getParameterName(value),
    id: value,
  }),
);

export const ParamMapper: FC<Props> = ({ name, isCustom, children }) => {
  const { t } = useTranslation();
  const items = useSelector(selectAdapterParametersItems);

  const [field] = useField<RawAdditionalParam[]>(name);

  return (
    <FieldArray name={name}>
      {arrayHelpers => (
        <ParamsWrapper>
          {children}
          {field.value.map((param, index) => (
            <MappingWrapper key={param.id}>
              <SelectField
                name={`${name}[${index}].sportsbookParamType`}
                options={sportsbookParamOptions}
              />
              {isCustom ? (
                <TextInputField name={`${name}[${index}].constValue`} />
              ) : (
                <SelectField
                  name={`${name}[${index}].name`}
                  options={items.map(item => ({
                    name: item.name!,
                    id: item.name!,
                  }))}
                />
              )}
              <Button
                type="button"
                color="action"
                onClick={() => arrayHelpers.remove(index)}
              >
                <FontAwesomeIcon icon={faTimes} size="1x" />
              </Button>
              <StyledCheckboxWrapper>
                <StyledCheckboxField
                  name={`${name}[${index}].invertValue`}
                  label={t('invert')}
                />
                <StyledSelectFieldNew
                  name={`${name}[${index}].relationType`}
                  options={[
                    RelationType.PLAYER,
                    RelationType.COMPETITOR_ALIAS,
                    RelationType.NORMAL,
                  ].map(item => ({
                    name: t(`relationTypes.${item}`),
                    id: item,
                  }))}
                />
              </StyledCheckboxWrapper>
            </MappingWrapper>
          ))}
          <AddButton
            type="button"
            color="action"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() =>
              arrayHelpers.push({
                id: ID(),
                relationType: {
                  id: RelationType.NORMAL,
                  name: t(`relationTypes.${RelationType.NORMAL}`),
                },
              })
            }
          >
            {t('add parameter')}
          </AddButton>
        </ParamsWrapper>
      )}
    </FieldArray>
  );
};

const ParamsWrapper = styled.div`
  display: grid;
  grid-row-gap: 16px;
`;

const MappingWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr auto;
  grid-gap: 6px 16px;
  align-items: center;
`;

const AddButton = styled(Button)`
  justify-self: start;
`;
const StyledCheckboxField = styled(CheckboxField)`
  grid-column: 1/4;
`;

const StyledCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const StyledSelectFieldNew = styled(SelectField)`
  max-width: 120px;
`;
