import { searchApi } from 'app/api';

const api = {
  endpoint: searchApi,
  methods: {
    fetchItems: 'getSearchResult',
  },
};

export default api;
