import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities, getEntityItems } from 'app/providers/EntitiesProvider';

const getLanguagesSubstate = (state: RootState) => getEntities(state).languages;

export const selectLanguages = createSelector(getLanguagesSubstate, languages =>
  getEntityItems(languages),
);
