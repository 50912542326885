import { countriesApi } from 'app/api';

const api = {
  endpoint: countriesApi,
  methods: {
    fetchItems: 'getCountries',
    fetchItem: 'getCountry',
    create: 'createCountry',
    update: 'updateCountry',
    delete: 'deleteCountry',
  },
};

export default api;
