import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupBase, Theme } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { ThemeContext } from 'styled-components';

import { BaseOptionType } from 'app/components/forms/Select/Select.types';
import { FilterContext } from 'app/components/ui/Table';
import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueContainer,
  ValueContainer,
} from 'app/components/ui/Table/components/common';
import { getSelectFilterStyles } from 'app/components/ui/Table/components/SelectTableFilter';

import { AsyncSelectTableFilterProps } from './AsyncSelectTableFilter.types';

export function AsyncSelectTableFilter<
  D extends {},
  OptionType extends BaseOptionType,
  isMulti extends boolean,
>({
  column: { id },
  ...props
}: AsyncSelectTableFilterProps<D, OptionType, isMulti>) {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { filters, handleFilterChange } = useContext(FilterContext);

  const placeholder =
    props.isMulti && filters[String(id)]?.length
      ? t('selected', { count: filters[String(id)]?.length })
      : t(id);

  return (
    <AsyncPaginate<OptionType, GroupBase<OptionType>, {}, isMulti>
      {...props}
      value={filters[String(id)] ?? null}
      placeholder={placeholder}
      onChange={handleFilterChange(id)}
      isClearable
      styles={getSelectFilterStyles(theme)}
      hideSelectedOptions={false}
      components={{
        IndicatorSeparator: null,
        ClearIndicator,
        DropdownIndicator,
        MultiValueContainer,
        ValueContainer,
      }}
      getOptionLabel={option => option.name}
      getOptionValue={option => String(option.id)}
      maxMenuHeight={150}
      theme={(selectTheme: Theme) => {
        return {
          ...selectTheme,
          colors: {
            ...selectTheme.colors,
            primary: theme.colors.primary[40],
            primary25: theme.colors.primary[20],
            primary50: theme.colors.primary[40],
            neutral5: theme.colors.primary[20],
            neutral0: theme.colors.primary[0],
          },
          borderRadius: 4,
          spacing: {
            ...selectTheme.spacing,
            controlHeight: 24,
          },
        };
      }}
    />
  );
}
