import { getEventSourceName } from 'app/pages/Events/utils';
import { EventSource, OutcomeCategory } from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  categories: Yup.array(Yup.number()).required('required'),
  margin: Yup.number().min(1, 'Must be more or equal 1'),
  maxOdd: Yup.number().min(0, 'Must be more or equal 0'),
  maxbet: Yup.number().min(0, 'Must be more or equal 0'),
  delay: Yup.number().min(0, 'Must be more or equal 0'),
});

export interface FormValues {
  categories: Array<number>;
  delay?: number;
  maxbet?: number;
  margin?: number;
  maxOdd?: number;
  mainSource?: EnumOption<EventSource>;
}

const EXCLUDED_ADAPTERS = [
  EventSource.FORCED_MODEL,
  EventSource.MANUAL,
  EventSource.MODEL,
  EventSource.OLIMP,
  EventSource.UNKNOWN,
];

export const sourcesOptions = Object.values(EventSource)
  .filter(item => !EXCLUDED_ADAPTERS.includes(item))
  .map(source => ({
    name: getEventSourceName(source),
    id: source,
  }));

export const initialCreateValues: FormValues = {
  categories: [],
};

export const getFilteredCategories = (
  categories: Dictionary<OutcomeCategory>,
  existingCategories: number[] = [],
  sportId: number,
) =>
  Object.values(categories).filter(category => {
    if (!category.sports.includes(sportId)) return false;
    return !existingCategories.includes(category.id);
  });
