export interface SortableByPriorityBase {
  priorityOrder?: number;
  name: string;
}

export const compareByPriority = <T extends SortableByPriorityBase>(
  a: T,
  b: T,
) => {
  if (a.priorityOrder && b.priorityOrder) {
    return a.priorityOrder - b.priorityOrder;
  }
  if (a.priorityOrder) {
    return -1;
  }
  if (b.priorityOrder) {
    return 1;
  }
  return a.name.localeCompare(b.name);
};

export const compareNames = (nameA = '', nameB = '') => {
  if (nameA === '' && nameB === '') {
    return 0;
  }
  if (nameB === '') {
    return -1;
  }
  if (nameA === '') {
    return 1;
  }
  return nameA.localeCompare(nameB);
};
