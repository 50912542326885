/**
 *
 * Events
 *
 */

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { UserGroupEnum } from 'sportsbook-openapi-react';

import { RoleGuards } from 'app/components/RoleGuards';

import { CreateEvent } from './components/CreateEvent';
import { EditEvent } from './components/EditEvent';
import { Event } from './components/Event';
import { EventsList } from './components/EventsList';

export function Events() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('events')}</title>
        <meta name="description" content="Description of Events" />
      </Helmet>
      <Routes>
        <Route
          path="create"
          element={
            <RoleGuards
              roles={[
                UserGroupEnum.ADMIN,
                UserGroupEnum.OPERATOR,
                UserGroupEnum.SUPERVISOR,
              ]}
              withRedirect={true}
            >
              <CreateEvent />
            </RoleGuards>
          }
        />
        <Route path=":id/" element={<Event />} />
        <Route
          path=":id/edit"
          element={
            <RoleGuards
              roles={[
                UserGroupEnum.ADMIN,
                UserGroupEnum.OPERATOR,
                UserGroupEnum.SUPERVISOR,
              ]}
              withRedirect={true}
            >
              <EditEvent />
            </RoleGuards>
          }
        />
        <Route path="/" element={<EventsList />} />
      </Routes>
    </>
  );
}
