import keyBy from 'lodash/keyBy';
import { Competitor, Event } from 'sportsbook-openapi-react';

export function getHomeAwayCompetitors(event?: Event): {
  homeCompetitor?: Competitor;
  awayCompetitor?: Competitor;
} {
  const competitors = keyBy(event?.competitors, 'id');
  const homeCompetitorId = event?.homeCompetitors?.[0];
  const awayCompetitorId = event?.competitors?.filter(
    c => c.id !== homeCompetitorId,
  )?.[0]?.id;

  return {
    homeCompetitor: homeCompetitorId
      ? competitors[homeCompetitorId]
      : undefined,
    awayCompetitor: awayCompetitorId
      ? competitors[awayCompetitorId]
      : undefined,
  };
}

export function getEventTitle(event?: Event) {
  const { homeCompetitor, awayCompetitor } = getHomeAwayCompetitors(event);

  return [homeCompetitor, awayCompetitor]
    .filter(c => !!c)
    .map(c => c?.name)
    .join(' – ');
}
