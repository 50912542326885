import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faAngleDown, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEventSourceName } from 'app/pages/Events/utils';
import { uniq } from 'lodash-es';
import {
  Event,
  EventSource,
  SportEventOddsLineType,
  UserGroupEnum,
} from 'sportsbook-openapi-react';

import { useUpdateEvent } from 'hooks/events';
import { useRequestState } from 'hooks/useRequestState';
import { MODELS_UI_URL } from 'consts';
import getModelsUrl from 'consts/getModelsUrl';
import { RootState } from 'types';
import { TestIds } from 'types/testIds.types';

import { Select } from 'app/components/forms';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Popup } from 'app/components/ui';

import {
  adapterActions,
  selectMappedEventsItemsById,
} from 'app/providers/AdaptersProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

import * as S from './MainSource.styles';

interface Props {
  event: Event;
  fetchOdds: () => void;
}

export function MainSource({ event, fetchOdds }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updateEvent = useUpdateEvent({
    onSuccess: () => {
      window.location.reload();
    },
  });
  const { isCustomer } = useUserStore(getIsCustomer);
  const sourcesItems = useSelector((state: RootState) =>
    selectMappedEventsItemsById(state, event.id),
  );

  const eventMainSourceOptions = useMemo(() => {
    return uniq(
      [...(sourcesItems.map(s => s.source) ?? []), EventSource.MODEL].map(
        source => ({
          name: getEventSourceName(source),
          id: source! as string,
        }),
      ),
    );
  }, [sourcesItems]);

  const [eventMainSource, setEventMainSource] = useState<
    Maybe<{ name: string; id: string }>
  >(
    event.mainSource
      ? { name: event.mainSource.replace('SOURCE_', ''), id: event.mainSource }
      : null,
  );

  useEffect(() => {
    if (eventMainSource?.id && event.mainSource !== eventMainSource?.id) {
      updateEvent({ ...event, mainSource: eventMainSource.id as EventSource });
    }
  }, [eventMainSource, event, updateEvent]);

  useEffect(() => {
    if (!isCustomer) {
      dispatch(adapterActions.mappedEventsFetch({ ids: [event.id] }));
    }
  }, [dispatch, event.id, isCustomer]);

  const handleRefreshClick = () => {
    dispatch(
      actionsNT.eventsOddsUpdate({
        eventId: event.id,
        lineType: event.live
          ? SportEventOddsLineType.LIVE
          : SportEventOddsLineType.PRE,
      }),
    );
  };

  const handleSuspendAllClick = () => {
    dispatch(actionsNT.eventSuspendsCreate({ eventId: event.id }));
  };

  const { isLoading } = useRequestState('eventSuspends', 'create', () => {
    window.location.reload();
  });
  const { isLoading: refreshIsLoading } = useRequestState(
    'eventsOdds',
    'update',
    fetchOdds,
  );

  return (
    <S.Wrapper>
      {!isCustomer ? (
        <S.SourceWrapper>
          <S.Label>{t('default source')}</S.Label>
          <S.SelectWrapper>
            <Select
              options={eventMainSourceOptions}
              getOptionValue={value => String(value.id)}
              value={eventMainSource}
              onChange={value =>
                setEventMainSource(value as typeof eventMainSource)
              }
            />
          </S.SelectWrapper>
        </S.SourceWrapper>
      ) : null}

      <RoleGuards
        roles={[
          UserGroupEnum.ADMIN,
          UserGroupEnum.SUPERVISOR,
          UserGroupEnum.OPERATOR,
        ]}
      >
        <S.SuspendButtonWrapper>
          <Button
            color="action"
            disabled={isLoading}
            onClick={handleSuspendAllClick}
          >
            {t('suspend all')}
          </Button>
        </S.SuspendButtonWrapper>

        <S.PopupWrapper>
          <Popup
            button={
              <Button
                color="action"
                testId={TestIds.EventPageModelsCalculateSelect}
              >
                {t('calculate via model')}
                <FontAwesomeIcon icon={faAngleDown} color="000000" />
              </Button>
            }
            body={
              <S.Column>
                <S.Span
                  onClick={() => window.open(getModelsUrl(event), '_blank')}
                  data-test-id={`${TestIds.EventPageModelsCalculateSelect}--models`}
                >
                  MODELS
                </S.Span>
                <S.Span
                  onClick={() =>
                    window.open(
                      `${MODELS_UI_URL}/manual-odds/${event.id}`,
                      '_blank',
                    )
                  }
                  data-test-id={`${TestIds.EventPageModelsCalculateSelect}--manual_odds`}
                >
                  MANUAL ODDS
                </S.Span>
              </S.Column>
            }
          />
        </S.PopupWrapper>
      </RoleGuards>
      <S.RefreshButtonWrapper>
        <Button
          color="action"
          disabled={refreshIsLoading}
          onClick={handleRefreshClick}
          testId={TestIds.EventPageUpdateButton}
        >
          <FontAwesomeIcon icon={faRedo} />
          {t('refresh')}
        </Button>
      </S.RefreshButtonWrapper>
    </S.Wrapper>
  );
}
