import { PayloadAction } from '@reduxjs/toolkit';
import uniq from 'lodash-es/uniq';
import {
  DelayList,
  DelaysApiGetDelaysEventListRequest,
} from 'sportsbook-openapi-react';

import { PaginatedGetRequest } from 'app/providers/EntitiesProvider/types';
import {
  entityReducersGenerator,
  toIdsArray,
  toIdsMap,
} from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator({
    entity: 'delays',
  }),
  delaysByEventFetchItems(
    state,
    action: PayloadAction<
      PaginatedGetRequest<DelaysApiGetDelaysEventListRequest>
    >,
  ) {
    state.delays.fetchItems.error = null;
    state.delays.fetchItems.loading = true;
    state.delays.withPagination = action.payload?.withPagination;
    state.delays.onlyEntries = action.payload?.onlyEntries;
  },
  delaysByEventFetchItemsSuccess(state, action: PayloadAction<DelayList>) {
    const { items, hasMore } = action.payload;
    if (!state.delays.onlyEntries) {
      if (state.delays.withPagination) {
        state.delays.sortedIds = uniq([
          ...state.delays.sortedIds,
          ...toIdsArray(items),
        ]);
      } else {
        state.delays.sortedIds = toIdsArray(items);
      }
    }
    state.delays.entries = {
      ...state.delays.entries,
      ...toIdsMap(items),
    };
    state.delays.fetchItems.loading = false;
    state.delays.hasMore = hasMore;
  },
  delaysByEventFetchItemsError(state, action: PayloadAction<Error>) {
    state.delays.fetchItems.error = action.payload;
    state.delays.fetchItems.loading = false;
  },
};

export default reducer;
