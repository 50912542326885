import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';

const getEventMappingsSubstate = (state: RootState) =>
  getEntities(state).eventsMappings;

export const getEventMappings = (state, id: number) =>
  getEventMappingsSubstate(state).entries[id];

export const getEventCreateLoading = state =>
  getEventMappingsSubstate(state).create.loading;
export const getEventUpdateLoading = state =>
  getEventMappingsSubstate(state).update.loading;
