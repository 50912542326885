import { PropsWithChildren } from 'react';

import { useWhoIam } from 'hooks/user';

import { ErrorPage, LoadingPage } from 'app/components/ui';

const UserProvider = ({ children }: PropsWithChildren) => {
  const { isLoading, isError, unauthorized } = useWhoIam();

  if (isLoading || unauthorized) return <LoadingPage />;
  if (isError) return <ErrorPage />;

  return children;
};

export default UserProvider;
