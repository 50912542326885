import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { MatchPhase, MatchPhaseWithoutId } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';

import { initialCreateValues, validationSchema } from './constants';
import { MatchPhaseForm } from './MatchPhaseForm';

interface Props {
  onClose: () => void;
  onSuccess?: (phase: MatchPhase) => void;
}

export const MatchPhaseCreatePanel = ({ onClose, onSuccess }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleMatchPhaseCreated = (result: MatchPhase) => {
    if (onSuccess) onSuccess(result);
    onClose();
  };

  useRequestState(Entities.MATCH_PHASES, 'create', handleMatchPhaseCreated);

  const onSubmit = (matchPhaseWithoutId: MatchPhaseWithoutId) => {
    dispatch(
      actionsNT.matchPhasesCreate({
        matchPhaseWithoutId,
      }),
    );
  };

  return (
    <Formik<MatchPhaseWithoutId>
      initialValues={initialCreateValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.create.matchPhase')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <MatchPhaseForm />
        </Panel>
      )}
    </Formik>
  );
};
