/**
 *
 * Header
 *
 */

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { User, UserGroupEnum } from 'sportsbook-openapi-react';

import { pruneCache } from 'utils/sessionStorage';
import useBooleanState from 'hooks/useBooleanState';
import { API_URL } from 'consts';

import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Dialog } from 'app/components/ui';
import MenuIcon from 'assets/images/menu.svg?react';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getFavoriteEvents } from 'app/providers/EntitiesProvider/eventFavorites';

import * as S from './Header.styles';

interface Props {
  className?: any;
  user: Maybe<User>;
}

export const Header: FC<Props> = ({ className, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const favorite = useSelector(getFavoriteEvents);

  const [isDeleteDialogVisible, showDeleteDialog, hideDeleteDialog] =
    useBooleanState();

  const deleteAllFavorites = () => {
    if (!user) return;
    dispatch(
      actionsNT.eventFavoritesDeleteList({
        userId: user.id,
      }),
    );
  };

  return (
    <S.Wrapper className={className}>
      <S.StyledLogo />
      <Path />
      <S.Spacer />
      <S.UserBlock>
        <S.UserAvatar>{user?.name[0]}</S.UserAvatar>
        <S.UserLink>{user?.name}</S.UserLink>
      </S.UserBlock>

      <RoleGuards
        roles={[
          UserGroupEnum.ADMIN,
          UserGroupEnum.CUSTOMER,
          UserGroupEnum.OPERATOR,
          UserGroupEnum.SUPERVISOR,
        ]}
      >
        <Button color="link" onClick={showDeleteDialog}>
          {t('delete all favorites')}({Object.values(favorite).length})
        </Button>
      </RoleGuards>

      <Button
        color="link"
        onClick={() => {
          window.open(`${API_URL}/logout`, '_self');
          pruneCache();
        }}
      >
        {t('logout')}
      </Button>
      {isDeleteDialogVisible && (
        <Dialog
          text={t('deleteFavorites')}
          onClose={hideDeleteDialog}
          onConfirm={deleteAllFavorites}
        />
      )}
    </S.Wrapper>
  );
};

export const Menu = () => {
  return (
    <S.MenuWrapper>
      <MenuIcon />
    </S.MenuWrapper>
  );
};

export const Path: FC = () => {
  const location = useLocation();
  const pathElements = location.pathname.split('/').slice(1);
  const { t } = useTranslation();

  return (
    <S.PathWrapper>
      {pathElements.map((path, index, paths) => (
        <S.StyledLink key={path} to={`/${paths.slice(0, index + 1).join('/')}`}>
          {t(path)}
        </S.StyledLink>
      ))}
    </S.PathWrapper>
  );
};
