import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr auto auto auto;
  grid-template-areas: 'source . suspend popup refresh';
  padding: 12px 16px 12px 24px;
  grid-column-gap: 16px;
`;

export const SelectWrapper = styled.div`
  margin-left: -8px;
  width: 200px;
`;

export const Label = styled.span`
  color: ${p => p.theme.colors.primary[800]};
  font-size: ${p => p.theme.fonts.typography['H400'].size};
  font-weight: ${p => p.theme.fonts.typography['H400'].weight};
`;

export const Column = styled.div`
  display: grid;
`;

export const Span = styled.span`
  cursor: pointer;
  padding: 8px 10px;
  margin: 0 -8px;
  font-size: ${p => p.theme.fonts.typography['H200'].size};
  font-weight: ${p => p.theme.fonts.typography['H200'].weight};
  &:hover {
    background-color: ${p => p.theme.colors.primary[20]};
  }
`;

export const SourceWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  grid-area: source;
`;

export const SuspendButtonWrapper = styled.div`
  grid-area: suspend;
`;

export const PopupWrapper = styled.div`
  grid-area: popup;
`;

export const RefreshButtonWrapper = styled.div`
  grid-area: refresh;
`;
