import { sourcesApi } from 'app/api';

const api = {
  endpoint: sourcesApi,
  methods: {
    fetchItems: 'getSources',
    fetchItem: 'getSource',
    create: 'createSource',
    update: 'updateSource',
    delete: 'deleteSource',
  },
};

export default api;
