import { FC } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EventFormik } from 'app/pages/Events/components/EventFormik';

import { FETCH_ALL_LIMIT } from 'consts';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectMatchTypesItems } from 'app/providers/EntitiesProvider/matchTypes';

import * as S from './CreateEvent.styles';

export const CreateEvent: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsNT.matchTypesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const items = useSelector(selectMatchTypesItems);

  const initialMatchType = useMemo(
    () => items.find(item => item.name === 'MAIN'),
    [items],
  );

  return (
    <S.Wrapper>
      <S.CreateEventContainer>
        <EventFormik initialEvent={{ matchType: initialMatchType }} />
      </S.CreateEventContainer>
    </S.Wrapper>
  );
};
