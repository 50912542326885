import { Column } from 'react-table';
import { MaxOdd } from 'sportsbook-openapi-react';

import MaxOddCell from './MaxOddCell';

interface Props {
  header: string;
  accessor: string;
  maxOdds: Dictionary<MaxOdd>;
  updateMaxOddValue: (props: {
    outcomeCategoryId: number;
    maxOdd?: MaxOdd;
    value: number;
  }) => void;
  isOperator: boolean;
  isEditable: boolean;
}

export const MaxOddColumn = <T extends object>({
  header,
  accessor,
  maxOdds,
  isOperator,
  isEditable,
  updateMaxOddValue,
}: Props): Column<T> => {
  return {
    Header: header,
    id: 'maxOdd',
    accessor: accessor as keyof T,
    Cell: ({ value: categoryId }) => {
      const maxOdd = maxOdds[categoryId];
      return (
        <MaxOddCell
          maxOdd={maxOdd}
          outcomeCategoryId={categoryId}
          handleCellChange={updateMaxOddValue}
          isOperator={isOperator}
          isEditable={isEditable}
        />
      );
    },
  };
};
