import type { User } from 'sportsbook-openapi-react';

import createZustandStore from 'store/createStore';

import type { UserState, UserStore } from './interfaces';

const initialState: UserState = { user: {} as User };

const useUserStore = createZustandStore<UserStore>(
  set => ({
    ...initialState,
    setUser: user => set(() => ({ user }), false, 'setUser'),
    setCustomer: customer => set(() => ({ customer }), false, 'setCustomer'),
  }),
  'UserStore',
);

export default useUserStore;

export * from './selectors';
