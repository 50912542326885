import React, { useState } from 'react';

type UseBooleanStateResult = [boolean, () => void, () => void, () => void];

const useBooleanState = (initialValue = false): UseBooleanStateResult => {
  const [isActive, setIsActive] = useState<boolean>(initialValue);
  const setTrue = React.useCallback(() => setIsActive(true), [setIsActive]);
  const setFalse = React.useCallback(() => setIsActive(false), [setIsActive]);
  const toggle = React.useCallback(
    () => setIsActive(prevState => !prevState),
    [setIsActive],
  );

  return [isActive, setTrue, setFalse, toggle];
};

export default useBooleanState;
