/**
 *
 * Directory
 *
 */

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { Panel } from './components/Panel';

export function Directory() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('dictionary')}</title>
        <meta name="description" content="Description of Directory" />
      </Helmet>
      <Panel />
      <Outlet />
    </>
  );
}
