import { useTranslation } from 'react-i18next';
import { MarketType, SportEventParameterType } from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';

import {
  AsyncSelectField,
  SelectField,
  TextInputField,
} from 'app/components/forms';
import { Span } from 'app/components/ui';

import * as S from './styles';

const marketTypesOptions = Object.values(MarketType).map(value => ({
  id: value,
  name: value,
}));

const paramsOptions = Object.values(SportEventParameterType).map(value => ({
  id: value,
  name: value,
}));

export const MarketForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <S.FormBlock>
        <Span>{t('name')}</Span>
        <TextInputField name="name" />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('market type')}</Span>
        <SelectField name="type" options={marketTypesOptions} />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('category')}</Span>
        <AsyncSelectField
          name="outcomeCategory"
          loadOptions={getAsyncLoadOptions({ entity: 'categories' })}
          debounceTimeout={500}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('params')}</Span>
        <SelectField isMulti name="params" options={paramsOptions} />
      </S.FormBlock>
    </>
  );
};
