/**
 *
 * Languages
 *
 */

import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { omit } from 'lodash-es';
import { Language, UserGroupEnum } from 'sportsbook-openapi-react';

import { includes } from 'utils/filters';
import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { useSearch } from 'hooks/useSearch';

import { TextInput, TextInputCell } from 'app/components/forms';
import { LanguageCreatePanel } from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectLanguages } from 'app/providers/EntitiesProvider/languages';

import { useUserStore } from 'store';
import { getIsOperator, getIsTranslator } from 'store/user';

import * as S from './Languages.styles';

export function Languages() {
  const items = useSelector(selectLanguages);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const isOperator = useUserStore(getIsOperator);
  const isTranslator = useUserStore(getIsTranslator);

  const [isCreateLanguageVisible, showCreateLanguage, hideCreateLanguage] =
    useBooleanState();

  const handleUpdateLanguage = useCallback(
    (language: Language) => {
      dispatch(
        actionsNT.languagesUpdate({
          languageId: language.id,
          languageWithOutId: omit(language, 'id'),
        }),
      );
    },
    [dispatch],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.LANGUAGES,
  });

  const columns: Column<Language>[] = useMemo(
    () => [
      {
        Header: t('code').toString(),
        accessor: 'code',
        Cell: ({ value, row: { original: data } }) => {
          return !(isTranslator || isOperator) ? (
            <TextInputCell
              defaultValue={value}
              onSave={newValue => {
                handleUpdateLanguage({
                  ...data,
                  code: newValue,
                });
              }}
              validate={value => value !== ''}
            />
          ) : (
            <>{value}</>
          );
        },
      },
      {
        Header: t('name').toString(),
        accessor: 'name',
        Cell: ({ value, row: { original: data } }) => {
          return !(isTranslator || isOperator) ? (
            <TextInputCell
              defaultValue={value}
              onSave={newValue => {
                handleUpdateLanguage({
                  ...data,
                  name: newValue,
                });
              }}
              validate={value => value.length > 3}
            />
          ) : (
            <>{value}</>
          );
        },
      },
      ...(!(isTranslator || isOperator)
        ? [
            {
              Header: '',
              accessor: 'id' as keyof Language,
              id: 'audit',
              Cell: ({ row: { original: data } }) => (
                <Button
                  color="action"
                  onClick={() => {
                    openAudit({ id: String(data.id), includeRelated: false });
                  }}
                >
                  {t('audit')}
                </Button>
              ),
            },
          ]
        : []),
    ],
    [t, isTranslator, isOperator, handleUpdateLanguage, openAudit],
  );

  useEffect(() => {
    dispatch(actionsNT.languagesFetchItems());
  }, [dispatch]);

  const filteredData = useMemo(
    () => items.filter(language => includes(language.name, searchQuery)),
    [searchQuery, items],
  );

  return (
    <S.Wrapper>
      <S.DropdownWrapper>
        <DictionaryDropdown value={'languages'} />
      </S.DropdownWrapper>
      <S.ContentWrapper>
        <S.Header>
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.CUSTOMER,
              UserGroupEnum.SUPERVISOR,
            ]}
          >
            <Button onClick={showCreateLanguage}>{t('add language')}</Button>
          </RoleGuards>
          <TextInput
            placeholder={t('search')}
            onChange={e => setSearchQueryDebounced(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />
        </S.Header>
        <Table
          columns={columns}
          data={filteredData}
          columnsWidth={['fit-content', 'auto', 'fit-content']}
        />
      </S.ContentWrapper>
      {isCreateLanguageVisible && (
        <LanguageCreatePanel onClose={hideCreateLanguage} />
      )}
    </S.Wrapper>
  );
}
