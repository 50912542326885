import styled from 'styled-components';

import { StyledTextAreaProps } from './TextArea.types';

export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;

  border: 1px solid ${({ invalid }) => (invalid ? 'red' : '#e5e5e5')};
  border-radius: 4px;
  transition:
    border-color 0.2s ease,
    box-shadow 0.2s ease;

  text-align: center;
  text-align: left;

  &::placeholder {
    color: #d0d9e0;
  }
`;
