import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  MatchType as MatchTypeType,
  MatchTypeWithOutId,
} from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { IS_DELETION_DISABLED } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';

import { validationSchema } from './constants';
import { MatchTypeForm } from './MatchTypeForm';

interface Props {
  id: number;
  onClose: () => void;
}

export const MatchTypeEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const matchType: MatchTypeType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.MATCH_TYPES, id),
  );

  useRequestState(Entities.MATCH_TYPES, 'update', onClose);

  const onSubmit = (values: MatchTypeWithOutId) => {
    dispatch(
      actionsNT.matchTypesUpdate({
        matchTypeId: matchType.id,
        matchTypeWithOutId: {
          name: values.name,
          sports: values.sports,
          translations: [],
          code: values.code!,
        },
      }),
    );
  };

  const onDelete = () => {
    dispatch(actionsNT.categoriesDelete({ categoryId: matchType.id }));
    onClose();
  };

  return (
    <Formik<MatchTypeWithOutId>
      initialValues={matchType}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.matchType')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
              leftButtonText={t('delete')}
              leftButtonDisabled={IS_DELETION_DISABLED}
              onLeftButtonClick={onDelete}
            />
          }
        >
          <MatchTypeForm />
        </Panel>
      )}
    </Formik>
  );
};
