/**
 *
 * Tooltip
 *
 */
import { FC } from 'react';

import useBooleanState from 'hooks/useBooleanState';

import * as S from './Tooltip.styles';
import { Props, TooltipPositionEnum } from './types';

export const Tooltip: FC<Props> = ({
  children,
  content,
  position = TooltipPositionEnum.RIGHT,
  isVisible = true,
}) => {
  const [isHovered, setIsHoveredTrue, setIsHoveredFalse] = useBooleanState();

  return (
    <S.Wrapper
      onMouseEnter={setIsHoveredTrue}
      onMouseLeave={setIsHoveredFalse}
      onBlur={setIsHoveredFalse}
    >
      {isVisible && isHovered && (
        <S.TooltipWrapper position={position}>{content}</S.TooltipWrapper>
      )}
      {children}
    </S.Wrapper>
  );
};
