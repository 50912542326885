import { cashoutBlocksApi } from 'app/api';

const api = {
  endpoint: cashoutBlocksApi,
  methods: {
    fetchItems: 'getCategoryCashoutBlocksByCustomer',
    fetchItem: 'getCategoryCashoutBlockByCustomer',
    update: 'upsertCategoryCashoutBlockByCustomer',
    delete: 'deleteCategoryCashoutBlockByCustomer',
  },
};

export default api;
