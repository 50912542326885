import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  user-select: none;
  cursor: pointer;
  position: relative;

  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 5px;
  align-items: center;
`;

export const List = styled.div`
  z-index: 2;
  position: absolute;
  top: 120%;
  left: 0;
  padding: 10px;

  background: white;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.div`
  display: flex;

  * {
    flex-grow: 1;
    font-size: 14px;
    line-height: 200%;
    width: fit-content;
    color: #333333;
    text-decoration: none;
    white-space: nowrap;
    &:visited {
      color: #333333;
      text-decoration: none;
    }
  }
`;
