import * as S from './NavTabs.styles';

interface Props {
  tabs: {
    path: string;
    label: string;
    count?: number;
  }[];
  withNumber?: boolean;
  withDots?: boolean;
  className?: any;
}

export const NavTabs = ({ tabs, withNumber, className }: Props) => {
  return (
    <S.Wrapper className={className}>
      {tabs.map(tab => (
        <S.Item key={tab.path} to={tab.path}>
          <S.Label>{tab.label}</S.Label>
          {withNumber && <S.Count>{tab.count}</S.Count>}
        </S.Item>
      ))}
    </S.Wrapper>
  );
};
