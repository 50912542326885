import { PayloadAction } from '@reduxjs/toolkit';
import uniq from 'lodash-es/uniq';
import {
  Logo,
  LogosApiDeleteLogosRequest,
  LogosApiGetLogosRequest,
  LogosApiInsertLogoRequest,
  LogosResponse,
} from 'sportsbook-openapi-react';

import { EntitiesState } from 'app/providers/EntitiesProvider/types';
import {
  entityReducersGenerator,
  toIdsArray,
  toIdsMap,
} from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    Logo,
    LogosApiDeleteLogosRequest,
    LogosApiGetLogosRequest,
    LogosApiInsertLogoRequest
  >({
    entity: 'logos',
  }),
  logosFetchItemsSuccess(state, action: PayloadAction<LogosResponse>) {
    const { items } = action.payload;
    if (!state.logos.onlyEntries) {
      if (state.logos.withPagination) {
        state.logos.sortedIds = uniq([
          ...state.logos.sortedIds,
          ...toIdsArray(items),
        ]);
      } else {
        state.logos.sortedIds = toIdsArray(items);
      }
    }
    state.logos.entries = {
      ...state.logos.entries,
      ...toIdsMap(items),
    };
    state.logos.fetchItems.loading = false;
  },
  logosCreateListSuccess(state: EntitiesState, action: PayloadAction<Logo>) {
    const { id } = action.payload;
    const existing = Object.values(state.logos.entries).find(
      item => action.payload.kind === item.kind,
    );

    if (existing) {
      state.logos.sortedIds = state.logos.sortedIds.filter(
        id => id !== existing.id,
      );
      delete state.logos.entries[existing.id];
    }
    state.logos.sortedIds.push(id);
    state.logos.entries[id] = action.payload;
    state.logos.createList.loading = false;
  },
  logosFetchItemsError(state, action: PayloadAction<Error>) {
    state.logos.fetchItems.error = action.payload;
    state.logos.fetchItems.loading = false;
  },
  logosDelete(state, action: PayloadAction<LogosApiDeleteLogosRequest>) {
    state.logos.sortedIds = state.logos.sortedIds.filter(
      id => !action.payload.ids?.includes(id),
    );
    action.payload.ids?.forEach(id => {
      delete state.logos.entries[id];
    });
    state.logos.delete.loading = true;
    state.logos.delete.error = null;
  },
};

export default reducer;
