/**
 *
 * Results
 *
 */

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { EventsList } from 'app/pages/Events/components/EventsList';

import { ResultedEvent } from './components';

export function Results() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('results')}</title>
        <meta name="description" content={t('results')} />
      </Helmet>
      <Routes>
        <Route path=":id" element={<ResultedEvent />} />
        <Route path="/" element={<EventsList />} />
      </Routes>
    </>
  );
}
