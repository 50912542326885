import { PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash-es/keyBy';
import {
  EntityTag,
  TagsApiDeleteCustomerTagRequest,
} from 'sportsbook-openapi-react';

import {
  entityReducersGenerator,
  toIdsArray,
} from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator({ entity: 'tagsByCustomer' }),
  tagsByCustomerUpdateSuccess(state, action: PayloadAction<EntityTag>) {
    const tag = { ...action.payload, id: action.payload.id! };
    state.tagsByCustomer.update.loading = false;
    state.tagsByCustomer.entries[tag.id] = tag;
    if (!state.tagsByCustomer.sortedIds.includes(tag.id)) {
      state.tagsByCustomer.sortedIds.push(tag.id);
    }
  },
  tagsByCustomerFetchItemsSuccess(
    state,
    action: PayloadAction<Array<EntityTag>>,
  ) {
    const items = action.payload.map(item => ({
      ...item,
      id: item.id!,
    }));
    state.tagsByCustomer.fetchItems.loading = false;
    state.tagsByCustomer.entries = {
      ...state.tagsByCustomer.entries,
      ...keyBy(items, 'id'),
    };
    state.tagsByCustomer.sortedIds = toIdsArray(items);
  },
  tagsByCustomerDelete(
    state,
    action: PayloadAction<TagsApiDeleteCustomerTagRequest>,
  ) {
    const { tagId } = action.payload;
    state.tagsByCustomer.delete.loading = true;
    delete state.tagsByCustomer.entries[tagId];
    state.tagsByCustomer.sortedIds = state.tagsByCustomer.sortedIds.filter(
      id => id !== tagId,
    );
  },
};

export default reducer;
