import { UserGroupEnum } from 'sportsbook-openapi-react';

import type { UserState } from './interfaces';

export const getUser = (state: UserState) => state.user;
export const getCustomer = (state: UserState) => state.customer;
export const getUserGroup = (state: UserState) => state.user.group;

export const getIsTranslator = (state: UserState) =>
  getUserGroup(state) === UserGroupEnum.TRANSLATOR;
export const getIsOperator = (state: UserState) =>
  getUserGroup(state) === UserGroupEnum.OPERATOR;
export const getIsCustomer = (state: UserState) => {
  const isCustomer = getUserGroup(state) === UserGroupEnum.CUSTOMER;
  return {
    isCustomer,
    customerId: isCustomer ? getUser(state).customerId : undefined,
  };
};
