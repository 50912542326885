import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  OutcomeType,
  OutcomeTypeCrossMappingWithOutcomeTypes,
} from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';
import { getMarketsEntries } from 'app/providers/EntitiesProvider/markets';

import { validationSchema } from './constants';
import { CrossMappingForm } from './CrossMappingForm';

interface Props {
  id: number;
  onClose: () => void;
  categoryId: number;
}

export const CrossMappingCreatePanel = ({ onClose, categoryId, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsNT.marketsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.categoriesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const outcomeType: OutcomeType | undefined = useSelector(state =>
    getEntityByTypeAndId(state, Entities.TYPES, id),
  );

  const markets = useSelector(getMarketsEntries);

  useRequestState(Entities.CROSS_MAPPINGS, 'create', onClose);

  const onSubmit = (values: OutcomeTypeCrossMappingWithOutcomeTypes) => {
    dispatch(
      actionsNT.crossMappingsCreate({
        typeId: values.outcomeType?.id!,
        outcomeTypeCrossMappingWithOutId: {
          outcomeTypeIdCross: values.crossOutcomeType?.id!,
          crossParams: values.crossParams,
        },
      }),
    );
  };

  return (
    <Formik<OutcomeTypeCrossMappingWithOutcomeTypes>
      initialValues={{
        outcomeType: outcomeType,
        crossOutcomeType: undefined,
        crossParams: [],
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.create.crossMapping')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <CrossMappingForm id={id} categoryId={categoryId} markets={markets} />
        </Panel>
      )}
    </Formik>
  );
};
