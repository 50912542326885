import { PayloadAction } from '@reduxjs/toolkit';
import {
  Adapter,
  AdapterEvents,
  AdaptersApiDeleteSportEventMappingRequest,
  AdaptersApiExcludeSportEventRequest,
  AdaptersApiGetSportEventsByIdsRequest,
  SportEventProbabilitiesOriginal,
} from 'sportsbook-openapi-react';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { toIdsArray, toIdsMap } from 'app/providers/EntitiesProvider';

import { adapterEntityInitialState, initialState } from './constants';
import { reducersGenerator } from './reducersGenerator';
import { AdapterActions, ContainerState } from './types';

const adaptersProviderSlice = createSlice({
  name: 'adaptersProvider',
  initialState,
  reducers: {
    fetchAdapters(state) {
      state.adapters.fetch.loading = true;
      state.adapters.fetch.error = null;
    },
    fetchAdaptersSuccess(state, action: PayloadAction<Adapter[]>) {
      state.adapters.entries = action.payload;
      state.adapters.fetch.loading = false;
    },
    fetchAdaptersFailure(state, action: PayloadAction<Error>) {
      state.adapters.fetch.loading = false;
      state.adapters.fetch.error = action.payload;
    },
    /* Adapter Competitors actions */
    ...reducersGenerator('competitors', {
      reset: true,
      fetch: true,
      map: true,
      remove: true,
    }),

    /* Adapter Markets actions */
    ...reducersGenerator('markets', {
      reset: true,
      fetch: true,
      map: true,
      remove: true,
    }),

    /* Adapter Outcome Types actions */
    ...reducersGenerator('types', {
      reset: true,
      fetch: true,
      map: true,
      remove: true,
    }),

    /* Adapter Players actions */
    ...reducersGenerator('players', {
      reset: true,
      fetch: true,
      map: true,
      remove: true,
    }),

    /* Adapter Events actions */
    ...reducersGenerator('events', {
      reset: true,
      fetch: true,
      map: true,
      remove: true,
    }),
    eventsDeleteMapping(
      state,
      action: PayloadAction<AdaptersApiDeleteSportEventMappingRequest>,
    ) {
      state.entities.events.delete.loading = true;
      state.entities.events.delete.error = null;
      state.entities.events.sortedIds = state.entities.events.sortedIds.filter(
        id => id !== action.payload.id,
      );
      delete state.entities.events.entries[action.payload.id];

      Object.keys(state.entities.mappedEvents).forEach(id => {
        state.entities.mappedEvents[id].sortedIds = state.entities.mappedEvents[
          id
        ].sortedIds.filter(id => id !== action.payload.id);
        delete state.entities.mappedEvents[id].entries[action.payload.id];
      });
    },
    /**
     * Event excluding
     */
    eventsUpdate(
      state,
      action: PayloadAction<AdaptersApiExcludeSportEventRequest>,
    ) {
      state.entities.events.sortedIds = state.entities.events.sortedIds.filter(
        id => id !== action.payload.id,
      );
      delete state.entities.events.entries[action.payload.id];
    },
    /* Adapter Event Probabilities actions */
    ...reducersGenerator('eventProbabilities', {
      reset: true,
      fetch: true,
    }),
    eventProbabilitiesFetchSuccess(
      state,
      action: PayloadAction<SportEventProbabilitiesOriginal>,
    ) {
      state.entities.eventProbabilities.entries[action.payload.eventId!] =
        action.payload;

      state.entities.eventProbabilities.fetch.loading = false;
    },

    /* Adapter Sports actions */
    ...reducersGenerator('sports', {
      reset: true,
      fetch: true,
      map: true,
      remove: true,
    }),

    /* Adapter Tournaments actions */
    ...reducersGenerator('tournaments', {
      reset: true,
      fetch: true,
      map: true,
      remove: true,
    }),

    /* Adapter Countries actions */
    ...reducersGenerator('countries', {
      reset: true,
      fetch: true,
    }),

    /* Adapter Parameters actions */
    ...reducersGenerator('parameters', {
      reset: true,
      fetch: true,
      withRewrite: true,
    }),

    /* Adapter Match Phases actions */
    ...reducersGenerator('matchPhases', {
      reset: true,
      fetch: true,
      map: true,
      remove: true,
    }),

    /**
     * Mapped events
     */
    mappedEventsFetch(
      state,
      action: PayloadAction<AdaptersApiGetSportEventsByIdsRequest>,
    ) {
      const { ids } = action.payload;
      ids?.forEach(id => {
        if (!state.entities.mappedEvents[id]) {
          state.entities.mappedEvents[id] = {
            ...adapterEntityInitialState,
            fetch: {
              loading: true,
              error: null,
            },
          };
        } else {
          state.entities.mappedEvents[id].fetch.loading = true;
          state.entities.mappedEvents[id].fetch.error = null;
        }
      });
    },
    mappedEventsFetchSuccess(
      state,
      action: PayloadAction<AdapterEvents & { ids: number[] }>,
    ) {
      const { ids, items, hasMore } = action.payload;
      ids.forEach(id => {
        state.entities.mappedEvents[id].fetch.loading = false;

        state.entities.mappedEvents[id].entries = {
          ...state.entities.mappedEvents[id].entries,
          ...toIdsMap(items),
        };
        state.entities.mappedEvents[id].sortedIds.push(
          ...toIdsArray(items, state.entities.mappedEvents[id].sortedIds),
        );
        state.entities.mappedEvents[id].hasMore = hasMore;
        state.entities.mappedEvents[id].fetch.loading = false;
      });
    },
    mappedEventsFetchFailure(
      state: ContainerState,
      action: PayloadAction<{ ids: number[]; error: Error }>,
    ) {
      const { ids, error } = action.payload;

      ids.forEach(id => {
        state.entities.mappedEvents[id].fetch.loading = false;
        state.entities.mappedEvents[id].fetch.error = error.message;
      });
    },
  },
});

const { actions, reducer, name: sliceKey } = adaptersProviderSlice;
const adapterActions = actions as unknown as AdapterActions;
export { adapterActions, reducer, sliceKey };
