import { eventsApi } from 'app/api';

const api = {
  endpoint: eventsApi,
  methods: {
    fetchItem: 'getEventMappings',
    create: 'createEventMapping',
    update: 'updateEventMapping',
  },
};

export default api;
