import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from 'app/pages/Sources/components/EntitiesTables/styles';
import { AdapterSport } from 'sportsbook-openapi-react';

import { useBottomScroll } from 'hooks/useBottomScroll';
import { useLazyLoading } from 'hooks/useLazyLoading';
import { FETCH_ALL_LIMIT } from 'consts';

import { LoadingIndicator } from 'app/components/ui';

import {
  adapterActions,
  getAdapterMatchPhasesHasMore,
  getAdapterMatchPhasesLoading,
  selectAdapterMatchPhasesItems,
} from 'app/providers/AdaptersProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { getMatchPhasesEntries } from 'app/providers/EntitiesProvider/matchPhases';

import { MatchPhaseRow } from './MatchPhaseRow';

interface Props {
  mapped: boolean;
  adapter: string;
  searchQuery: string;
  sport?: Maybe<AdapterSport>;
}

export const MatchPhasesTable: FC<Props> = ({
  mapped,
  adapter,
  sport,
  searchQuery,
}) => {
  const dispatch = useDispatch();
  const [editingRow, setEditingRow] =
    useState<Maybe<number | string>>(undefined);

  useEffect(() => {
    dispatch(actionsNT.matchPhasesFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const entries = useSelector(getMatchPhasesEntries);

  const adapterLoading = useSelector(getAdapterMatchPhasesLoading);
  const hasMore = useSelector(getAdapterMatchPhasesHasMore);
  const items = useSelector(selectAdapterMatchPhasesItems);

  const onLazyLoad = useCallback(
    ({ limit, offset }) =>
      dispatch(
        adapterActions.matchPhasesFetch({
          adapter,
          mapped,
          limit,
          offset,
          sportId: sport?.id,
          name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
        }),
      ),
    [dispatch, adapter, mapped, sport?.id, searchQuery],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [mapped, sport, searchQuery],
    initialPagination: {
      offset: 0,
      limit: 30,
    },
    onPaginationReset: () => {
      dispatch(adapterActions.matchPhasesReset());
    },
  });

  const handleBottomScroll = useCallback(() => {
    onBottom();
  }, [onBottom]);

  const scrollRef = useBottomScroll<HTMLDivElement>(handleBottomScroll);

  if (adapterLoading && !items.length) {
    return <LoadingIndicator type="full" />;
  }

  return (
    <S.TableWrapper ref={scrollRef}>
      <S.StyledTable key="mapped">
        {mapped && (
          <S.THead>
            <S.Tr>
              <S.Th weight={40}>От источника</S.Th>
              <S.Th weight={35}>В справочнике</S.Th>
              <S.Th weight={25} />
            </S.Tr>
          </S.THead>
        )}
        <S.TBody>
          {items.map(row => (
            <MatchPhaseRow
              key={row.id}
              mapped={mapped}
              adapter={adapter}
              matchPhase={row}
              matchPhases={entries}
              isEditing={editingRow === row.id}
              setEditingRow={setEditingRow}
            />
          ))}
        </S.TBody>
      </S.StyledTable>
      {adapterLoading && <LoadingIndicator type="absolute" />}
    </S.TableWrapper>
  );
};
