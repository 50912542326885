import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import omit from 'lodash-es/omit';
import { UserWithOutIdGroupEnum } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getCustomers } from 'app/providers/EntitiesProvider/customers';

import { createInitialValues, FormValues, validationSchema } from './constants';
import { UserForm } from './UserForm';

interface Props {
  onClose: () => void;
}

export const UserCreatePanel = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customers = useSelector(getCustomers);

  useEffect(() => {
    dispatch(actionsNT.customersFetchItems({ limit: FETCH_ALL_LIMIT }));
    return () => {
      dispatch(actionsNT.usersCreateReset());
    };
  }, [dispatch]);

  useRequestState(Entities.USERS, 'create', onClose);

  const onSubmit = (values: FormValues) => {
    const omittedValues =
      values.group?.id === UserWithOutIdGroupEnum.CUSTOMER
        ? {
            ...values,
            customerId: values.customer?.id,
            group: values.group?.id! as UserWithOutIdGroupEnum,
          }
        : {
            ...omit(values, 'customerId'),
            group: values.group?.id! as UserWithOutIdGroupEnum,
          };
    dispatch(
      actionsNT.usersCreate({
        userWithOutId: omittedValues,
      }),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={createInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.create.user')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <UserForm customers={customers} actionType="create" />
        </Panel>
      )}
    </Formik>
  );
};
