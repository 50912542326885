import { PayloadAction } from '@reduxjs/toolkit';
import {
  BetPayMax,
  BetPayMaxList,
  BetPayMaxListCreated,
  CashoutBlocksApiDeleteCategoryCashoutBlockByCustomerRequest,
  CashoutBlocksApiGetCategoryCashoutBlockByCustomerRequest,
  CashoutBlocksApiGetCategoryCashoutBlocksByCustomerRequest,
  CashoutBlocksApiUpsertCategoryCashoutBlockByCustomerRequest,
  CategoriesApiCreateCategoryRequest,
  CategoriesApiDeleteCategoryRequest,
  CategoriesApiGetCategoriesRequest,
  CategoriesApiGetCategoryRequest,
  CategoriesApiUpdateCategoryRequest,
  CategoryCashoutBlock,
  CategoryExclusion,
  Comment,
  CommentsApiCreateCommentRequest,
  CommentsApiCreateEntityCommentRequest,
  CommentsApiDeleteCommentRequest,
  CommentsApiDeleteEntityCommentRequest,
  CommentsApiGetCommentsRequest,
  CommentsApiGetEntityCommentsRequest,
  CommentsApiUpdateCommentRequest,
  CommentWithEntityView,
  Competitor,
  CompetitorList,
  CompetitorsApiCreateCompetitorRequest,
  CompetitorsApiDeleteCompetitorRequest,
  CompetitorsApiGetCompetitorRequest,
  CompetitorsApiGetCompetitorsRequest,
  CompetitorsApiUpdateCompetitorRequest,
  CountriesApiCreateCountryRequest,
  CountriesApiDeleteCountryRequest,
  CountriesApiGetCountriesRequest,
  CountriesApiGetCountryRequest,
  CountriesApiUpdateCountryRequest,
  Country,
  CountryList,
  Currency,
  CurrencyApiCreateCurrencyRequest,
  CurrencyApiDeleteCurrencyRequest,
  CurrencyApiGetCurrenciesRequest,
  CurrencyApiGetCurrencyRequest,
  CurrencyApiUpdateCurrencyRequest,
  CurrencyList,
  Customer,
  CustomerList,
  CustomersApiCreateBetPayMaxListRequest,
  CustomersApiCreateBetPayMaxRequest,
  CustomersApiCreateCustomerRequest,
  CustomersApiDeleteBetPayMaxRequest,
  CustomersApiDeleteCustomerRequest,
  CustomersApiGetBetPayMaxEventListRequest,
  CustomersApiGetBetPayMaxListRequest,
  CustomersApiGetBetPayMaxRequest,
  CustomersApiGetCustomerRequest,
  CustomersApiGetCustomersRequest,
  CustomersApiUpdateBetPayMaxRequest,
  CustomersApiUpdateCustomerRequest,
  CustomerSportEventOdds,
  CustomerToken,
  Delay,
  DelaysApiCreateDelayListRequest,
  DelaysApiCreateDelayRequest,
  DelaysApiDeleteDelayRequest,
  DelaysApiGetDelayRequest,
  DelaysApiGetDelaysEventListRequest,
  DelaysApiGetDelaysRequest,
  DelaysApiUpdateDelayRequest,
  EntityTag,
  Event,
  EventFavoriteMapping,
  EventFavoriteMappingListResponse,
  EventLinkedIds,
  EventList,
  EventMapping,
  EventsApiCreateEventMappingRequest,
  EventsApiCreateEventRequest,
  EventsApiDeleteEventRequest,
  EventsApiGetCustomerEventOddsRequest,
  EventsApiGetEventLinkedIdsRequest,
  EventsApiGetEventMappingsRequest,
  EventsApiGetEventOddsRequest,
  EventsApiGetEventRequest,
  EventsApiGetEventResultsRequest,
  EventsApiGetEventsRequest,
  EventsApiGetEventStatsRequest,
  EventsApiGetEventStatusByCustomerRequest,
  EventsApiGetEventSuspendsRequest,
  EventsApiRefreshEventOddsRequest,
  EventsApiSettleReturnProbabilitiesRequest,
  EventsApiSuspendProbabilitiesRequest,
  EventsApiUpdateEventMappingRequest,
  EventsApiUpdateEventRequest,
  EventsApiUpsertEventResultsRequest,
  EventsApiUpsertEventStatusByCustomerRequest,
  EventsApiUpsertEventSuspendsRequest,
  EventStatus,
  EventSuspend,
  Exclusion,
  ExclusionsApiDeleteCategoryExclusionByCustomerRequest,
  ExclusionsApiGetCategoryExclusionByCustomerRequest,
  ExclusionsApiGetCategoryExclusionsByCustomerRequest,
  ExclusionsApiGetExclusionsByCustomerRequest,
  ExclusionsApiUpsertCategoryExclusionByCustomerRequest,
  ExclusionsApiUpsertExclusionByCustomerRequest,
  Group,
  GroupList,
  GroupsApiCreateGroupRequest,
  GroupsApiDeleteGroupRequest,
  GroupsApiGetGroupRequest,
  GroupsApiGetGroupsRequest,
  GroupsApiUpdateGroupRequest,
  Language,
  LanguageList,
  LanguagesApiCreateLanguageRequest,
  LanguagesApiDeleteLanguageRequest,
  LanguagesApiGetLanguageRequest,
  LanguagesApiUpdateLanguageRequest,
  Logo,
  LogosApiDeleteLogosRequest,
  LogosApiGetLogosRequest,
  LogosApiInsertLogoRequest,
  Margin,
  MarginList,
  MarginListCreated,
  MarginsApiCreateMarginListRequest,
  MarginsApiCreateMarginRequest,
  MarginsApiDeleteMarginRequest,
  MarginsApiGetMarginEventListRequest,
  MarginsApiGetMarginRequest,
  MarginsApiGetMarginsRequest,
  MarginsApiUpdateMarginRequest,
  MarketsApiCreateMarketRequest,
  MarketsApiDeleteMarketRequest,
  MarketsApiGetMarketRequest,
  MarketsApiGetMarketsRequest,
  MarketsApiUpdateMarketRequest,
  MatchPhase,
  MatchPhaseList,
  MatchPhasesApiCreateMatchPhaseRequest,
  MatchPhasesApiDeleteMatchPhaseRequest,
  MatchPhasesApiGetMatchPhaseRequest,
  MatchPhasesApiGetMatchPhasesRequest,
  MatchPhasesApiUpdateMatchPhaseRequest,
  MatchType,
  MatchTypeList,
  MatchTypesApiCreateMatchTypeRequest,
  MatchTypesApiDeleteMatchTypeRequest,
  MatchTypesApiGetMatchTypeRequest,
  MatchTypesApiGetMatchTypesRequest,
  MatchTypesApiUpdateMatchTypeRequest,
  MaxOdd,
  MaxOddsApiCreateMaxOddListRequest,
  MaxOddsApiDeleteMaxOddRequest,
  MaxOddsApiGetMaxOddRequest,
  MaxOddsApiGetMaxOddsRequest,
  MaxOddsApiUpdateMaxOddRequest,
  OutcomeCategory,
  OutcomeCategoryList,
  OutcomeCategorySourceMapping,
  OutcomeCategorySourceMappingApiCreateOutcomeCategorySourceMappingListRequest,
  OutcomeCategorySourceMappingApiCreateOutcomeCategorySourceMappingRequest,
  OutcomeCategorySourceMappingApiDeleteOutcomeCategorySourceMappingRequest,
  OutcomeCategorySourceMappingApiGetOutcomeCategorySourceMappingRequest,
  OutcomeCategorySourceMappingApiGetOutcomeCategorySourceMappingsRequest,
  OutcomeCategorySourceMappingApiUpdateOutcomeCategorySourceMappingRequest,
  OutcomeCategorySourceMappingListCreated,
  OutcomeCategorySourceMappingsList,
  OutcomeMarket,
  OutcomeMarketList,
  OutcomeType,
  OutcomeTypeCrossMappingWithOutcomeTypes,
  OutcomeTypeList,
  Player,
  PlayerList,
  PlayersApiCreatePlayerRequest,
  PlayersApiDeletePlayerRequest,
  PlayersApiGetPlayerRequest,
  PlayersApiGetPlayersRequest,
  PlayersApiUpdatePlayerRequest,
  SearchApiGetSearchResultRequest,
  Source,
  SourceList,
  Sport,
  SportEventOdds,
  SportEventResult,
  SportEventStats,
  SportList,
  SportsApiCreateSportRequest,
  SportsApiDeleteSportRequest,
  SportsApiGetSportRequest,
  SportsApiGetSportsRequest,
  SportsApiUpdateSportRequest,
  Tag,
  TagsApiCreateTagRequest,
  TagsApiDeleteCustomerTagRequest,
  TagsApiDeleteTagRequest,
  TagsApiGetTagRequest,
  TagsApiGetTagsByCustomerRequest,
  TagsApiGetTagsRequest,
  TagsApiUpdateTagRequest,
  TagsApiUpsertTagByCustomerRequest,
  Tournament,
  TournamentList,
  TournamentsApiCreateTournamentRequest,
  TournamentsApiDeleteTournamentRequest,
  TournamentsApiGetTournamentRequest,
  TournamentsApiGetTournamentsRequest,
  TournamentsApiUpdateTournamentRequest,
  TypesApiCreateOutcomeTypeCrossMappingRequest,
  TypesApiCreateTypeRequest,
  TypesApiDeleteOutcomeTypeCrossMappingRequest,
  TypesApiDeleteTypeRequest,
  TypesApiGetAllOutcomeTypeCrossMappingsRequest,
  TypesApiGetTypeRequest,
  TypesApiGetTypesRequest,
  TypesApiUpdateOutcomeTypeCrossMappingRequest,
  TypesApiUpdateTypeRequest,
  User,
  UserListWrapper,
  UsersApiCreateEventFavoriteMappingRequest,
  UsersApiCreateUserRequest,
  UsersApiDeleteAllEventFavoriteMappingRequest,
  UsersApiDeleteEventFavoriteMappingRequest,
  UsersApiDeleteUserRequest,
  UsersApiGetEventFavoriteListRequest,
  UsersApiGetUserRequest,
  UsersApiGetUsersRequest,
  UsersApiUpdateUserRequest,
} from 'sportsbook-openapi-react';

export interface RequestState {
  loading: boolean;
  error?: any;
  result?: any;
}

export type CreateRequestState<T> = RequestState & {
  id: number | null;
  result: T | null;
};

export type CreateListRequestState<T> = RequestState & {
  ids: number[] | null;
  result: T[] | null;
};

export type UpdateRequestState<T> = RequestState & {
  id: number | null;
  result: T | null;
};

export type EntityMethods =
  | 'fetchItems'
  | 'fetchItem'
  | 'create'
  | 'createList'
  | 'update'
  | 'delete';

export interface Entity<T> {
  entries: Dictionary<T>;
  sortedIds: number[];
  fetchItems: RequestState;
  fetchItem: RequestState;
  create: CreateRequestState<T>;
  createList: CreateListRequestState<T>;
  update: UpdateRequestState<T>;
  delete: RequestState;
  deleteList: RequestState;
  hasMore: boolean;
  withPagination?: boolean;
  onlyEntries?: boolean;
}

export interface EntityWithStringId<T> extends Omit<Entity<T>, 'sortedIds'> {
  sortedIds: string[];
}

interface WithPaginationToken {
  paginationToken: string;
}

export enum Entities {
  CATEGORIES = 'categories',
  COMPETITORS = 'competitors',
  COUNTRIES = 'countries',
  CURRENCIES = 'currencies',
  CUSTOMERS = 'customers',
  USERS = 'users',
  EVENTS = 'events',
  GROUPS = 'groups',
  MARGINS = 'margins',
  MARKETS = 'markets',
  SPORTS = 'sports',
  TOURNAMENTS = 'tournaments',
  TYPES = 'types',
  PLAYERS = 'players',
  SOURCES = 'sources',
  LANGUAGES = 'languages',
  MATCH_TYPES = 'matchTypes',
  MATCH_PHASES = 'matchPhases',
  TAGS = 'tags',
  DELAYS = 'delays',
  CROSS_MAPPINGS = 'crossMappings',
  COMMENTS = 'comments',
  OUTCOMECATEGORYSOURCEMAPPINGS = 'outcomeCategorySourceMappings',
  LOGOS = 'logos',
  MAXODDS = 'maxOdds',
}

export interface EntitiesState {
  [Entities.CATEGORIES]: Entity<OutcomeCategory>;
  [Entities.COMPETITORS]: Entity<Competitor>;
  [Entities.COUNTRIES]: Entity<Country>;
  [Entities.CURRENCIES]: Entity<Currency>;
  [Entities.CUSTOMERS]: Entity<Customer>;
  betPayMax: Entity<BetPayMax>;
  [Entities.OUTCOMECATEGORYSOURCEMAPPINGS]: Entity<OutcomeCategorySourceMapping>;
  customerTokens: Entity<CustomerToken>;
  [Entities.USERS]: EntityWithStringId<User> & WithPaginationToken;
  [Entities.EVENTS]: Entity<Event>;
  eventFavorites: Entity<EventFavoriteMapping>;
  eventsOdds: Entity<SportEventOdds>;
  eventsOddsReturns: Entity<void>;
  eventsMappings: Entity<Dictionary<EventMapping>>;
  eventsResults: Entity<Array<SportEventResult>>;
  eventStats: Entity<SportEventStats>;
  eventSuspends: Entity<Dictionary<EventSuspend>>;
  [Entities.GROUPS]: Entity<Group>;
  [Entities.MARGINS]: Entity<Margin>;
  matchTypes: Entity<MatchType>;
  [Entities.MARKETS]: Entity<OutcomeMarket>;
  [Entities.SPORTS]: Entity<Sport>;
  [Entities.TOURNAMENTS]: Entity<Tournament>;
  [Entities.TYPES]: Entity<OutcomeType> & {
    byCategory: Dictionary<Dictionary<OutcomeType>>;
  };
  [Entities.PLAYERS]: Entity<Player>;
  [Entities.SOURCES]: EntityWithStringId<Source>;
  exclusions: Entity<Exclusion>;
  categoryExclusions: Entity<CategoryExclusion>;
  categoryCashoutBlocks: Entity<CategoryCashoutBlock>;
  languages: Entity<Language>;
  [Entities.MATCH_PHASES]: Entity<MatchPhase>;
  tags: Entity<Tag>;
  comments: Entity<Comment>;
  commentsByEntity: Omit<Entity<CommentWithEntityView>, 'sortedIds'> & {
    sortedIds: Record<string, number[]>;
  };
  [Entities.CROSS_MAPPINGS]: Entity<OutcomeTypeCrossMappingWithOutcomeTypes>;
  tagsByCustomer: Entity<EntityTag & { id: number }>;
  eventLinkedIds: Entity<EventLinkedIds>;
  delays: Entity<Delay>;
  logos: Entity<Logo>;
  search: {
    fetchItems: RequestState;
    sports: {
      hasMore: boolean;
      items: Sport[];
    };
    sportGroups: {
      hasMore: boolean;
      items: Group[];
    };
    tournaments: {
      hasMore: boolean;
      items: Tournament[];
    };
  };
  maxOdds: Entity<MaxOdd>;
}

export interface FetchItemsDefaultData {
  items: [{ id: string }];
  hasMore: boolean;
}

export interface FetchItemDefaultData {
  id: string;
}

export interface CreateDefaultData {
  id: string;
}

export interface CreateListDefaultData<T> {
  items: (T & { id: string })[];
}

export interface UpdateDefaultData {
  id: string;
}

export interface DeleteDefaultData {
  categoryId: string;
}

export interface EntitiesErrorType {
  message?: string;
}

export interface SideEffects {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

/*
P - Payload Type
D - Returned Date Type
 */
// TODO resolve types
interface EntitiesAction<P> {
  (
    payload?: P & { sideEffects?: SideEffects },
  ): PayloadAction<P & { sideEffects?: SideEffects }>;
}
// type EntitiesAction<P, D> = any;
export type PaginatedGetRequest<T> = T & {
  withPagination?: boolean;
  onlyEntries?: boolean;
};

export interface EntitiesActions {
  /*
  Categories
   */
  categoriesFetchItems: EntitiesAction<
    PaginatedGetRequest<CategoriesApiGetCategoriesRequest>
  >;
  categoriesFetchItemsSuccess: EntitiesAction<OutcomeCategoryList>;
  categoriesFetchItemsError: EntitiesAction<EntitiesErrorType>;
  categoriesFetchItem: EntitiesAction<CategoriesApiGetCategoryRequest>;
  categoriesFetchItemSuccess: EntitiesAction<OutcomeCategory>;
  categoriesFetchItemError: EntitiesAction<EntitiesErrorType>;
  categoriesCreate: EntitiesAction<CategoriesApiCreateCategoryRequest>;
  categoriesCreateSuccess: EntitiesAction<OutcomeCategory>;
  categoriesCreateError: EntitiesAction<EntitiesErrorType>;
  categoriesCreateReset: EntitiesAction<void>;
  categoriesUpdate: EntitiesAction<CategoriesApiUpdateCategoryRequest>;
  categoriesUpdateSuccess: EntitiesAction<OutcomeCategory>;
  categoriesUpdateError: EntitiesAction<EntitiesErrorType>;
  categoriesDelete: EntitiesAction<CategoriesApiDeleteCategoryRequest>;
  categoriesDeleteSuccess: EntitiesAction<void>;
  categoriesDeleteError: EntitiesAction<EntitiesErrorType>;
  categoriesReset: EntitiesAction<void>;
  /*
  Competitors
   */
  competitorsFetchItems: EntitiesAction<
    PaginatedGetRequest<CompetitorsApiGetCompetitorsRequest>
  >;
  competitorsFetchItemsSuccess: EntitiesAction<CompetitorList>;
  competitorsFetchItemsError: EntitiesAction<EntitiesErrorType>;
  competitorsFetchItem: EntitiesAction<CompetitorsApiGetCompetitorRequest>;
  competitorsFetchItemSuccess: EntitiesAction<Competitor>;
  competitorsFetchItemError: EntitiesAction<EntitiesErrorType>;
  competitorsCreate: EntitiesAction<CompetitorsApiCreateCompetitorRequest>;
  competitorsCreateSuccess: EntitiesAction<Competitor>;
  competitorsCreateError: EntitiesAction<EntitiesErrorType>;
  competitorsCreateReset: EntitiesAction<void>;
  competitorsUpdate: EntitiesAction<CompetitorsApiUpdateCompetitorRequest>;
  competitorsUpdateSuccess: EntitiesAction<Competitor>;
  competitorsUpdateError: EntitiesAction<EntitiesErrorType>;
  competitorsDelete: EntitiesAction<CompetitorsApiDeleteCompetitorRequest>;
  competitorsDeleteSuccess: EntitiesAction<void>;
  competitorsDeleteError: EntitiesAction<EntitiesErrorType>;
  competitorsReset: EntitiesAction<void>;
  /*
  Countries
   */
  countriesFetchItems: EntitiesAction<
    PaginatedGetRequest<CountriesApiGetCountriesRequest>
  >;
  countriesFetchItemsSuccess: EntitiesAction<CountryList>;
  countriesFetchItemsError: EntitiesAction<EntitiesErrorType>;
  countriesFetchItem: EntitiesAction<CountriesApiGetCountryRequest>;
  countriesFetchItemSuccess: EntitiesAction<Country>;
  countriesFetchItemError: EntitiesAction<EntitiesErrorType>;
  countriesCreate: EntitiesAction<CountriesApiCreateCountryRequest>;
  countriesCreateSuccess: EntitiesAction<Country>;
  countriesCreateError: EntitiesAction<EntitiesErrorType>;
  countriesCreateReset: EntitiesAction<void>;
  countriesUpdate: EntitiesAction<CountriesApiUpdateCountryRequest>;
  countriesUpdateSuccess: EntitiesAction<Country>;
  countriesUpdateError: EntitiesAction<EntitiesErrorType>;
  countriesDelete: EntitiesAction<CountriesApiDeleteCountryRequest>;
  countriesDeleteSuccess: EntitiesAction<void>;
  countriesDeleteError: EntitiesAction<EntitiesErrorType>;
  countriesReset: EntitiesAction<void>;
  /*
  Currencies
   */
  currenciesFetchItems: EntitiesAction<
    PaginatedGetRequest<CurrencyApiGetCurrenciesRequest>
  >;
  currenciesFetchItemsSuccess: EntitiesAction<CurrencyList>;
  currenciesFetchItemsError: EntitiesAction<EntitiesErrorType>;
  currenciesFetchItem: EntitiesAction<CurrencyApiGetCurrencyRequest>;
  currenciesFetchItemSuccess: EntitiesAction<Currency>;
  currenciesFetchItemError: EntitiesAction<EntitiesErrorType>;
  currenciesCreate: EntitiesAction<CurrencyApiCreateCurrencyRequest>;
  currenciesCreateSuccess: EntitiesAction<Currency>;
  currenciesCreateError: EntitiesAction<EntitiesErrorType>;
  currenciesCreateReset: EntitiesAction<void>;
  currenciesUpdate: EntitiesAction<CurrencyApiUpdateCurrencyRequest>;
  currenciesUpdateSuccess: EntitiesAction<Currency>;
  currenciesUpdateError: EntitiesAction<EntitiesErrorType>;
  currenciesDelete: EntitiesAction<CurrencyApiDeleteCurrencyRequest>;
  currenciesDeleteSuccess: EntitiesAction<void>;
  currenciesDeleteError: EntitiesAction<EntitiesErrorType>;
  currenciesReset: EntitiesAction<void>;
  /*
  Customers
   */
  customersFetchItems: EntitiesAction<
    PaginatedGetRequest<CustomersApiGetCustomersRequest>
  >;
  customersFetchItemsSuccess: EntitiesAction<CustomerList>;
  customersFetchItemsError: EntitiesAction<EntitiesErrorType>;
  customersFetchItem: EntitiesAction<CustomersApiGetCustomerRequest>;
  customersFetchItemSuccess: EntitiesAction<Customer>;
  customersFetchItemError: EntitiesAction<EntitiesErrorType>;
  customersCreate: EntitiesAction<CustomersApiCreateCustomerRequest>;
  customersCreateSuccess: EntitiesAction<Customer>;
  customersCreateError: EntitiesAction<EntitiesErrorType>;
  customersCreateReset: EntitiesAction<void>;
  customersUpdate: EntitiesAction<CustomersApiUpdateCustomerRequest>;
  customersUpdateSuccess: EntitiesAction<Customer>;
  customersUpdateError: EntitiesAction<EntitiesErrorType>;
  customersDelete: EntitiesAction<CustomersApiDeleteCustomerRequest>;
  customersDeleteSuccess: EntitiesAction<void>;
  customersDeleteError: EntitiesAction<EntitiesErrorType>;
  customersReset: EntitiesAction<void>;
  /*
  BetPayMax
  */
  betPayMaxFetchItems: EntitiesAction<
    PaginatedGetRequest<CustomersApiGetBetPayMaxListRequest>
  >;
  betPayMaxFetchItemsSuccess: EntitiesAction<BetPayMaxList>;
  betPayMaxFetchItemsError: EntitiesAction<EntitiesErrorType>;
  betPayMaxFetchItem: EntitiesAction<CustomersApiGetBetPayMaxRequest>;
  betPayMaxFetchItemSuccess: EntitiesAction<BetPayMax>;
  betPayMaxFetchItemError: EntitiesAction<EntitiesErrorType>;
  betPayMaxCreate: EntitiesAction<CustomersApiCreateBetPayMaxRequest>;
  betPayMaxCreateSuccess: EntitiesAction<BetPayMax>;
  betPayMaxCreateError: EntitiesAction<EntitiesErrorType>;
  betPayMaxCreateReset: EntitiesAction<void>;
  betPayMaxCreateList: EntitiesAction<CustomersApiCreateBetPayMaxListRequest>;
  betPayMaxCreateListSuccess: EntitiesAction<BetPayMaxListCreated>;
  betPayMaxCreateListError: EntitiesAction<EntitiesErrorType>;
  betPayMaxCreateListReset: EntitiesAction<void>;
  betPayMaxUpdate: EntitiesAction<CustomersApiUpdateBetPayMaxRequest>;
  betPayMaxUpdateSuccess: EntitiesAction<BetPayMax>;
  betPayMaxUpdateError: EntitiesAction<EntitiesErrorType>;
  betPayMaxDelete: EntitiesAction<CustomersApiDeleteBetPayMaxRequest>;
  betPayMaxDeleteSuccess: EntitiesAction<void>;
  betPayMaxDeleteError: EntitiesAction<EntitiesErrorType>;
  betPayMaxByEventFetchItems: EntitiesAction<
    PaginatedGetRequest<CustomersApiGetBetPayMaxEventListRequest>
  >;
  betPayMaxReset: EntitiesAction<void>;
  /*
  Users
   */
  usersFetchItems: EntitiesAction<PaginatedGetRequest<UsersApiGetUsersRequest>>;
  usersFetchItemsSuccess: EntitiesAction<UserListWrapper>;
  usersFetchItemsError: EntitiesAction<EntitiesErrorType>;
  usersFetchItem: EntitiesAction<UsersApiGetUserRequest>;
  usersFetchItemSuccess: EntitiesAction<User>;
  usersFetchItemError: EntitiesAction<EntitiesErrorType>;
  usersCreate: EntitiesAction<UsersApiCreateUserRequest>;
  usersCreateSuccess: EntitiesAction<User>;
  usersCreateError: EntitiesAction<EntitiesErrorType>;
  usersCreateReset: EntitiesAction<void>;
  usersUpdate: EntitiesAction<UsersApiUpdateUserRequest>;
  usersUpdateSuccess: EntitiesAction<User>;
  usersUpdateError: EntitiesAction<EntitiesErrorType>;
  usersDelete: EntitiesAction<UsersApiDeleteUserRequest>;
  usersDeleteSuccess: EntitiesAction<void>;
  usersDeleteError: EntitiesAction<EntitiesErrorType>;
  usersReset: EntitiesAction<void>;
  /*
  Events
   */
  eventsFetchItems: EntitiesAction<
    PaginatedGetRequest<EventsApiGetEventsRequest>
  >;
  eventsFetchItemsSuccess: EntitiesAction<EventList>;
  eventsFetchItemsError: EntitiesAction<EntitiesErrorType>;
  eventsFetchItem: EntitiesAction<
    EventsApiGetEventRequest & { ignoreLoading?: boolean }
  >;
  eventsFetchItemSuccess: EntitiesAction<Event>;
  eventsFetchItemError: EntitiesAction<EntitiesErrorType>;
  eventsCreate: EntitiesAction<EventsApiCreateEventRequest>;
  eventsCreateSuccess: EntitiesAction<Event>;
  eventsCreateError: EntitiesAction<EntitiesErrorType>;
  eventsCreateReset: EntitiesAction<void>;
  eventsUpdate: EntitiesAction<
    EventsApiUpdateEventRequest & { withDeletion?: boolean }
  >;
  eventsUpdateSuccess: EntitiesAction<Event>;
  eventsUpdateError: EntitiesAction<EntitiesErrorType>;
  eventsUpdateReset: EntitiesAction<void>;
  eventsDelete: EntitiesAction<EventsApiDeleteEventRequest>;
  eventsDeleteSuccess: EntitiesAction<void>;
  eventsDeleteError: EntitiesAction<EntitiesErrorType>;
  eventsReset: EntitiesAction<void>;
  /*
  EventsOdds
   */
  eventsOddsFetchItem: EntitiesAction<EventsApiGetEventOddsRequest>;
  eventsFetchOddsItemSuccess: EntitiesAction<{
    odds: SportEventOdds;
    eventId: number;
  }>;
  eventsOddsFetchItemError: EntitiesAction<EntitiesErrorType>;
  eventsOddsUpdate: EntitiesAction<EventsApiRefreshEventOddsRequest>;
  eventsOddsUpdateSuccess: EntitiesAction<void>;
  eventsOddsUpdateError: EntitiesAction<EntitiesErrorType>;
  eventsOddsReturnsCreate: EntitiesAction<EventsApiSettleReturnProbabilitiesRequest>;
  eventsOddsByCustomerFetchItem: EntitiesAction<EventsApiGetCustomerEventOddsRequest>;
  eventsOddsByCustomerItemSuccess: EntitiesAction<{
    odds: CustomerSportEventOdds;
    eventId: number;
  }>;
  eventsOddsByCustomerFetchItemError: EntitiesAction<EntitiesErrorType>;
  /*
  EventsMappings
   */
  eventsMappingsFetchItem: EntitiesAction<EventsApiGetEventMappingsRequest>;
  eventsMappingsFetchItemSuccess: EntitiesAction<{
    mappings: Array<EventMapping>;
    eventId: number;
  }>;
  eventsMappingsFetchItemError: EntitiesAction<EntitiesErrorType>;
  eventsMappingsCreate: EntitiesAction<EventsApiCreateEventMappingRequest>;
  eventsMappingsCreateSuccess: EntitiesAction<{
    mappings: EventMapping;
    eventId: number;
  }>;
  eventsMappingsCreateError: EntitiesAction<EntitiesErrorType>;
  eventsMappingsCreateReset: EntitiesAction<void>;
  eventsMappingsUpdate: EntitiesAction<EventsApiUpdateEventMappingRequest>;
  eventsMappingsUpdateSuccess: EntitiesAction<{
    mappings: EventMapping;
    eventId: number;
  }>;
  eventsMappingsUpdateError: EntitiesAction<EntitiesErrorType>;
  /*
  EventsResults
   */
  eventsResultsFetchItem: EntitiesAction<EventsApiGetEventResultsRequest>;
  eventsResultsFetchItemSuccess: EntitiesAction<{
    results: Array<SportEventResult>;
    eventId: number;
  }>;
  eventsResultsFetchItemError: EntitiesAction<EntitiesErrorType>;
  eventsResultsUpdate: EntitiesAction<EventsApiUpsertEventResultsRequest>;
  eventsResultsUpdateSuccess: EntitiesAction<{
    results: Array<SportEventResult>;
    eventId: number;
  }>;
  eventsResultsUpdateError: EntitiesAction<EntitiesErrorType>;
  /*
  EventSuspends
   */
  eventSuspendsFetchItem: EntitiesAction<EventsApiGetEventSuspendsRequest>;
  eventSuspendsFetchItemSuccess: EntitiesAction<{
    suspends: Array<EventSuspend>;
    eventId: number;
  }>;
  eventSuspendsFetchItemError: EntitiesAction<EntitiesErrorType>;
  eventSuspendsUpdate: EntitiesAction<EventsApiUpsertEventSuspendsRequest>;
  eventSuspendsUpdateSuccess: EntitiesAction<{
    suspends: Array<EventSuspend>;
    eventId: number;
  }>;
  eventSuspendsUpdateError: EntitiesAction<EntitiesErrorType>;
  eventSuspendsCreate: EntitiesAction<EventsApiSuspendProbabilitiesRequest>;
  eventSuspendsCreateSuccess: EntitiesAction<void>;
  eventSuspendsCreateError: EntitiesAction<EntitiesErrorType>;
  /*
  EventStatus
  */
  eventStatusByCustomerFetchItem: EntitiesAction<EventsApiGetEventStatusByCustomerRequest>;
  eventStatusByCustomerFetchItemSuccess: EntitiesAction<{
    status: EventStatus;
    eventId: number;
  }>;
  eventStatusByCustomerFetchItemError: EntitiesAction<EntitiesErrorType>;
  eventStatusByCustomerUpdate: EntitiesAction<EventsApiUpsertEventStatusByCustomerRequest>;
  eventStatusByCustomerUpdateSuccess: EntitiesAction<{
    status: EventStatus;
    eventId: number;
  }>;
  eventStatusByCustomerUpdateError: EntitiesAction<EntitiesErrorType>;
  /*
  EventStats
   */
  eventStatsFetchItem: EntitiesAction<EventsApiGetEventStatsRequest>;
  eventStatsFetchItemSuccess: EntitiesAction<{
    results: SportEventStats;
    eventId: number;
  }>;
  eventStatsFetchItemError: EntitiesAction<EntitiesErrorType>;
  /*
  UsersFavorites
   */
  eventFavoritesFetchItems: EntitiesAction<UsersApiGetEventFavoriteListRequest>;
  eventFavoritesFetchItemsSuccess: EntitiesAction<EventFavoriteMappingListResponse>;
  eventFavoritesFetchItemsError: EntitiesAction<EntitiesErrorType>;
  eventFavoritesCreate: EntitiesAction<UsersApiCreateEventFavoriteMappingRequest>;
  eventFavoritesCreateSuccess: EntitiesAction<EventFavoriteMapping>;
  eventFavoritesCreateError: EntitiesAction<EntitiesErrorType>;
  eventFavoritesDelete: EntitiesAction<
    UsersApiDeleteEventFavoriteMappingRequest & { deleteEvent?: boolean }
  >;
  eventFavoritesDeleteSuccess: EntitiesAction<void>;
  eventFavoritesDeleteError: EntitiesAction<EntitiesErrorType>;
  eventFavoritesDeleteList: EntitiesAction<UsersApiDeleteAllEventFavoriteMappingRequest>;
  eventFavoritesDeleteListSuccess: EntitiesAction<void>;
  eventFavoritesDeleteListError: EntitiesAction<EntitiesErrorType>;
  /*
  Groups
   */
  groupsFetchItems: EntitiesAction<
    PaginatedGetRequest<GroupsApiGetGroupsRequest>
  >;
  groupsFetchItemsSuccess: EntitiesAction<GroupList>;
  groupsFetchItemsError: EntitiesAction<EntitiesErrorType>;
  groupsFetchItem: EntitiesAction<GroupsApiGetGroupRequest>;
  groupsFetchItemSuccess: EntitiesAction<Group>;
  groupsFetchItemError: EntitiesAction<EntitiesErrorType>;
  groupsCreate: EntitiesAction<GroupsApiCreateGroupRequest>;
  groupsCreateSuccess: EntitiesAction<Group>;
  groupsCreateError: EntitiesAction<EntitiesErrorType>;
  groupsCreateReset: EntitiesAction<void>;
  groupsUpdate: EntitiesAction<GroupsApiUpdateGroupRequest>;
  groupsUpdateSuccess: EntitiesAction<Group>;
  groupsUpdateError: EntitiesAction<EntitiesErrorType>;
  groupsDelete: EntitiesAction<GroupsApiDeleteGroupRequest>;
  groupsDeleteSuccess: EntitiesAction<void>;
  groupsDeleteError: EntitiesAction<EntitiesErrorType>;
  groupsReset: EntitiesAction<void>;
  /*
  Margins
   */
  marginsFetchItems: EntitiesAction<
    PaginatedGetRequest<MarginsApiGetMarginsRequest>
  >;
  marginsFetchItemsSuccess: EntitiesAction<MarginList>;
  marginsFetchItemsError: EntitiesAction<EntitiesErrorType>;
  marginsFetchItem: EntitiesAction<MarginsApiGetMarginRequest>;
  marginsFetchItemSuccess: EntitiesAction<Margin>;
  marginsFetchItemError: EntitiesAction<EntitiesErrorType>;
  marginsCreate: EntitiesAction<MarginsApiCreateMarginRequest>;
  marginsCreateSuccess: EntitiesAction<Margin>;
  marginsCreateError: EntitiesAction<EntitiesErrorType>;
  marginsCreateReset: EntitiesAction<void>;
  marginsCreateList: EntitiesAction<MarginsApiCreateMarginListRequest>;
  marginsCreateListSuccess: EntitiesAction<MarginListCreated>;
  marginsCreateListError: EntitiesAction<EntitiesErrorType>;
  marginsCreateListReset: EntitiesAction<void>;
  marginsUpdate: EntitiesAction<MarginsApiUpdateMarginRequest>;
  marginsUpdateSuccess: EntitiesAction<Margin>;
  marginsUpdateError: EntitiesAction<EntitiesErrorType>;
  marginsDelete: EntitiesAction<MarginsApiDeleteMarginRequest>;
  marginsDeleteSuccess: EntitiesAction<void>;
  marginsDeleteError: EntitiesAction<EntitiesErrorType>;
  marginsByEventFetchItems: EntitiesAction<
    PaginatedGetRequest<MarginsApiGetMarginEventListRequest>
  >;
  marginsReset: EntitiesAction<void>;
  /*
  MatchType
   */
  matchTypesFetchItems: EntitiesAction<
    PaginatedGetRequest<MatchTypesApiGetMatchTypesRequest>
  >;
  matchTypesFetchItemsSuccess: EntitiesAction<MatchTypeList>;
  matchTypesFetchItemsError: EntitiesAction<EntitiesErrorType>;
  matchTypesFetchItem: EntitiesAction<MatchTypesApiGetMatchTypeRequest>;
  matchTypesFetchItemSuccess: EntitiesAction<MatchType>;
  matchTypesFetchItemError: EntitiesAction<EntitiesErrorType>;
  matchTypesCreate: EntitiesAction<MatchTypesApiCreateMatchTypeRequest>;
  matchTypesCreateSuccess: EntitiesAction<MatchType>;
  matchTypesCreateError: EntitiesAction<EntitiesErrorType>;
  matchTypesCreateReset: EntitiesAction<void>;
  matchTypesUpdate: EntitiesAction<MatchTypesApiUpdateMatchTypeRequest>;
  matchTypesUpdateSuccess: EntitiesAction<MatchType>;
  matchTypesUpdateError: EntitiesAction<EntitiesErrorType>;
  matchTypesDelete: EntitiesAction<MatchTypesApiDeleteMatchTypeRequest>;
  matchTypesDeleteSuccess: EntitiesAction<void>;
  matchTypesDeleteError: EntitiesAction<EntitiesErrorType>;
  matchTypesReset: EntitiesAction<void>;
  /*
  Markets
   */
  marketsFetchItems: EntitiesAction<
    PaginatedGetRequest<MarketsApiGetMarketsRequest>
  >;
  marketsFetchItemsSuccess: EntitiesAction<OutcomeMarketList>;
  marketsFetchItemsError: EntitiesAction<EntitiesErrorType>;
  marketsFetchItem: EntitiesAction<MarketsApiGetMarketRequest>;
  marketsFetchItemSuccess: EntitiesAction<OutcomeMarket>;
  marketsFetchItemError: EntitiesAction<EntitiesErrorType>;
  marketsCreate: EntitiesAction<MarketsApiCreateMarketRequest>;
  marketsCreateSuccess: EntitiesAction<OutcomeMarket>;
  marketsCreateError: EntitiesAction<EntitiesErrorType>;
  marketsCreateReset: EntitiesAction<void>;
  marketsUpdate: EntitiesAction<MarketsApiUpdateMarketRequest>;
  marketsUpdateSuccess: EntitiesAction<OutcomeMarket>;
  marketsUpdateError: EntitiesAction<EntitiesErrorType>;
  marketsDelete: EntitiesAction<MarketsApiDeleteMarketRequest>;
  marketsDeleteSuccess: EntitiesAction<void>;
  marketsDeleteError: EntitiesAction<EntitiesErrorType>;
  marketsReset: EntitiesAction<void>;
  /*
  Sports
   */
  sportsFetchItems: EntitiesAction<
    PaginatedGetRequest<SportsApiGetSportsRequest>
  >;
  sportsFetchItemsSuccess: EntitiesAction<SportList>;
  sportsFetchItemsError: EntitiesAction<EntitiesErrorType>;
  sportsFetchItem: EntitiesAction<SportsApiGetSportRequest>;
  sportsFetchItemSuccess: EntitiesAction<Sport>;
  sportsFetchItemError: EntitiesAction<EntitiesErrorType>;
  sportsCreate: EntitiesAction<SportsApiCreateSportRequest>;
  sportsCreateSuccess: EntitiesAction<Sport>;
  sportsCreateError: EntitiesAction<EntitiesErrorType>;
  sportsCreateReset: EntitiesAction<void>;
  sportsUpdate: EntitiesAction<SportsApiUpdateSportRequest>;
  sportsUpdateSuccess: EntitiesAction<Sport>;
  sportsUpdateError: EntitiesAction<EntitiesErrorType>;
  sportsUpdateReset: EntitiesAction<void>;
  sportsDelete: EntitiesAction<SportsApiDeleteSportRequest>;
  sportsDeleteSuccess: EntitiesAction<void>;
  sportsDeleteError: EntitiesAction<EntitiesErrorType>;
  sportsReset: EntitiesAction<void>;
  /*
  Tournaments
   */
  tournamentsFetchItems: EntitiesAction<
    PaginatedGetRequest<TournamentsApiGetTournamentsRequest>
  >;
  tournamentsFetchItemsSuccess: EntitiesAction<TournamentList>;
  tournamentsFetchItemsError: EntitiesAction<EntitiesErrorType>;
  tournamentsFetchItem: EntitiesAction<TournamentsApiGetTournamentRequest>;
  tournamentsFetchItemSuccess: EntitiesAction<Tournament>;
  tournamentsFetchItemError: EntitiesAction<EntitiesErrorType>;
  tournamentsCreate: EntitiesAction<TournamentsApiCreateTournamentRequest>;
  tournamentsCreateSuccess: EntitiesAction<Tournament>;
  tournamentsCreateError: EntitiesAction<EntitiesErrorType>;
  tournamentsCreateReset: EntitiesAction<void>;
  tournamentsUpdate: EntitiesAction<TournamentsApiUpdateTournamentRequest>;
  tournamentsUpdateSuccess: EntitiesAction<Tournament>;
  tournamentsUpdateError: EntitiesAction<EntitiesErrorType>;
  tournamentsDelete: EntitiesAction<TournamentsApiDeleteTournamentRequest>;
  tournamentsDeleteSuccess: EntitiesAction<void>;
  tournamentsDeleteError: EntitiesAction<EntitiesErrorType>;
  tournamentsReset: EntitiesAction<void>;
  /*
  Types
   */
  typesFetchItems: EntitiesAction<PaginatedGetRequest<TypesApiGetTypesRequest>>;
  typesFetchItemsSuccess: EntitiesAction<OutcomeTypeList>;
  typesFetchItemsError: EntitiesAction<EntitiesErrorType>;
  typesFetchItem: EntitiesAction<TypesApiGetTypeRequest>;
  typesFetchItemSuccess: EntitiesAction<OutcomeType>;
  typesFetchItemError: EntitiesAction<EntitiesErrorType>;
  typesCreate: EntitiesAction<TypesApiCreateTypeRequest>;
  typesCreateSuccess: EntitiesAction<OutcomeType>;
  typesCreateError: EntitiesAction<EntitiesErrorType>;
  typesCreateReset: EntitiesAction<void>;
  typesUpdate: EntitiesAction<TypesApiUpdateTypeRequest>;
  typesUpdateSuccess: EntitiesAction<OutcomeType>;
  typesUpdateError: EntitiesAction<EntitiesErrorType>;
  typesDelete: EntitiesAction<TypesApiDeleteTypeRequest>;
  typesDeleteSuccess: EntitiesAction<void>;
  typesDeleteError: EntitiesAction<EntitiesErrorType>;
  typesReset: EntitiesAction<void>;
  /*
  Players
   */
  playersFetchItems: EntitiesAction<
    PaginatedGetRequest<PlayersApiGetPlayersRequest>
  >;
  playersFetchItemsSuccess: EntitiesAction<PlayerList>;
  playersFetchItemsError: EntitiesAction<EntitiesErrorType>;
  playersFetchItem: EntitiesAction<PlayersApiGetPlayerRequest>;
  playersFetchItemSuccess: EntitiesAction<Player>;
  playersFetchItemError: EntitiesAction<EntitiesErrorType>;
  playersCreate: EntitiesAction<PlayersApiCreatePlayerRequest>;
  playersCreateSuccess: EntitiesAction<Player>;
  playersCreateError: EntitiesAction<EntitiesErrorType>;
  playersCreateReset: EntitiesAction<void>;
  playersUpdate: EntitiesAction<PlayersApiUpdatePlayerRequest>;
  playersUpdateSuccess: EntitiesAction<Player>;
  playersUpdateError: EntitiesAction<EntitiesErrorType>;
  playersDelete: EntitiesAction<PlayersApiDeletePlayerRequest>;
  playersDeleteSuccess: EntitiesAction<void>;
  playersDeleteError: EntitiesAction<EntitiesErrorType>;
  playersReset: EntitiesAction<void>;
  /*
  Sources
   */
  sourcesFetchItems: EntitiesAction<undefined>;
  sourcesFetchItemsSuccess: EntitiesAction<SourceList>;
  sourcesFetchItemsError: EntitiesAction<EntitiesErrorType>;
  sourcesReset: EntitiesAction<void>;
  /*
  Exclusions
  */
  exclusionsFetchItems: EntitiesAction<ExclusionsApiGetExclusionsByCustomerRequest>;
  exclusionsFetchItemsSuccess: EntitiesAction<Exclusion[]>;
  exclusionsFetchItemsError: EntitiesAction<EntitiesErrorType>;
  exclusionsUpdate: EntitiesAction<ExclusionsApiUpsertExclusionByCustomerRequest>;
  exclusionsUpdateSuccess: EntitiesAction<Exclusion>;
  exclusionsUpdateError: EntitiesAction<EntitiesErrorType>;
  exclusionsReset: EntitiesAction<void>;
  /*
  Category exclusion
  */
  categoryExclusionsFetchItems: EntitiesAction<ExclusionsApiGetCategoryExclusionsByCustomerRequest>;
  categoryExclusionsFetchItem: EntitiesAction<ExclusionsApiGetCategoryExclusionByCustomerRequest>;
  categoryExclusionsUpdate: EntitiesAction<ExclusionsApiUpsertCategoryExclusionByCustomerRequest>;
  categoryExclusionsDelete: EntitiesAction<ExclusionsApiDeleteCategoryExclusionByCustomerRequest>;
  categoryExclusionsReset: EntitiesAction<void>;
  /*
  Category cashoutBlock
  */
  categoryCashoutBlocksFetchItems: EntitiesAction<CashoutBlocksApiGetCategoryCashoutBlocksByCustomerRequest>;
  categoryCashoutBlocksFetchItem: EntitiesAction<CashoutBlocksApiGetCategoryCashoutBlockByCustomerRequest>;
  categoryCashoutBlocksUpdate: EntitiesAction<CashoutBlocksApiUpsertCategoryCashoutBlockByCustomerRequest>;
  categoryCashoutBlocksDelete: EntitiesAction<CashoutBlocksApiDeleteCategoryCashoutBlockByCustomerRequest>;
  categoryCashoutBlocksReset: EntitiesAction<void>;
  /*
  Language
  */
  languagesFetchItems: EntitiesAction<void>;
  languagesFetchItemsSuccess: EntitiesAction<LanguageList>;
  languagesFetchItemsError: EntitiesAction<EntitiesErrorType>;
  languagesFetchItem: EntitiesAction<LanguagesApiGetLanguageRequest>;
  languagesFetchItemSuccess: EntitiesAction<Language>;
  languagesFetchItemError: EntitiesAction<EntitiesErrorType>;
  languagesCreate: EntitiesAction<LanguagesApiCreateLanguageRequest>;
  languagesCreateSuccess: EntitiesAction<Language>;
  languagesCreateError: EntitiesAction<EntitiesErrorType>;
  languagesCreateReset: EntitiesAction<void>;
  languagesUpdate: EntitiesAction<LanguagesApiUpdateLanguageRequest>;
  languagesUpdateSuccess: EntitiesAction<Language>;
  languagesUpdateError: EntitiesAction<EntitiesErrorType>;
  languagesDelete: EntitiesAction<LanguagesApiDeleteLanguageRequest>;
  languagesDeleteSuccess: EntitiesAction<void>;
  languagesDeleteError: EntitiesAction<EntitiesErrorType>;
  languagesReset: EntitiesAction<void>;
  /*
  MatchPhase
  */
  matchPhasesFetchItems: EntitiesAction<
    PaginatedGetRequest<MatchPhasesApiGetMatchPhasesRequest>
  >;
  matchPhasesFetchItemsSuccess: EntitiesAction<MatchPhaseList>;
  matchPhasesFetchItemsError: EntitiesAction<EntitiesErrorType>;
  matchPhasesFetchItem: EntitiesAction<MatchPhasesApiGetMatchPhaseRequest>;
  matchPhasesFetchItemSuccess: EntitiesAction<MatchPhase>;
  matchPhasesFetchItemError: EntitiesAction<EntitiesErrorType>;
  matchPhasesCreate: EntitiesAction<MatchPhasesApiCreateMatchPhaseRequest>;
  matchPhasesCreateSuccess: EntitiesAction<MatchPhase>;
  matchPhasesCreateError: EntitiesAction<EntitiesErrorType>;
  matchPhasesCreateReset: EntitiesAction<void>;
  matchPhasesUpdate: EntitiesAction<MatchPhasesApiUpdateMatchPhaseRequest>;
  matchPhasesUpdateSuccess: EntitiesAction<MatchPhase>;
  matchPhasesUpdateError: EntitiesAction<EntitiesErrorType>;
  matchPhasesDelete: EntitiesAction<MatchPhasesApiDeleteMatchPhaseRequest>;
  matchPhasesDeleteSuccess: EntitiesAction<void>;
  matchPhasesDeleteError: EntitiesAction<EntitiesErrorType>;
  matchPhasesReset: EntitiesAction<void>;
  /*
  Tags
  */
  tagsFetchItems: EntitiesAction<PaginatedGetRequest<TagsApiGetTagsRequest>>;
  tagsFetchItem: EntitiesAction<TagsApiGetTagRequest>;
  tagsCreate: EntitiesAction<TagsApiCreateTagRequest>;
  tagsCreateReset: EntitiesAction<void>;
  tagsUpdate: EntitiesAction<TagsApiUpdateTagRequest>;
  tagsUpdateReset: EntitiesAction<void>;
  tagsDelete: EntitiesAction<TagsApiDeleteTagRequest>;
  tagsReset: EntitiesAction<void>;
  /*
  Tags by customer
  */
  tagsByCustomerFetchItems: EntitiesAction<
    PaginatedGetRequest<TagsApiGetTagsByCustomerRequest>
  >;
  tagsByCustomerUpdate: EntitiesAction<TagsApiUpsertTagByCustomerRequest>;
  tagsByCustomerDelete: EntitiesAction<TagsApiDeleteCustomerTagRequest>;
  tagsByCustomerReset: EntitiesAction<void>;
  /*
  Comments
  */
  commentsFetchItems: EntitiesAction<
    PaginatedGetRequest<CommentsApiGetCommentsRequest>
  >;
  commentsFetchItem: EntitiesAction<CommentsApiGetCommentsRequest>;
  commentsCreate: EntitiesAction<CommentsApiCreateCommentRequest>;
  commentsCreateReset: EntitiesAction<void>;
  commentsUpdate: EntitiesAction<CommentsApiUpdateCommentRequest>;
  commentsUpdateReset: EntitiesAction<void>;
  commentsDelete: EntitiesAction<CommentsApiDeleteCommentRequest>;
  commentsReset: EntitiesAction<void>;
  /*
  Comments by entity
  */
  commentsByEntityFetchItems: EntitiesAction<
    PaginatedGetRequest<CommentsApiGetEntityCommentsRequest>
  >;
  commentsByEntityCreate: EntitiesAction<CommentsApiCreateEntityCommentRequest>;
  commentsByEntityDelete: EntitiesAction<CommentsApiDeleteEntityCommentRequest>;
  /*
  Event linked ids
  */
  eventLinkedIdsFetchItem: EntitiesAction<EventsApiGetEventLinkedIdsRequest>;
  /*
  Delays
  */
  delaysFetchItems: EntitiesAction<
    PaginatedGetRequest<DelaysApiGetDelaysRequest>
  >;
  delaysFetchItem: EntitiesAction<DelaysApiGetDelayRequest>;
  delaysCreate: EntitiesAction<DelaysApiCreateDelayRequest>;
  delaysCreateList: EntitiesAction<DelaysApiCreateDelayListRequest>;
  delaysUpdate: EntitiesAction<DelaysApiUpdateDelayRequest>;
  delaysDelete: EntitiesAction<DelaysApiDeleteDelayRequest>;
  delaysByEventFetchItems: EntitiesAction<
    PaginatedGetRequest<DelaysApiGetDelaysEventListRequest>
  >;
  delaysReset: EntitiesAction<void>;
  /** CrossMappings */
  crossMappingsFetchItems: EntitiesAction<TypesApiGetAllOutcomeTypeCrossMappingsRequest>;
  crossMappingsCreate: EntitiesAction<TypesApiCreateOutcomeTypeCrossMappingRequest>;
  crossMappingsUpdate: EntitiesAction<TypesApiUpdateOutcomeTypeCrossMappingRequest>;
  crossMappingsDelete: EntitiesAction<TypesApiDeleteOutcomeTypeCrossMappingRequest>;
  /**
   * Search
   */
  searchFetchItems: EntitiesAction<SearchApiGetSearchResultRequest>;
  searchReset: EntitiesAction<void>;
  /*
  outcomeCategorySourceMappings
   */
  outcomeCategorySourceMappingsFetchItems: EntitiesAction<
    PaginatedGetRequest<OutcomeCategorySourceMappingApiGetOutcomeCategorySourceMappingsRequest>
  >;
  outcomeCategorySourceMappingsFetchItemsSuccess: EntitiesAction<OutcomeCategorySourceMappingsList>;
  outcomeCategorySourceMappingsFetchItemsError: EntitiesAction<EntitiesErrorType>;
  outcomeCategorySourceMappingsFetchItem: EntitiesAction<OutcomeCategorySourceMappingApiGetOutcomeCategorySourceMappingRequest>;
  outcomeCategorySourceMappingsFetchItemSuccess: EntitiesAction<OutcomeCategorySourceMapping>;
  outcomeCategorySourceMappingsFetchItemError: EntitiesAction<EntitiesErrorType>;
  outcomeCategorySourceMappingsCreate: EntitiesAction<OutcomeCategorySourceMappingApiCreateOutcomeCategorySourceMappingRequest>;
  outcomeCategorySourceMappingsCreateSuccess: EntitiesAction<OutcomeCategorySourceMapping>;
  outcomeCategorySourceMappingsCreateError: EntitiesAction<EntitiesErrorType>;
  outcomeCategorySourceMappingsCreateReset: EntitiesAction<void>;
  outcomeCategorySourceMappingsCreateList: EntitiesAction<OutcomeCategorySourceMappingApiCreateOutcomeCategorySourceMappingListRequest>;
  outcomeCategorySourceMappingsCreateListSuccess: EntitiesAction<OutcomeCategorySourceMappingListCreated>;
  outcomeCategorySourceMappingsCreateListError: EntitiesAction<EntitiesErrorType>;
  outcomeCategorySourceMappingsCreateListReset: EntitiesAction<void>;
  outcomeCategorySourceMappingsUpdate: EntitiesAction<OutcomeCategorySourceMappingApiUpdateOutcomeCategorySourceMappingRequest>;
  outcomeCategorySourceMappingsUpdateSuccess: EntitiesAction<OutcomeCategorySourceMapping>;
  outcomeCategorySourceMappingsUpdateError: EntitiesAction<EntitiesErrorType>;
  outcomeCategorySourceMappingsDelete: EntitiesAction<OutcomeCategorySourceMappingApiDeleteOutcomeCategorySourceMappingRequest>;
  outcomeCategorySourceMappingsDeleteSuccess: EntitiesAction<void>;
  outcomeCategorySourceMappingsDeleteError: EntitiesAction<EntitiesErrorType>;
  outcomeCategorySourceMappingsReset: EntitiesAction<void>;
  /**
   * Logos
   */
  logosFetchItems: EntitiesAction<LogosApiGetLogosRequest>;
  logosCreateList: EntitiesAction<LogosApiInsertLogoRequest>;
  logosCreateListReset: EntitiesAction<void>;
  logosDelete: EntitiesAction<LogosApiDeleteLogosRequest>;
  logosReset: EntitiesAction<void>;
  /**
   * MaxOdds
   */
  maxOddsFetchItems: EntitiesAction<
    PaginatedGetRequest<MaxOddsApiGetMaxOddsRequest>
  >;
  maxOddsFetchItem: EntitiesAction<MaxOddsApiGetMaxOddRequest>;
  maxOddsCreateList: EntitiesAction<MaxOddsApiCreateMaxOddListRequest>;
  maxOddsUpdate: EntitiesAction<MaxOddsApiUpdateMaxOddRequest>;
  maxOddsDelete: EntitiesAction<MaxOddsApiDeleteMaxOddRequest>;
  maxOddsReset: EntitiesAction<void>;
}

export enum DirectoryEntitiesEnum {
  SPORTS = 'sports',
  GROUPS = 'groups',
  TOURNAMENTS = 'tournaments',
}
