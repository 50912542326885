import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getEventTitle } from 'app/pages/Events/utils';
import {
  EventResultForm,
  EventResultHeader,
  EventResultInfo,
  EventResults,
} from 'app/pages/Results/components';

import { LoadingIndicator } from 'app/components/ui';

import { useEvent } from 'app/providers/EntitiesProvider/events';

import * as S from './ResultedEvent.styles';

export const ResultedEvent: FC = () => {
  const { t } = useTranslation();
  const { event, loading, error } = useEvent();

  return (
    <>
      {!event || loading ? (
        <S.EventLoading>
          <LoadingIndicator />
        </S.EventLoading>
      ) : error ? (
        <S.EventError>{t('event error')}</S.EventError>
      ) : (
        <>
          <Helmet>
            <title>{`${t('event')}: ${getEventTitle(event)}`}</title>
            <meta name="description" content="Description of Events" />
          </Helmet>
          <S.EventContainer>
            <EventResultHeader event={event} />
            <EventResultInfo event={event} />
            <EventResultForm event={event} />
            <EventResults event={event} />
          </S.EventContainer>
        </>
      )}
    </>
  );
};
