import { exclusionsApi } from 'app/api';

const api = {
  endpoint: exclusionsApi,
  methods: {
    fetchItems: 'getExclusionsByCustomer',
    update: 'upsertExclusionByCustomer',
  },
};

export default api;
