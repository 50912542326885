import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { UserGroupEnum } from 'sportsbook-openapi-react';

import { TestIds } from 'types/testIds.types';

import { RoleGuards } from 'app/components/RoleGuards';
import { Tooltip } from 'app/components/ui';
import CreateIcon from 'assets/images/create.svg?react';
import DirectoryIcon from 'assets/images/directory.svg?react';
import LineIcon from 'assets/images/line.svg?react';
import LiveIcon from 'assets/images/live.svg?react';
import ResultsIcon from 'assets/images/results.svg?react';
import SourceIcon from 'assets/images/source.svg?react';
import TranslationsIcon from 'assets/images/translate.svg?react';
import UsersIcon from 'assets/images/users.svg?react';

import * as S from './Navigation.styles';

const navLinks = [
  {
    href: '/events/create',
    label: <CreateIcon />,
    translationKey: 'create',
    roles: [
      UserGroupEnum.ADMIN,
      UserGroupEnum.OPERATOR,
      UserGroupEnum.SUPERVISOR,
    ],
  },
  {
    href: '/dictionary',
    label: <DirectoryIcon />,
    translationKey: 'dictionary',
    roles: [
      UserGroupEnum.ADMIN,
      UserGroupEnum.CUSTOMER,
      UserGroupEnum.SUPERVISOR,
      UserGroupEnum.TRANSLATOR,
      UserGroupEnum.OPERATOR,
    ],
  },
  {
    href: '/sources/betconstruct',
    label: <SourceIcon />,
    translationKey: 'sources',
    roles: [
      UserGroupEnum.ADMIN,
      UserGroupEnum.OPERATOR,
      UserGroupEnum.SUPERVISOR,
    ],
  },
  {
    href: '/events',
    translationKey: 'events',
    label: <LineIcon />,
    roles: [
      UserGroupEnum.ADMIN,
      UserGroupEnum.OPERATOR,
      UserGroupEnum.SUPERVISOR,
      UserGroupEnum.CUSTOMER,
    ],
  },
  {
    href: '/live',
    translationKey: 'live',
    label: <LiveIcon />,
    roles: [
      UserGroupEnum.ADMIN,
      UserGroupEnum.OPERATOR,
      UserGroupEnum.SUPERVISOR,
      UserGroupEnum.CUSTOMER,
    ],
  },
  {
    href: '/results',
    label: <ResultsIcon />,
    translationKey: 'results',
    roles: [
      UserGroupEnum.ADMIN,
      UserGroupEnum.OPERATOR,
      UserGroupEnum.CUSTOMER,
      UserGroupEnum.SUPERVISOR,
    ],
  },
  {
    href: '/users',
    label: <UsersIcon />,
    translationKey: 'users',
    roles: [UserGroupEnum.ADMIN, UserGroupEnum.SUPERVISOR],
  },
  {
    href: '/translations',
    label: <TranslationsIcon />,
    translationKey: 'translations',
    roles: [
      UserGroupEnum.ADMIN,
      UserGroupEnum.SUPERVISOR,
      UserGroupEnum.TRANSLATOR,
      UserGroupEnum.OPERATOR,
    ],
  },
];

interface Props {
  className?: any;
}

export const Navigation: FC<Props> = ({ className }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const selectedNavItem = useMemo(
    () =>
      navLinks.find(navLink => {
        if (navLink.href === '/sources/betconstruct') {
          return location.pathname.includes('/sources');
        }
        return new RegExp('^' + navLink.href, 'i').test(location.pathname);
      }),
    [location],
  );

  const isSelected = link => selectedNavItem?.href === link.href;

  return (
    <S.Wrapper className={className}>
      {navLinks.map(link => {
        return link.roles ? (
          <RoleGuards roles={link.roles} key={link.href}>
            <Tooltip content={t(link.translationKey)} key={link.href}>
              <S.StyledLink
                to={link.href}
                selected={isSelected(link)}
                data-test-id={`${TestIds.SidebarMenuButton}--${link.translationKey}`}
              >
                {link.label}
              </S.StyledLink>
            </Tooltip>
          </RoleGuards>
        ) : (
          <Tooltip content={t(link.translationKey)} key={link.href}>
            <S.StyledLink
              to={link.href}
              selected={isSelected(link)}
              data-test-id={`${TestIds.SidebarMenuButton}--${link.translationKey}`}
            >
              {link.label}
            </S.StyledLink>
          </Tooltip>
        );
      })}
    </S.Wrapper>
  );
};
