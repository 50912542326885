import { useTranslation } from 'react-i18next';
import en from 'date-fns/locale/en-GB';
import ru from 'date-fns/locale/ru';

const dateFnsLocales = {
  'ru-RU': ru,
  en,
};

export const useLocale = () => {
  const { i18n } = useTranslation();

  return dateFnsLocales[i18n.language] ?? en;
};
