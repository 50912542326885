import { MaxOdd } from 'sportsbook-openapi-react';

import { parseFloatFormatted } from 'utils/number';
import { moreOrEqZero } from 'utils/validation';

import { TextInputCell } from 'app/components/forms';

import { DEFAULT_MAXODD_VALUE } from 'app/providers/EntitiesProvider/hooks/useMaxOdd';

interface Props {
  maxOdd: MaxOdd;
  outcomeCategoryId: number;
  handleCellChange: (props: {
    outcomeCategoryId: number;
    maxOdd: MaxOdd;
    value: number;
  }) => void;
  isOperator: boolean;
  isEditable: boolean;
}

const MaxOddCell = ({
  maxOdd,
  outcomeCategoryId,
  isOperator,
  isEditable,
  handleCellChange,
}: Props) => {
  return isOperator && !isEditable ? (
    <>{maxOdd?.maxOdd}</>
  ) : (
    <div onClick={event => event.stopPropagation()}>
      <TextInputCell
        // @ts-ignore
        min={0}
        defaultValue={maxOdd?.maxOdd ?? DEFAULT_MAXODD_VALUE}
        onSave={newValue => {
          handleCellChange({
            maxOdd,
            value: parseFloatFormatted(newValue),
            outcomeCategoryId,
          });
        }}
        type="number"
        validate={moreOrEqZero}
      />
    </div>
  );
};

export default MaxOddCell;
