import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AdapterEvent,
  Competitor,
  Event,
  EventKind,
  EventsApiCreateEventRequest,
  EventSource,
  EventStatus,
} from 'sportsbook-openapi-react';

import { actionsNT, SideEffects } from 'app/providers/EntitiesProvider';
import { getEventsEntries } from 'app/providers/EntitiesProvider/events';

export const MAIN_MATCH_TYPE_ID = 1;

export interface PopulatedEvent extends Event {
  competitors: Competitor[];
}

export const useUpdateEvent = (sideEffects?: SideEffects) => {
  const dispatch = useDispatch();

  return useCallback(
    (event: PopulatedEvent, withDeletion?: boolean) => {
      dispatch(
        actionsNT.eventsUpdate({
          eventId: event.id,
          eventWithOutId: {
            ...event,
            matchTypeId: event.matchTypeId ?? MAIN_MATCH_TYPE_ID,
            scheduledLive: new Date(event.eventDate),
            eventDate: new Date(event.eventDate),
            competitors: event.competitors?.map(competitor => competitor.id),
            tournamentId: event.tournament?.id!,
            status: event.status || 'EVENT_OPEN',
          },
          withDeletion,
          sideEffects,
        }),
      );
    },
    [dispatch, sideEffects],
  );
};

const getSourceFromAdapterName = (adapter: string) =>
  `SOURCE_${adapter.toUpperCase()}` as EventSource;

interface CreateEventProps {
  tournamentId: number;
  groupId: number;
  competitorA: number;
  competitorB?: number;
  adapterEvent: AdapterEvent;
  kind?: EventKind;
}

export const useCreateEventForAdapter = adapter => {
  const dispatch = useDispatch();

  return useCallback(
    ({
      tournamentId,
      groupId,
      competitorA,
      competitorB,
      adapterEvent,
      kind,
    }: CreateEventProps) => {
      const competitors = [competitorA, competitorB].filter(
        Boolean,
      ) as number[];
      const homeCompetitors = [competitorA!];
      const payload: EventsApiCreateEventRequest = {
        eventWithOutId: {
          tournamentId,
          groupId,
          competitors,
          homeCompetitors,
          live: false,
          eventDate: new Date(adapterEvent.scheduled!),
          mainSource: getSourceFromAdapterName(adapter),
          translations: [],
          traderId: '',
          status: EventStatus.OPEN,
          matchTypeId: MAIN_MATCH_TYPE_ID,
          dependentEvents: [],
          dependentTournaments: [],
          resulted: false,
          kind,
        },
      };
      dispatch(actionsNT.eventsCreate(payload));
    },
    [dispatch, adapter],
  );
};

export const useChangeEventStatus = () => {
  const events = useSelector(getEventsEntries);
  const updateEvent = useUpdateEvent();

  return (status: EventStatus) => (eventId: number) => {
    const event = events[eventId];

    updateEvent({
      ...event,
      status,
    });
  };
};

export const useSuspendEvent = () => {
  const changeEventStatus = useChangeEventStatus();

  return changeEventStatus(EventStatus.SUSPENDED);
};
