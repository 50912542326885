import { FC } from 'react';
import { useField } from 'formik';

import { RadioButton, RadioButtonProps } from 'app/components/forms';

interface Props<Value = string> extends Omit<RadioButtonProps, 'onChange'> {
  name: string;
  value: Value;
  onActivate?: (value: Value) => void;
}

export const RadioButtonField: FC<Props> = ({
  name,
  value,
  label,
  disabled,
  onActivate: onChange,
}) => {
  const [field, , helpers] = useField(name);

  const handleChange = checked => {
    if (checked) {
      helpers.setValue(value);
      onChange?.(value);
    }
  };

  return (
    <RadioButton
      label={label}
      checked={field.value === value}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};
