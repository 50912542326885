import { FC } from 'react';

import { Wrapper } from './RadioButton.styles';

export interface RadioButtonProps {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export const RadioButton: FC<RadioButtonProps> = ({
  label,
  checked = false,
  disabled = false,
  onChange,
}) => {
  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <Wrapper disabled={disabled}>
      <input
        type="radio"
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      {label}
    </Wrapper>
  );
};
