import { typesApi } from 'app/api';

const api = {
  endpoint: typesApi,
  methods: {
    fetchItems: 'getAllOutcomeTypeCrossMappings',
    fetchItem: 'getOutcomeTypeCrossMapping',
    create: 'createOutcomeTypeCrossMapping',
    update: 'updateOutcomeTypeCrossMapping',
    delete: 'deleteOutcomeTypeCrossMapping',
  },
};

export default api;
