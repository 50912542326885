import styled from 'styled-components';

import { TextInput } from 'app/components/forms';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const InputWraper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const PeriodWrapper = styled.div`
  grid-column: 1/3;
`;

export const StyledInput = styled(TextInput)`
  width: 100%;
`;

export const StyledCheckboxWrapper = styled.div`
  display: grid;
  grid-column: 1/3;
  grid-template-columns: auto 1fr;
  gap: 8px;
`;
