import { PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash-es/keyBy';
import {
  EventFavoriteMapping,
  EventFavoriteMappingListResponse,
  UsersApiDeleteEventFavoriteMappingRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';

const reducer = {
  eventFavoritesFetchItems(state) {
    state.eventFavorites.fetchItem.error = null;
    state.eventFavorites.fetchItem.loading = true;
  },
  eventFavoritesFetchItemsSuccess(
    state,
    action: PayloadAction<EventFavoriteMappingListResponse>,
  ) {
    state.eventFavorites.entries = keyBy(
      action.payload.items,
      item => `${item.userId}_${item.eventId}`,
    );
    state.eventFavorites.fetchItem.loading = false;
  },
  eventFavoritesFetchItemsError(
    state,
    action: PayloadAction<EntitiesErrorType>,
  ) {
    state.eventFavorites.fetchItem.error = action.payload;
    state.eventFavorites.fetchItem.loading = false;
  },
  eventFavoritesCreate(state) {
    state.eventFavorites.create.error = null;
    state.eventFavorites.create.loading = true;
  },
  eventFavoritesCreateSuccess(
    state,
    action: PayloadAction<EventFavoriteMapping>,
  ) {
    state.eventFavorites.entries[
      `${action.payload.userId}_${action.payload.eventId}`
    ] = action.payload;
    state.eventFavorites.create.loading = false;
  },
  eventFavoritesCreateError(state, action: PayloadAction<EntitiesErrorType>) {
    state.eventFavorites.create.error = action.payload;
    state.eventFavorites.create.loading = false;
  },
  eventFavoritesDelete(
    state,
    action: PayloadAction<
      UsersApiDeleteEventFavoriteMappingRequest & {
        deleteEvent?: boolean;
      }
    >,
  ) {
    delete state.eventFavorites.entries[
      `${action.payload.userId}_${action.payload.eventId}`
    ];
    state.eventFavorites.delete.error = null;
    state.eventFavorites.delete.loading = true;
    if (action.payload.deleteEvent) {
      state.events.sortedIds = state.events.sortedIds.filter(
        id => id !== action.payload.eventId,
      );
    }
  },
  eventFavoritesDeleteSuccess(state) {
    state.eventFavorites.delete.loading = false;
  },
  eventFavoritesDeleteError(state, action: PayloadAction<EntitiesErrorType>) {
    state.eventFavorites.delete.error = action.payload;
    state.eventFavorites.delete.loading = false;
  },
  eventFavoritesDeleteList(state) {
    state.eventFavorites.entries = {};
    state.eventFavorites.deleteList.error = null;
    state.eventFavorites.deleteList.loading = true;
  },
  eventFavoritesDeleteListSuccess(state) {
    state.eventFavorites.deleteList.loading = false;
  },
  eventFavoritesDeleteListError(
    state,
    action: PayloadAction<EntitiesErrorType>,
  ) {
    state.eventFavorites.deleteList.error = action.payload;
    state.eventFavorites.deleteList.loading = false;
  },
};

export default reducer;
