import styled from 'styled-components';

import { Text } from 'app/components/ui/Text/Text';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 13;

  background-color: rgba(0, 0, 0, 0.2);
`;

interface DialogWrapperProps {
  strictWidth?: boolean;
}

export const DialogWrapper = styled.div<DialogWrapperProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 20px;
  width: ${({ strictWidth }) => strictWidth && '360px'};

  background-color: ${p => p.theme.colors.primary[0]};
  padding: 20px;
  border-radius: 4px;
`;

export const ChildrenWrapper = styled.div`
  grid-column: 1/3;
`;

export const StyledText = styled(Text)`
  grid-column: 1/3;
  text-align: center;
`;
