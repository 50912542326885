import { Helmet } from 'react-helmet-async';

import * as S from './ErrorPage.styles';

export function ErrorPage() {
  return (
    <>
      <Helmet>
        <title>Error Page</title>
        <meta name="description" content="Error Page" />
      </Helmet>
      <S.Wrapper>
        <S.Title>
          <span role="img" aria-label="Crying Face">
            😢
          </span>
        </S.Title>
        <S.P>Something went wrong :(</S.P>
      </S.Wrapper>
    </>
  );
}
