import { useState } from 'react';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { adaptersApi } from 'app/api';

import { Button } from 'app/components/ui';

interface Props {
  id?: string;
  adapter?: string;
}

export const RefreshCell = ({ id, adapter }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!id || !adapter) {
    return null;
  }

  const handleRefreshEventMappings = async () => {
    setIsLoading(true);
    try {
      await adaptersApi.refreshFromOriginalsSportEvent({
        id,
        adapter,
        eventMappingRefreshRequest: { sportsbookId: 0 },
      });
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      color="action"
      onClick={handleRefreshEventMappings}
      disabled={isLoading}
    >
      <FontAwesomeIcon icon={faRedo} />
    </Button>
  );
};
