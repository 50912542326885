import { commentsApi } from 'app/api';

const api = {
  endpoint: commentsApi,
  methods: {
    fetchItems: 'getComments',
    fetchItem: 'getComment',
    create: 'createComment',
    update: 'updateComment',
    delete: 'deleteComment',
  },
};

export default api;
