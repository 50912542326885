import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { UserGroupEnum } from 'sportsbook-openapi-react';

import { useUserStore } from 'store';
import { getUser } from 'store/user';

interface RoleGuardProps {
  roles: Array<UserGroupEnum>;
  withRedirect?: boolean;
}

export const RoleGuards: FC<PropsWithChildren<RoleGuardProps>> = ({
  roles,
  children,
  withRedirect = false,
}) => {
  const user = useUserStore(getUser);

  if (user && roles.includes(user.group)) {
    return <>{children}</>;
  }

  if (withRedirect) {
    return <Navigate to={'/dictionary'} />;
  }

  return null;
};
