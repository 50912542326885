import { Delay } from 'sportsbook-openapi-react';

import { parseFloatFormatted } from 'utils/number';
import { moreOrEqZero } from 'utils/validation';

import { TextInputCell } from 'app/components/forms';

interface Props {
  delay: Delay;
  outcomeCategoryId: number;
  handleCellChange: (props: {
    outcomeCategoryId: number;
    delay?: Delay;
    value: number;
  }) => void;
}

const DelayCell = ({ delay, outcomeCategoryId, handleCellChange }: Props) => {
  return (
    <div onClick={event => event.stopPropagation()}>
      <TextInputCell
        // @ts-ignore
        min={0}
        defaultValue={delay?.value ?? '0'}
        onSave={newValue => {
          handleCellChange({
            delay,
            value: parseFloatFormatted(newValue),
            outcomeCategoryId,
          });
        }}
        type="number"
        validate={moreOrEqZero}
      />
    </div>
  );
};

export default DelayCell;
