import { Column } from 'react-table';
import {
  EventSource,
  OutcomeCategorySourceMapping,
} from 'sportsbook-openapi-react';

import { OptionType, SelectCell } from 'app/components/forms';

interface Props {
  header: string;
  accessor: string;
  categorySourceMappings: Dictionary<OutcomeCategorySourceMapping>;
  updateCategorySourceMappingValue: (props: {
    categoryId: number;
    categorySourceMapping?: OutcomeCategorySourceMapping;
    value: EventSource;
  }) => void;
  deleteCategorySourceMappingByCategory: (props: {
    categoryId: number;
  }) => void;
  isOperator: boolean;
  sourcesOptions: OptionType[];
}

export const SourceMappingColumn = <T extends object>({
  header,
  accessor,
  categorySourceMappings,
  updateCategorySourceMappingValue,
  deleteCategorySourceMappingByCategory,
  isOperator,
  sourcesOptions,
}: Props): Column<T> => {
  return {
    Header: header,
    id: 'source',
    accessor: accessor as keyof T,
    Cell: ({ value: categoryId }) => {
      const categorySourceMapping = categorySourceMappings[categoryId];
      return (
        <>
          {isOperator ? (
            <>{categorySourceMapping?.categoryMainSource}</>
          ) : (
            <SelectCell
              onChange={value => {
                if (value !== 'delete') {
                  updateCategorySourceMappingValue({
                    categorySourceMapping,
                    value: value as EventSource,
                    categoryId: categoryId,
                  });
                } else deleteCategorySourceMappingByCategory({ categoryId });
              }}
              options={sourcesOptions}
              value={categorySourceMapping?.categoryMainSource}
            ></SelectCell>
          )}
        </>
      );
    },
  };
};
