import { PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash-es/keyBy';
import {
  CommentsApiDeleteEntityCommentRequest,
  CommentWithEntityView,
  EntityCommentWithComment,
} from 'sportsbook-openapi-react';

import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator({ entity: 'commentsByEntity' }),
  commentsByEntityFetchItemsSuccess(
    state,
    action: PayloadAction<Array<CommentWithEntityView>>,
  ) {
    const items = action.payload;
    state.commentsByEntity.fetchItems.loading = false;
    state.commentsByEntity.entries = {
      ...state.commentsByEntity.entries,
      ...keyBy(items, 'id'),
    };

    items.forEach(item => {
      state.commentsByEntity.sortedIds[`${item.entityType}_${item.entityId}`]
        ? !state.commentsByEntity.sortedIds[
            `${item.entityType}_${item.entityId}`
          ].includes(item.id!) &&
          state.commentsByEntity.sortedIds[
            `${item.entityType}_${item.entityId}`
          ].push(item.id!)
        : (state.commentsByEntity.sortedIds[
            `${item.entityType}_${item.entityId}`
          ] = [item.id!]);
    });
  },
  commentsByEntityCreateSuccess(
    state,
    action: PayloadAction<EntityCommentWithComment>,
  ) {
    const entry = action.payload;
    const commentId = entry.comment?.id;

    state.commentsByEntity.entries[commentId!] = {
      id: commentId,
      name: entry.comment?.name,
      disabled: entry.comment?.disabled,
      translations: entry.comment?.translations,
      entityId: entry.entityId,
      entityType: entry.entityType,
    };

    state.commentsByEntity.sortedIds[`${entry.entityType}_${entry.entityId}`]
      ? !state.commentsByEntity.sortedIds[
          `${entry.entityType}_${entry.entityId}`
        ].includes(commentId!) &&
        state.commentsByEntity.sortedIds[
          `${entry.entityType}_${entry.entityId}`
        ].push(commentId!)
      : (state.commentsByEntity.sortedIds[
          `${entry.entityType}_${entry.entityId}`
        ] = [commentId!]);

    state.commentsByEntity.create.loading = false;
  },
  commentsByEntityDelete(
    state,
    action: PayloadAction<CommentsApiDeleteEntityCommentRequest>,
  ) {
    const { entityCommentWithoutId } = action.payload;
    if (!entityCommentWithoutId) {
      return;
    }
    const { commentId, entityId, entityType } = entityCommentWithoutId;
    state.commentsByEntity.delete.loading = true;
    state.commentsByEntity.sortedIds[`${entityType}_${entityId}`] =
      state.commentsByEntity.sortedIds[`${entityType}_${entityId}`].filter(
        id => id !== commentId,
      );
  },
};

export default reducer;
