import { PayloadAction } from '@reduxjs/toolkit';
import { MappingRequest } from 'sportsbook-openapi-react';

import {
  AdapterState,
  ContainerState,
  MapRequest,
} from 'app/providers/AdaptersProvider/types';

const defaultMap =
  <
    T extends MapRequest & {
      id: string;
      mappingRequest?: MappingRequest;
    },
  >(
    entity: keyof AdapterState,
  ) =>
  (state: ContainerState, action: PayloadAction<T>) => {
    state.entities[entity].map.loading = true;
    state.entities[entity].map.error = null;
    if (action.payload.withDeletion) {
      state.entities[entity].sortedIds = state.entities[
        entity
      ].sortedIds.filter(id => id !== action.payload.id);
      delete state.entities[entity].entries[action.payload.id];
    } else {
      state.entities[entity].entries[action.payload.id] = {
        ...state.entities[entity].entries[action.payload.id],
        ...action.payload.mappingRequest,
      };
    }

    state.entities[entity].map.loading = true;
    state.entities[entity].map.error = null;
  };

const defaultMapSuccess =
  (entity: keyof AdapterState) => (state: ContainerState) => {
    state.entities[entity].map.loading = false;
  };

const defaultMapFailure =
  <T extends Error = Error>(entity: keyof AdapterState) =>
  (state: ContainerState, action: PayloadAction<T>) => {
    state.entities[entity].map.loading = false;
    state.entities[entity].map.error = action.payload.message;
  };

export const mapReducersGenerator = (entityName: keyof AdapterState) => ({
  [`${entityName}Map`]: defaultMap(entityName),
  [`${entityName}MapSuccess`]: defaultMapSuccess(entityName),
  [`${entityName}MapFailure`]: defaultMapFailure(entityName),
});
