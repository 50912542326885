export const includes = (word: string, subword: string) =>
  word.toLowerCase().includes(subword.toLowerCase());

export enum FilterCacheKeys {
  RESULTS = 'results_filter',
  LIVE = 'live_filters',
  LINE = 'line_filters',
  SOURCE = 'source_filters',
  SOURCE_ENTITIES = 'source_entities_sorting',
  DICTIONARY_ENTITIES = 'dictionary_entities_search',
}
