import { createContext, Fragment, useCallback, useMemo, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useTranslation } from 'react-i18next';
import { PropsValue } from 'react-select';
import {
  Column,
  useMountedLayoutEffect,
  useRowSelect,
  useTable,
} from 'react-table';
import { isFunction } from 'formik';
import get from 'lodash/get';

import { BaseOptionType } from 'app/components/forms/Select/Select.types';

import { Collapse } from '..';

import { TableCheckbox } from './components';
import { useFilters, useSorting } from './Table.hooks';
import * as S from './Table.styles';
import { Filters, Sorting, TableProps } from './Table.types';

const isStringArray = (test: any[]): boolean => {
  return Array.isArray(test) && test.every(value => typeof value === 'string');
};

type FilterContextType = {
  filters: Record<string, any>;
  handleFilterChange: (
    id: string,
  ) => (value: PropsValue<BaseOptionType> | string) => void;
};

export const FilterContext = createContext<FilterContextType>({
  filters: {},
  handleFilterChange: () => () => {},
});

type SortingContextType = {
  sorting: Record<string, any>;
  handleSortingChange: (id: string) => (asc: boolean | undefined) => void;
};

export const SortingContext = createContext<SortingContextType>({
  sorting: {},
  handleSortingChange: () => () => {},
});

export const Table = <
  DataType extends {} = {},
  FiltersType extends {} = Filters,
  SortingType extends {} = Sorting,
>({
  fixed,
  columns,
  data,
  onRowClick,
  rowStyles = '',
  onBottom,
  columnsWidth,
  isSelected,
  filters = { value: {} as FiltersType, onFilterChange: () => {} },
  sorting = { value: {} as SortingType, onSortingChange: () => {} },
  renderExpandedRow,
  withCheckboxes,
  onSelectCheckbox,
  emptyPlaceholder,
  selectedInitialRowIds,
  isTableInExpander,
  testId,
}: TableProps<DataType, FiltersType, SortingType>) => {
  const { t } = useTranslation();

  const [expandedRowKey, setExpandedRowKey] = useState('');

  const handleBottomScroll = useCallback(() => {
    if (isFunction(onBottom)) {
      onBottom();
    }
  }, [onBottom]);

  const scrollRef = useBottomScrollListener<HTMLDivElement>(
    handleBottomScroll,
    {
      offset: 0,
      debounce: 200,
    },
  );

  const processedColumns = useMemo(
    () =>
      isStringArray(columns)
        ? (columns as string[]).map(
            column =>
              ({
                Header: t(column),
                accessor: column,
              }) as Column<DataType>,
          )
        : (columns as Column<DataType>[]),
    [columns, t],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleRowSelected,
  } = useTable<DataType>(
    {
      columns: processedColumns,
      data,
      initialState: {
        selectedRowIds: selectedInitialRowIds ? selectedInitialRowIds : {},
      },
    },
    useRowSelect,
    hooks => {
      if (withCheckboxes) {
        hooks.visibleColumns.push(columns => {
          return [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <TableCheckbox {...getToggleAllRowsSelectedProps()} />
              ),
              Cell: ({ row, rows }) => (
                <TableCheckbox
                  {...row.getToggleRowSelectedProps()}
                  onClick={e => handleCheckboxClick(e, row, rows)}
                />
              ),
            },
            ...columns,
          ];
        });
      }
    },
  );

  const handleCheckboxClick = useCallback(
    (e, row, rows) => {
      const startIndex = parseInt(
        rows.findIndex(row => row.getToggleRowSelectedProps().checked === true),
      );
      if (e.shiftKey && startIndex >= 0) {
        const endIndex = parseInt(row.id);
        if (startIndex < endIndex) {
          for (let i = startIndex + 1; i < endIndex; i++) {
            toggleRowSelected(String(i), true);
          }
        } else if (startIndex > endIndex) {
          for (let i = endIndex; i < startIndex; i++) {
            toggleRowSelected(String(i));
          }
        }
      }
    },
    [toggleRowSelected],
  );

  useMountedLayoutEffect(() => {
    if (onSelectCheckbox) {
      onSelectCheckbox(selectedFlatRows);
    }
  }, [onSelectCheckbox, selectedFlatRows]);

  const handleRowClick = useCallback(
    (data, key) => () => {
      setExpandedRowKey(expandedRowKey => (expandedRowKey === key ? '' : key));
      isFunction(onRowClick) && onRowClick(data);
    },
    [onRowClick],
  );

  const filterContextValue = useFilters<FiltersType>(
    filters.value,
    filters.onFilterChange,
  );
  const sortingContextValue = useSorting<SortingType>(
    sorting.value,
    sorting.onSortingChange,
  );

  return (
    <S.TableWrapper ref={scrollRef}>
      <S.StyledTable {...getTableProps()} fixed={fixed}>
        <FilterContext.Provider value={filterContextValue}>
          <SortingContext.Provider value={sortingContextValue}>
            <S.StyledTHead
              data-test-id={testId ? `${testId}-header` : undefined}
            >
              {headerGroups.map(headerGroup => (
                <S.StyledTHeadTr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <S.StyledTh
                      {...column.getHeaderProps()}
                      size={get(columnsWidth, `[${index}]`)}
                      isTableInExpander={isTableInExpander}
                    >
                      {column.render('Header')}
                    </S.StyledTh>
                  ))}
                </S.StyledTHeadTr>
              ))}
            </S.StyledTHead>
          </SortingContext.Provider>
        </FilterContext.Provider>
        <S.StyledTBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            const isExpendedRowShown = expandedRowKey === rowProps.key;

            const rowTestId =
              testId && row.original['id']
                ? `${testId}-row--${row.original['id']}`
                : undefined;

            return (
              <Fragment key={rowProps.key}>
                <S.TBodyTr
                  {...rowProps}
                  onClick={handleRowClick(row.original, rowProps.key)}
                  styles={
                    isFunction(rowStyles) ? rowStyles(row.original) : rowStyles
                  }
                  isSelected={
                    isFunction(isSelected) && isSelected(row.original)
                  }
                  data-test-id={rowTestId}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <S.Td
                        {...cell.getCellProps()}
                        size={get(columnsWidth, `[${index}]`)}
                      >
                        {cell.render('Cell')}
                      </S.Td>
                    );
                  })}
                </S.TBodyTr>
                <S.CollapseTr>
                  <S.CollapseTd colSpan={row.cells.length}>
                    {!!renderExpandedRow && (
                      <Collapse isExpanded={isExpendedRowShown}>
                        <S.CollapseChildWrapper>
                          {renderExpandedRow(row)}
                        </S.CollapseChildWrapper>
                      </Collapse>
                    )}
                  </S.CollapseTd>
                </S.CollapseTr>
              </Fragment>
            );
          })}
        </S.StyledTBody>
      </S.StyledTable>
      {!rows.length && (
        <S.EmptyPlaceholderWrapper>
          {emptyPlaceholder}
        </S.EmptyPlaceholderWrapper>
      )}
    </S.TableWrapper>
  );
};
