import {
  SOURCES_NAMES,
  WITH_PROBS_ID,
  WITHOUT_PROBS_ID,
} from './SourcesPage.constants';

export const getSourceName = (source: string) =>
  SOURCES_NAMES[source] || source;

export const getProbsSelectValue = (probsReceived?: boolean) => {
  if (probsReceived) return WITH_PROBS_ID;
  if (probsReceived === false) return WITHOUT_PROBS_ID;
  return '';
};
