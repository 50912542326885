import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import { DictionaryDiff, DictionaryEntityDiff } from 'sportsbook-openapi-react';

import { RootState } from 'types';

import { AuditedEntities, getEntityItems } from 'app/providers/AuditProvider';
import {
  actionsNT,
  Entities,
  getEntities,
} from 'app/providers/EntitiesProvider';

interface UsePopulatedAuditItemsProps<T extends AuditedEntities> {
  entity: T;
}

const populatedFields = [
  'sport_id',
  'group_id',
  'tournament_id',
  'dependent_tournaments',
  'dependent_events',
  'home_competitors',
  'competitors',
  'match_type_id',
  'country_id',
  'base_event',
  'event_id',
  'market_id',
  'outcome_id',
];

const dateFields = ['scheduled', 'scheduled_end'];

const processedFields = [...populatedFields, ...dateFields];

const fieldNameToEntity: Record<
  ArrayElement<typeof populatedFields>,
  keyof Omit<Required<RootState>['entities'], 'search'>
> = {
  sport_id: Entities.SPORTS,
  group_id: Entities.GROUPS,
  tournament_id: Entities.TOURNAMENTS,
  dependent_tournaments: Entities.TOURNAMENTS,
  dependent_events: Entities.EVENTS,
  home_competitors: Entities.COMPETITORS,
  competitors: Entities.COMPETITORS,
  match_type_id: Entities.MATCH_TYPES,
  country_id: Entities.COUNTRIES,
  base_event: Entities.EVENTS,
  event_id: Entities.EVENTS,
};

const fieldNameToAction: Record<
  keyof typeof fieldNameToEntity,
  (payload: {
    ids?: number[];
    onlyEntries?: boolean;
  }) => PayloadAction<typeof payload>
> = {
  sport_id: actionsNT.sportsFetchItems,
  group_id: actionsNT.groupsFetchItems,
  tournament_id: actionsNT.tournamentsFetchItems,
  dependent_tournaments: actionsNT.tournamentsFetchItems,
  dependent_events: actionsNT.eventsFetchItems,
  home_competitors: actionsNT.competitorsFetchItems,
  competitors: actionsNT.competitorsFetchItems,
  match_type_id: actionsNT.matchTypesFetchItems,
  country_id: actionsNT.countriesFetchItems,
  base_event: actionsNT.eventsFetchItems,
  event_id: actionsNT.eventsFetchItems,
};

const fieldNameToLocale: Record<keyof typeof fieldNameToEntity, string> = {
  sport_id: 'sport',
  group_id: 'group',
  tournament_id: 'tournament',
  dependent_tournaments: 'dependent tournaments',
  dependent_events: 'dependent events',
  home_competitors: 'home competitor',
  competitors: 'competitors',
  match_type_id: 'matchType',
  country_id: 'country',
  base_event: 'base event',
  event_id: 'event',
  scheduled: 'scheduled',
  scheduled_end: 'scheduled end',
};

const isArray = (value: string) => value.startsWith('[') && value.endsWith(']');
const parseArray = (value: string) => isArray(value) && JSON.parse(value);

export const usePopulatedAuditItems = <T extends AuditedEntities>({
  entity,
}: UsePopulatedAuditItemsProps<T>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entities = useSelector(getEntities);
  const requestedIds = useRef<
    Partial<Record<keyof typeof fieldNameToEntity, number[]>>
  >({});

  const items = useSelector((state: RootState) =>
    getEntityItems(state, entity),
  );

  const allIds = useMemo(
    () =>
      mapValues(
        groupBy(items.map(item => item.diffs).flat(), 'fieldName'),
        diffs =>
          diffs
            .filter(diff => diff !== undefined)
            .map(diff => diff!)
            .map(diff => {
              const result: number[] = [];
              if (diff?._new) {
                if (isArray(diff?._new)) {
                  result.push(
                    ...parseArray(diff._new).map(item => Number(item)),
                  );
                } else if (diff._new !== 'null') {
                  result.push(Number(diff._new));
                }
              }
              if (diff?.old) {
                if (isArray(diff?.old[0])) {
                  result.push(
                    ...parseArray(diff.old).map(item => Number(item)),
                  );
                } else if (diff.old !== 'null') {
                  result.push(Number(diff.old));
                }
              }

              return result;
            })
            .flat(),
      ),
    [items],
  );

  const fetchNewIds = useCallback(
    (key: keyof typeof fieldNameToEntity) => {
      if (!allIds[key]) {
        return;
      }
      const idsToFetch = allIds[key].filter(
        id => !requestedIds.current[key]?.includes(id),
      );

      if (requestedIds.current[key]) {
        requestedIds.current[key]?.push(...idsToFetch);
      } else {
        requestedIds.current[key] = idsToFetch;
      }

      if (idsToFetch.length)
        dispatch(
          fieldNameToAction[key]({
            ids: allIds[key],
            onlyEntries: true,
          }),
        );
    },
    [allIds, dispatch],
  );

  useEffect(() => {
    Object.keys(fieldNameToEntity).forEach(key => {
      fetchNewIds(key);
    });
  }, [fetchNewIds]);

  const getFieldLabel = useCallback(
    (fieldName: string, item: string, labelKey: string = 'name') =>
      get(
        entities[fieldNameToEntity[fieldName]]?.entries[item],
        labelKey,
        item,
      ),
    [entities],
  );

  const getById = useCallback(
    (fieldName: string, entry: string) => {
      return isArray(entry)
        ? parseArray(entry)
            .map(item => getFieldLabel(fieldName, item))
            .join(', ')
        : getFieldLabel(fieldName, entry);
    },
    [getFieldLabel],
  );

  const populateItem = useCallback(
    (fieldName: string, entry: string) => {
      if (entry === 'null') {
        return t('not set');
      }
      if (populatedFields.includes(fieldName)) {
        return getById(fieldName, entry);
      }
      if (dateFields.includes(fieldName)) {
        return dayjs.unix(+entry).format('DD.MM.YYYY HH:mm:ss');
      }
    },
    [getById, t],
  );

  const getEntityName = useCallback(
    (item: DictionaryDiff) => ({
      ...item,
      diffs: item.diffs?.map(diff => {
        const newDiff: Partial<DictionaryEntityDiff> = { ...diff };
        if (diff.fieldName && processedFields.includes(diff.fieldName)) {
          newDiff.fieldName = t(fieldNameToLocale[diff.fieldName]);

          if (diff.old) {
            newDiff.old = populateItem(diff.fieldName, diff.old);
          }
          if (diff._new) {
            newDiff._new = populateItem(diff.fieldName, diff._new);
          }
        }
        return newDiff;
      }),
    }),
    [populateItem, t],
  );

  return useMemo(() => items.map(getEntityName), [getEntityName, items]);
};
