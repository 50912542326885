import styled from 'styled-components';

interface WrapperProps {
  hasSearch?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  font-size: ${p => p.theme.fonts.typography['H100'].size};
  font-weight: ${p => p.theme.fonts.typography['H100'].weight};
  white-space: nowrap;
`;

export const SelectWrapper = styled.div`
  width: 150px;
`;

export const Spacer = styled.div``;
