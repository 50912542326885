import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUpdateTranslations } from 'app/pages/Translations/hooks';
import {
  convertTranslatedEntityToEntity,
  getFilters,
  selectTranslatedEntitiesItems,
} from 'app/pages/Translations/selectors';
import { getTranslatedEntity } from 'app/pages/Translations/selectors';
import { TranslatedEntities } from 'app/pages/Translations/types';
import { keyBy } from 'lodash-es';
import { Player, ShortName, Translation } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import { useLazyLoading } from 'hooks/useLazyLoading';
import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { TextInputCell } from 'app/components/forms';
import { AdapterCompetitorsNamesPanel } from 'app/components/panels';
import { Button, LoadingIndicator, Table } from 'app/components/ui';

import { adapterActions } from 'app/providers/AdaptersProvider';
import {
  actionsNT,
  getEntityHasMoreByType,
} from 'app/providers/EntitiesProvider';
import { getCategoriesEntries } from 'app/providers/EntitiesProvider/categories';
import { getCompetitorsEntries } from 'app/providers/EntitiesProvider/competitors';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import { useUserStore } from 'store';
import { getIsOperator } from 'store/user';

import {
  entityToAction,
  entityToAudit,
  entityToResetAction,
} from './constants';
import * as S from './EntitiesTable.styles';

interface BaseEntity {
  id?: number;
  name?: string;
  sportId?: number;
  code?: string;
  competitors?: number[];
  translations?: Translation[];
  categoryId?: number;
  shortNames?: ShortName[];
}

function getColumnWidth(entity) {
  switch (entity) {
    case TranslatedEntities.GROUPS:
    case TranslatedEntities.TOURNAMENTS:
    case TranslatedEntities.PLAYERS:
    case TranslatedEntities.TYPES:
    case TranslatedEntities.COMPETITORS: {
      return [25, 25, 50];
    }
    case TranslatedEntities.SHORT_NAMES: {
      return [20, 20, 20, 20, 20];
    }
    default:
      return [30, 70];
  }
}

export function EntitiesTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const entity = useSelector(getTranslatedEntity);

  const [outcomeTypeId, setOutcomeTypeId] = useState<null | number>(null);

  const filters = useSelector(getFilters);
  const isOperator = useUserStore(getIsOperator);

  const openAudit = useAudit({
    entity: entityToAudit[entity],
  });

  const items = useSelector(selectTranslatedEntitiesItems);
  const hasMore = useSelector(state =>
    getEntityHasMoreByType(state, convertTranslatedEntityToEntity(entity)),
  );

  const { isLoading } = useRequestState(
    convertTranslatedEntityToEntity(entity),
    'fetchItems',
  );

  const sportEntries = useSelector(getSportsEntries);

  const competitorEntries = useSelector(getCompetitorsEntries);

  const categoryEntries = useSelector(getCategoriesEntries);

  const { updateTranslation, deleteTranslation } =
    useUpdateTranslations()[entity];

  const onLazyLoad = useCallback(
    ({ limit, offset }) =>
      dispatch(
        entityToAction[entity]({
          limit,
          offset,
          withPagination: true,
          translated: filters.translated,
          languageCode: filters.langCode,
          sportId: filters.sportId,
          name: filters.name,
          disabled: false,
          translationName: filters.translationName,
        }),
      ),
    [dispatch, entity, filters],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [entity, filters],
    onPaginationReset: () => {
      dispatch(entityToResetAction[entity]());
    },
  });

  useEffect(() => {
    if (
      [
        TranslatedEntities.TOURNAMENTS,
        TranslatedEntities.GROUPS,
        TranslatedEntities.COMPETITORS,
      ].includes(entity)
    ) {
      dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
    } else if (entity !== TranslatedEntities.SPORTS) {
      dispatch(actionsNT.sportsReset());
    }
  }, [dispatch, entity]);

  useEffect(() => {
    if (
      [TranslatedEntities.SHORT_NAMES, TranslatedEntities.TYPES].includes(
        entity,
      )
    ) {
      dispatch(actionsNT.categoriesFetchItems({ limit: FETCH_ALL_LIMIT }));
    } else if (entity !== TranslatedEntities.CATEGORIES) {
      dispatch(actionsNT.categoriesReset());
    }
  }, [dispatch, entity]);

  useEffect(() => {
    if (entity === TranslatedEntities.PLAYERS) {
      const ids = (items as unknown as Player[]).map(p => p.competitors).flat();

      if (ids.length) {
        dispatch(
          actionsNT.competitorsFetchItems({
            ids,
          }),
        );
      }
    } else if (entity !== TranslatedEntities.COMPETITORS) {
      dispatch(actionsNT.competitorsReset());
    }
  }, [dispatch, entity, items]);

  useEffect(() => {
    dispatch(adapterActions.fetchAdapters());
  }, [dispatch]);

  const sportColumn: Column<BaseEntity> = useMemo(
    () => ({
      Header: String(t('sport type')),
      accessor: 'sportId',
      Cell: ({ value }) => <>{value ? sportEntries[value]?.name : ''}</>,
    }),
    [sportEntries, t],
  );

  const competitorColumn: Column<BaseEntity> = useMemo(
    () => ({
      Header: String(t('competitor')),
      accessor: 'competitors',
      Cell: ({ value }) => (
        <>{value?.length ? competitorEntries[value[0]]?.name : ''}</>
      ),
    }),
    [competitorEntries, t],
  );

  const categoryColumn: Column<BaseEntity> = useMemo(
    () => ({
      Header: String(t('category')),
      accessor: 'categoryId',
      Cell: ({ value }) => <>{value ? categoryEntries[value]?.name : ''}</>,
    }),
    [categoryEntries, t],
  );

  const columns = useMemo(
    () =>
      [
        entity === TranslatedEntities.SHORT_NAMES && {
          Header: String(t('short name')),
          accessor: 'shortNames',
          id: 'shortName',
          Cell: ({ value }) => (
            <>{value?.find(item => item.langCode === 'RU')?.value}</>
          ),
        },
        {
          Header: String(t('name')),
          accessor: 'name',
        },
        entity === TranslatedEntities.SHORT_NAMES && {
          Header: String(t('outcome code')),
          accessor: 'code',
        },
        entity === TranslatedEntities.SHORT_NAMES && categoryColumn,
        entity === TranslatedEntities.TOURNAMENTS && sportColumn,
        entity === TranslatedEntities.GROUPS && sportColumn,
        entity === TranslatedEntities.COMPETITORS && sportColumn,
        entity === TranslatedEntities.PLAYERS && competitorColumn,
        entity === TranslatedEntities.TYPES && categoryColumn,
        {
          Header: String(t('translation')),
          accessor:
            entity === TranslatedEntities.SHORT_NAMES
              ? 'shortNames'
              : 'translations',
          Cell: ({ value, row: { original: data } }) => {
            const entryTranslations = keyBy(value, 'langCode');
            const defaultValue =
              filters.langCode && entryTranslations[filters.langCode]
                ? entryTranslations[filters.langCode]
                : { langCode: filters.langCode, value: '' };
            if (isOperator) return <>{defaultValue.value}</>;
            return (
              <TextInputCell
                key={defaultValue.value}
                defaultValue={defaultValue.value}
                onSave={value => {
                  updateTranslation(data, {
                    langCode: defaultValue.langCode!,
                    value,
                  });
                }}
                validate={value => value.length > 0}
              />
            );
          },
        },
        entity === TranslatedEntities.COMPETITORS && {
          Header: '',
          accessor: 'id',
          id: 'adapterCompetitorName',
          Cell: ({ value }) => (
            <Button
              color="action"
              onClick={() => {
                setOutcomeTypeId(value!);
              }}
            >
              {t('adapter translation')}
            </Button>
          ),
        },
        !isOperator && {
          Header: '',
          accessor: 'id',
          id: 'audit',
          Cell: ({ value }) => (
            <Button
              color="action"
              onClick={() => {
                openAudit({ id: String(value), includeRelated: false });
              }}
            >
              {t('audit')}
            </Button>
          ),
        },
        !isOperator && {
          Header: '',
          accessor:
            entity === TranslatedEntities.SHORT_NAMES
              ? 'shortNames'
              : 'translations',
          id: 'delete',
          Cell: ({ value, row: { original: data } }) => {
            const hasTranlsation = !!value.find(
              entry => entry.langCode === filters.langCode,
            );
            return (
              <Button
                disabled={!hasTranlsation}
                color="action"
                onClick={() => {
                  deleteTranslation(data, filters.langCode!);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            );
          },
        },
      ].filter(el => Boolean(el)) as Column<BaseEntity>[],
    [
      entity,
      t,
      sportColumn,
      competitorColumn,
      categoryColumn,
      isOperator,
      filters.langCode,
      updateTranslation,
      openAudit,
      deleteTranslation,
    ],
  );

  const columnsWidth = getColumnWidth(entity);

  return (
    <S.TableWrapper>
      <Table
        columns={columns}
        data={items}
        onBottom={onBottom}
        columnsWidth={columnsWidth}
      />
      {isLoading && <LoadingIndicator type="absolute" />}
      {!!outcomeTypeId && (
        <AdapterCompetitorsNamesPanel
          onClose={() => {
            setOutcomeTypeId(null);
          }}
          id={outcomeTypeId}
        />
      )}
    </S.TableWrapper>
  );
}
