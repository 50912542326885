/**
 *
 * Players
 *
 */

import { useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { omit, uniq } from 'lodash-es';
import {
  Competitor,
  Player,
  PlayerWithOutId,
  UserGroupEnum,
} from 'sportsbook-openapi-react';

import { includes } from 'utils/filters';
import { getAsyncLoadOptions } from 'utils/select';
import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { useLazyLoading } from 'hooks/useLazyLoading';
import { useRequestState } from 'hooks/useRequestState';
import { useSearch } from 'hooks/useSearch';
import { useTheme } from 'styles';

import { AsyncSelect, TextInput, TextInputCell } from 'app/components/forms';
import { PlayerCreatePanel, PlayerEditPanel } from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, LoadingIndicator, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getCompetitorsEntries } from 'app/providers/EntitiesProvider/competitors';
import {
  getPlayersHasMore,
  selectPlayersItems,
} from 'app/providers/EntitiesProvider/players';

import { useUserStore } from 'store';
import { getIsTranslator } from 'store/user';

import * as S from './Players,styles';

export function Players() {
  const [requestedCompetitors, setRequestCompetitors] = useState<number[]>([]);

  const items = useSelector(selectPlayersItems);
  const { isLoading } = useRequestState(Entities.PLAYERS, 'fetchItems');
  const hasMore = useSelector(getPlayersHasMore);

  const entries = useSelector(getCompetitorsEntries);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const theme = useTheme();

  const [isCreatePlayerVisible, showCreatePlayer, hideCreatePlayer] =
    useBooleanState();
  const [editPlayerId, setEditPlayerId] = useState<null | number>(null);

  const [competitors, setCompetitors] = useState<Competitor[] | null>([]);
  const isTranslator = useUserStore(getIsTranslator);

  const onLazyLoad = useCallback(
    ({ limit, offset }) =>
      dispatch(
        actionsNT.playersFetchItems({
          limit,
          offset,
          withPagination: true,
          name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
          competitorIds: competitors?.map(competitor => competitor.id),
        }),
      ),
    [competitors, dispatch, searchQuery],
  );

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [searchQuery, competitors],
    onPaginationReset: () => {
      dispatch(actionsNT.playersReset());
    },
  });

  useEffect(() => {
    const competitorsToRequest = items
      .flatMap(item => item.competitors)
      .filter(c => !requestedCompetitors.includes(c));
    if (competitorsToRequest.length) {
      dispatch(
        actionsNT.competitorsFetchItems({
          ids: competitorsToRequest,
          withPagination: true,
        }),
      );
      setRequestCompetitors(prev => uniq([...prev, ...competitorsToRequest]));
    }
  }, [dispatch, items, requestedCompetitors]);

  const handleUpdatePlayer = useCallback(
    (player: Player) => {
      dispatch(
        actionsNT.playersUpdate({
          playerId: +player?.id!,
          playerWithOutId: {
            ...omit(player, 'id'),
          } as PlayerWithOutId,
        }),
      );
    },
    [dispatch],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.PLAYERS,
  });

  const columns: Column<Player>[] = useMemo(
    () => [
      {
        Header: t('name').toString(),
        accessor: 'name',
        Cell: ({ value, row: { original: data } }) => {
          return !isTranslator ? (
            <TextInputCell
              defaultValue={value}
              onSave={newValue => {
                handleUpdatePlayer({
                  ...data,
                  name: newValue,
                });
              }}
              validate={value => value.length > 3}
            />
          ) : (
            <>{value}</>
          );
        },
      },
      {
        Header: t('competitor').toString(),
        accessor: 'competitors',
        Cell: ({ value }) => (
          <>
            {value
              .filter(cId => !!entries[cId])
              .map(cId => entries[cId].name)
              .join(', ')}
          </>
        ),
      },
      ...(!isTranslator
        ? [
            {
              Header: '',
              id: 'action',
              Cell: ({ row: { original: data } }) => (
                <S.ButtonsWrapper>
                  <Button
                    color="action"
                    onClick={() => {
                      setEditPlayerId(data.id);
                    }}
                  >
                    {t('edit')}
                  </Button>
                  <Button
                    color="action"
                    onClick={() => {
                      openAudit({ id: data.id, includeRelated: false });
                    }}
                  >
                    {t('audit')}
                  </Button>
                </S.ButtonsWrapper>
              ),
            },
          ]
        : []),
    ],
    [t, isTranslator, handleUpdatePlayer, entries, openAudit],
  );

  const filteredData = useMemo(
    () => items.filter(player => includes(player.name!, searchQuery)),
    [items, searchQuery],
  );

  return (
    <S.Wrapper>
      <S.DropdownWrapper>
        <DictionaryDropdown value={'players'} />
      </S.DropdownWrapper>
      <S.ContentWrapper>
        <S.Header>
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.CUSTOMER,
              UserGroupEnum.OPERATOR,
              UserGroupEnum.SUPERVISOR,
            ]}
          >
            <Button onClick={showCreatePlayer}>{t('add player')}</Button>
          </RoleGuards>
          <TextInput
            placeholder={t('search')}
            onChange={e => setSearchQueryDebounced(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />
          <S.SelectWrapper>
            <AsyncSelect
              isMulti
              placeholder={t('competitor')}
              loadOptions={getAsyncLoadOptions({ entity: 'competitors' })}
              value={competitors}
              onChange={value => {
                if (Array.isArray(value)) {
                  setCompetitors(value);
                }
              }}
              isClearable
            />
          </S.SelectWrapper>
        </S.Header>
        <Table
          columns={columns}
          data={filteredData}
          onBottom={onBottom}
          columnsWidth={['auto', 'auto', 'fit-content']}
          rowStyles={row =>
            row.disabled
              ? `background-color: ${theme.colors.primary[50]} !important;
          &:hover {
            background-color: ${theme.colors.primary[50]} !important;
          }
          `
              : ''
          }
        />
        {isLoading && <LoadingIndicator type="absolute" />}
      </S.ContentWrapper>
      {isCreatePlayerVisible && (
        <PlayerCreatePanel onClose={hideCreatePlayer} />
      )}
      {!!editPlayerId && (
        <PlayerEditPanel
          onClose={() => {
            setEditPlayerId(null);
          }}
          id={editPlayerId}
        />
      )}
    </S.Wrapper>
  );
}
