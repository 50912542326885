import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { Entities } from 'app/providers/EntitiesProvider/types';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getPlayersSubstate = (state: RootState) =>
  getEntities(state)[Entities.PLAYERS];

export const getPlayersEntries = (state: RootState) =>
  getPlayersSubstate(state).entries;

export const getPlayersHasMore = (state: RootState) =>
  getPlayersSubstate(state).hasMore;

export const selectPlayersItems = createSelector(getPlayersSubstate, players =>
  getEntityItems(players),
);

export const getPlayerById = (state: RootState, id: number) =>
  getPlayersSubstate(state).entries[id];
