import {
  Group,
  GroupList,
  GroupsApiDeleteGroupRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    GroupList,
    Group,
    GroupsApiDeleteGroupRequest,
    EntitiesErrorType
  >({
    entity: 'groups',
  }),
};

export default reducer;
