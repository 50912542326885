import dayjs from 'dayjs';

const MS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;

export const getDateFromDateAndTime = (
  date: Maybe<Date>,
  time: Maybe<Date>,
) => {
  if (!date || !time) return new Date();
  try {
    const dateStr = dayjs(date).format('YYYY-MM-DD');
    const timeStr = dayjs(time).format('HH-mm');
    const [year, month, day, hour, minute] = `${dateStr}-${timeStr}`
      .split('-')
      .map(i => +i);

    return new Date(year, month - 1, day, hour, minute);
  } catch (e) {
    return new Date();
  }
};

export const getMinutesSeconds = (timestamp: number) => {
  const secondsRaw = timestamp / MS_IN_SECOND;
  const minutes = Math.floor(secondsRaw / SECONDS_IN_MINUTE);
  const seconds = secondsRaw - minutes * SECONDS_IN_MINUTE;
  return { minutes, seconds };
};

const formatDecimal = (value: number) =>
  value > 9 ? String(value) : `0${value}`;

export const getMinutesSecondsFormatted = (timestamp: number) => {
  const { minutes, seconds } = getMinutesSeconds(timestamp);

  return `${formatDecimal(minutes)}:${formatDecimal(seconds)}`;
};

export const parseDate = (date?: Date | string) => {
  if (!date) return undefined;
  return new Date(date);
};
