import { ls } from 'utils/ls';

export const API_URL = ['development', 'test'].includes(
  process.env.NODE_ENV as string,
)
  ? '/sportsbook-api'
  : ls.getItem('API_URL');

export const MODELS_UI_URL = ['development', 'test'].includes(
  process.env.NODE_ENV as string,
)
  ? 'https://sportsbook-models-ui.sbsandbox.betdev.cloud'
  : ls.getItem('MODELS_UI_URL');

export const API_KEY = 'sportsbook-api';
export const MODELS_UI_KEY = 'sportsbook-models-ui';

export const IS_DELETION_DISABLED = true;
export const IS_DELETION_DISABLED_KEY = 'IS_DELETION_DIALOG_SHOWED';

export const clearLSConfig = () => {
  ls.removeItem('API_URL');
  ls.removeItem('MODELS_UI_URL');
};

export const RU_LANG_CODE = 'RU';

export * from './requests';
export * from './sports';
