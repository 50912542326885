import { PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash-es/keyBy';
import { Exclusion } from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<Exclusion[], Exclusion, void, EntitiesErrorType>({
    entity: 'exclusions',
  }),
  exclusionsFetchItemsSuccess(state, action: PayloadAction<Exclusion[]>) {
    state.exclusions.entries = keyBy(
      action.payload,
      item => `${item.entityType}_${item.entityId}`,
    );
    state.exclusions.fetchItems.loading = false;
  },
  exclusionsUpdateSuccess(state, action: PayloadAction<Exclusion>) {
    state.exclusions.entries[
      `${action.payload.entityType}_${action.payload.entityId}`
    ] = action.payload;
    state.exclusions.update.loading = false;
  },
};

export default reducer;
