import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeneralValuesPopup } from 'app/pages/Events/components/Odds/GeneralValuesPopup';
import { SelectedCategory } from 'app/pages/Events/utils';
import { compareByPriority } from 'app/pages/Events/utils/sort';
import { get } from 'lodash-es';
import {
  EntityType,
  Event,
  EventSource,
  SportEventOddCategory,
  UserGroupEnum,
} from 'sportsbook-openapi-react';

import { includesText } from 'utils/string';
import { useRequestState } from 'hooks/useRequestState';
import { useSearch } from 'hooks/useSearch';
import { FETCH_ALL_LIMIT } from 'consts';
import { RootState } from 'types';

import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Popup, Table } from 'app/components/ui';
import { ColumnWidth } from 'app/components/ui/Table/Table.types';
import {
  BetpaymaxColumn,
  CashoutColumn,
  DelayColumn,
  ExclusionColumn,
  MarginColumn,
  MaxOddColumn,
} from 'app/components/сolumns';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCategoriesEntries } from 'app/providers/EntitiesProvider/categories';
import { getEventMappings } from 'app/providers/EntitiesProvider/eventsMappings';
import { getEventOddsByEventId } from 'app/providers/EntitiesProvider/eventsOdds';
import {
  useBetPayMax,
  useCategoryCashoutBlock,
  useCategoryExclusion,
  useDelay,
  useMargin,
} from 'app/providers/EntitiesProvider/hooks';
import { useMaxOdd } from 'app/providers/EntitiesProvider/hooks/useMaxOdd';
import { getSourcesEntries } from 'app/providers/EntitiesProvider/sources';

import { useUserStore } from 'store';
import { getIsCustomer, getIsOperator } from 'store/user';

import * as S from './OddsCategoriesTable.styles';

interface Props {
  event: Event;
  selectedCategory: SelectedCategory;
  selectCategoryId: React.Dispatch<React.SetStateAction<number>>;
}

const othersColumnsWidth: ColumnWidth[] = [
  'auto',
  'fit-content',
  5,
  5,
  'min-content',
  'auto',
];
const customerColumnsWidth: ColumnWidth[] = [
  'auto',
  5,
  5,
  30,
  5,
  'auto',
  10,
  'min-content',
];

const getRowStyles = () => `
  cursor: pointer;
`;

const sourceToCode = {
  [EventSource.BETCONSTRUCT]: 'BC',
  [EventSource.BETGENIUS]: 'BG',
  [EventSource.BETRADAR]: 'BR',
  [EventSource.MODEL]: 'ML',
  [EventSource.LSPORTS]: 'LS',
  [EventSource.BETBAZAR]: 'BB',
  [EventSource.BASELINE]: 'BL',
  [EventSource.FONBET]: 'FB',
  [EventSource.BETER]: 'BTR',
  [EventSource.OPENBET]: 'OB',
  [EventSource.SPORTLEVEL]: 'SL',
  [EventSource.MAXSPORTS]: 'MS',
};

export const OddsCategoriesTable: FC<Props> = ({
  event,
  selectedCategory,
  selectCategoryId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const sourcesRaw = useSelector(getSourcesEntries);

  const odds = useSelector((state: RootState) =>
    getEventOddsByEventId(state, event.id),
  );

  const categories = useSelector(getCategoriesEntries);
  const mappings = useSelector(state => getEventMappings(state, event.id));
  const { isCustomer, customerId } = useUserStore(getIsCustomer);
  const isOperator = useUserStore(getIsOperator);

  const { delays, updateDelayValue, openDelayAudit } = useDelay({
    entityId: event.id,
    entityType: EntityType.SPORT_EVENT,
  });
  const { betPayMaxes, updateBetPayMaxValue, openBetPayMaxAudit } =
    useBetPayMax({
      entityId: event.id,
      entityType: EntityType.SPORT_EVENT,
      live: event.live,
    });
  const { openMarginAudit, margins, updateMarginValue } = useMargin({
    entityId: event.id,
    entityType: EntityType.SPORT_EVENT,
    live: event.live,
  });
  const { maxOdds, updateMaxOddValue } = useMaxOdd({
    entityId: event.id,
    entityType: EntityType.SPORT_EVENT,
    live: event.live,
  });
  const { categoryExclusions, updateCategoryExclusion, openExclusionAudit } =
    useCategoryExclusion({
      entityId: event.id,
      entityType: EntityType.SPORT_EVENT,
      live: event.live,
    });

  const { categoryCashoutBlocks, updateCategoryCashoutBlock } =
    useCategoryCashoutBlock({
      entityId: event.id,
      entityType: EntityType.SPORT_EVENT,
      live: event.live,
    });

  const handleCategoryClick = useCallback(
    data => {
      selectCategoryId(data.outcomeCategoryId);
    },
    [selectCategoryId],
  );

  const fetchBetPayMax = useCallback(() => {
    if (odds?.categories && isCustomer) {
      dispatch(
        actionsNT.betPayMaxByEventFetchItems({
          customerId: customerId!,
          eventId: event.id,
          outcomeCategoryIds: odds?.categories.map(c => c.outcomeCategoryId),

          limit: FETCH_ALL_LIMIT,
        }),
      );
      dispatch(
        actionsNT.delaysByEventFetchItems({
          customerId: customerId!,
          eventId: event.id,
          outcomeCategoryIds: odds?.categories.map(c => c.outcomeCategoryId),
        }),
      );
    }
    dispatch(
      actionsNT.marginsByEventFetchItems({
        customerId,
        live: event.live,
        eventId: event.id,
        outcomeCategoryIds: odds?.categories.map(c => c.outcomeCategoryId),
      }),
    );
  }, [
    odds?.categories,
    isCustomer,
    dispatch,
    customerId,
    event.live,
    event.id,
  ]);

  useRequestState('eventsOdds', 'fetchItem', fetchBetPayMax);

  const generalOddsColumns = useMemo<Column<SportEventOddCategory>[]>(
    () => [
      {
        Header: t('category').toString(),
        accessor: 'outcomeCategoryId',
        Cell: ({ row: { original: data } }) => (
          <>
            {categories[data.outcomeCategoryId]?.name || data.outcomeCategoryId}
          </>
        ),
      },
      ...(!isCustomer
        ? [
            {
              Header: t('source').toString(),
              id: 'source',
              Cell: ({ row: { original: data } }) => {
                const source = get(
                  sourcesRaw,
                  `${
                    get(mappings, `[${data.outcomeCategoryId}].source`) ??
                    event.mainSource ??
                    odds?.mainSource
                  }`,
                );
                return (
                  <div title={source?.name}>{sourceToCode[source?.code]}</div>
                );
              },
            },
          ]
        : []),
      MarginColumn<SportEventOddCategory>({
        header: t('margin short').toString(),
        accessor: 'outcomeCategoryId',
        updateMarginValue,
        margins,
        isEditable: true,
        isOperator: isOperator,
      }),
      MaxOddColumn<SportEventOddCategory>({
        header: t('maxOdd short').toString(),
        accessor: 'outcomeCategoryId',
        updateMaxOddValue,
        maxOdds,
        isEditable: true,
        isOperator: isOperator,
      }),
      ...(isCustomer
        ? [
            BetpaymaxColumn<SportEventOddCategory>({
              header: t('maxbet short').toString(),
              accessor: 'outcomeCategoryId',
              updateBetPayMaxValue,
              betPayMaxes,
            }),
            ...(event.live
              ? [
                  DelayColumn<SportEventOddCategory>({
                    header: t('delay').toString(),
                    accessor: 'outcomeCategoryId',
                    updateDelayValue,
                    delays,
                  }),
                ]
              : []),
            ExclusionColumn<SportEventOddCategory>({
              header: t('exclusion short').toString(),
              accessor: 'outcomeCategoryId',
              updateCategoryExclusion,
              categoryExclusions,
            }),
            CashoutColumn<SportEventOddCategory>({
              header: t('cashout block').toString(),
              accessor: 'outcomeCategoryId',
              updateCategoryCashoutBlock,
              categoryCashoutBlocks,
            }),
            {
              Header: t('audit').toString(),
              accessor: 'outcomeCategoryId',
              id: 'audit',
              Cell: ({ value }) => (
                <Popup
                  placement="bottom-end"
                  button={
                    <Button color="action">
                      {t('audit')}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </Button>
                  }
                  body={
                    <div>
                      <Button
                        color="tretiary"
                        onClick={() => {
                          openMarginAudit(value);
                        }}
                      >
                        {t('margin')}
                      </Button>
                      <Button
                        color="tretiary"
                        onClick={() => {
                          openBetPayMaxAudit(value);
                        }}
                      >
                        {t('maxbet')}
                      </Button>
                      <Button
                        color="tretiary"
                        onClick={() => {
                          openDelayAudit(value);
                        }}
                      >
                        {t('delay')}
                      </Button>
                      <Button
                        color="tretiary"
                        onClick={() => {
                          openExclusionAudit(value);
                        }}
                      >
                        {t('exclusion')}
                      </Button>
                    </div>
                  }
                />
              ),
            } as Column<SportEventOddCategory>,
          ]
        : [
            {
              Header: '',
              accessor: 'outcomeCategoryId',
              id: 'audit',
              Cell: ({ value }) => (
                <S.ButtonWrapper>
                  <Button
                    color="action"
                    onClick={() => {
                      openMarginAudit(value);
                    }}
                  >
                    {t('audit')}
                  </Button>
                </S.ButtonWrapper>
              ),
            } as Column<SportEventOddCategory>,
          ]),
    ],
    [
      t,
      isCustomer,
      updateMarginValue,
      margins,
      isOperator,
      updateMaxOddValue,
      maxOdds,
      updateBetPayMaxValue,
      betPayMaxes,
      event.live,
      event.mainSource,
      updateDelayValue,
      delays,
      updateCategoryExclusion,
      categoryExclusions,
      updateCategoryCashoutBlock,
      categoryCashoutBlocks,
      categories,
      sourcesRaw,
      mappings,
      odds?.mainSource,
      openMarginAudit,
      openBetPayMaxAudit,
      openDelayAudit,
      openExclusionAudit,
    ],
  );

  const allCategories = useMemo(
    () => odds?.categories ?? [],
    [odds?.categories],
  );

  const filteredCategories = useMemo(
    () =>
      [
        ...allCategories.filter(
          c =>
            categories[c.outcomeCategoryId] &&
            includesText(categories[c.outcomeCategoryId].name, searchQuery),
        ),
      ].sort((a, b) => {
        const categoryA = categories[a.outcomeCategoryId];
        const categoryB = categories[b.outcomeCategoryId];

        if (!categoryA || !categoryB) return 1;
        return compareByPriority(categoryA, categoryB);
      }),
    [categories, allCategories, searchQuery],
  );

  const isSelected = useCallback(
    (row: SportEventOddCategory) =>
      row.outcomeCategoryId === selectedCategory?.outcomeCategoryId,
    [selectedCategory],
  );

  const columnsWidth = isCustomer ? customerColumnsWidth : othersColumnsWidth;

  return (
    <S.OddsGeneralTable id="table">
      <S.CategoriesTableControls>
        <S.Search
          placeholder={t('search')}
          onChange={e => setSearchQueryDebounced(e.target.value)}
          icon={<FontAwesomeIcon icon={faSearch} />}
        />
        <RoleGuards roles={[UserGroupEnum.CUSTOMER]}>
          <Popup
            placement="bottom-end"
            button={
              <Button color="action">
                {t('set general values')}
                <FontAwesomeIcon icon={faChevronDown} />
              </Button>
            }
            body={
              <GeneralValuesPopup
                entityId={event.id}
                entityType={EntityType.SPORT_EVENT}
                live={event.live}
                outcomeCategoriesIds={allCategories.map(
                  c => c.outcomeCategoryId,
                )}
              />
            }
          />
        </RoleGuards>
      </S.CategoriesTableControls>
      <Table
        columns={generalOddsColumns}
        data={filteredCategories}
        onRowClick={handleCategoryClick}
        isSelected={isSelected}
        rowStyles={getRowStyles}
        columnsWidth={columnsWidth}
      />
    </S.OddsGeneralTable>
  );
};
