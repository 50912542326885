import { PayloadAction } from '@reduxjs/toolkit';
import { auditApi } from 'app/api';
import { HTTPError } from 'ky';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AuditApi, AuditResponse } from 'sportsbook-openapi-react';

import { getEntityPaginationToken } from './selectors';
import { actions } from './slice';
import { AuditedEntities, GetAuditPayload } from './types';

const entityToMethod: Record<AuditedEntities, keyof AuditApi> = {
  [AuditedEntities.CUSTOMERS]: 'getAuditByCustomerEntity',
  [AuditedEntities.COMMENTS]: 'getAuditByComment',
  [AuditedEntities.COMPETITORS]: 'getAuditByCompetitors',
  [AuditedEntities.COUNTRIES]: 'getAuditByCountry',
  [AuditedEntities.CURRENCIES]: 'getAuditByCurrency',
  [AuditedEntities.EVENTS]: 'getAuditBySportEvent',
  [AuditedEntities.GROUPS]: 'getAuditBySportGroup',
  [AuditedEntities.TOURNAMENTS]: 'getAuditByTournament',
  [AuditedEntities.SPORTS]: 'getAuditBySport',
  [AuditedEntities.LANGUAGES]: 'getAuditByLanguage',
  [AuditedEntities.MATCH_PHASES]: 'getAuditByMatchPhase',
  [AuditedEntities.MATCH_TYPES]: 'getAuditByMatchType',
  [AuditedEntities.OUTCOME_MARKETS]: 'getAuditByMarket',
  [AuditedEntities.OUTCOME_CATEGORIES]: 'getAuditByOutcomeCategory',
  [AuditedEntities.OUTCOME_TYPES]: 'getAuditByOutcomeType',
  [AuditedEntities.PLAYERS]: 'getAuditByPlayer',
  [AuditedEntities.TAGS]: 'getAuditByTag',
  [AuditedEntities.CROSS_MAPPINGS]: 'getAuditByOutcomeTypeCross',
  [AuditedEntities.ENTITY_COMMENTS]: 'getAuditByEntityComment',
  [AuditedEntities.ENTITY_BETPAYMAXES]: 'getAuditByBetPayMax',
  [AuditedEntities.ENTITY_DELAYS]: 'getAuditByDelay',
  [AuditedEntities.ENTITY_MARGINS]: 'getAuditByMargin',
  [AuditedEntities.ENTITY_EXCLUSIONS]: 'getAuditByExclusion',
  [AuditedEntities.ENTITY_TAGS]: 'getAuditByEntityTag',
  [AuditedEntities.ENTITY_MAXODDS]: 'getAuditByMaxOdd',
  [AuditedEntities.EVENT_MAPPING]: 'getAuditBySourceEventMapping',
  [AuditedEntities.EVENT_MAIN_SOURCE]: 'getAuditBySportEventMainSource',
  [AuditedEntities.CUSTOMER_CATEGORY_EXLUSION]: 'getAuditByCategoryExclusion',
  [AuditedEntities.CUSTOMER_EVENT_STATUS]: 'getAuditByCustomerEventStatus',
  [AuditedEntities.CUSTOMER_EVENT_SUSPEND]: 'getAuditBySportEventSuspend',
  [AuditedEntities.USER_ACTIONS]: 'getAuditByUser',
  [AuditedEntities.CUSTOMER_ACTIONS]: 'getAuditByCustomer',
};

export function* handleGetAudit<T extends AuditedEntities>(
  action: PayloadAction<GetAuditPayload<T>>,
) {
  const { entity, payload } = action.payload;
  const paginationToken = yield select(state =>
    getEntityPaginationToken(state, entity),
  );

  if (paginationToken && payload.auditRequest) {
    payload.auditRequest.paginationToken = paginationToken;
  }

  try {
    const response: AuditResponse = yield call(function () {
      return auditApi[entityToMethod[entity]](payload as any);
    });

    yield put(actions.getAuditSuccess({ response, entity }));
  } catch (e) {
    let err = '';
    if (e instanceof HTTPError) {
      err = e.message;
    }
    yield put(actions.getAuditError({ error: err, entity }));
  }
}

export function* auditProviderSaga() {
  yield takeLatest(actions.getAudit, handleGetAudit);
}
