import { PayloadAction } from '@reduxjs/toolkit';
import keyBy from 'lodash-es/keyBy';
import {
  CategoryExclusion,
  ExclusionsApiDeleteCategoryExclusionByCustomerRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    CategoryExclusion[],
    CategoryExclusion,
    void,
    EntitiesErrorType
  >({
    entity: 'categoryExclusions',
  }),
  categoryExclusionsFetchItemsSuccess(
    state,
    action: PayloadAction<CategoryExclusion[]>,
  ) {
    state.categoryExclusions.entries = {
      ...state.categoryExclusions.entries,
      ...keyBy(action.payload, 'categoryId'),
    };
    state.categoryExclusions.fetchItems.loading = false;
  },
  categoryExclusionsFetchItemSuccess(
    state,
    action: PayloadAction<CategoryExclusion>,
  ) {
    const { categoryId } = action.payload;
    state.categoryExclusions.entries[categoryId] = action.payload;
    state.categoryExclusions.fetchItem.loading = false;
  },
  categoryExclusionsUpdateSuccess(
    state,
    action: PayloadAction<CategoryExclusion>,
  ) {
    const { categoryId } = action.payload;
    state.categoryExclusions.entries[categoryId] = action.payload;
    state.categoryExclusions.update.loading = false;
  },
  categoryExclusionsDelete(
    state,
    action: PayloadAction<ExclusionsApiDeleteCategoryExclusionByCustomerRequest>,
  ) {
    const { categoryId } = action.payload;
    delete state.categoryExclusions.entries[categoryId];
    state.categoryExclusions.delete.loading = true;
  },
};

export default reducer;
