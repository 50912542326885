import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Group as GroupType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { IS_DELETION_DISABLED } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import { FormValues, validationSchema } from './constants';
import { GroupForm } from './GroupForm';

interface Props {
  onClose: () => void;
  id: number;
  sportId?: number;
}

export const GroupEditPanel = ({ onClose, id, sportId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const group: GroupType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.GROUPS, id),
  );
  const sports = useSelector(getSportsEntries);

  useRequestState(Entities.GROUPS, 'update', onClose);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.groupsUpdate({
        groupId: group.id,
        groupWithOutId: {
          sportId: values.sport?.id!,
          name: values.name,
          translations: group.translations,
        },
      }),
    );
  };

  const onDelete = () => {
    dispatch(actionsNT.groupsDelete({ groupId: group.id }));
    onClose();
  };

  return (
    <Formik
      initialValues={{
        name: group.name,
        sport: sports[group.sportId],
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.group')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
              leftButtonText={t('delete')}
              leftButtonDisabled={IS_DELETION_DISABLED}
              onLeftButtonClick={onDelete}
            />
          }
        >
          <GroupForm sportId={sportId} sports={sports} actionType="edit" />
        </Panel>
      )}
    </Formik>
  );
};
