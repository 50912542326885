import { createPortal } from 'react-dom';

import { SlidePanel } from '..';

interface PanelProps {
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  footer?: JSX.Element;
  onSubmit?: () => void;
  panelWidth?: number;
}

export const Panel = ({
  title,
  onClose,
  onSubmit,
  children,
  footer,
  panelWidth,
}: PanelProps) => {
  return createPortal(
    <SlidePanel
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
      panelWidth={panelWidth}
      footer={footer}
    >
      {children}
    </SlidePanel>,
    document.getElementById('root') as HTMLElement,
  );
};
