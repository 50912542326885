import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Logo, LogoEntityType, LogoKind } from 'sportsbook-openapi-react';
import styled from 'styled-components';

import { useRequestState } from 'hooks/useRequestState';

import { Select } from 'app/components/forms';
import { Button, Span, Table } from 'app/components/ui';
import { ColumnWidth } from 'app/components/ui/Table/Table.types';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { selectLogosItems } from 'app/providers/EntitiesProvider/logos';

const kindOptions = Object.values(LogoKind).map(kind => ({
  name: kind,
  id: kind,
}));

interface Props {
  entityType: LogoEntityType;
  entityId: number;
  withTitle?: boolean;
}

const columnsWidth: ColumnWidth[] = [
  'fit-content',
  'auto',
  'auto',
  'fit-content',
];

export const LogoUploader: FC<Props> = ({
  entityType,
  entityId,
  withTitle = true,
}) => {
  const [state, setState] = useState<{
    file: File | null;
    kind: LogoKind | null;
    path: string;
  }>({
    file: null,
    kind: LogoKind.MAIN,
    path: '',
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const items = useSelector(selectLogosItems);

  const uploadLogo = (event: ChangeEvent<HTMLInputElement>) => {
    var fr = new FileReader();
    fr.onloadend = function () {
      if (typeof fr.result === 'string' && event.target.files !== null) {
        setState(prev => ({
          ...prev,
          file: event.target.files![0],
          path: fr.result as string,
        }));
      }
    };
    if (event.target.files !== null) {
      fr.readAsDataURL(event.target.files?.[0]);
    }
  };

  const { isLoading } = useRequestState(Entities.LOGOS, 'createList', () =>
    setState(prev => ({ ...prev, file: null })),
  );

  const handleUploadLogo = () => {
    if (state.kind && state.file) {
      dispatch(
        actionsNT.logosCreateList({
          entityId,
          entityType,
          kind: state.kind!,
          file: state.file!,
        }),
      );
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };

  useEffect(() => {
    dispatch(actionsNT.logosFetchItems({ entityType, entityId }));
  }, [dispatch, entityId, entityType]);

  const handleDelete = useCallback(
    (id: number) => () => {
      dispatch(
        actionsNT.logosDelete({
          entityId,
          entityType,
          ids: [id],
        }),
      );
    },
    [dispatch, entityId, entityType],
  );

  const columns: Column<Logo>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'url',
        Cell: ({ value }) => {
          return <img src={value} alt="logo" style={{ maxWidth: '50px' }} />;
        },
      },
      {
        Header: t('extension').toString(),
        accessor: 'id',
        Cell: ({ row: { original: data } }) => {
          return (
            <>
              {data.extension ? (
                <StyledLink href={data.url!} target="_blank">
                  <>{data.extension.toLocaleLowerCase()} </>
                </StyledLink>
              ) : null}
              {data.thumbnails?.map(item => (
                <Fragment key={item.url}>
                  <StyledLink href={item.url} target="_blank">
                    {item.width}x{item.height}
                  </StyledLink>{' '}
                </Fragment>
              ))}
            </>
          );
        },
      },
      {
        Header: t('type').toString(),
        accessor: 'kind',
      },
      {
        Header: '',
        id: 'action',
        accessor: 'id',
        Cell: ({ value }) => (
          <Button color="link" onClick={handleDelete(value)} type="button">
            {t('delete')}
          </Button>
        ),
      },
    ],
    [handleDelete, t],
  );

  return (
    <Wrapper>
      {withTitle ? <Span>{t('logos')}</Span> : null}
      <Label htmlFor="file-input">
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
        {t('chose file')}
        <StyledInput
          ref={inputRef}
          id="file-input"
          type="file"
          accept=".svg, .jpg, .jpeg, .png"
          onChange={uploadLogo}
        />
      </Label>
      {state.file && (
        <>
          <ImageWrapper>
            <StyledImage src={state.path} alt="uploaded logo" />
            <StyledButton
              onClick={() => setState(prev => ({ ...prev, file: null }))}
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{ color: 'white' }}
              />
            </StyledButton>
          </ImageWrapper>
          <ButtonsWrapper>
            <Select
              options={kindOptions}
              value={state.kind ? { name: state.kind, id: state.kind } : null}
              onChange={value =>
                setState(prev => ({ ...prev, kind: value!.id }))
              }
            />
            <Button
              onClick={handleUploadLogo}
              color="action"
              type="button"
              disabled={isLoading}
            >
              {t('upload')}
            </Button>
          </ButtonsWrapper>
        </>
      )}
      <Table columns={columns} data={items} columnsWidth={columnsWidth} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
`;

const StyledInput = styled.input`
  display: none;
`;

const Label = styled.label`
  gap: 10px;
  width: 150px;
  background-color: ${p => p.theme.colors.primary[40]};
  border-radius: 4px;
  color: ${p => p.theme.colors.primary[110]};

  &:hover {
    background-color: ${p => p.theme.colors.primary[30]};
  }

  text-align: center;
  cursor: pointer;
  padding: 5px 0;
`;

const StyledImage = styled.img`
  width: 60px;
`;

const ImageWrapper = styled.div`
  width: 60px;
  position: relative;
`;

const StyledButton = styled.button`
  position: absolute;
  top: 0px;
  right: -5px;
  background: none;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 300px;
`;

const StyledLink = styled.a`
  color: ${p => p.theme.colors.blue[300]};
  font-size: ${p => p.theme.fonts.typography['H200'].size};
  font-weight: ${p => p.theme.fonts.typography['H200'].weight};
  text-decoration: none;
  &:hover {
    color: ${p => p.theme.colors.blue[500]};
  }
`;
