import {
  Sport,
  SportList,
  SportsApiDeleteSportRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    SportList,
    Sport,
    SportsApiDeleteSportRequest,
    EntitiesErrorType
  >({
    entity: 'sports',
  }),
};

export default reducer;
