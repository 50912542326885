import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from 'app/pages/Sources/components/EntitiesTables/styles';
import { FormikProvider, useFormik } from 'formik';
import { AdapterMatchPhase, MatchPhase } from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';

import { SelectField } from 'app/components/forms';
import { MatchPhaseCreatePanel } from 'app/components/panels';
import { Button, TextLoader } from 'app/components/ui';
import LargePlus from 'assets/images/large-plus.svg?react';

import { adapterActions } from 'app/providers/AdaptersProvider';

type Values = {
  sportsbookMatchPhase?: MatchPhase;
};

interface Props {
  mapped: boolean;
  adapter: string;
  matchPhase: AdapterMatchPhase;
  matchPhases: Dictionary<MatchPhase>;
  isEditing: boolean;
  setEditingRow: (editingId: string | undefined) => void;
}

export const MatchPhaseRow = ({
  matchPhase,
  matchPhases,
  mapped,
  adapter,
  isEditing,
  setEditingRow,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [
    isCreateMatchPhasePanelVisible,
    showCreateMatchPhasePanel,
    hideCreateMatchPhasePanel,
  ] = useBooleanState();

  const options = Object.values(matchPhases);

  const handleDeleteMapping = () => {
    if (!matchPhase.id) return;
    dispatch(
      adapterActions.matchPhasesDeleteMapping({ id: matchPhase.id, adapter }),
    );
    setEditingRow(undefined);
  };

  const handleEdit = () => {
    setEditingRow(matchPhase.id);
  };

  const formik = useFormik<Values>({
    initialValues: {
      sportsbookMatchPhase: mapped
        ? matchPhases[matchPhase.sportsbookId!]
        : undefined,
    },
    onSubmit: ({ sportsbookMatchPhase }) => {
      dispatch(
        adapterActions.matchPhasesMap({
          id: matchPhase.id!,
          adapter,
          mappingRequest: {
            sportsbookId: sportsbookMatchPhase?.id!,
          },
          withDeletion: !mapped,
        }),
      );
      setEditingRow(undefined);
    },
    enableReinitialize: true,
  });

  const { values, submitForm, setFieldValue } = formik;

  const onMatchPhaseCreated = entity => {
    setFieldValue('sportsbookMatchPhase', entity);
  };

  return (
    <FormikProvider value={formik}>
      {mapped ? (
        <S.Tr>
          <S.Td>{matchPhase.name}</S.Td>
          {isEditing ? (
            <S.SelectTd>
              <SelectField name="sportsbookMatchPhase" options={options} />
              <Button color="action" onClick={showCreateMatchPhasePanel}>
                <LargePlus />
              </Button>
            </S.SelectTd>
          ) : (
            <S.Td>{values.sportsbookMatchPhase?.name || <TextLoader />}</S.Td>
          )}
          <S.Td>
            {isEditing ? (
              <S.ButtonsBlock key="editing">
                <S.ButtonsWrapper>
                  <Button
                    color="action"
                    onClick={submitForm}
                    disabled={!values.sportsbookMatchPhase}
                  >
                    <FontAwesomeIcon icon={faSave} />
                    {t('save')}
                  </Button>
                  <Button
                    color="action"
                    onClick={handleDeleteMapping}
                    disabled={!values.sportsbookMatchPhase}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    {t('delete')}
                  </Button>
                </S.ButtonsWrapper>
              </S.ButtonsBlock>
            ) : (
              <S.ButtonsBlock key="normal">
                <Button color="action" onClick={handleEdit}>
                  <FontAwesomeIcon icon={faEdit} />
                  {t('edit')}
                </Button>
              </S.ButtonsBlock>
            )}
          </S.Td>
        </S.Tr>
      ) : (
        <S.Tr>
          <S.Td weight={45}>{matchPhase.name}</S.Td>
          <S.SelectTd weight={45}>
            <SelectField name="sportsbookMatchPhase" options={options} />
            <Button color="action" onClick={showCreateMatchPhasePanel}>
              <LargePlus />
            </Button>
          </S.SelectTd>
          <S.Td weight={10} textAlign={'right'}>
            <Button
              onClick={submitForm}
              disabled={!values.sportsbookMatchPhase}
            >
              {t('map')}
            </Button>
          </S.Td>
        </S.Tr>
      )}
      {isCreateMatchPhasePanelVisible && (
        <MatchPhaseCreatePanel
          onClose={hideCreateMatchPhasePanel}
          onSuccess={onMatchPhaseCreated}
        />
      )}
    </FormikProvider>
  );
};
