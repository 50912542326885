import styled from 'styled-components';

import { Select } from 'app/components/forms';
import { Button } from 'app/components/ui';

export const Wrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 56px minmax(0, 1fr);
`;

export const AddButton = styled(Button)`
  grid-area: add;
`;

export const TextWrapper = styled.span`
  font-size: 14px;
  color: #000000;
  grid-area: text;
`;

export const StyledSelectNew = styled(Select)`
  grid-area: select;
`;

export const InputWrapper = styled.div`
  grid-area: input;
`;

export const Spacer = styled.div`
  grid-area: spacer;
`;

export const RefreshButton = styled(Button)`
  grid-area: refresh;
`;

export const ControlsWrapper = styled.div`
  display: grid;
  padding: 0 20px;
  grid-template-columns: auto auto 2fr 4fr 6fr auto;
  grid-template-areas: 'add text select input spacer refresh';
  align-items: center;
  grid-column-gap: 20px;
`;
