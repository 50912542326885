import { PayloadAction } from '@reduxjs/toolkit';
import {
  CustomersApiDeleteBetPayMaxRequest,
  CustomerToken,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import {
  entityReducersGenerator,
  toIdsArray,
  toIdsMap,
} from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    Array<CustomerToken>,
    CustomerToken,
    CustomersApiDeleteBetPayMaxRequest,
    EntitiesErrorType
  >({
    entity: 'customerTokens',
  }),
  customerTokensFetchItemsSuccess(
    state,
    action: PayloadAction<Array<CustomerToken>>,
  ) {
    state.customerTokens.entries = toIdsMap(action.payload);
    state.customerTokens.sortedIds = toIdsArray(action.payload);
    state.customerTokens.fetchItems.loading = false;
  },
};

export default reducer;
