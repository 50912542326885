import { PayloadAction } from '@reduxjs/toolkit';
import uniq from 'lodash-es/uniq';
import values from 'lodash-es/values';
import {
  User,
  UserListWrapper,
  UsersApiDeleteUserRequest,
} from 'sportsbook-openapi-react';

import { entityInitialState } from 'app/providers/EntitiesProvider/constants';
import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import {
  entityReducersGenerator,
  toIdsMap,
} from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    UserListWrapper,
    User,
    UsersApiDeleteUserRequest,
    EntitiesErrorType
  >({
    entity: 'users',
  }),
  usersFetchItemsSuccess(
    state,
    action: PayloadAction<Required<UserListWrapper>>,
  ) {
    const { userList } = action.payload;
    state.users.entries = {
      ...state.users.entries,
      ...toIdsMap(userList),
    };
    state.users.sortedIds = uniq([
      ...state.users.sortedIds,
      ...userList.map(user => user.id),
    ]);
    state.users.fetchItems.loading = false;
    state.users.paginationToken = action.payload.paginationToken;
  },
  usersDelete(state, action: PayloadAction<UsersApiDeleteUserRequest>) {
    const { username } = action.payload;
    const deletedUser = values(state.users.entries).find(
      user => user.name === username,
    );
    if (deletedUser?.id) {
      delete state.users.entries[deletedUser?.id!];
      state.users.sortedIds = state.users.sortedIds.filter(
        id => id !== deletedUser.id,
      );
    }
    state.users.delete.loading = true;
  },
  usersReset(state) {
    state.users = { ...entityInitialState, paginationToken: '' };
  },
};

export default reducer;
