import { AuditEntityState, ContainerState } from './types';

export const initialState: ContainerState = {
  entities: {},
  panel: null,
};

export const entityInitialState: AuditEntityState = {
  isLoading: false,
  items: [],
  paginationToken: null,
};
