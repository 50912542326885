import styled from 'styled-components';

import { StyledTextProps } from './Text.types';

export const StyledSpan = styled.span<StyledTextProps>`
  color: ${p =>
    ({
      100: p.theme.colors.primary[900],
      95: p.theme.colors.primary[800],
      90: p.theme.colors.primary[700],
      85: p.theme.colors.primary[600],
      80: p.theme.colors.primary[500],
      75: p.theme.colors.primary[400],
      70: p.theme.colors.primary[300],
      65: p.theme.colors.primary[200],
      60: p.theme.colors.primary[100],
      55: p.theme.colors.primary[90],
      50: p.theme.colors.primary[80],
      45: p.theme.colors.primary[70],
      40: p.theme.colors.primary[60],
      35: p.theme.colors.primary[50],
      30: p.theme.colors.primary[40],
      25: p.theme.colors.primary[30],
      20: p.theme.colors.primary[20],
      15: p.theme.colors.primary[10],
      10: p.theme.colors.primary[0],
    })[p.textColor]};
  font-size: ${p =>
    ({
      h1: 34,
      h2: 28,
      h3: 24,
      h4: 20,
      title: 18,
      l: 16,
      m: 14,
      s: 12,
    })[p.size]}px;
  font-weight: ${p => p.weight};
  font-family: Roboto, sans-serif;
`;
