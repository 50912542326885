import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { OutcomeCategory } from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import useBooleanState from 'hooks/useBooleanState';

import {
  AsyncSelectField,
  SelectField,
  TextInputField,
} from 'app/components/forms';
import { CategoryCreatePanel } from 'app/components/panels/category';
import { MarketCreatePanel } from 'app/components/panels/market';
import { Span } from 'app/components/ui';

import { FormValues, marketLinkOptions } from './constants';
import * as S from './styles';

interface Props {
  categories: Dictionary<OutcomeCategory>;
}

export const OutcomeForm = ({ categories }: Props) => {
  const { t } = useTranslation();
  const [isCreateCategoryVisible, showCreateCategory, hideCreateCategory] =
    useBooleanState();
  const [isCreateMarketVisible, showCreateMarket, hideCreateMarket] =
    useBooleanState();

  const {
    values: { category },
  } = useFormikContext<FormValues>();

  const createCategoryOption: any = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreateCategory,
  };
  const createMarketOption = {
    label: t('create'),
    isButton: true,
    notSelectable: true,
    onClick: showCreateMarket,
  };
  const categoriesOptions = [
    createCategoryOption,
    ...Object.values(categories),
  ];

  return (
    <>
      <S.FormBlock>
        <Span>{t('name')}</Span>
        <TextInputField name="name" />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('category')}</Span>
        <SelectField name="category" options={categoriesOptions} />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('market')}</Span>
        <AsyncSelectField
          name="market"
          loadOptionsOnMenuOpen
          debounceTimeout={200}
          loadOptions={getAsyncLoadOptions({
            entity: 'markets',
            createOption: createMarketOption,
            categoryId: category?.id,
          })}
          cacheUniqs={[category?.id]}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('market link')}</Span>
        <SelectField name="marketLink" options={marketLinkOptions} />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('outcome code')}</Span>
        <TextInputField name="code" />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('short name')}</Span>
        <TextInputField name="shortName" />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('priority order')}</Span>
        <TextInputField name="priorityOrder" type="number" />
      </S.FormBlock>
      {isCreateCategoryVisible && (
        <CategoryCreatePanel onClose={hideCreateCategory} />
      )}
      {isCreateMarketVisible && (
        <MarketCreatePanel onClose={hideCreateMarket} />
      )}
    </>
  );
};
