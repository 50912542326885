import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { adapterEntityInitialState } from './constants';
import { getAdapterEntityItems } from './utils';

const EXCLUDED_ADAPTERS = ['olimp', 'unknown'];

const getAdaptersProviderSubstate = (state: RootState) =>
  state.adaptersProvider;
export const getAdapterEntities = (state: RootState) =>
  getAdaptersProviderSubstate(state).entities;
const getAdapterEntitiesMappedEvents = (state: RootState) =>
  getAdapterEntities(state).mappedEvents;
const getAdapters = (state: RootState) =>
  getAdaptersProviderSubstate(state).adapters;
const getAdaptersEntries = (state: RootState) => getAdapters(state).entries;

export const selectAdapters = createSelector([getAdaptersEntries], entries =>
  entries.filter(item => !EXCLUDED_ADAPTERS.includes(item.name!)),
);

export const selectMappedEventsItemsById = createSelector(
  [getAdapterEntitiesMappedEvents, (_, eventId: number) => eventId],
  (entities, eventId) => {
    const mappedEvents = entities[eventId] || adapterEntityInitialState;
    return getAdapterEntityItems(mappedEvents);
  },
);

// events
const getAdapterEvents = (state: RootState) => getAdapterEntities(state).events;
export const getAdapterEventsHasMore = (state: RootState) =>
  getAdapterEvents(state).hasMore;
export const getAdapterEventsSortedIds = (state: RootState) =>
  getAdapterEvents(state).sortedIds;
export const getAdapterEventsLoading = (state: RootState) =>
  getAdapterEvents(state).fetch.loading;
export const selectAdapterEventsItems = createSelector(
  [getAdapterEvents],
  events => getAdapterEntityItems(events),
);

// countries
export const getAdapterCountries = (state: RootState) =>
  getAdapterEntities(state).countries;
export const getAdapterCountriesEntries = (state: RootState) =>
  getAdapterCountries(state).entries;
export const selectAdapterCountriesItems = createSelector(
  [getAdapterCountries],
  state => getAdapterEntityItems(state),
);

// types
const getAdapterTypes = (state: RootState) => getAdapterEntities(state).types;
export const getAdapterTypesEntries = (state: RootState) =>
  getAdapterTypes(state).entries;
export const getAdapterTypesHasMore = (state: RootState) =>
  getAdapterTypes(state).hasMore;
export const getAdapterTypesLoading = (state: RootState) =>
  getAdapterTypes(state).fetch.loading;
export const selectAdapterTypesItems = createSelector(
  [getAdapterTypes],
  types => getAdapterEntityItems(types),
);

// markets
export const getAdapterMarketEntries = (state: RootState) =>
  getAdapterEntities(state).markets.entries;

// sports
const getAdapterSports = (state: RootState) => getAdapterEntities(state).sports;
export const getAdapterSportsLoading = (state: RootState) =>
  getAdapterEntities(state).sports.fetch.loading;
export const getAdapterSportsEntries = (state: RootState) =>
  getAdapterSports(state).entries;
export const selectAdapterSportsItems = createSelector(
  [getAdapterSports],
  sports => getAdapterEntityItems(sports),
);

// parameters
const getAdapterParameters = (state: RootState) =>
  getAdapterEntities(state).parameters;
export const selectAdapterParametersItems = createSelector(
  [getAdapterParameters],
  parameters => getAdapterEntityItems(parameters),
);

// competitors
const getAdapterCompetitors = (state: RootState) =>
  getAdapterEntities(state).competitors;
export const getAdapterCompetitorsHasMore = (state: RootState) =>
  getAdapterEntities(state).competitors.hasMore;
export const getAdapterCompetitorsLoading = (state: RootState) =>
  getAdapterEntities(state).competitors.fetch.loading;
export const selectAdapterCompetitorsItems = createSelector(
  [getAdapterCompetitors],
  competitors => getAdapterEntityItems(competitors),
);

// players
const getAdapterPlayers = (state: RootState) =>
  getAdapterEntities(state).players;
export const getAdapterPlayersEntries = (state: RootState) =>
  getAdapterPlayers(state).entries;
export const getAdapterPlayersHasMore = (state: RootState) =>
  getAdapterPlayers(state).hasMore;
export const getAdapterPlayersLoading = (state: RootState) =>
  getAdapterPlayers(state).fetch.loading;
export const selectAdapterPlayersItems = createSelector(
  [getAdapterPlayers],
  players => getAdapterEntityItems(players),
);

// tournaments
const getAdapterTournaments = (state: RootState) =>
  getAdapterEntities(state).tournaments;
export const getAdapterTournamentsHasMore = (state: RootState) =>
  getAdapterTournaments(state).hasMore;
export const getAdapterTournamentsLoading = (state: RootState) =>
  getAdapterTournaments(state).fetch.loading;
export const selectAdapterTournamentsItems = createSelector(
  [getAdapterTournaments],
  tournaments => getAdapterEntityItems(tournaments),
);

// matchPhases
const getAdapterMatchPhases = (state: RootState) =>
  getAdapterEntities(state).matchPhases;
export const getAdapterMatchPhasesLoading = (state: RootState) =>
  getAdapterMatchPhases(state).fetch.loading;
export const getAdapterMatchPhasesHasMore = (state: RootState) =>
  getAdapterMatchPhases(state).hasMore;
export const selectAdapterMatchPhasesItems = createSelector(
  [getAdapterMatchPhases],
  matchPhases => getAdapterEntityItems(matchPhases),
);
