import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import {
  OutcomeMarket,
  OutcomeTypeCrossMappingWithOutcomeTypes,
} from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import { RootState } from 'types';

import { AsyncSelectField } from 'app/components/forms';
import { Span } from 'app/components/ui';

import { getCrossMappingsByCategoryId } from 'app/providers/EntitiesProvider/crossMappings';

import { CrossParamsMapper } from './CrossParamsMapper';
import * as S from './styles';

interface Props {
  id: number;
  categoryId: number;
  markets: Dictionary<OutcomeMarket>;
}

export const CrossMappingForm = ({ id, markets, categoryId }: Props) => {
  const { t } = useTranslation();
  const {
    values: { outcomeType, crossOutcomeType },
  } = useFormikContext<OutcomeTypeCrossMappingWithOutcomeTypes>();

  const crossMappings = useSelector((state: RootState) =>
    getCrossMappingsByCategoryId(state, categoryId),
  );

  return (
    <>
      <S.FormBlock>
        <Span>{t('outcome')}</Span>
        <AsyncSelectField
          name="outcomeType"
          debounceTimeout={500}
          loadOptions={getAsyncLoadOptions({
            entity: 'types',
            categoryId,
            excludeOptions: crossMappings
              .map(c => c.outcomeType)
              .filter(item => item?.id !== id),
          })}
        />
      </S.FormBlock>
      <S.FormBlock>
        <Span>{t('cross outcome')}</Span>
        <AsyncSelectField
          name="crossOutcomeType"
          debounceTimeout={500}
          loadOptions={getAsyncLoadOptions({
            entity: 'types',
            categoryId,
          })}
        />
      </S.FormBlock>
      {outcomeType &&
        crossOutcomeType &&
        markets[outcomeType.marketId] &&
        markets[crossOutcomeType.marketId] && (
          <CrossParamsMapper
            name="crossParams"
            market={markets[outcomeType.marketId]}
            crossMarket={markets[crossOutcomeType.marketId]}
          />
        )}
    </>
  );
};
