import styled from 'styled-components';

import { Button } from 'app/components/ui';

interface StyledWrapperProps {
  isExcluded: boolean;
}

export const StyledButton = styled(Button)<StyledWrapperProps>`
  background: ${p =>
    p.isExcluded ? p.theme.colors.red[300] : p.theme.colors.green[300]};
  &:hover {
    background: ${p =>
      p.isExcluded ? p.theme.colors.red[400] : p.theme.colors.green[400]};
  }
  &:active {
    background: ${p =>
      p.isExcluded ? p.theme.colors.red[500] : p.theme.colors.green[500]};
  }
  &:disabled {
    background: ${p => p.theme.colors.primary[40]};
    &:hover {
      background: ${p => p.theme.colors.primary[40]};
    }
  }
`;

export const Column = styled.div`
  display: grid;
`;

export const Span = styled.span`
  cursor: pointer;
  padding: 8px 10px;
  margin: 0 -8px;
  &:hover {
    background-color: ${p => p.theme.colors.primary[20]};
  }
  &:active {
    background-color: ${p => p.theme.colors.primary[40]};
  }
`;
