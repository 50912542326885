import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from 'app/pages/Sources/components/EntitiesTables/styles';
import { FormikProvider, useFormik } from 'formik';
import { AdapterCompetitor, Competitor, Sport } from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import useBooleanState from 'hooks/useBooleanState';

import { AsyncSelectField } from 'app/components/forms';
import { CompetitorCreatePanel } from 'app/components/panels';
import { TextLoader } from 'app/components/ui';
import { Button } from 'app/components/ui';
import LargePlus from 'assets/images/large-plus.svg?react';

import { adapterActions } from 'app/providers/AdaptersProvider';

type Values = {
  sportsbookCompetitor?: Competitor;
};

interface Props {
  competitor: AdapterCompetitor;
  mapped: boolean;
  adapter: string;
  sportsbookSport: Maybe<Sport>;
  sportName?: string;
  competitors: Dictionary<Competitor>;
  isEditing: boolean;
  isFetched: boolean;
  setEditingRow: (editingId: string | undefined) => void;
}

export const CompetitorRow = ({
  competitor,
  mapped,
  adapter,
  sportsbookSport,
  isEditing,
  setEditingRow,
  isFetched,
  sportName,
  competitors,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [
    isCreateCompetitorPanelVisible,
    showCreateCompetitorPanel,
    hideCreateCompetitorPanel,
  ] = useBooleanState();

  const handleDeleteMapping = () => {
    if (!competitor.id) return;
    dispatch(
      adapterActions.competitorsDeleteMapping({ id: competitor.id, adapter }),
    );
    setEditingRow(undefined);
  };

  const handleEdit = () => {
    setEditingRow(competitor.id);
  };

  const formik = useFormik<Values>({
    initialValues: {
      sportsbookCompetitor: mapped
        ? competitors[competitor.sportsbookId!]
        : undefined,
    },
    onSubmit: ({ sportsbookCompetitor }) => {
      dispatch(
        adapterActions.competitorsMap({
          id: competitor.id!,
          adapter,
          mappingRequest: {
            sportsbookId: sportsbookCompetitor?.id!,
          },
          withDeletion: !mapped,
        }),
      );
      setEditingRow(undefined);
    },
    enableReinitialize: true,
  });

  const { values, submitForm, setFieldValue } = formik;

  const onCompetitorCreated = entity => {
    setFieldValue('sportsbookCompetitor', entity);
  };

  const competitorSelect = (
    <AsyncSelectField
      name="sportsbookCompetitor"
      loadOptions={getAsyncLoadOptions({
        entity: 'competitors',
        sportId: sportsbookSport?.id,
        disabled: false,
      })}
      cacheUniqs={[sportsbookSport]}
    />
  );

  return (
    <FormikProvider value={formik}>
      {mapped ? (
        <S.Tr>
          <S.Td>{competitor.name}</S.Td>
          {isEditing ? (
            <S.SelectTd>
              {competitorSelect}
              <Button color="action" onClick={showCreateCompetitorPanel}>
                <LargePlus />
              </Button>
            </S.SelectTd>
          ) : (
            <S.Td>
              {!isFetched ? <TextLoader /> : values.sportsbookCompetitor?.name}
            </S.Td>
          )}
          <S.Td>{sportName}</S.Td>
          <S.Td>
            {isEditing ? (
              <S.ButtonsBlock key="editing">
                <S.ButtonsWrapper>
                  <Button
                    color="action"
                    onClick={submitForm}
                    disabled={!values.sportsbookCompetitor}
                  >
                    <FontAwesomeIcon icon={faSave} />
                    {t('save')}
                  </Button>
                  <Button
                    color="action"
                    onClick={handleDeleteMapping}
                    disabled={!values.sportsbookCompetitor}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    {t('delete')}
                  </Button>
                </S.ButtonsWrapper>
              </S.ButtonsBlock>
            ) : (
              <S.ButtonsBlock key="normal">
                <Button color="action" onClick={handleEdit}>
                  <FontAwesomeIcon icon={faEdit} />
                  {t('edit')}
                </Button>
              </S.ButtonsBlock>
            )}
          </S.Td>
        </S.Tr>
      ) : (
        <S.Tr>
          <S.Td weight={35}>{competitor.name}</S.Td>
          <S.Td weight={20}>{sportName}</S.Td>
          <S.SelectTd weight={45}>
            {competitorSelect}
            <Button color="action" onClick={showCreateCompetitorPanel}>
              <LargePlus />
            </Button>
          </S.SelectTd>
          <S.Td weight={10} textAlign={'right'}>
            <Button
              onClick={submitForm}
              disabled={!values.sportsbookCompetitor}
            >
              {t('map')}
            </Button>
          </S.Td>
        </S.Tr>
      )}
      {isCreateCompetitorPanelVisible && (
        <CompetitorCreatePanel
          onClose={hideCreateCompetitorPanel}
          onSuccess={onCompetitorCreated}
        />
      )}
    </FormikProvider>
  );
};
