/**
 *
 * TextArea
 *
 */
import { FC } from 'react';

import { StyledTextArea } from './TextArea.styles';
import { TextAreaProps } from './TextArea.types';

export const TextArea: FC<TextAreaProps> = props => {
  return <StyledTextArea {...props} data-testid="TextArea" />;
};
