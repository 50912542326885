import {
  MatchType,
  MatchTypeList,
  MatchTypesApiDeleteMatchTypeRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    MatchTypeList,
    MatchType,
    MatchTypesApiDeleteMatchTypeRequest,
    EntitiesErrorType
  >({
    entity: 'matchTypes',
  }),
};

export default reducer;
