import styled from 'styled-components';

export const Column = styled.div`
  display: grid;
`;

export const Span = styled.span`
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  padding: 6px;
  font-size: ${p => p.theme.fonts.typography['H400'].size};
  font-weight: ${p => p.theme.fonts.typography['H400'].weight};
`;
