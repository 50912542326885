import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { CountryWithOutId } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';

import { createInitialValues, validationSchema } from './constants';
import { CountryForm } from './CountryForm';

interface Props {
  onClose: () => void;
}

export const CountryCreatePanel = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useRequestState(Entities.COUNTRIES, 'create', onClose);

  const onSubmit = (values: CountryWithOutId) => {
    dispatch(
      actionsNT.countriesCreate({
        countryWithOutId: {
          name: values.name,
          code: values.code,
          code2: values.code2,
          translations: [],
        },
      }),
    );
  };

  return (
    <Formik<CountryWithOutId>
      initialValues={createInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.create.country')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <CountryForm />
        </Panel>
      )}
    </Formik>
  );
};
