import { useTranslation } from 'react-i18next';
import { BackButton } from 'app/pages/Events/components/EventHeader/BackButton';
import { FavoriteButton } from 'app/pages/Events/components/EventHeader/FavoriteButton';
import { StatusPopup } from 'app/pages/Events/components/EventHeader/StatusPopup';
import { Event, UserGroupEnum } from 'sportsbook-openapi-react';

import { useUpdateEvent } from 'hooks/events';

import { RoleGuards } from 'app/components/RoleGuards';
import { Button } from 'app/components/ui';

import * as S from './EventResultHeader.styles';

interface Props {
  event: Event;
}

export function EventResultHeader({ event }: Props) {
  const { t } = useTranslation();
  const updateEvent = useUpdateEvent();

  const handleResultedEventClick = () => {
    updateEvent({
      ...event,
      resulted: !event.resulted,
    });
  };

  return (
    <S.Wrapper>
      <S.EventHeader>
        <BackButton event={event} />
        <S.H2>{`${t('event')} ID ${event.id}`}</S.H2>
        <StatusPopup event={event} />
        <div />
        <FavoriteButton event={event} />
        <RoleGuards
          roles={[
            UserGroupEnum.ADMIN,
            UserGroupEnum.OPERATOR,
            UserGroupEnum.SUPERVISOR,
          ]}
        >
          <div>
            {event.resulted ? (
              <Button color="secondary" onClick={handleResultedEventClick}>
                {t('mark as unresulted')}
              </Button>
            ) : (
              <Button color="action" onClick={handleResultedEventClick}>
                {t('mark as resulted')}
              </Button>
            )}
          </div>
        </RoleGuards>
      </S.EventHeader>
    </S.Wrapper>
  );
}
