import {
  OutcomeCategory,
  SportEventOddCategory,
  SportEventOdds,
} from 'sportsbook-openapi-react';

export type SelectedCategory = Maybe<
  SportEventOddCategory & {
    name: string | number;
  }
>;

export const getSelectedCategory = (
  selectedCategoryId: number,
  categories: Dictionary<OutcomeCategory>,
  odds: SportEventOdds | undefined,
): SelectedCategory => {
  if (selectedCategoryId && categories && odds?.categories) {
    const selectedCategory = odds.categories.find(
      category => category.outcomeCategoryId === selectedCategoryId,
    );
    if (selectedCategory) {
      return {
        ...selectedCategory,
        name: categories[selectedCategoryId]?.name || selectedCategoryId,
      };
    }
  }
  return null;
};
