import { PayloadAction } from '@reduxjs/toolkit';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import uniq from 'lodash-es/uniq';
import values from 'lodash-es/values';
import {
  OutcomeType,
  OutcomeTypeList,
  TypesApiDeleteTypeRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import {
  entityReducersGenerator,
  toIdsArray,
  toIdsMap,
} from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    OutcomeTypeList,
    OutcomeType,
    TypesApiDeleteTypeRequest,
    EntitiesErrorType
  >({
    entity: 'types',
  }),
  typesFetchItemsSuccess(
    state,
    action: PayloadAction<Required<OutcomeTypeList>>,
  ) {
    const { items, hasMore } = action.payload;
    if (!state.types.onlyEntries) {
      if (state.types.withPagination) {
        state.types.sortedIds = uniq([
          ...state.types.sortedIds,
          ...toIdsArray(items),
        ]);
      } else {
        state.types.sortedIds = toIdsArray(items);
      }
    }
    state.types.entries = {
      ...state.types.entries,
      ...toIdsMap(items),
    };
    state.types.byCategory = {
      ...state.types.byCategory,
      ...mapValues(
        groupBy(items, item => String(item.categoryId)),
        value => toIdsMap(value),
      ),
    };
    state.types.fetchItems.loading = false;
    state.types.hasMore = hasMore;
  },
  typesFetchItemSuccess(state, action: PayloadAction<OutcomeType>) {
    const type = action.payload;
    state.types.entries[type.id] = type;
    if (!state.types.sortedIds.includes(type.id)) {
      state.types.sortedIds.push(type.id);
    }
    state.types.byCategory[type.categoryId][type.id] = type;
    state.types.fetchItem.loading = false;
  },
  typesCreateSuccess(state, action: PayloadAction<OutcomeType>) {
    const type = action.payload;
    state.types.entries[type.id] = type;
    state.types.sortedIds.push(type.id);
    if (!state.types.byCategory[type.categoryId]) {
      state.types.byCategory[type.categoryId] = {};
    }
    state.types.byCategory[type.categoryId][type.id] = type;
    state.types.create.loading = false;
    state.types.create.id = type.id;
    state.types.create.result = type;
  },
  typesUpdateSuccess(state, action: PayloadAction<OutcomeType>) {
    const type = action.payload;
    state.types.entries[type.id] = type;
    state.types.byCategory[type.categoryId][type.id] = type;
    state.types.update.loading = false;
    state.types.update.id = type.id;
    state.types.update.result = type;
  },
  typesDelete(state, action: PayloadAction<TypesApiDeleteTypeRequest>) {
    const { typeId } = action.payload;
    delete state.types.entries[typeId];
    state.types.byCategory = mapValues(
      groupBy(
        values(state.types.entries).filter(item => item.id !== typeId),
        (item: OutcomeType) => String(item.categoryId),
      ),
      value => toIdsMap(value),
    );
    state.types.delete.loading = true;
  },
};

export default reducer;
