import styled from 'styled-components';

export const EventFieldLabel = styled.span`
  color: ${p => p.theme.colors.primary[80]};
  font-size: ${p => p.theme.fonts.typography['H200'].size};
  font-weight: ${p => p.theme.fonts.typography['H200'].weight};
  grid-column: 1/2;
  white-space: nowrap;
`;

export const EventFieldValue = styled.span`
  font-size: ${p => p.theme.fonts.typography['H200'].size};
  font-weight: ${p => p.theme.fonts.typography['H200'].weight};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
