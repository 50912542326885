import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faRedo, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compareByPriority, SelectedCategory } from 'app/pages/Events/utils';
import { ResultTabs } from 'app/pages/Results/components/EventResultsTable';
import { get, groupBy, isEqual } from 'lodash-es';
import {
  Event,
  SportEventOdd,
  SportEventOddCategory,
  SportEventResult,
  SportEventResultType,
} from 'sportsbook-openapi-react';

import { includesText } from 'utils/string';
import { useSearch } from 'hooks/useSearch';
import { RootState } from 'types';

import { Button, Table, Tabs } from 'app/components/ui';

import { getCategoriesEntries } from 'app/providers/EntitiesProvider/categories';
import { getEventOddsByEventId } from 'app/providers/EntitiesProvider/eventsOdds';
import { getEventResults } from 'app/providers/EntitiesProvider/eventsResults';
import { getSourcesEntries } from 'app/providers/EntitiesProvider/sources';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

import * as S from './ResultCategoriesTable.styles';

interface Props {
  event: Event;
  selectedCategory: SelectedCategory;
  selectCategoryId: React.Dispatch<React.SetStateAction<number>>;
  resultTab: ResultTabs;
  selectResultTab: React.Dispatch<React.SetStateAction<ResultTabs>>;
}

const sportEventOddFilter =
  (mappedResults: Dictionary<SportEventResult[]> | undefined) =>
  (probability: SportEventOdd) => {
    if (!mappedResults) return false;
    const resultsByType = mappedResults[probability.outcomeTypeId];
    if (!resultsByType || resultsByType.length === 0) return true;
    const result = resultsByType.find(sportEventResult =>
      isEqual(
        new Set(sportEventResult.params),
        new Set(probability.parameters),
      ),
    );
    if (!result) return true;
    return result.result === SportEventResultType.NA;
  };

export const ResultCategoriesTable: FC<Props> = ({
  event,
  selectedCategory,
  selectCategoryId,
  resultTab,
  selectResultTab,
}) => {
  const { t } = useTranslation();
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const sourcesRaw = useSelector(getSourcesEntries);
  const odds = useSelector((state: RootState) =>
    getEventOddsByEventId(state, event.id),
  );
  const { isCustomer } = useUserStore(getIsCustomer);

  const results = useSelector(state => getEventResults(state, event.id));

  const categories = useSelector(getCategoriesEntries);

  const mappedResults = useMemo(
    () => groupBy(results, result => String(result.outcomeTypeId)),
    [results],
  );

  const generalOddsColumns: Column<SportEventOddCategory>[] = useMemo(
    () => [
      {
        Header: t('category').toString(),
        accessor: 'outcomeCategoryId',
        Cell: ({ value }) => <>{categories[value]?.name ?? value}</>,
      },
      ...(!isCustomer
        ? [
            {
              Header: t('source').toString(),
              id: 'source',
              Cell: ({ row: { original: data } }) => (
                <>
                  {get(
                    sourcesRaw,
                    `${
                      get(
                        odds?.sourceMappings,
                        `[${data.outcomeCategoryId}]`,
                      ) ?? odds?.mainSource
                    }
            .name`,
                  )}
                </>
              ),
            },
          ]
        : []),
    ],
    [
      t,
      isCustomer,
      categories,
      sourcesRaw,
      odds?.sourceMappings,
      odds?.mainSource,
    ],
  );

  const oddsGeneralTable = useMemo(
    () =>
      odds?.categories
        .filter(c => {
          if (resultTab === ResultTabs.UNCALCULATED) {
            return (
              !!c.sources.filter(
                source =>
                  source.probabilities.filter(
                    sportEventOddFilter(mappedResults),
                  ).length,
              ).length &&
              includesText(categories[c.outcomeCategoryId]?.name, searchQuery)
            );
          }
          return includesText(
            categories[c.outcomeCategoryId]?.name,
            searchQuery,
          );
        })
        .sort((a, b) => {
          const categoryA = categories[a.outcomeCategoryId];
          const categoryB = categories[b.outcomeCategoryId];

          if (!categoryA || !categoryB) return 1;
          return compareByPriority(categoryA, categoryB);
        }) || [],
    [odds, resultTab, categories, searchQuery, mappedResults],
  );

  const uncalculatedCount = useMemo(
    () =>
      odds?.categories
        .map(c =>
          c.sources
            .map(source =>
              source.probabilities.filter(sportEventOddFilter(mappedResults)),
            )
            .flat(),
        )
        .flat().length,
    [odds, mappedResults],
  );

  const handleCategoryClick = useCallback(
    (data: SportEventOddCategory) => {
      selectCategoryId(data.outcomeCategoryId);
    },
    [selectCategoryId],
  );

  const isSelected = useCallback(
    (row: SportEventOddCategory) =>
      row.outcomeCategoryId === selectedCategory?.outcomeCategoryId,
    [selectedCategory],
  );

  return (
    <S.OddsGeneralTableWrapper>
      <S.SearchWrapper>
        <S.Search
          placeholder={t('search')}
          onChange={e => setSearchQueryDebounced(e.target.value)}
          icon={<FontAwesomeIcon icon={faSearch} />}
        />
        <Button color="action" onClick={() => window.location.reload()}>
          <FontAwesomeIcon icon={faRedo} />
          {t('refresh')}
        </Button>
      </S.SearchWrapper>

      <Tabs
        tabs={[
          {
            value: ResultTabs.ALL,
            label: t(ResultTabs.ALL),
          },
          {
            value: ResultTabs.UNCALCULATED,
            label: t(ResultTabs.UNCALCULATED),
            count: uncalculatedCount,
          },
        ]}
        withNumber
        onChange={selectResultTab}
      />
      <S.OddsGeneralTable>
        <Table
          columns={generalOddsColumns}
          data={oddsGeneralTable}
          onRowClick={handleCategoryClick}
          rowStyles="cursor: pointer;"
          columnsWidth={['auto', 'auto']}
          isSelected={isSelected}
        />
      </S.OddsGeneralTable>
    </S.OddsGeneralTableWrapper>
  );
};
