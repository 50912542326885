import {
  MarketsApiDeleteMarketRequest,
  OutcomeMarket,
  OutcomeMarketList,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    OutcomeMarketList,
    OutcomeMarket,
    MarketsApiDeleteMarketRequest,
    EntitiesErrorType
  >({
    entity: 'markets',
  }),
};

export default reducer;
