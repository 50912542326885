import styled from 'styled-components';

interface StyledInputProps {
  invalid: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  box-sizing: border-box;
  height: 24px;
  margin: -5px -5px;
  padding: 4px;
  width: 100%;
  color: ${p => p.theme.colors.primary[500]};
  font-weight: ${p => p.theme.fonts.typography['H100'].weight};
  font-size: ${p => p.theme.fonts.typography['H100'].size};

  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;

  background-color: transparent;

  outline: none;

  transition:
    border 0.2s ease,
    background-color 0.2s ease,
    box-shadow 0.2s ease;

  &:hover {
    border-color: ${p => p.theme.colors.primary[60]};
  }

  &:focus {
    border-color: ${p => p.theme.colors.primary[700]};
    box-shadow: 0 0 1px 1px ${p => p.theme.colors.primary[700]};

    ${p =>
      p.invalid &&
      `
        border-color: ${p.theme.colors.red[500]};
        box-shadow: 0 0 1px 1px ${p.theme.colors.red[500]};    
      `}
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
  }
`;
