import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { TagWithOutId } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT } from 'app/providers/EntitiesProvider';

import { createInitialValues, validationSchema } from './constants';
import { TagForm } from './TagForm';

interface Props {
  onClose: () => void;
}

export const TagCreatePanel = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useRequestState('tags', 'create', onClose);

  const onSubmit = (values: TagWithOutId) => {
    dispatch(
      actionsNT.tagsCreate({
        tagWithOutId: values,
      }),
    );
  };

  return (
    <Formik<TagWithOutId>
      initialValues={createInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.create.tag')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <TagForm />
        </Panel>
      )}
    </Formik>
  );
};
