/**
 *
 * Checkbox
 *
 */
import { FC } from 'react';
import { useField } from 'formik';

import { Checkbox } from 'app/components/forms';

import { CheckboxFieldProps } from './CheckboxField.types';

export const CheckboxField: FC<CheckboxFieldProps> = ({
  name,
  onChangeSideEffect,
  indeterminate,
  ...rest
}) => {
  const [field, , helpers] = useField(name);

  const onChange = value => {
    helpers.setValue(value);
    if (onChangeSideEffect) {
      onChangeSideEffect(value);
    }
  };

  return (
    <Checkbox
      {...rest}
      checked={field.value}
      indeterminate={indeterminate}
      onChange={onChange}
    />
  );
};
