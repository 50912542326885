import styled from 'styled-components';

export const ValueWrapper = styled.div`
  margin: 16px 0;
  height: 32px;
  display: grid;
  grid-template-columns: minmax(0, 120px) auto;
  align-items: center;
  grid-column-gap: 8px;
`;
