import { useEffect, useState } from 'react';
import { adaptersApi } from 'app/api';
import { Adapter } from 'sportsbook-openapi-react';

interface Props {
  adapters: Adapter[];
  id?: number;
}

export const useAdapterCompetitors = ({ adapters, id }: Props) => {
  const [adapterCompetitors, setAdapterCompetitors] = useState<
    Dictionary<string>
  >({});

  useEffect(() => {
    (async () => {
      const competitorsNames = await Promise.all(
        adapters.map(
          adapter =>
            new Promise<{
              adapter: string;
              competitorName: string | undefined;
            }>(async resolve => {
              try {
                const competitors = await adaptersApi.getAdapterCompetitors({
                  adapter: adapter.name!,
                  sportsbookId: id,
                });

                resolve({
                  adapter: adapter.name!,
                  competitorName: competitors.items[0].name,
                });
              } catch {
                resolve({ adapter: adapter.name!, competitorName: undefined });
              }
            }),
        ),
      );

      setAdapterCompetitors(
        competitorsNames.reduce((acc, cur) => {
          acc[cur.adapter] = cur.competitorName;
          return acc;
        }, {}),
      );
    })();
  }, [adapters, id]);

  return adapterCompetitors;
};
