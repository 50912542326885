import styled from 'styled-components';

import { Button } from 'app/components/ui';

export const Content = styled.div`
  padding: 20px;

  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-row-gap: 15px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
