import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
`;

export const Content = styled.div`
  display: grid;
  grid-template-rows: 35px minmax(0, 1fr);
  padding: 20px;
`;
