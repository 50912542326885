import {
  Tournament,
  TournamentList,
  TournamentsApiDeleteTournamentRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    TournamentList,
    Tournament,
    TournamentsApiDeleteTournamentRequest,
    EntitiesErrorType
  >({
    entity: 'tournaments',
  }),
};

export default reducer;
