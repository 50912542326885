import {
  OutcomeCategory,
  OutcomeMarket,
  OutcomeMarketLink,
} from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  category: Yup.object().required('Required'),
  market: Yup.object().required('Required'),
  code: Yup.string().required('Required'),
  shortName: Yup.string().notRequired(),
  priorityOrder: Yup.number().positive('Must be positive'),
  marketLink: Yup.object().required('Required'),
});

export interface FormValues {
  name: string;
  market?: OutcomeMarket;
  category?: OutcomeCategory;
  code: string;
  shortName: string;
  priorityOrder?: number;
  marketLink?: EnumOption<OutcomeMarketLink>;
}

export const marketLinkOptions = Object.values(OutcomeMarketLink).map(
  value => ({
    id: value,
    name: value,
  }),
);
