import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { Entities } from 'app/providers/EntitiesProvider/types';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getCountriesSubstate = (state: RootState) =>
  getEntities(state)[Entities.COUNTRIES];

export const getCountriesEntries = (state: RootState) =>
  getCountriesSubstate(state).entries;

export const selectCountries = createSelector(
  [getCountriesSubstate],
  countries => getEntityItems(countries),
);

export const selectCountry = (state: RootState, id: number) =>
  getCountriesEntries(state)[id];
