import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getCommentsSubstate = (state: RootState) => getEntities(state).comments;

export const getCommentsHasMore = (state: RootState) =>
  getCommentsSubstate(state).hasMore;

export const selectCommentsItems = createSelector(
  [getCommentsSubstate],
  competitors => getEntityItems(competitors),
);
