import { outcomeCategorySourceMappingApi } from 'app/api';

const api = {
  endpoint: outcomeCategorySourceMappingApi,
  methods: {
    fetchItems: 'getOutcomeCategorySourceMappings',
    fetchItem: 'getOutcomeCategorySourceMapping',
    create: 'createOutcomeCategorySourceMapping',
    createList: 'createOutcomeCategorySourceMappingList',
    update: 'updateOutcomeCategorySourceMapping',
    delete: 'deleteOutcomeCategorySourceMapping',
  },
};

export default api;
