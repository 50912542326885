import { PayloadAction } from '@reduxjs/toolkit';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';

const reducer = {
  eventSuspendsCreate(state) {
    state.eventSuspends.create.error = null;
    state.eventSuspends.create.loading = true;
  },
  eventSuspendsCreateSuccess(state) {
    state.eventSuspends.create.loading = false;
  },
  eventSuspendsCreateError(state, action: PayloadAction<EntitiesErrorType>) {
    state.eventSuspends.create.error = action.payload;
    state.eventSuspends.create.loading = false;
  },
};

export default reducer;
