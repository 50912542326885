/**
 *
 * Currencies
 *
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { omit } from 'lodash-es';
import { Currency } from 'sportsbook-openapi-react';

import { includes } from 'utils/filters';
import { useAudit } from 'hooks/audit/useAudit';
import { useSearch } from 'hooks/useSearch';
import { FETCH_ALL_LIMIT } from 'consts';

import { TextInput, TextInputCell } from 'app/components/forms';
import { CurrencyEditPanel } from 'app/components/panels';
import { Button, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectCurrencies } from 'app/providers/EntitiesProvider/currencies';

import { useUserStore } from 'store';
import { getIsTranslator } from 'store/user';

import * as S from './Currencies.styles';

export function Currencies() {
  const { items } = useSelector(selectCurrencies);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const isTranslator = useUserStore(getIsTranslator);
  const [editCurrencyId, setEditCurrencyId] = useState<null | number>(null);

  const handleUpdateCurrency = useCallback(
    (currency: Currency) => {
      dispatch(
        actionsNT.currenciesUpdate({
          currencyId: currency.id,
          currencyWithOutId: omit(currency, 'id'),
        }),
      );
    },
    [dispatch],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.CURRENCIES,
  });

  const columns: Column<Currency>[] = useMemo(
    () => [
      {
        Header: t('name').toString(),
        accessor: 'name',
      },
      {
        Header: t('symbol').toString(),
        accessor: 'symbol',
        Cell: ({ value, row: { original: data } }) => {
          return !isTranslator ? (
            <TextInputCell
              defaultValue={value}
              onSave={newValue => {
                handleUpdateCurrency({
                  ...data,
                  symbol: newValue,
                });
              }}
              validate={value => value.length === 1}
            />
          ) : (
            <>{value}</>
          );
        },
      },
      ...(!isTranslator
        ? [
            {
              Header: '',
              id: 'action',
              Cell: ({ row: { original: data } }) => (
                <S.ButtonsWrapper>
                  <Button
                    color="action"
                    onClick={() => {
                      setEditCurrencyId(data.id);
                    }}
                  >
                    {t('edit')}
                  </Button>
                </S.ButtonsWrapper>
              ),
            },
            {
              Header: '',
              accessor: 'id' as keyof Currency,
              id: 'audit',
              Cell: ({ row: { original: data } }) => (
                <Button
                  color="action"
                  onClick={() => {
                    openAudit({ id: String(data.id), includeRelated: false });
                  }}
                >
                  {t('audit')}
                </Button>
              ),
            },
          ]
        : []),
    ],
    [t, isTranslator, handleUpdateCurrency, openAudit],
  );

  useEffect(() => {
    dispatch(actionsNT.currenciesFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  const filteredData = useMemo(
    () => items.filter(currency => includes(currency.name, searchQuery)),
    [searchQuery, items],
  );

  return (
    <S.Wrapper>
      <S.DropdownWrapper>
        <DictionaryDropdown value={'currencies'} />
      </S.DropdownWrapper>
      <S.ContentWrapper>
        <S.Header>
          <TextInput
            placeholder={t('search')}
            onChange={e => setSearchQueryDebounced(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />
        </S.Header>
        <Table
          columns={columns}
          data={filteredData}
          columnsWidth={['fit-content', 'auto', 'fit-content', 'fit-content']}
        />
      </S.ContentWrapper>
      {!!editCurrencyId && (
        <CurrencyEditPanel
          onClose={() => {
            setEditCurrencyId(null);
          }}
          id={editCurrencyId}
        />
      )}
    </S.Wrapper>
  );
}
