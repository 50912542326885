import { createSelector } from '@reduxjs/toolkit';
import values from 'lodash-es/values';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';

const getSourcesSubstate = (state: RootState) => getEntities(state).sources;
export const getSourcesEntries = (state: RootState) =>
  getSourcesSubstate(state).entries;

export const selectSourcesItems = createSelector([getSourcesEntries], sources =>
  values(sources),
);

export const selectSourcesOptions = createSelector(
  [selectSourcesItems],
  sources =>
    sources.map(source => ({
      id: source.code,
      name: source.name,
    })),
);
