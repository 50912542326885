import { FC } from 'react';
import merge from 'lodash/merge';
import {
  ThemeProvider as StyledThemeProvider,
  ThemeProviderProps,
} from 'styled-components';

import { PartialThemeContext, ThemeContext } from './ThemeProvider.types';

const colors = {
  primary: {
    900: '#091E42',
    800: '#172B4D',
    700: '#253858',
    600: '#344563',
    500: '#42526E',
    400: '#505F79',
    300: '#5E6C84',
    200: '#6B778C',
    100: '#7A869A',
    90: '#8993A4',
    80: '#97A0AF',
    70: '#A5ADBA',
    60: '#B3BAC5',
    50: '#C1C7D0',
    40: '#DFE1E6',
    30: '#EBECF0',
    20: '#F4F5F7',
    10: '#FAFBFC',
    0: '#FFFFFF',
  },
  blue: {
    500: '#0747A6',
    400: '#0052CC',
    300: '#0065FF',
    200: '#2684FF',
    100: '#4C9AFF',
    75: '#B3D4FF',
    50: '#DEEBFF',
  },
  red: {
    500: '#CC0000',
    400: '#FF0000',
    300: '#FF3333',
    200: '#FF6666',
    100: '#FF9999',
  },
  green: {
    500: '#006B36',
    400: '#009249',
    300: '#00B85C',
    200: '#00D66B',
    100: '#00F57A',
  },
  black: '#1A1A1A',
};

export const defaultTheme: ThemeContext = {
  colors,
  fonts: {
    family: `'Roboto', sans-serif`,
    typography: {
      H900: {
        size: '34px',
        weight: '500',
      },
      H800: {
        size: '28px',
        weight: '500',
      },
      H700: {
        size: '24px',
        weight: '500',
      },
      H600: {
        size: '20px',
        weight: '500',
      },
      H500: {
        size: '18px',
        weight: '500',
      },
      H400: {
        size: '16px',
        weight: '500',
      },
      H300: {
        size: '16px',
        weight: '400',
      },
      H200: {
        size: '14px',
        weight: '500',
      },
      H100: {
        size: '14px',
        weight: '400',
      },
      H90: {
        size: '12px',
        weight: '500',
      },
      H80: {
        size: '12px',
        weight: '400',
      },
    },
  },
  icon: { size: {} },
};

/* Эта обертка нужна для получения дефолтной темы приложения, 
чтобы при кастомизации можно было изменить только нужные подполя
*/

export const ThemeProvider: FC<
  Partial<ThemeProviderProps<PartialThemeContext>>
> = props => {
  const mergedTheme = merge(defaultTheme, props.theme);

  return (
    <StyledThemeProvider {...props} theme={mergedTheme}></StyledThemeProvider>
  );
};
