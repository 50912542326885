import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { adaptersApi } from 'app/api';
import { Formik } from 'formik';
import { CompetitorType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { adapterActions, selectAdapters } from 'app/providers/AdaptersProvider';
import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getCompetitorEntry } from 'app/providers/EntitiesProvider/competitors';
import { getCountriesEntries } from 'app/providers/EntitiesProvider/countries';
import { getPlayersEntries } from 'app/providers/EntitiesProvider/players';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import { CompetitorForm } from './CompetitorForm';
import { FormValues, validationSchema } from './constants';

const typesOptions = Object.values(CompetitorType).map(value => ({
  id: value,
  name: value,
}));

interface Props {
  id: number;
  onClose: () => void;
  sportId?: number;
}

export const CompetitorEditPanel = ({ onClose, id, sportId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const competitor = useSelector(state => getCompetitorEntry(state, id));
  const adapters = useSelector(selectAdapters);
  const sports = useSelector(getSportsEntries);
  const countries = useSelector(getCountriesEntries);
  const players = useSelector(getPlayersEntries);

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(actionsNT.countriesFetchItems({ limit: FETCH_ALL_LIMIT }));
    dispatch(adapterActions.fetchAdapters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actionsNT.competitorsFetchItem({ competitorId: id }));
  }, [id, dispatch]);

  useEffect(() => {
    if (competitor?.referenceIds?.[0]) {
      dispatch(
        actionsNT.playersFetchItem({ playerId: competitor.referenceIds[0] }),
      );
    }
  }, [competitor?.referenceIds, dispatch]);

  useRequestState(Entities.COMPETITORS, 'update', onClose);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.competitorsUpdate({
        competitorId: competitor?.id!,
        competitorWithOutId: {
          name: values.name,
          sportId: values.sport?.id!,
          countryId: values.country?.id!,
          translations: [],
          type: values.type?.id!,
          referenceIds: values.player ? [values.player.id] : [],
        },
      }),
    );
  };

  const onDelete = () => {
    adapters.map(async adapter => {
      try {
        const competitors = await adaptersApi.getAdapterCompetitors({
          adapter: adapter.name!,
          sportsbookId: id,
        });
        if (competitors.items[0].id) {
          await adaptersApi.deleteCompetitorMapping({
            id: competitors.items[0].id!,
            adapter: adapter.name!,
          });
        }
      } catch {}
    });
    dispatch(
      actionsNT.competitorsUpdate({
        competitorId: competitor?.id!,
        competitorWithOutId: {
          ...competitor!,
          disabled: !competitor?.disabled,
        },
      }),
    );
  };

  return (
    <Formik<FormValues>
      initialValues={{
        name: competitor?.name!,
        sport: competitor?.sportId ? sports[competitor.sportId] : undefined,
        country: competitor?.countryId
          ? countries[competitor.countryId]
          : undefined,
        type: typesOptions.find(item => item.id === competitor?.type),
        player: competitor?.referenceIds?.[0]
          ? players[competitor?.referenceIds?.[0]]
          : undefined,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.competitor')}
          onClose={onClose}
          onSubmit={handleSubmit}
          panelWidth={25}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
              leftButtonText={competitor?.disabled ? t('enable') : t('disable')}
              onLeftButtonClick={onDelete}
            />
          }
        >
          <CompetitorForm
            actionType="edit"
            competitor={competitor}
            sportId={sportId}
          />
        </Panel>
      )}
    </Formik>
  );
};
