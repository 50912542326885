import { forwardRef } from 'react';

import { IconWrapper, InputWrapper, StyledInput } from './TextInput.styles';
import { TextInputProps } from './TextInput.types';

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ size = 's', icon, ...props }, ref) => {
    return (
      <InputWrapper size={size}>
        <StyledInput {...props} ref={ref} />
        {icon && <IconWrapper>{icon}</IconWrapper>}
      </InputWrapper>
    );
  },
);
