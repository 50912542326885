import styled from 'styled-components';

import { ThemeContext } from 'app/components/ui/ThemeProvider';

import { SelectState } from './Select.types';

function getBorderColor(
  selectState: SelectState,
  theme: ThemeContext,
  isFocused?: boolean,
  isDisabled?: boolean,
) {
  if (isDisabled) {
    return theme.colors.primary[60];
  }
  if (isFocused) {
    return theme.colors.primary[500];
  }
  if (selectState.invalid) {
    return theme.colors.red[500];
  }
  if (selectState.success) {
    return theme.colors.green[400];
  }
  return theme.colors.primary[60];
}

function getHoverBorderColor(
  selectState: SelectState,
  theme: ThemeContext,
  isFocused?: boolean,
  isDisabled?: boolean,
) {
  if (isDisabled) {
    return theme.colors.primary[60];
  }
  if (isFocused) {
    return theme.colors.primary[500];
  }
  if (selectState.invalid) {
    return theme.colors.red[500];
  }
  if (selectState.success) {
    return theme.colors.green[400];
  }
  if (selectState.readOnly) {
    return theme.colors.primary[60];
  }
  return theme.colors.primary[500];
}

function getBoxShadow(
  selectState: SelectState,
  theme: ThemeContext,
  isFocused?: boolean,
) {
  if (selectState.invalid) {
    return 'none';
  }
  if (isFocused) {
    return `0 0 1px 1px ${theme.colors.primary[500]}`;
  }
  if (selectState.success) {
    return `0 0 1px 1px ${theme.colors.green[400]}`;
  }
  return 'none';
}

function getBackgroundColor(selectState: SelectState, theme: ThemeContext) {
  if (selectState.readOnly || selectState.disabled) {
    return theme.colors.primary[20];
  }
  return theme.colors.primary[0];
}

function getColor(selectState: SelectState, theme: ThemeContext) {
  if (selectState.disabled) {
    return theme.colors.primary[60];
  }
  if (selectState.readOnly) {
    return theme.colors.primary[500];
  }
  return theme.colors.primary[700];
}

export function getStyles(selectState: SelectState, theme: ThemeContext) {
  return {
    container: provided => ({
      ...provided,
      width: '100%',
      fontFamily: theme.fonts.family,
    }),
    control: (provided, { isFocused, isDisabled }) => ({
      ...provided,
      borderColor: getBorderColor(selectState, theme, isFocused, isDisabled),
      boxShadow: getBoxShadow(selectState, theme, isFocused),
      transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
      backgroundColor: getBackgroundColor(selectState, theme),
      color: getColor(selectState, theme),
      '&:hover': {
        borderColor: getHoverBorderColor(
          selectState,
          theme,
          isFocused,
          isDisabled,
        ),
        boxShadow: getBoxShadow(selectState, theme, isFocused),
      },
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: '4px',
      paddingTop: 0,
      paddingBottom: 0,
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: theme.fonts.typography['H200'].size,
      color: theme.colors.primary[80],
      lineHeight: '19px',
    }),
    singleValue: provided => ({
      ...provided,
      zIndex: 2,
      fontSize: theme.fonts.typography['H200'].size,
      lineHeight: '19px',
    }),
    valueContainer: provided => ({
      ...provided,
      fontSize: theme.fonts.typography['H200'].size,
      padding: '1px 8px',
    }),
    clearIndicator: provided => ({
      ...provided,
      padding: '4px',
    }),
    menu: provided => ({
      ...provided,
      zIndex: 3,
    }),
  };
}

export const SelectWrapper = styled.div`
  width: 100%;
`;
