import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Span } from 'app/components/ui/Span/Span';

export const Wrapper = styled.div`
  display: flex;
  padding: 0 20px;
  height: 40px;
  border-bottom: 1px solid #e5e5e5;
`;

type ItemProps = {
  withDots?: boolean;
  withNumber?: boolean;
};

export const Item = styled(NavLink)<ItemProps>`
  cursor: pointer;
  width: min-content;
  padding: 0 10px;

  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;

  font-size: 14px;
  color: black;
  text-decoration: none;

  &.active {
    background: ${p => p.theme.colors.primary[40]};
    box-shadow:
      black 0px -1px 0px 0px inset,
      black 0px 1px 0px 0px;
  }

  ${({ withDots, withNumber }) => {
    if (withDots && withNumber)
      return 'grid-template-columns: repeat(3, auto);';
    if (withDots || withNumber)
      return 'grid-template-columns: repeat(2, auto);';
  }}
`;

export const Label = styled(Span)`
  width: fit-content;
  white-space: nowrap;
`;

export const Count = styled.span`
  white-space: nowrap;
  font-size: 14px;
  color: #808080;
  width: fit-content;
`;
