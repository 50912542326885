import { PayloadAction } from '@reduxjs/toolkit';
import {
  EventList,
  EventsApiDeleteEventRequest,
  EventsApiUpdateEventRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    EventList,
    Event,
    EventsApiDeleteEventRequest,
    EntitiesErrorType
  >({
    entity: 'events',
  }),
  eventsUpdate(
    state,
    action: PayloadAction<
      EventsApiUpdateEventRequest & {
        withDeletion?: boolean;
      }
    >,
  ) {
    state.events.update.error = null;
    state.events.update.id = null;
    state.events.update.result = null;
    state.events.update.loading = true;
    const { withDeletion, eventId } = action.payload;
    if (withDeletion) {
      delete state.events.entries[eventId];
      state.events.sortedIds = state.events.sortedIds.filter(
        id => id !== eventId,
      );
    }
  },
};

export default reducer;
