import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Text } from 'app/components/ui';

interface StyledLinkProps {
  isDisabled: boolean;
}

export const SearchResultWrapper = styled.div`
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${p => p.theme.colors.primary[0]};
  z-index: 2;

  display: grid;
  grid-auto-rows: min-content;
  padding: 0 1rem;
  overflow: auto;
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  background: ${p => (p.isDisabled ? p.theme.colors.primary[50] : ``)};
  color: ${p => p.theme.colors.primary[900]};
  text-decoration: none;
  margin: 0 -1rem;
  padding: 0.5rem 1rem;
  &:hover {
    background-color: ${p => p.theme.colors.primary[20]};
  }
`;

export const EntityKey = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
  background-color: ${p => p.theme.colors.primary[40]};
  color: ${p => p.theme.colors.primary[500]};
  width: fit-content;
  margin: 0.5rem 0;
`;

export const EmptyMessage = styled(Text)`
  text-align: center;
`;
