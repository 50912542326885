import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import { getHomeAwayCompetitors } from 'app/pages/Events/utils';
import { get } from 'lodash-es';
import { Event } from 'sportsbook-openapi-react';

import { SelectEventTableProps } from 'app/components/modals/SelectEventModal/SelectEventModalContent';
import { Table } from 'app/components/ui';

import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import { EmptyMessage } from './SelectEventTable.styles';

export const SelectEventTable: FC<SelectEventTableProps> = ({
  items,
  value,
  onChange,
  loaded,
  excludedEvents,
}) => {
  const { t } = useTranslation();
  const sports = useSelector(getSportsEntries);

  const columns = useMemo<Column<Event>[]>(
    () => [
      {
        Header: t('sport'),
        id: 'sport',
        accessor: 'tournament',
        Cell: ({ value }) => <>{get(sports, `[${value?.sportId}].name`)}</>,
      },
      {
        Header: t('tournament'),
        id: 'tournament',
        accessor: 'tournament',
        Cell: ({ value }) => <>{value?.name}</>,
      },
      {
        Header: t('homeCompetitors'),
        id: 'homeCompetitors',
        Cell: ({ row: { original: data } }) => {
          const { homeCompetitor } = getHomeAwayCompetitors(data);
          return <>{homeCompetitor?.name}</>;
        },
      },
      {
        Header: t('homeCompetitors'),
        id: 'awayCompetitors',
        Cell: ({ row: { original: data } }) => {
          const { awayCompetitor } = getHomeAwayCompetitors(data);
          return <>{awayCompetitor?.name}</>;
        },
      },
    ],
    [sports, t],
  );

  if (!loaded) {
    return null;
  }

  if (loaded && !items.length) {
    return <EmptyMessage>{t('nothing found')}</EmptyMessage>;
  }

  return (
    <Table
      columns={columns}
      data={items.filter(
        item =>
          !excludedEvents?.some(excludeItem => excludeItem.id === item.id),
      )}
      onRowClick={onChange}
      isSelected={row => row.id === value?.id}
    />
  );
};
