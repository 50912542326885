import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const SortableHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-row-gap: 5px;
`;

export const SortingContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 2px;
  font-size: 10px;
  margin: -2px 0;
`;

export const SortingIcon = styled(FontAwesomeIcon)<{ $active: boolean }>`
  cursor: pointer;
  fill: ${p => p.theme.colors.primary[20]};
  transition: fill 0.2s ease;
  path {
    fill: ${p => p.theme.colors.primary[50]};
    transition: fill 0.2s ease;
  }
  &:hover {
    fill: ${p => p.theme.colors.primary[80]};
    path {
      fill: ${p => p.theme.colors.primary[80]};
    }
  }
  ${p =>
    p.$active &&
    `
    path {
    fill: ${p.theme.colors.primary[100]};
    }
    &:hover {
      fill: ${p.theme.colors.primary[200]};
      path {
        fill: ${p.theme.colors.primary[200]};
      }
    } 
  `}
`;
