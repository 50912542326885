import { Column } from 'react-table';
import { CategoryExclusion } from 'sportsbook-openapi-react';

import ExclusionCell from './ExclusionCell';

interface Props {
  header: string;
  accessor: string;
  categoryExclusions: Dictionary<CategoryExclusion>;
  updateCategoryExclusion: (categoryId: number, excluded: boolean) => void;
}

export const ExclusionColumn = <T extends object>({
  header,
  accessor,
  categoryExclusions,
  updateCategoryExclusion,
}: Props): Column<T> => {
  return {
    Header: header,
    id: 'categoryExclusion',
    accessor: accessor as keyof T,
    Cell: ({ value: categoryId }) => {
      const categoryExclusion = categoryExclusions[categoryId];

      return (
        <ExclusionCell
          categoryId={categoryId}
          excluded={categoryExclusion?.excluded}
          updateCategoryExclusion={updateCategoryExclusion}
        />
      );
    },
  };
};
