import styled from 'styled-components';

import { Checkbox } from 'app/components/forms';

export const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 240px);
  gap: 16px;
`;

export const FilterBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
`;

export const StyledCheckbox = styled(Checkbox)`
  flex: 1;
`;
