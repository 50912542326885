import { Event } from 'sportsbook-openapi-react';

import { MODELS_UI_URL } from 'consts';

const getModelsUrl = (event: Event) => {
  const sportId = event.tournament!.sportId;
  return `${MODELS_UI_URL}/cl/${sportId}/${event.id}`;
};

export default getModelsUrl;
