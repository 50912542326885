import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Group } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { actionsNT, Entities } from 'app/providers/EntitiesProvider';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import { FormValues, validationSchema } from './constants';
import { GroupForm } from './GroupForm';

interface Props {
  onClose: () => void;
  sportId?: number;
  onSuccess?: (group: Group) => void;
}

export const GroupCreatePanel = ({ onClose, onSuccess, sportId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sports = useSelector(getSportsEntries);

  const handleGroupCreated = (result: Group) => {
    if (onSuccess) onSuccess(result);
    onClose();
  };

  useRequestState(Entities.GROUPS, 'create', handleGroupCreated);

  const onSubmit = (values: FormValues) => {
    dispatch(
      actionsNT.groupsCreate({
        groupWithOutId: {
          name: values.name,
          sportId: values.sport?.id!,
          translations: [],
        },
      }),
    );
  };

  return (
    <Formik
      initialValues={{
        name: '',
        sport: sportId ? sports[sportId] : undefined,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.create.group')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('create')}
              disabled={!dirty || !isValid}
            />
          }
        >
          <GroupForm actionType="create" sportId={sportId} sports={sports} />
        </Panel>
      )}
    </Formik>
  );
};
