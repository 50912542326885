import { useTranslation } from 'react-i18next';

import { TextInputField } from 'app/components/forms';
import { Span } from 'app/components/ui';

import * as S from './styles';

export const CountryForm = () => {
  const { t } = useTranslation();

  return (
    <S.FormBlock>
      <Span>{t('name')}</Span>
      <TextInputField name="name" />
      <Span>{t('code full')}</Span>
      <TextInputField name="code" />
      <Span>{t('country code')}</Span>
      <TextInputField name="code2" />
    </S.FormBlock>
  );
};
