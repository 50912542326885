import { components, ValueContainerProps } from 'react-select';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BaseOptionType } from 'app/components/forms/Select/Select.types';

export const ClearIndicator = props => {
  const {
    children = <FontAwesomeIcon icon={faTimes} />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      {children}
    </div>
  );
};

export const DropdownIndicator = props => {
  const {
    children = <FontAwesomeIcon icon={faChevronDown} />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('dropdownIndicator', props)}
    >
      {children}
    </div>
  );
};

export const ValueContainer = <
  OptionType extends BaseOptionType,
  IsMulti extends boolean,
>({
  children,
  ...props
}: ValueContainerProps<OptionType, IsMulti>) => {
  return (
    <components.ValueContainer {...props}>
      {props.isMulti &&
        !props.selectProps.inputValue &&
        props.selectProps.placeholder}
      {children}
    </components.ValueContainer>
  );
};

export const MultiValueContainer = () => {
  return null;
};
