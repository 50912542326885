import { useTranslation } from 'react-i18next';
import { LogoEntityType } from 'sportsbook-openapi-react';

import { LogoUploader } from 'app/components/LogoUploader';
import { Panel } from 'app/components/ui';

interface Props {
  countryId: number;
  countryName: string;
  onClose: () => void;
}

export const CountryLogos = ({ onClose, countryId, countryName }: Props) => {
  const { t } = useTranslation();

  return (
    <Panel title={t('logos')} onClose={onClose} panelWidth={40}>
      <>
        {t('country')}: {countryName}
      </>
      <LogoUploader
        entityId={countryId}
        entityType={LogoEntityType.COUNTRY}
        withTitle={false}
      />
    </Panel>
  );
};
