import { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Formik } from 'formik';
import { EntityType } from 'sportsbook-openapi-react';

import { TextInputField } from 'app/components/forms';

import {
  BETPAYMAX_TRANSMISSION_COEFFICIENT,
  useBetPayMaxMethods,
  UseCategoryCashoutBlockMethods,
  useCategoryExclusionMethods,
  useDelayMethods,
  useMarginMethods,
} from 'app/providers/EntitiesProvider/hooks';
import { useMaxOddMethods } from 'app/providers/EntitiesProvider/hooks/useMaxOdd';

import * as S from './GeneralValuesPopup.styles';

interface Props {
  outcomeCategoriesIds: number[];
  entityType: EntityType;
  entityId: number;
  live: boolean;
}

interface MaxbetValues {
  maxBet?: number;
}
interface DelayValues {
  delay?: number;
}
interface MarginValues {
  margin?: number;
}
interface MaxOddValues {
  maxOdd?: number;
}

interface CashoutBlockValues {
  cashoutBlocked: boolean;
}
interface ExclusionValues {
  excluded: boolean;
}

enum PopupStateEnum {
  EMPTY,
  MAXBET,
  DELAY,
  MARGIN,
  CASHOUT_BLOCK,
  MAXODD,
  EXCLUSION,
}

export const GeneralValuesPopup: FC<Props> = ({
  outcomeCategoriesIds,
  entityType,
  entityId,
  live,
}) => {
  const { t } = useTranslation();

  const stopPropagationHandler = (event: MouseEvent) => {
    event.stopPropagation();
  };

  const { createBetPayMaxList } = useBetPayMaxMethods({
    entityType,
    entityId,
    live: live,
  });
  const { createDelaysList } = useDelayMethods({
    entityType,
    entityId,
  });
  const { createMarginsList } = useMarginMethods({
    entityType,
    entityId,
    live,
  });
  const { createMaxOddList } = useMaxOddMethods({
    entityType,
    entityId,
    live,
  });
  const { createCategoryCashoutBlockList } = UseCategoryCashoutBlockMethods({
    entityType,
    entityId,
    live,
  });
  const { createCategoryExclusionList } = useCategoryExclusionMethods({
    entityType,
    entityId,
    live,
  });

  const [popupState, setPopupState] = useState(PopupStateEnum.EMPTY);

  switch (popupState) {
    case PopupStateEnum.EMPTY:
      return (
        <S.ButtonWrapper>
          <S.PlainButton
            color="tretiary"
            onClick={event => {
              event.stopPropagation();
              setPopupState(PopupStateEnum.MAXBET);
            }}
          >
            {t('maxbet')}
          </S.PlainButton>
          <S.PlainButton
            color="tretiary"
            onClick={event => {
              event.stopPropagation();
              setPopupState(PopupStateEnum.DELAY);
            }}
          >
            {t('delay')}
          </S.PlainButton>
          <S.PlainButton
            color="tretiary"
            onClick={event => {
              event.stopPropagation();
              setPopupState(PopupStateEnum.MARGIN);
            }}
          >
            {t('margin')}
          </S.PlainButton>
          <S.PlainButton
            color="tretiary"
            onClick={event => {
              event.stopPropagation();
              setPopupState(PopupStateEnum.CASHOUT_BLOCK);
            }}
          >
            {t('cashout block')}
          </S.PlainButton>
          <S.PlainButton
            color="tretiary"
            onClick={event => {
              event.stopPropagation();
              setPopupState(PopupStateEnum.EXCLUSION);
            }}
          >
            {t('exclusion')}
          </S.PlainButton>
          <S.PlainButton
            color="tretiary"
            onClick={event => {
              event.stopPropagation();
              setPopupState(PopupStateEnum.MAXODD);
            }}
          >
            {t('maxOdd')}
          </S.PlainButton>
        </S.ButtonWrapper>
      );
    case PopupStateEnum.MAXBET:
      return (
        <Formik<MaxbetValues>
          onSubmit={values => {
            createBetPayMaxList(
              outcomeCategoriesIds.map(outcomeCategoryId => ({
                value: values.maxBet! * BETPAYMAX_TRANSMISSION_COEFFICIENT,
                maxPay: false,
                outcomeCategoryId,
              })),
            );
          }}
          initialValues={{
            maxBet: 1000,
          }}
        >
          {({ handleSubmit }) => (
            <S.Column onClick={stopPropagationHandler} onSubmit={handleSubmit}>
              <S.FormField>
                <S.FormText>{t('maxbet')}</S.FormText>
                <S.ContentWrapper>
                  <S.InputWrapper>
                    <TextInputField name="maxBet" type="number" />
                  </S.InputWrapper>
                  <S.StyledButton type="submit">
                    <FontAwesomeIcon icon={faCheck} size="xs" />
                  </S.StyledButton>
                </S.ContentWrapper>
              </S.FormField>
            </S.Column>
          )}
        </Formik>
      );
    case PopupStateEnum.MARGIN:
      return (
        <Formik<MarginValues>
          onSubmit={values => {
            createMarginsList(
              outcomeCategoriesIds.map(outcomeCategoryId => ({
                margin: values.margin!,
                outcomeCategoryId,
              })),
            );
          }}
          initialValues={{}}
        >
          {({ handleSubmit }) => (
            <S.Column onClick={stopPropagationHandler} onSubmit={handleSubmit}>
              <S.FormField>
                <S.FormText>{t('margin')}</S.FormText>
                <S.ContentWrapper>
                  <S.InputWrapper>
                    <TextInputField name="margin" type="number" />
                  </S.InputWrapper>
                  <S.StyledButton type="submit">
                    <FontAwesomeIcon icon={faCheck} size="xs" />
                  </S.StyledButton>
                </S.ContentWrapper>
              </S.FormField>
            </S.Column>
          )}
        </Formik>
      );
    case PopupStateEnum.MAXODD:
      return (
        <Formik<MaxOddValues>
          onSubmit={values => {
            createMaxOddList(
              outcomeCategoriesIds.map(outcomeCategoryId => ({
                value: values.maxOdd!,
                outcomeCategoryId,
              })),
            );
          }}
          initialValues={{}}
        >
          {({ handleSubmit }) => (
            <S.Column onClick={stopPropagationHandler} onSubmit={handleSubmit}>
              <S.FormField>
                <S.FormText>{t('maxOdd short')}</S.FormText>
                <S.ContentWrapper>
                  <S.InputWrapper>
                    <TextInputField name="maxOdd" type="number" />
                  </S.InputWrapper>
                  <S.StyledButton type="submit">
                    <FontAwesomeIcon icon={faCheck} size="xs" />
                  </S.StyledButton>
                </S.ContentWrapper>
              </S.FormField>
            </S.Column>
          )}
        </Formik>
      );
    case PopupStateEnum.DELAY:
      return (
        <Formik<DelayValues>
          onSubmit={values => {
            createDelaysList(
              outcomeCategoriesIds.map(outcomeCategoryId => ({
                value: values.delay!,
                outcomeCategoryId,
              })),
            );
          }}
          initialValues={{}}
        >
          {({ handleSubmit }) => (
            <S.Column onClick={stopPropagationHandler} onSubmit={handleSubmit}>
              <S.FormField>
                <S.FormText>{t('delay')}</S.FormText>
                <S.ContentWrapper>
                  <S.InputWrapper>
                    <TextInputField name="delay" type="number" />
                  </S.InputWrapper>
                  <S.StyledButton type="submit">
                    <FontAwesomeIcon icon={faCheck} size="xs" />
                  </S.StyledButton>
                </S.ContentWrapper>
              </S.FormField>
            </S.Column>
          )}
        </Formik>
      );
    case PopupStateEnum.CASHOUT_BLOCK:
      return (
        <Formik<CashoutBlockValues>
          onSubmit={values => {
            createCategoryCashoutBlockList(
              outcomeCategoriesIds.map(categoryId => ({
                categoryId,
                cashoutBlocked: values.cashoutBlocked,
              })),
            );
          }}
          initialValues={{ cashoutBlocked: false }}
        >
          {({ handleSubmit }) => (
            <S.Column onClick={stopPropagationHandler} onSubmit={handleSubmit}>
              <S.FormField>
                <S.CheckboxFieldWrapper>
                  <Field type="checkbox" label="" name="cashoutBlocked" />
                  <S.FormText>{t('cashout block')}</S.FormText>
                </S.CheckboxFieldWrapper>
                <S.StyledButton type="submit">
                  <FontAwesomeIcon icon={faCheck} size="xs" />
                </S.StyledButton>
              </S.FormField>
            </S.Column>
          )}
        </Formik>
      );
    case PopupStateEnum.EXCLUSION:
      return (
        <Formik<ExclusionValues>
          onSubmit={values => {
            createCategoryExclusionList(
              outcomeCategoriesIds.map(categoryId => ({
                categoryId,
                excluded: values.excluded,
              })),
            );
          }}
          initialValues={{ excluded: false }}
        >
          {({ handleSubmit }) => (
            <S.Column onClick={stopPropagationHandler} onSubmit={handleSubmit}>
              <S.FormField>
                <S.CheckboxFieldWrapper>
                  <Field type="checkbox" label="" name="excluded" />
                  <S.FormText>{t('exclusion')}</S.FormText>
                </S.CheckboxFieldWrapper>
                <S.StyledButton type="submit">
                  <FontAwesomeIcon icon={faCheck} size="xs" />
                </S.StyledButton>
              </S.FormField>
            </S.Column>
          )}
        </Formik>
      );
  }
};
