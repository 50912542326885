import { logosApi } from 'app/api';

const api = {
  endpoint: logosApi,
  methods: {
    fetchItems: 'getLogos',
    createList: 'insertLogo',
    delete: 'deleteLogos',
  },
};

export default api;
