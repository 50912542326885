/* --- STATE --- */
export interface TranslationsState {
  filters: TranslationFilters;
  entity: TranslatedEntities;
}

export type ContainerState = TranslationsState;

export enum TranslatedEntities {
  CATEGORIES = 'categories',
  COMPETITORS = 'competitors',
  COUNTRIES = 'countries',
  GROUPS = 'groups',
  SPORTS = 'sports',
  TOURNAMENTS = 'tournaments',
  TYPES = 'types',
  PLAYERS = 'players',
  SHORT_NAMES = 'shortNames',
  MATCH_TYPES = 'matchTypes',
  MATCH_PHASES = 'matchPhases',
  COMMENTS = 'comments',
  CURRENCIES = 'currencies',
}

export interface TranslationFilters {
  langCode?: string;
  translated?: boolean;
  sportId?: number;
  name?: string;
  translationName?: string;
}
