import { Translation } from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
});

export interface FormValues {
  name: string;
  disabled: boolean;
  translations?: Translation[];
}

export const createInitialValues: FormValues = {
  name: '',
  translations: [],
  disabled: false,
};
