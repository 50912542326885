import { mapValues } from 'lodash-es';

export const transformDate = payload => {
  if (payload instanceof Date) {
    return (payload as Date).toJSON();
  }
  if (payload instanceof Array) {
    return payload.map(transformDate);
  }
  if (payload instanceof Object) {
    return mapValues(payload, value => transformDate(value));
  }
  return payload;
};

export const applyTransform = payload => {
  /*
  All the necessary middlewares for response processing should be placed here
   */
  return transformDate(payload);
  // return payload;
};
