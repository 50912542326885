import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { adaptersApi } from 'app/api';
import { FormikProvider, useFormik } from 'formik';
import { Tournament as TournamentType } from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import { adapterActions, selectAdapters } from 'app/providers/AdaptersProvider';
import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';
import { getCountriesEntries } from 'app/providers/EntitiesProvider/countries';
import { getGroupsEntries } from 'app/providers/EntitiesProvider/groups';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';
import { getTournamentsEntries } from 'app/providers/EntitiesProvider/tournaments';

import { FormValues, validationSchema } from './constants';
import { TournamentForm } from './TournamentForm';

interface Props {
  id: number;
  onClose: () => void;
}

export const TournamentEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tournament: TournamentType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.TOURNAMENTS, id),
  );
  const sports = useSelector(getSportsEntries);
  const groups = useSelector(getGroupsEntries);
  const countries = useSelector(getCountriesEntries);
  const adapters = useSelector(selectAdapters);
  const tournaments = useSelector(getTournamentsEntries);

  useRequestState(Entities.TOURNAMENTS, 'update', onClose);

  useEffect(() => {
    dispatch(adapterActions.fetchAdapters());
  }, [dispatch]);

  useEffect(() => {
    if (!!tournament.baseTournamentId) {
      dispatch(
        actionsNT.tournamentsFetchItem({
          tournamentId: tournament.baseTournamentId,
        }),
      );
    }
  }, [dispatch, tournament.baseTournamentId]);

  const changeTournamentStatus = () => {
    adapters.map(async adapter => {
      try {
        const tournaments = await adaptersApi.getAdapterTournaments({
          adapter: adapter.name!,
          sportsbookId: id,
        });
        if (tournaments.items[0].id) {
          await adaptersApi.deleteTournamentMapping({
            id: tournaments.items[0].id!,
            adapter: adapter.name!,
          });
        }
      } catch {}
    });
    dispatch(
      actionsNT.tournamentsUpdate({
        tournamentId: tournament.id,
        tournamentWithOutId: {
          ...tournament,
          disabled: !tournament?.disabled,
        },
      }),
    );
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      name: tournament.name,
      sport: sports[tournament.sportId],
      group: groups[tournament.groupId],
      country: countries[tournament.countryId],
      kind: tournament.kind
        ? { id: tournament.kind, name: tournament.kind }
        : undefined,
      baseTournament: tournament.baseTournamentId
        ? tournaments[tournament.baseTournamentId]
        : undefined,
    },
    validationSchema,
    onSubmit: values => {
      dispatch(
        actionsNT.tournamentsUpdate({
          tournamentId: tournament.id,
          tournamentWithOutId: {
            ...tournament,
            name: values.name,
            sportId: values.sport?.id!,
            groupId: values.group?.id!,
            countryId: values.country?.id!,
            translations: [],
            kind: values.kind?.id,
            baseTournamentId: values.baseTournament?.id,
          },
        }),
      );
    },
    enableReinitialize: true,
  });
  const { values, setFieldValue, handleSubmit, isValid, dirty } = formik;

  useEffect(() => {
    if (values.sport?.id && values.group?.sportId !== values.sport.id) {
      setFieldValue('group', null);
    }
  }, [setFieldValue, values.group?.sportId, values.sport?.id]);

  return (
    <FormikProvider value={formik}>
      <Panel
        title={t('panels.edit.tournament')}
        onClose={onClose}
        onSubmit={handleSubmit}
        footer={
          <PanelFooter
            submitButtonText={t('edit')}
            disabled={!dirty || !isValid}
            leftButtonText={tournament?.disabled ? t('enable') : t('disable')}
            onLeftButtonClick={changeTournamentStatus}
          />
        }
      >
        <TournamentForm
          countries={countries}
          sports={sports}
          groups={groups}
          tournament={tournament}
          actionType="edit"
        />
      </Panel>
    </FormikProvider>
  );
};
