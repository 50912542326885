import { PayloadAction } from '@reduxjs/toolkit';
import {
  OutcomeApiDeleteCategoryRequest,
  OutcomeCategory,
  OutcomeCategoryList,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    OutcomeCategoryList,
    OutcomeCategory,
    OutcomeApiDeleteCategoryRequest,
    EntitiesErrorType
  >({
    entity: 'categories',
  }),
  categoriesCreateSuccess(state, action: PayloadAction<OutcomeCategory>) {
    const category = action.payload;
    state.categories.entries[category.id] = category;
    state.categories.sortedIds.push(category.id);
    state.types.byCategory[category.id] = {};
    state.categories.create.loading = false;
    state.categories.create.id = category.id;
    state.categories.create.result = category;
  },
};

export default reducer;
