import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityType, MaxOdd, OutcomeCategory } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectMaxOddsByCategory } from 'app/providers/EntitiesProvider/maxOdds';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

interface UseMaxOddMethodsProps {
  entityId: number;
  entityType: EntityType;
  live: boolean;
}

export const DEFAULT_MAXODD_VALUE = 50;

export const useMaxOddMethods = ({
  entityId,
  entityType,
  live = false,
}: UseMaxOddMethodsProps) => {
  const dispatch = useDispatch();
  const { customerId } = useUserStore(getIsCustomer);

  const fetchMaxOdds = useCallback(() => {
    dispatch(actionsNT.maxOddsReset());
    dispatch(
      actionsNT.maxOddsFetchItems({
        customerId,
        entityId,
        entityType,
        limit: FETCH_ALL_LIMIT,
      }),
    );
  }, [customerId, dispatch, entityId, entityType]);

  const fetchMaxOdd = useCallback(
    ({ maxOddId }) => {
      dispatch(
        actionsNT.maxOddsFetchItem({
          maxOddId,
        }),
      );
    },
    [dispatch],
  );

  const createMaxOdd = useCallback(
    ({
      outcomeCategoryId,
      maxOdd,
    }: {
      outcomeCategoryId: number;
      maxOdd: number;
    }) => {
      dispatch(
        actionsNT.maxOddsCreateList({
          maxOddListWithOutId: {
            items: [
              {
                customerId,
                outcomeCategoryId,
                maxOdd,
                entityId,
                entityType,
                live,
              },
            ],
          },
        }),
      );
    },
    [customerId, dispatch, entityId, entityType, live],
  );

  const createMaxOddList = useCallback(
    (items: { outcomeCategoryId: number; value: number }[]) => {
      dispatch(
        actionsNT.maxOddsCreateList({
          maxOddListWithOutId: {
            items: items.map(item => ({
              customerId,
              entityId,
              entityType,
              live,
              maxOdd: item.value,
              ...item,
            })),
          },
        }),
      );
    },
    [customerId, dispatch, entityId, entityType, live],
  );

  const updateMaxOdd = useCallback(
    (maxOdd: MaxOdd, { value }) => {
      dispatch(
        actionsNT.maxOddsUpdate({
          maxOddId: maxOdd.id,
          maxOddWithOutId: {
            ...maxOdd,
            customerId,
            maxOdd: value,
          },
        }),
      );
    },
    [customerId, dispatch],
  );

  const deleteMaxOdd = useCallback(
    ({ maxOddId }: { maxOddId: number }) => {
      dispatch(
        actionsNT.maxOddsDelete({
          maxOddId,
        }),
      );
    },
    [dispatch],
  );

  return {
    fetchMaxOdds,
    fetchMaxOdd,
    createMaxOdd,
    updateMaxOdd,
    deleteMaxOdd,
    createMaxOddList,
  };
};

export const useMaxOdd = (props: UseMaxOddMethodsProps) => {
  const {
    fetchMaxOdds,
    fetchMaxOdd,
    createMaxOdd,
    createMaxOddList,
    updateMaxOdd,
    deleteMaxOdd,
  } = useMaxOddMethods(props);

  useEffect(() => {
    fetchMaxOdds();
  }, [fetchMaxOdds]);

  const maxOddsByCategory = useSelector(selectMaxOddsByCategory);

  const deleteMaxOddByCategory = useCallback(
    ({ outcomeCategoryId }: { outcomeCategoryId: number }) => {
      const maxOdd = maxOddsByCategory[outcomeCategoryId];
      if (maxOdd) {
        deleteMaxOdd({ maxOddId: maxOdd.id });
      }
    },
    [deleteMaxOdd, maxOddsByCategory],
  );

  const { isLoading: isMaxOddsLoading } = useRequestState(
    'maxOdds',
    'fetchItems',
  );

  const updateMaxOddValue = useCallback(
    ({
      maxOdd,
      value,
      outcomeCategoryId,
    }: {
      maxOdd?: MaxOdd;
      value: number;
      outcomeCategoryId: number;
    }) => {
      if (maxOdd?.entityType === props.entityType) {
        updateMaxOdd(maxOdd, {
          value,
        });
      } else {
        createMaxOdd({
          outcomeCategoryId,
          maxOdd: value,
        });
      }
    },
    [createMaxOdd, props.entityType, updateMaxOdd],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.ENTITY_MAXODDS,
  });

  const openMaxOddAudit = useCallback<
    (outcomeCategoryId: OutcomeCategory['id']) => void
  >(
    outcomeCategoryId => {
      const maxOdd = maxOddsByCategory[outcomeCategoryId];

      if (maxOdd) {
        openAudit({ id: String(maxOdd.id), includeRelated: false });
      }
    },
    [maxOddsByCategory, openAudit],
  );

  return {
    isMaxOddsLoading,
    openMaxOddAudit,
    updateMaxOddValue,
    deleteMaxOddByCategory,
    fetchMaxOdd,
    createMaxOddList,
    maxOdds: maxOddsByCategory,
  };
};
