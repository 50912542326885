import { playersApi } from 'app/api';

const api = {
  endpoint: playersApi,
  methods: {
    fetchItems: 'getPlayers',
    fetchItem: 'getPlayer',
    create: 'createPlayer',
    update: 'updatePlayer',
    delete: 'deletePlayer',
  },
};

export default api;
