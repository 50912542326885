import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { useField } from 'formik';
import { Event, UserGroupEnum } from 'sportsbook-openapi-react';
import styled from 'styled-components';

import { getEventName } from 'app/components/panels/dependency/utils';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Table } from 'app/components/ui';

import { selectEventsItems } from 'app/providers/EntitiesProvider/events';

const getEventPath = eventId => `/events/${eventId}`;

export const EventsTable = ({ name }: { name: string }) => {
  const { t } = useTranslation();
  const events = useSelector(selectEventsItems);
  const [field, , helpers] = useField<number[]>(name);
  const eventIds = field.value;

  const columns: Column<Event>[] = [
    {
      Header: t('dependent events').toString(),
      id: 'name',
      Cell: ({ row: { original } }) => <>{getEventName(original)}</>,
    },
    {
      Header: t('id').toString(),
      accessor: 'id',
      Cell: ({ value }) => (
        <StyledLink target="_blank" to={getEventPath(value)}>
          {value}
        </StyledLink>
      ),
    },
    {
      Header: '',
      accessor: 'id',
      id: 'button',
      Cell: ({ value }) => (
        <RoleGuards
          roles={[
            UserGroupEnum.ADMIN,
            UserGroupEnum.OPERATOR,
            UserGroupEnum.SUPERVISOR,
          ]}
        >
          <Button
            color="link"
            onClick={() => {
              helpers.setValue(eventIds.filter(eventId => eventId !== value));
            }}
          >
            {t('delete')}
          </Button>
        </RoleGuards>
      ),
    },
  ];

  const data = useMemo(
    () => events.filter(item => eventIds.includes(item.id)),
    [eventIds, events],
  );

  return <Table columns={columns} data={data} />;
};

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${p => p.theme.colors.blue[300]};
  &:visited {
    color: ${p => p.theme.colors.blue[300]};
  }
`;
