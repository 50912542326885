import styled from 'styled-components';

export const EventSelectWrapper = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  height: 100%;
  min-width: 200px;
  width: 100%;
  padding: 0 10px;
  transition:
    border-color 0.2s ease,
    background-color 0.2s ease;
  line-height: 32px;

  font-family: ${props => props.theme.fonts.family};
  font-size: ${props => props.theme.fonts.typography['H200'].size};
  font-weight: ${props => props.theme.fonts.typography['H200'].weight};
  color: ${props => props.theme.colors.primary[700]};

  border-style: solid;
  border-color: ${({ theme }) => theme.colors.primary[60]};
  border-width: 1px;
  border-radius: 4px;

  background-color: ${({ theme }) => theme.colors.primary[0]};

  &:hover {
    border-color: ${p => p.theme.colors.primary[500]};
  }
  &:disabled {
    color: ${props => props.theme.colors.primary[60]};
    &:hover {
      border-color: ${p => p.theme.colors.primary[60]};
    }
  }
  &:read-only {
    color: ${props => props.theme.colors.primary[500]};
    &:hover {
      border-color: ${p => p.theme.colors.primary[60]};
    }
  }
  &:focus {
    border-color: ${p => p.theme.colors.primary[500]};
    box-shadow: 0 0 1px 1px ${p => p.theme.colors.primary[500]};
    outline: none;
    &:hover {
      border-color: ${p => p.theme.colors.primary[500]};
      box-shadow: 0 0 1px 1px ${p => p.theme.colors.primary[500]};
    }
  }
  ::placeholder {
    color: ${p => p.theme.colors.primary[80]};
  }

  &:empty:before {
    content: attr(placeholder);
    color: ${p => p.theme.colors.primary[80]};
  }

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
