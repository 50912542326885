import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  OutcomeCategory as OutcomeCategoryType,
  OutcomeCategoryWithOutId,
} from 'sportsbook-openapi-react';

import { useRequestState } from 'hooks/useRequestState';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';

import { CategoryForm } from './CategoryForm';
import { validationSchema } from './constants';

interface Props {
  id: number;
  onClose: () => void;
}

export const CategoryEditPanel = ({ onClose, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const category: OutcomeCategoryType = useSelector(state =>
    getEntityByTypeAndId(state, Entities.CATEGORIES, id),
  );

  useRequestState(Entities.CATEGORIES, 'update', onClose);

  const onSubmit = (values: OutcomeCategoryWithOutId) => {
    dispatch(
      actionsNT.categoriesUpdate({
        categoryId: category.id,
        outcomeCategoryWithOutId: {
          name: values.name,
          sports: values.sports,
          priorityOrder: Number(values.priorityOrder),
          translations: [],
        },
      }),
    );
  };

  const changeCategoryStatus = () => {
    dispatch(
      actionsNT.categoriesUpdate({
        categoryId: category.id,
        outcomeCategoryWithOutId: {
          ...category,
          disabled: !category.disabled,
        },
      }),
    );
  };

  return (
    <Formik<OutcomeCategoryWithOutId>
      initialValues={category}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, dirty, isValid }) => (
        <Panel
          title={t('panels.edit.category')}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter
              submitButtonText={t('edit')}
              disabled={!dirty || !isValid}
              leftButtonText={category.disabled ? t('enable') : t('disable')}
              onLeftButtonClick={changeCategoryStatus}
            />
          }
        >
          <CategoryForm />
        </Panel>
      )}
    </Formik>
  );
};
