import { RootState } from 'types';

import { entityInitialState } from './constants';
import { AuditedEntities } from './types';

const selectDomain = (state: RootState) => state.auditProvider;

const getEntity = (state: RootState, entity: AuditedEntities) =>
  selectDomain(state).entities[entity] || entityInitialState;

export const getEntityPaginationToken = (
  state: RootState,
  entity: AuditedEntities,
) => getEntity(state, entity).paginationToken;

export const getEntityItems = (state: RootState, entity: AuditedEntities) =>
  getEntity(state, entity).items;

export const getEntityHasMore = (state: RootState, entity: AuditedEntities) =>
  !!getEntityPaginationToken(state, entity)?.length;

export const getAuditPanel = (state: RootState) => selectDomain(state).panel;
