import { TranslatedEntities } from 'app/pages/Translations/types';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';

export const entityToAction = {
  [TranslatedEntities.SPORTS]: actionsNT.sportsFetchItems,
  [TranslatedEntities.GROUPS]: actionsNT.groupsFetchItems,
  [TranslatedEntities.TOURNAMENTS]: actionsNT.tournamentsFetchItems,
  [TranslatedEntities.COMPETITORS]: actionsNT.competitorsFetchItems,
  [TranslatedEntities.COUNTRIES]: actionsNT.countriesFetchItems,
  [TranslatedEntities.PLAYERS]: actionsNT.playersFetchItems,
  [TranslatedEntities.CATEGORIES]: actionsNT.categoriesFetchItems,
  [TranslatedEntities.TYPES]: actionsNT.typesFetchItems,
  [TranslatedEntities.SHORT_NAMES]: actionsNT.typesFetchItems,
  [TranslatedEntities.MATCH_TYPES]: actionsNT.matchTypesFetchItems,
  [TranslatedEntities.MATCH_PHASES]: actionsNT.matchPhasesFetchItems,
  [TranslatedEntities.COMMENTS]: actionsNT.commentsFetchItems,
  [TranslatedEntities.CURRENCIES]: actionsNT.currenciesFetchItems,
};

export const entityToResetAction = {
  [TranslatedEntities.SPORTS]: actionsNT.sportsReset,
  [TranslatedEntities.GROUPS]: actionsNT.groupsReset,
  [TranslatedEntities.TOURNAMENTS]: actionsNT.tournamentsReset,
  [TranslatedEntities.COMPETITORS]: actionsNT.competitorsReset,
  [TranslatedEntities.COUNTRIES]: actionsNT.countriesReset,
  [TranslatedEntities.PLAYERS]: actionsNT.playersReset,
  [TranslatedEntities.CATEGORIES]: actionsNT.categoriesReset,
  [TranslatedEntities.TYPES]: actionsNT.typesReset,
  [TranslatedEntities.SHORT_NAMES]: actionsNT.typesReset,
  [TranslatedEntities.MATCH_TYPES]: actionsNT.matchTypesReset,
  [TranslatedEntities.MATCH_PHASES]: actionsNT.matchPhasesReset,
  [TranslatedEntities.COMMENTS]: actionsNT.commentsReset,
  [TranslatedEntities.CURRENCIES]: actionsNT.currenciesReset,
};

export const entityToAudit = {
  [TranslatedEntities.SPORTS]: AuditedEntities.SPORTS,
  [TranslatedEntities.GROUPS]: AuditedEntities.GROUPS,
  [TranslatedEntities.TOURNAMENTS]: AuditedEntities.TOURNAMENTS,
  [TranslatedEntities.COMPETITORS]: AuditedEntities.COMPETITORS,
  [TranslatedEntities.COUNTRIES]: AuditedEntities.COUNTRIES,
  [TranslatedEntities.PLAYERS]: AuditedEntities.PLAYERS,
  [TranslatedEntities.CATEGORIES]: AuditedEntities.OUTCOME_CATEGORIES,
  [TranslatedEntities.TYPES]: AuditedEntities.OUTCOME_TYPES,
  [TranslatedEntities.SHORT_NAMES]: AuditedEntities.OUTCOME_TYPES,
  [TranslatedEntities.MATCH_TYPES]: AuditedEntities.MATCH_TYPES,
  [TranslatedEntities.MATCH_PHASES]: AuditedEntities.MATCH_PHASES,
  [TranslatedEntities.COMMENTS]: AuditedEntities.COMMENTS,
  [TranslatedEntities.CURRENCIES]: AuditedEntities.CURRENCIES,
};
