import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Delay, EntityType, OutcomeCategory } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import { useRequestState } from 'hooks/useRequestState';
import { FETCH_ALL_LIMIT } from 'consts';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectDelaysByCategory } from 'app/providers/EntitiesProvider/delays';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

interface UseDelayMethodsProps {
  entityId: number;
  entityType: EntityType;
}

export const useDelayMethods = ({
  entityId,
  entityType,
}: UseDelayMethodsProps) => {
  const dispatch = useDispatch();
  const { isCustomer, customerId } = useUserStore(getIsCustomer);

  const fetchDelays = useCallback(() => {
    if (isCustomer && customerId) {
      dispatch(actionsNT.delaysReset());
      dispatch(
        actionsNT.delaysFetchItems({
          customerId,
          entityId,
          entityType,
          limit: FETCH_ALL_LIMIT,
        }),
      );
    }
  }, [customerId, dispatch, entityId, entityType, isCustomer]);

  const fetchDelay = useCallback(
    ({ delayId }) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.delaysFetchItem({
            delayId,
          }),
        );
      }
    },
    [customerId, dispatch, isCustomer],
  );

  const createDelay = useCallback(
    ({
      outcomeCategoryId,
      value,
    }: {
      outcomeCategoryId: number;
      value: number;
    }) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.delaysCreate({
            delayWithOutId: {
              customerId,
              outcomeCategoryId,
              value,
              entityId,
              entityType,
            },
          }),
        );
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer],
  );

  const createDelaysList = useCallback(
    (items: { outcomeCategoryId: number; value: number }[]) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.delaysCreateList({
            delayListWithOutId: {
              items: items.map(item => ({
                customerId,
                entityId,
                entityType,
                ...item,
              })),
            },
          }),
        );
      }
    },
    [customerId, dispatch, entityId, entityType, isCustomer],
  );

  const updateDelay = useCallback(
    (delay: Delay, { value }) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.delaysUpdate({
            delayId: delay.id,
            delayWithOutId: {
              ...delay,
              customerId,
              value,
            },
          }),
        );
      }
    },
    [customerId, dispatch, isCustomer],
  );

  const deleteDelay = useCallback(
    ({ delayId }: { delayId: number }) => {
      if (isCustomer && customerId) {
        dispatch(
          actionsNT.delaysDelete({
            delayId,
          }),
        );
      }
    },
    [customerId, dispatch, isCustomer],
  );

  return {
    fetchDelays,
    fetchDelay,
    createDelay,
    createDelaysList,
    updateDelay,
    deleteDelay,
  };
};

export const useDelay = (props: UseDelayMethodsProps) => {
  const {
    fetchDelays,
    fetchDelay,
    createDelay,
    createDelaysList,
    updateDelay,
    deleteDelay,
  } = useDelayMethods(props);

  useEffect(() => {
    fetchDelays();
  }, [fetchDelays]);

  const delaysByCategory = useSelector(selectDelaysByCategory);

  const deleteDelayByCategory = useCallback(
    ({ outcomeCategoryId }: { outcomeCategoryId: number }) => {
      const delay = delaysByCategory[outcomeCategoryId];
      if (delay) {
        deleteDelay({ delayId: delay.id });
      }
    },
    [delaysByCategory, deleteDelay],
  );

  const { isLoading: isDelaysLoading } = useRequestState(
    'delays',
    'fetchItems',
  );

  const updateDelayValue = useCallback(
    ({
      delay,
      value,
      outcomeCategoryId,
    }: {
      delay?: Delay;
      value: number;
      outcomeCategoryId: number;
    }) => {
      if (delay?.entityType === props.entityType) {
        updateDelay(delay, {
          value,
        });
      } else {
        createDelay({
          outcomeCategoryId,
          value: value,
        });
      }
    },
    [createDelay, props.entityType, updateDelay],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.ENTITY_DELAYS,
  });

  const openDelayAudit = useCallback<
    (outcomeCategoryId: OutcomeCategory['id']) => void
  >(
    outcomeCategoryId => {
      const delay = delaysByCategory[outcomeCategoryId];

      if (delay) {
        openAudit({ id: String(delay.id), includeRelated: false });
      }
    },
    [delaysByCategory, openAudit],
  );

  return {
    fetchDelay,
    createDelaysList,
    deleteDelayByCategory,
    isDelaysLoading,
    openDelayAudit,
    updateDelayValue,
    delays: delaysByCategory,
  };
};
