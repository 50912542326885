import { tournamentsApi } from 'app/api';

const api = {
  endpoint: tournamentsApi,
  methods: {
    fetchItems: 'getTournaments',
    fetchItem: 'getTournament',
    create: 'createTournament',
    update: 'updateTournament',
    delete: 'deleteTournament',
  },
};

export default api;
