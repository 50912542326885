import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Event } from 'sportsbook-openapi-react';

import useBooleanState from 'hooks/useBooleanState';
import { RootState } from 'types';

import { SourceEventsPanel } from 'app/components/panels';
import { Button } from 'app/components/ui';

import {
  adapterActions,
  selectMappedEventsItemsById,
} from 'app/providers/AdaptersProvider';

import { useUserStore } from 'store';
import { getIsCustomer } from 'store/user';

interface Props {
  eventId: Event['id'];
}

export const ShowSourcesButton = ({ eventId }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sourcesItems = useSelector((state: RootState) =>
    selectMappedEventsItemsById(state, eventId),
  );
  const [
    isSourceEventsPanelVisible,
    showSourceEventsPanel,
    hideSourceEventsPanel,
  ] = useBooleanState();
  const { isCustomer } = useUserStore(getIsCustomer);

  useEffect(() => {
    if (!isCustomer) {
      dispatch(adapterActions.mappedEventsFetch({ ids: [eventId] }));
    }
  }, [dispatch, eventId, isCustomer]);

  return (
    <>
      <Button
        disabled={!sourcesItems.length}
        color="link"
        onClick={showSourceEventsPanel}
      >
        {sourcesItems.length > 0
          ? t('look source events', {
              count: sourcesItems.length,
            })
          : t('no source events')}
      </Button>
      {isSourceEventsPanelVisible && (
        <SourceEventsPanel
          adapterEvents={sourcesItems}
          onClose={hideSourceEventsPanel}
          eventId={eventId}
        />
      )}
    </>
  );
};
