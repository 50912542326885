import { darken, lighten } from 'polished';
import styled from 'styled-components';

import { Button } from 'app/components/ui';

type ItemProps = {
  selected: boolean;
  withoutIcon: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  transition: all 0.2s ease;
  background-color: transparent;
  border: 1px solid #808080;
  border-radius: 4px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  &:hover {
    background-color: ${darken(0.1, '#e6e6e6')};
  }
`;

export const Item = styled.div<ItemProps>`
  cursor: pointer;
  user-select: none;
  line-height: 32px;
  padding-right: 10px;
  border-radius: 3px;

  display: grid;
  grid-template-columns: 28px 1fr auto;
  align-items: center;

  ${StyledButton} {
    opacity: 0;
  }

  &:hover {
    background-color: ${lighten(5, '#e6e6e6')};
    ${StyledButton} {
      opacity: 1;
    }
  }

  ${props =>
    props.selected &&
    `
      background-color: #e6e6e6;
      &:hover {
        background-color: #e6e6e6;
      }
    `}

  ${props =>
    props.withoutIcon &&
    `
      padding-left: 10px;
      grid-template-columns: 1fr auto;
    `}

  transition: background-color 0.2s ease;
`;

interface ChevronProps {
  opened: boolean;
}

export const ChevronWrapper = styled.div<ChevronProps>`
  padding: 12px 10px;

  transform: rotate(-90deg);

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.opened &&
    `
      transform: rotate(0deg);
    `}
`;

export const StyledLabel = styled.span`
  flex-grow: 1;
`;

export type ChildrenWrapperProps = {
  nonNested: boolean;
  parent?: boolean;
};

export const ChildrenWrapper = styled.div<ChildrenWrapperProps>`
  margin-left: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${props =>
    props.nonNested &&
    `
      padding: 0;
      list-style: none;
      max-height: 500px;
      overflow-y: scroll;
      
      ${Item} {
        border-bottom: 1px solid #e6e6e6;
      }
    `}

  ${props =>
    props.parent &&
    `
      padding: 20px;
      margin: 0;
      border-bottom: 1px solid #e6e6e6;
    `}
`;
