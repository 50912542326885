import { useEffect } from 'react';

export const useEnter = (onEnter: () => void) => {
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.keyCode === 13) {
        event.stopImmediatePropagation();
        event.preventDefault();
        onEnter();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onEnter]);
};
