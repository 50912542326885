import { TFunction } from 'i18next';
import keyBy from 'lodash-es/keyBy';
import {
  AdapterOutcomeParameterMapping,
  OutcomeType,
} from 'sportsbook-openapi-react';

import { ID } from 'utils/id';

import { RawAdditionalParam } from './ParamMapper';

export interface FormValues {
  sportsbookType?: OutcomeType;
  additionalParams: RawAdditionalParam[];
  customParams: RawAdditionalParam[];
}

export const transformType = (type: OutcomeType) => ({
  ...type,
  name: `${type.name} (${type.code})`,
});

export const transformRawAdditionalParams = (
  params: RawAdditionalParam[],
): AdapterOutcomeParameterMapping[] =>
  params
    .map(
      (rawParam): AdapterOutcomeParameterMapping => ({
        name: rawParam.name?.id,
        constValue: rawParam.constValue,
        sportsbookParamType: rawParam.sportsbookParamType?.id,
        relationType: rawParam.relationType?.id,
        invertValue: rawParam.invertValue,
      }),
    )
    .filter(param => !!param.sportsbookParamType);

export const transformAdditionalParams = (
  t: TFunction,
  params?: AdapterOutcomeParameterMapping[],
): Maybe<RawAdditionalParam[]> =>
  params?.map(param => ({
    ...param,
    name: {
      id: param.name!,
      name: param.name!,
    },
    sportsbookParamType: {
      id: param.sportsbookParamType!,
      name: param.sportsbookParamType!,
    },
    relationType: {
      id: param.relationType!,
      name: t(`relationTypes.${param.relationType!}`),
    },
    id: ID(),
  }));

export const transformForwardedRawParams = (
  t: TFunction,
  params: string[],
  additionalParams?: AdapterOutcomeParameterMapping[],
): RawAdditionalParam[] => {
  const additionalParamsDictionary = keyBy(additionalParams, 'name') ?? {};

  return params.map(param => ({
    id: ID(),
    invertValue: additionalParamsDictionary[param]?.invertValue,
    name: {
      id: param,
      name: param,
    },
    sportsbookParamType: additionalParamsDictionary[param]
      ? {
          id: additionalParamsDictionary[param].sportsbookParamType!,
          name: additionalParamsDictionary[param].sportsbookParamType!,
        }
      : undefined,
    relationType: additionalParamsDictionary[param]
      ? {
          id: additionalParamsDictionary[param].relationType!,
          name: t(
            `relationTypes.${additionalParamsDictionary[param].relationType!}`,
          ),
        }
      : undefined,
  }));
};
