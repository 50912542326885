import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CommentWithEntityView, EntityType } from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import { FETCH_ALL_LIMIT } from 'consts';

import { AsyncSelect } from 'app/components/forms';

import { actionsNT } from 'app/providers/EntitiesProvider';
import { getCommentsByEntityTypeAndEntityId } from 'app/providers/EntitiesProvider/commentsByEntity';

interface Props {
  entityId: number;
  entityType: EntityType;
}

export const CommentsSelect: FC<Props> = ({ entityId, entityType }) => {
  const dispatch = useDispatch();

  const entityComments = useSelector(
    getCommentsByEntityTypeAndEntityId(entityType, entityId),
  );

  useEffect(() => {
    dispatch(
      actionsNT.commentsByEntityFetchItems({
        entityId,
        entityType,
      }),
    );
    dispatch(
      actionsNT.commentsFetchItems({ limit: FETCH_ALL_LIMIT, disabled: false }),
    );
  }, [dispatch, entityId, entityType]);

  const handleCommentsChange =
    (previousComments: CommentWithEntityView[]) =>
    (currentComments: CommentWithEntityView[]) => {
      const addedComments = currentComments.filter(
        comment =>
          !previousComments.find(
            prevComment => comment?.id === prevComment?.id,
          ),
      );
      const removedComments = previousComments.filter(
        comment =>
          !currentComments.find(curComment => comment?.id === curComment?.id),
      );

      addedComments.forEach(comment => {
        dispatch(
          actionsNT.commentsByEntityCreate({
            entityCommentWithoutId: {
              entityId,
              entityType,
              commentId: comment.id!,
            },
          }),
        );
      });

      removedComments.forEach(comment => {
        dispatch(
          actionsNT.commentsByEntityDelete({
            entityCommentWithoutId: {
              entityId,
              entityType,
              commentId: comment.id!,
            },
          }),
        );
      });
    };

  return (
    <AsyncSelect
      isMulti
      loadOptions={getAsyncLoadOptions({ entity: 'comments', disabled: false })}
      value={entityComments}
      onChange={value =>
        handleCommentsChange(entityComments)(value as CommentWithEntityView[])
      }
    />
  );
};
