import { ReactNode } from 'react';

export enum TooltipPositionEnum {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface TooltipWrapperProps {
  position: TooltipPositionEnum;
}

export interface Props extends Partial<TooltipWrapperProps> {
  content: ReactNode;
  isVisible?: boolean;
  children: React.ReactNode;
}
