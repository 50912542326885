import {
  Customer,
  UserWithOutId,
  UserWithOutIdGroupEnum,
} from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  group: Yup.object().required('Required'),
});

export const groupOptions = Object.values(UserWithOutIdGroupEnum).map(
  option => ({
    id: option as string,
    name: option.toLowerCase(),
  }),
);

export type FormValues = Omit<UserWithOutId, 'group'> & {
  group?: {
    id: string;
    name: string;
  };
  customer?: Customer;
};

export const createInitialValues: FormValues = {
  name: '',
  email: '',
  group: {
    id: 'TRADER',
    name: 'trader',
  },
  customer: undefined,
};
