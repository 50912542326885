import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import dayjs from 'dayjs';
import { get } from 'lodash-es';
import { AuditRequest, DictionaryDiff } from 'sportsbook-openapi-react';
import styled from 'styled-components';

import { usePopulatedAuditItems } from 'hooks/audit/usePopulatedAuditItems';
import { useLazyLoading } from 'hooks/useLazyLoading';
import { RootState } from 'types';

import { DateRangePicker } from 'app/components/forms';
import { SlidePanel, Table, Tabs } from 'app/components/ui';

import {
  actions,
  AuditedEntities,
  GetAuditPayload,
  getEntityHasMore,
} from 'app/providers/AuditProvider';

interface AuditProps<T extends AuditedEntities>
  extends Omit<GetAuditPayload<T>, 'entity'> {
  entity: T | T[];
}

export const Audit = <T extends AuditedEntities>({
  entity,
  payload,
}: AuditProps<T>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      if (Array.isArray(entity)) {
        entity.forEach(item => {
          dispatch(actions.resetAuditEntity({ entity: item }));
        });
      } else {
        dispatch(actions.resetAuditEntity({ entity }));
      }
    },
    [dispatch, entity],
  );

  const [filters, setFilters] = useState<AuditRequest>({
    from: dayjs().subtract(1, 'month').toDate(),
    to: dayjs().toDate(),
    limit: 20,
  });

  const isMultipleEntities = Array.isArray(entity);

  const [selectedEntity, setSelectedEntity] = useState<T>(
    isMultipleEntities ? entity[0] : entity,
  );

  const handleFilterChange = (key: keyof typeof filters) => value => {
    if (get(filters, key) !== value) {
      setFilters(prevFilters => ({
        ...prevFilters,
        [key]: value,
      }));
      dispatch(actions.resetAuditEntity({ entity: selectedEntity }));
    }
  };

  const handleTabChange = (newEntity: T) => {
    dispatch(actions.resetAuditEntity({ entity: selectedEntity }));
    setSelectedEntity(newEntity);
  };

  return (
    <SlidePanel
      title={t(`audit`)}
      onClose={() => {
        dispatch(actions.removePanel());
      }}
    >
      <StyledDatepickerWrapper>
        <DateRangePicker
          startDate={filters.from}
          endDate={filters.to}
          onStartDateChange={handleFilterChange('from')}
          onEndDateChange={handleFilterChange('to')}
        />
        {isMultipleEntities && (
          <TabsWrapper>
            <Tabs<T>
              tabs={entity.map(item => ({
                label: t(item.toString()),
                value: item,
              }))}
              onChange={handleTabChange}
            />
          </TabsWrapper>
        )}
      </StyledDatepickerWrapper>
      <AuditTable entity={selectedEntity} filters={filters} payload={payload} />
    </SlidePanel>
  );
};

interface AuditTableProps<T extends AuditedEntities>
  extends GetAuditPayload<T> {
  filters: AuditRequest;
}

const AuditTable = <T extends AuditedEntities>({
  entity,
  filters,
  payload,
}: AuditTableProps<T>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const items = usePopulatedAuditItems({ entity });
  const hasMore = useSelector((state: RootState) =>
    getEntityHasMore(state, entity),
  );

  const onLazyLoad = useCallback(() => {
    dispatch(
      actions.getAudit({
        entity,
        payload: {
          ...payload,
          auditRequest: { ...filters },
        },
      }),
    );
  }, [dispatch, entity, filters, payload]);

  const onBottom = useLazyLoading({
    onLazyLoad,
    hasMore,
    extraDeps: [filters],
  });

  const columns = useMemo<Column<DictionaryDiff>[]>(
    () => [
      {
        Header: t('user'),
        accessor: 'user',
      },
      {
        Header: t('date'),
        accessor: 'timestamp',
        Cell: ({ value }) => <>{dayjs(value).format('HH:mm:ss DD.MM.YYYY')}</>,
      },
      {
        Header: t('changes'),
        accessor: 'diffs',
        Cell: ({ value }) => {
          if (!value) return null;
          return (
            <DiffsWrapper>
              {value.map(item => (
                <span key={item.fieldName}>
                  {item.fieldName}: {item.old} =&gt; {item._new}
                </span>
              ))}
            </DiffsWrapper>
          );
        },
      },
    ],
    [t],
  );

  return (
    <Table
      columns={columns}
      data={items}
      onBottom={onBottom}
      columnsWidth={['auto', 'auto', 50]}
    />
  );
};

const StyledDatepickerWrapper = styled.div`
  z-index: 2;
`;

const DiffsWrapper = styled.div`
  display: grid;
`;

const TabsWrapper = styled.div`
  margin: 20px -20px;
`;
