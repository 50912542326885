import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { keyBy, pick, uniq, uniqBy, values } from 'lodash-es';
import { Event, Tournament } from 'sportsbook-openapi-react';

import { useUpdateEvent } from 'hooks/events';

import { PanelFooter } from 'app/components/panels/PanelFooter';
import { Panel } from 'app/components/ui';

import {
  actionsNT,
  Entities,
  getEntityByTypeAndId,
} from 'app/providers/EntitiesProvider';
import {
  getEventsEntries,
  getEventsUpdate,
} from 'app/providers/EntitiesProvider/events';
import { getTournamentsUpdate } from 'app/providers/EntitiesProvider/tournaments';

import { DependencyForm } from './DependencyForm';
import { CreateDependencyFormValues, DependencyEnum, isUpdated } from './utils';

interface Props {
  onClose: () => void;
  id: number;
  selectedEvents: number[];
  onSuccess?: (result: any) => void;
}

export const DependencyCreatePanel = ({
  id,
  onClose,
  onSuccess,
  selectedEvents,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const updateEvent = useUpdateEvent();

  const event: Event = useSelector(state =>
    getEntityByTypeAndId(state, Entities.EVENTS, id),
  );
  const events = useSelector(getEventsEntries);

  if (!event) {
    throw new Error('Not valid eventId provided');
  }
  const tournament: Tournament = event.tournament!;

  const eventUpdate = useSelector(getEventsUpdate);
  const tournamentUpdate = useSelector(getTournamentsUpdate);

  const loaded =
    submitted && (isUpdated(eventUpdate) || isUpdated(tournamentUpdate));

  const error = eventUpdate.error ?? tournamentUpdate.error;

  useEffect(() => {
    if (error) {
      setSubmitted(false);
    }
  }, [error, setSubmitted]);

  useEffect(() => {
    if (loaded) {
      if (onSuccess) {
        onSuccess({
          event: eventUpdate.result,
          tournament: tournamentUpdate.result,
        });
      }
      setSubmitted(false);
      onClose();
    }
  }, [
    loaded,
    eventUpdate.result,
    tournamentUpdate.result,
    setSubmitted,
    onClose,
    onSuccess,
  ]);

  const tournaments = uniqBy(
    values(pick(events, selectedEvents))
      .filter(event => !!event!.tournament)
      .map(event => event!.tournament!)
      .filter(item => item.id !== tournament.id),
    tournament => tournament.id,
  );

  const tournamentsEntries = keyBy(tournaments, 'id');

  const bindEvents = (eventsIds: number[]) =>
    eventsIds.forEach((selectedEventId, _, self) => {
      const event = events[selectedEventId];
      updateEvent({
        ...event,
        dependentEvents: uniq([
          ...event.dependentEvents,
          ...self.filter(eventId => selectedEventId !== eventId),
        ]),
      });
    });

  const bindTournamentsToEvent = () => {
    updateEvent({
      ...event,
      dependentTournaments: uniq([
        ...event.dependentTournaments,
        ...tournaments
          .map(t => t.id)
          .filter(tournamentId => tournamentId !== event.tournament!.id),
      ]),
    });
  };

  const bindTournaments = (tournamentsIds: number[]) =>
    tournamentsIds.forEach((selectedTournamentId, _, self) => {
      const tournament = tournamentsEntries[selectedTournamentId];
      dispatch(
        actionsNT.tournamentsUpdate({
          tournamentId: selectedTournamentId,
          tournamentWithOutId: {
            ...tournament,
            dependentTournaments: uniq([
              ...tournament.dependentTournaments,
              ...self.filter(
                tournamentId => selectedTournamentId !== tournamentId,
              ),
            ]),
          },
        }),
      );
    });

  const onSubmit = (values: CreateDependencyFormValues) => {
    switch (values.type) {
      case DependencyEnum.EVENT: {
        switch (values.dependentType) {
          case DependencyEnum.EVENT: {
            bindEvents(selectedEvents!);
            break;
          }
          case DependencyEnum.TOURNAMENT: {
            bindTournamentsToEvent();
            break;
          }
        }

        break;
      }
      case DependencyEnum.TOURNAMENT: {
        bindTournaments(tournaments.map(t => t.id));
        break;
      }
    }
    setSubmitted(true);
  };

  return (
    <Formik<CreateDependencyFormValues>
      initialValues={{
        type: DependencyEnum.EVENT,
        dependentType: DependencyEnum.EVENT,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Panel
          title={t(`panels.create.dependency`)}
          onClose={onClose}
          onSubmit={handleSubmit}
          footer={
            <PanelFooter submitButtonText={t('add')} disabled={submitted} />
          }
        >
          <DependencyForm
            events={events}
            selectedEvents={selectedEvents}
            event={event}
            tournament={tournament}
            tournaments={tournaments}
            error={error}
          />
        </Panel>
      )}
    </Formik>
  );
};
