/**
 *
 * Countries
 *
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DictionaryDropdown } from 'app/pages/Dictionary/components/DictionaryDropdown';
import { omit } from 'lodash-es';
import { Country, UserGroupEnum } from 'sportsbook-openapi-react';

import { useAudit } from 'hooks/audit/useAudit';
import useBooleanState from 'hooks/useBooleanState';
import { useSearch } from 'hooks/useSearch';
import { FETCH_ALL_LIMIT } from 'consts';

import { TextInput, TextInputCell } from 'app/components/forms';
import { CountryCreatePanel, CountryLogos } from 'app/components/panels';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Table } from 'app/components/ui';

import { AuditedEntities } from 'app/providers/AuditProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { selectCountries } from 'app/providers/EntitiesProvider/countries';

import { useUserStore } from 'store';
import { getIsOperator, getIsTranslator } from 'store/user';

import * as S from './Countries.styles';

export function Countries() {
  const items = useSelector(selectCountries);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { searchQuery, setSearchQueryDebounced } = useSearch();
  const [isCreateCountryVisible, showCreateCountry, hideCreateCountry] =
    useBooleanState();
  const [countryLogos, setCountryLogos] = useState<null | {
    countryId: number;
    countryName: string;
  }>(null);

  const handleUpdateCountry = useCallback(
    (country: Country) => {
      dispatch(
        actionsNT.countriesUpdate({
          countryId: country.id,
          countryWithOutId: omit(country, 'id'),
        }),
      );
    },
    [dispatch],
  );

  const openAudit = useAudit({
    entity: AuditedEntities.COUNTRIES,
  });

  const isOperator = useUserStore(getIsOperator);
  const isTranslator = useUserStore(getIsTranslator);

  const columns: Column<Country>[] = useMemo(
    () => [
      {
        Header: t('code').toString(),
        accessor: 'code',
        Cell: ({ value, row: { original: data } }) => {
          return !(isTranslator || isOperator) ? (
            <TextInputCell
              defaultValue={value}
              onSave={newValue => {
                handleUpdateCountry({
                  ...data,
                  code: newValue,
                });
              }}
              validate={value => value !== ''}
            />
          ) : (
            <>{value}</>
          );
        },
      },
      {
        Header: t('country code').toString(),
        accessor: 'code2',
        Cell: ({ value, row: { original: data } }) => {
          return !(isTranslator || isOperator) ? (
            <TextInputCell
              defaultValue={value}
              onSave={newValue => {
                handleUpdateCountry({
                  ...data,
                  code2: newValue,
                });
              }}
              validate={value => value !== ''}
            />
          ) : (
            <>{value}</>
          );
        },
      },
      {
        Header: t('name').toString(),
        accessor: 'name',
        Cell: ({ value, row: { original: data } }) => {
          return !(isTranslator || isOperator) ? (
            <TextInputCell
              defaultValue={value}
              onSave={newValue => {
                handleUpdateCountry({
                  ...data,
                  name: newValue,
                });
              }}
              validate={value => value.length > 2}
            />
          ) : (
            <>{value}</>
          );
        },
      },
      ...(!(isTranslator || isOperator)
        ? ([
            {
              Header: '',
              accessor: 'id',
              id: 'audit',
              Cell: ({ row: { original: data } }) => (
                <Button
                  color="action"
                  onClick={() => {
                    openAudit({ id: String(data.id), includeRelated: false });
                  }}
                >
                  {t('audit')}
                </Button>
              ),
            },
          ] as Column<Country>[])
        : []),
      {
        Header: '',
        accessor: 'id',
        id: 'logos',
        Cell: ({ value, row: { original: data } }) => {
          return (
            <Button
              color="action"
              onClick={() => {
                setCountryLogos({ countryId: value, countryName: data.name });
              }}
            >
              {t('logos')}
            </Button>
          );
        },
      },
    ],
    [
      t,
      isTranslator,
      isOperator,
      handleUpdateCountry,
      openAudit,
      setCountryLogos,
    ],
  );

  useEffect(() => {
    dispatch(
      actionsNT.countriesFetchItems({
        limit: FETCH_ALL_LIMIT,
        name: searchQuery.length ? searchQuery.toLowerCase() : undefined,
      }),
    );
  }, [dispatch, searchQuery]);

  return (
    <S.Wrapper>
      <S.DropdownWrapper>
        <DictionaryDropdown value={'countries'} />
      </S.DropdownWrapper>
      <S.ContentWrapper>
        <S.Header>
          <RoleGuards
            roles={[
              UserGroupEnum.ADMIN,
              UserGroupEnum.CUSTOMER,
              UserGroupEnum.SUPERVISOR,
            ]}
          >
            <Button onClick={showCreateCountry}>{t('add country')}</Button>
          </RoleGuards>
          <TextInput
            placeholder={t('search')}
            onChange={e => setSearchQueryDebounced(e.target.value)}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />
        </S.Header>
        <Table
          columns={columns}
          data={items}
          columnsWidth={[
            'fit-content',
            'fit-content',
            'auto',
            'fit-content',
            'fit-content',
          ]}
        />
      </S.ContentWrapper>
      {isCreateCountryVisible && (
        <CountryCreatePanel onClose={hideCreateCountry} />
      )}
      {!!countryLogos && (
        <CountryLogos
          onClose={() => {
            setCountryLogos(null);
          }}
          countryId={countryLogos.countryId}
          countryName={countryLogos.countryName}
        />
      )}
    </S.Wrapper>
  );
}
