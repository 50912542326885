import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';

import { RootState } from 'types';

const getMaxOdds = (state: RootState) => state.entities.maxOdds;
const getMaxOddsEntries = (state: RootState) => getMaxOdds(state).entries;

export const selectMaxOddsByCategory = createSelector(
  [getMaxOddsEntries],
  maxOdds => keyBy(maxOdds, 'outcomeCategoryId'),
);
