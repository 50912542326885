import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from 'app/pages/Sources/components/EntitiesTables/styles';

import { includesText } from 'utils/string';
import { FETCH_ALL_LIMIT } from 'consts';

import { LoadingIndicator } from 'app/components/ui';

import {
  getAdapterSportsLoading,
  selectAdapterSportsItems,
} from 'app/providers/AdaptersProvider';
import { actionsNT } from 'app/providers/EntitiesProvider';
import { getSportsEntries } from 'app/providers/EntitiesProvider/sports';

import { SportRow } from './SportRow';

interface Props {
  mapped: boolean;
  adapter: string;
  searchQuery: string;
}

export const SportsTable = ({ mapped, adapter, searchQuery }: Props) => {
  const dispatch = useDispatch();
  const [editingRow, setEditingRow] = useState<string | undefined>(undefined);

  const loading = useSelector(getAdapterSportsLoading);
  const items = useSelector(selectAdapterSportsItems);
  const entries = useSelector(getSportsEntries);

  const data = useMemo(
    () =>
      items.filter(sport => {
        const isMatchMapped = mapped
          ? !!sport.sportsbookId
          : !sport.sportsbookId;
        if (!isMatchMapped) return isMatchMapped;
        return includesText(sport.name, searchQuery);
      }),
    [items, mapped, searchQuery],
  );

  useEffect(() => {
    dispatch(actionsNT.sportsFetchItems({ limit: FETCH_ALL_LIMIT }));
  }, [dispatch]);

  if (loading && !data.length) return <LoadingIndicator type="full" />;

  return (
    <S.TableWrapper>
      <S.StyledTable key="mapped">
        {mapped && (
          <S.THead>
            <S.Tr>
              <S.Th weight={40}>От источника</S.Th>
              <S.Th weight={35}>В справочнике</S.Th>
              <S.Th weight={25} />
            </S.Tr>
          </S.THead>
        )}
        <S.TBody>
          {data.map(row => (
            <SportRow
              key={row.id}
              sport={row}
              sports={entries}
              mapped={mapped}
              adapter={adapter}
              isEditing={editingRow === row.id}
              setEditingRow={setEditingRow}
            />
          ))}
        </S.TBody>
      </S.StyledTable>
    </S.TableWrapper>
  );
};
