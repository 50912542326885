import { PayloadAction } from '@reduxjs/toolkit';
import { SearchResult } from 'sportsbook-openapi-react';

import { searchInitialState } from 'app/providers/EntitiesProvider/constants';

const reducer = {
  searchFetchItems(state) {
    state.search.fetchItems.loading = true;
  },
  searchFetchItemsSuccess(state, action: PayloadAction<SearchResult>) {
    state.search.fetchItems.loading = false;

    state.search.sports.hasMore = action.payload.sports.hasMore;
    state.search.sportGroups.hasMore = action.payload.sportGroups.hasMore;
    state.search.tournaments.hasMore = action.payload.tournaments.hasMore;

    state.search.sports.items.push(...action.payload.sports.items);
    state.search.sportGroups.items.push(...action.payload.sportGroups.items);
    state.search.tournaments.items.push(...action.payload.tournaments.items);
  },
  searchFetchItemsFailure(state) {
    state.search.fetchItems.loading = false;
  },
  searchReset(state) {
    state.search = searchInitialState;
  },
};

export default reducer;
