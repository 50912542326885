import styled from 'styled-components';

import { Button, Span } from 'app/components/ui';

export const EventForm = styled.form`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 330px);
  grid-template-rows: repeat(6, auto) 97px;
  grid-template-areas:
    'sport group .'
    'datetime tournament .'
    'competitorA competitorB .'
    'status matchType .'
    'kind . .'
    'note note .';
  grid-column-gap: 20px;
  grid-row-gap: 10px;
`;

type EventFormBlockProps = {
  area?: string;
};

export const EventFormBlock = styled.div<EventFormBlockProps>`
  display: grid;

  ${props =>
    props.area
      ? `
     grid-area: ${props.area};
  `
      : ``}
`;

export const CreateEventDateTime = styled.div`
  grid-area: datetime;
  display: grid;
  grid-gap: 14px;
  grid-template-columns: 3fr 2fr;
`;

export const CreateEventSpan = styled(Span)`
  color: #333333;
`;

export const EventActions = styled.div`
  position: fixed;
  bottom: 0;
  left: 56px;
  width: calc(100vw - 56px);
  height: 56px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #e5e5e5;

  button {
    margin-left: 10px;
  }
`;

export const StyledButton = styled(Button)`
  min-width: 100px;
`;
