import { tagsApi } from 'app/api';

const api = {
  endpoint: tagsApi,
  methods: {
    fetchItems: 'getTags',
    fetchItem: 'getTag',
    create: 'createTag',
    update: 'updateTag',
    delete: 'deleteTag',
  },
};

export default api;
