import { FC, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { paramsToColumns } from 'app/pages/Events/utils/params';
import {
  resultToCode,
  resultToColor,
} from 'app/pages/Results/components/EventResultsTable/constants';
import * as S from 'app/pages/Results/components/EventResultsTable/EventResults.styles';
import { isEqual, round } from 'lodash-es';
import {
  Event,
  OutcomeMarketLink,
  OutcomeType,
  SportEventOdd,
  SportEventResult,
  SportEventResultType,
} from 'sportsbook-openapi-react';

import { SelectCell } from 'app/components/forms';
import { Table } from 'app/components/ui';
import { TextInputTableFilter } from 'app/components/ui/Table/components';

import { actionsNT } from 'app/providers/EntitiesProvider';

interface Props {
  event: Event;
  results: SportEventOdd[];
  mappedResults: Dictionary<SportEventResult[]>;
  types: Dictionary<OutcomeType>;
  isCustomer: boolean;
  sorting: {};
  filters: {};
  setSorting: (newSorting: {}) => void;
  setFilters: (newFilters: {}) => void;
}

const resultTypes = Object.values(SportEventResultType);
const ResultLabel: FC<{ result: SportEventResultType }> = ({ result }) => (
  <S.StyledResultlWrapper>
    <S.StyledLabel style={{ background: resultToColor[result] }}>
      {resultToCode[result]}
    </S.StyledLabel>
    {result.replace('RESULT_', '').toUpperCase()}
  </S.StyledResultlWrapper>
);

const resultOptions = resultTypes.map(resultType => ({
  id: resultType,
  name: <ResultLabel result={resultType} />,
}));

export const ResultsTable: FC<Props> = ({
  event,
  results,
  mappedResults,
  types,
  isCustomer,
  sorting,
  filters,
  setSorting,
  setFilters,
}) => {
  const selectedArray = useRef<{ original: SportEventOdd }[]>([]);

  useEffect(() => {
    if (!results.length) {
      setFilters({});
      setSorting({});
    }
  }, [results.length, setFilters, setSorting]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const paramsColumns = useMemo(
    () => paramsToColumns(t, event, results),
    [event, results, t],
  );

  const handleSelected = array => {
    selectedArray.current = array;
  };

  const oddsColumns: Column<SportEventOdd>[] = useMemo(
    () => [
      {
        Header: TextInputTableFilter,
        id: 'code',
        accessor: 'outcomeTypeId',
        Cell: ({ value }) => <>{types[value]?.code || value}</>,
      },
      {
        Header: t('outcome').toString(),
        id: 'outcome',
        accessor: 'outcomeTypeId',
        Cell: ({ value }) => <>{types[value]?.name || value}</>,
      },
      {
        Header: t('market type').toString(),
        id: 'marketLink',
        accessor: 'outcomeTypeId',
        Cell: ({ value }) => {
          if (!types[value]?.marketLink) return null;
          if (types[value].marketLink === OutcomeMarketLink.ANY) return null;
          return t(`marketLinkNames.${types[value]?.marketLink}`);
        },
      },
      ...paramsColumns,
      {
        Header: t('coefficient').toString(),
        accessor: 'value',
        Cell: ({ value }) => <>{round(value, 2)}</>,
      },
      {
        Header: t('result').toString(),
        id: 'result',
        Cell: ({ row: { original: data } }) => {
          const value =
            mappedResults[data.outcomeTypeId]?.find(sportEventResult =>
              isEqual(
                new Set(sportEventResult.params),
                new Set(data.parameters),
              ),
            )?.result ?? null;
          if (isCustomer) {
            return value ? <ResultLabel result={value} /> : null;
          } else
            return (
              <SelectCell
                value={value}
                options={resultOptions}
                onChange={option => {
                  if (selectedArray.current.length) {
                    dispatch(
                      actionsNT.eventsResultsUpdate({
                        eventId: event.id,
                        sportEventResult: selectedArray.current.map(
                          outcomeType => ({
                            outcomeTypeId: outcomeType.original.outcomeTypeId,
                            params: outcomeType.original.parameters,
                            result: option as SportEventResultType,
                          }),
                        ),
                      }),
                    );
                  } else {
                    dispatch(
                      actionsNT.eventsResultsUpdate({
                        eventId: event.id,
                        sportEventResult: [
                          {
                            outcomeTypeId: data.outcomeTypeId,
                            params: data.parameters,
                            result: option as SportEventResultType,
                          },
                        ],
                      }),
                    );
                  }
                }}
              />
            );
        },
      },
    ],
    [dispatch, event.id, isCustomer, mappedResults, paramsColumns, t, types],
  );

  const columnsWidth = !isCustomer
    ? [
        'fit-content',
        14,
        'auto',
        'auto',
        'auto',
        ...Array(paramsColumns.length).fill(14),
        'fit-content',
      ]
    : [
        14,
        'auto',
        'auto',
        'auto',
        ...Array(paramsColumns.length).fill(14),
        'fit-content',
      ];

  return (
    <S.StyledOddsTableWrapper>
      {isCustomer ? (
        <Table
          columns={oddsColumns}
          data={results}
          columnsWidth={columnsWidth}
          sorting={{
            value: sorting,
            onSortingChange: setSorting,
          }}
          filters={{
            value: filters,
            onFilterChange: setFilters,
          }}
        />
      ) : (
        <Table
          columns={oddsColumns}
          data={results}
          columnsWidth={columnsWidth}
          withCheckboxes
          onSelectCheckbox={handleSelected}
          sorting={{
            value: sorting,
            onSortingChange: setSorting,
          }}
          filters={{
            value: filters,
            onFilterChange: setFilters,
          }}
        />
      )}
    </S.StyledOddsTableWrapper>
  );
};
