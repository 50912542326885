import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Logo from 'assets/images/logo.svg?react';

export const Wrapper = styled.div`
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;

  display: grid;
  grid-template-columns: auto auto 1fr auto auto auto;
  grid-template-areas: 'logo path . user exit';
  grid-column-gap: 20px;
  padding: 0 16px;
  align-items: center;
`;

export const StyledLogo = styled(Logo)`
  grid-area: logo;
`;

export const PathWrapper = styled.div`
  grid-area: path;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  margin-right: 10px;
  font-size: 14px;
  color: ${p => p.theme.colors.primary[80]};
  text-decoration: none;
  &:visited {
    color: ${p => p.theme.colors.primary[80]};
    text-decoration: none;
  }
`;

export const MenuWrapper = styled.div`
  grid-area: menu;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spacer = styled.div``;

export const UserBlock = styled.div`
  display: grid;
  grid-column-gap: 8px;
  align-items: center;
  grid-auto-flow: column;
`;

export const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${p => p.theme.colors.primary[900]};
  color: white;

  text-transform: capitalize;
  line-height: 2em;
`;

export const UserLink = styled.a`
  color: ${p => p.theme.colors.primary[900]};
  font-size: ${p => p.theme.fonts.typography['H200'].size};
  font-weight: ${p => p.theme.fonts.typography['H200'].weight};
`;
