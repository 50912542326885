import styled from 'styled-components';

export const IconWrapper = styled.div``;

export const Wrapper = styled.div`
  position: relative;

  box-sizing: border-box;

  height: 24px;
  margin: -5px -2px;
  padding: 2px;
  padding-right: 6px;
  width: 100%;
  color: ${p => p.theme.colors.primary[500]};
  font-weight: ${p => p.theme.fonts.typography['H100'].weight};
  font-size: ${p => p.theme.fonts.typography['H100'].size};

  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;

  background-color: transparent;

  outline: none;

  transition:
    border 0.2s ease,
    background-color 0.2s ease,
    box-shadow 0.2s ease;

  ${IconWrapper} {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    border-color: ${p => p.theme.colors.primary[60]};
    ${IconWrapper} {
      opacity: 1;
    }
  }

  &:focus {
    border-color: ${p => p.theme.colors.primary[700]};
    box-shadow: 0 0 1px 1px ${p => p.theme.colors.primary[700]};
    ${IconWrapper} {
      opacity: 1;
    }
  }
`;

export const LabelWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
`;

interface OptionsWrapperProps {
  extraStyles?: string;
}

export const OptionsWrapper = styled.div<OptionsWrapperProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;

  overflow-x: auto;

  max-height: 180px;
  width: fit-content;
  padding: 8px 0;

  background-color: ${p => p.theme.colors.primary[0]};
  box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.2);
  border-radius: 3px;

  ${p => p.extraStyles}
`;

export const OptionWrapper = styled.div`
  cursor: default;
  user-select: none;

  box-sizing: border-box;
  display: flex;
  align-items: center;

  flex-shrink: 0;
  height: 32px;
  min-width: 180px;
  padding: 0 6px;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${p => p.theme.colors.primary[20]};
  }
`;
