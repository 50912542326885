import { keyBy } from 'lodash-es';
import { AdapterCompetitor, AdapterEvent } from 'sportsbook-openapi-react';

export function getHomeAwayCompetitors(event: AdapterEvent): {
  homeCompetitor: AdapterCompetitor | undefined;
  awayCompetitor: AdapterCompetitor | undefined;
} {
  const adapterCompetitors = keyBy(event.competitors, 'id');
  const homeCompetitorId = event?.homeCompetitors?.[0];
  const awayCompetitorId = event?.competitors?.filter(
    c => c.id !== homeCompetitorId,
  )?.[0]?.id;

  return {
    homeCompetitor: homeCompetitorId
      ? adapterCompetitors[homeCompetitorId]
      : undefined,
    awayCompetitor: awayCompetitorId
      ? adapterCompetitors[awayCompetitorId]
      : undefined,
  };
}
