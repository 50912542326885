import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';

import { RootState } from 'types';

const getDelays = (state: RootState) => state.entities.delays;
const getDelaysEntries = (state: RootState) => getDelays(state).entries;

export const selectDelaysByCategory = createSelector(
  [getDelaysEntries],
  delays => keyBy(delays, 'outcomeCategoryId'),
);
