import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniq } from 'lodash-es';
import {
  Player,
  SportEventProbabilityOriginal,
} from 'sportsbook-openapi-react';

import { getAsyncLoadOptions } from 'utils/select';
import useBooleanState from 'hooks/useBooleanState';
import { RootState } from 'types';

import { AsyncSelect } from 'app/components/forms';
import { PlayerCreatePanel } from 'app/components/panels';
import { Button } from 'app/components/ui';

import {
  adapterActions,
  getAdapterPlayersEntries,
} from 'app/providers/AdaptersProvider';
import { getPlayerById } from 'app/providers/EntitiesProvider/players';

import * as S from './styles';

export const adapterToPlayerParam = {
  betradar: 'player',
  betconstruct: 'playerId',
};

export const rawParamsToColumns = (
  odds: SportEventProbabilityOriginal[],
  adapter: string,
): Column<SportEventProbabilityOriginal>[] => {
  const uniqParams: string[] = uniq(
    odds
      .filter(odd => !!odd.rawParams)
      .map(odd => Object.keys(odd.rawParams!))
      .flat(),
  );

  return uniqParams.map(name => {
    const isPlayer = name === adapterToPlayerParam[adapter];
    return {
      Header: () => {
        const { t } = useTranslation();
        if (isPlayer) {
          return <S.ThinHeader>{t('player')}</S.ThinHeader>;
        }
        return <S.ThinHeader>{name}</S.ThinHeader>;
      },
      id: name,
      accessor: 'rawParams',
      Cell: ({ value }) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const adapterPlayersEntries = useSelector(getAdapterPlayersEntries);

        const mappedPlayer = useSelector((state: RootState) => {
          if (!value) return undefined;
          const adapterPlayer =
            adapterPlayersEntries[value[adapterToPlayerParam[adapter]]];
          if (!adapterPlayer?.sportsbookId) return undefined;
          return getPlayerById(state, adapterPlayer.sportsbookId);
        });
        const [player, setPlayer] = useState<Maybe<Player>>(mappedPlayer);

        const [isCreatePlayerVisible, showCreatePlayer, hideCreatePlayer] =
          useBooleanState();

        const createPlayerOption = {
          label: t('create'),
          isButton: true,
          notSelectable: true,
          onClick: showCreatePlayer,
        };

        useEffect(() => {
          if (mappedPlayer) {
            setPlayer(mappedPlayer);
          }
        }, [mappedPlayer]);

        if (
          value &&
          isPlayer &&
          (adapter === 'betconstruct' || adapter === 'betradar')
        ) {
          return (
            <S.PlayersMappingCell>
              <S.ThinCell>
                {adapterPlayersEntries[value[name]]?.name}
              </S.ThinCell>
              <S.StyledSelect>
                <AsyncSelect
                  value={player}
                  onChange={selectedPlayer => {
                    setPlayer(selectedPlayer as Player);
                  }}
                  loadOptions={getAsyncLoadOptions({
                    entity: 'players',
                    disabled: false,
                    createOption: createPlayerOption,
                  })}
                />
              </S.StyledSelect>

              <Button
                color="tretiary"
                onClick={() => {
                  dispatch(
                    adapterActions.playersMap({
                      id: value[name],
                      adapter,
                      mappingRequest: {
                        sportsbookId: (player as Player).id,
                      },
                    }),
                  );
                }}
                disabled={
                  adapterPlayersEntries[value[adapterToPlayerParam[adapter]]]
                    ?.sportsbookId === player?.id
                }
              >
                <FontAwesomeIcon icon={faLink} />
              </Button>
              {isCreatePlayerVisible && (
                <PlayerCreatePanel onClose={hideCreatePlayer} />
              )}
            </S.PlayersMappingCell>
          );
        }
        if (value && isPlayer) {
          return (
            <S.ThinCell>
              {adapterPlayersEntries[value[name]]?.name}
              {isCreatePlayerVisible && (
                <PlayerCreatePanel onClose={hideCreatePlayer} />
              )}
            </S.ThinCell>
          );
        }
        if (value) {
          return (
            <S.ThinCell>
              {value[name]}
              {isCreatePlayerVisible && (
                <PlayerCreatePanel onClose={hideCreatePlayer} />
              )}
            </S.ThinCell>
          );
        }
        return null;
      },
    };
  });
};
