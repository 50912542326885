import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getHomeAwayCompetitors } from 'app/pages/Events/utils';

import useBooleanState from 'hooks/useBooleanState';

import { SelectEventModal } from 'app/components/modals';

import { EventSelectWrapper } from './EventSelect.styles';
import { EventSelectProps } from './EventSelect.types';

export const EventSelect: FC<EventSelectProps> = ({
  value,
  onChange,
  excludedEvents,
  initialFilters = {},
  placeholder: propsPlaceholder,
}) => {
  const [isModalVisible, showModal, hideModal] = useBooleanState();
  const { t } = useTranslation();

  const placeholder = propsPlaceholder ? propsPlaceholder : t('select');
  const textContent = useMemo(() => {
    if (!value) {
      return '';
    }
    const { homeCompetitor, awayCompetitor } = getHomeAwayCompetitors(value);
    return [homeCompetitor?.name, awayCompetitor?.name].join(' – ');
  }, [value]);

  return (
    <EventSelectWrapper onClick={showModal} placeholder={placeholder}>
      {textContent}
      {isModalVisible && (
        <SelectEventModal
          event={value}
          closeModal={hideModal}
          initialFilters={initialFilters}
          excludedEvents={excludedEvents}
          onChange={onChange}
        />
      )}
    </EventSelectWrapper>
  );
};
