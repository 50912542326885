import {
  CompetitorType,
  Country,
  Player,
  Sport,
} from 'sportsbook-openapi-react';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  sport: Yup.object().required('Required'),
  country: Yup.object().required('Required'),
  type: Yup.object().required('Required'),
});

export interface FormValues {
  name: string;
  sport?: Sport;
  country?: Country;
  player?: Player;
  type?: {
    id: CompetitorType;
    name: CompetitorType;
  };
}
