import styled from 'styled-components';

export const EventInfoContainer = styled.div`
  padding: 20px 24px 0;
`;

export const InfoBlock = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
`;

export const EventFields = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  align-items: center;
  align-content: flex-start;
`;
