import { createSelector } from 'reselect';

import { RootState } from 'types';

import { getEntities } from 'app/providers/EntitiesProvider/selectors';
import { getEntityItems } from 'app/providers/EntitiesProvider/utils';

const getCustomerTags = (state: RootState) => getEntities(state).tagsByCustomer;

export const getCustomerTagsEntries = (state: RootState) =>
  getCustomerTags(state).entries;

export const selectCustomerTagsItems = createSelector(
  [getCustomerTags],
  tagsByCustomer => getEntityItems(tagsByCustomer),
);
