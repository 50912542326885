import { PayloadAction } from '@reduxjs/toolkit';

import {
  AdapterState,
  ContainerState,
} from 'app/providers/AdaptersProvider/types';
import { toIdsArray, toIdsMap } from 'app/providers/EntitiesProvider';

const defaultFetch =
  (entity: keyof AdapterState) => (state: ContainerState) => {
    state.entities[entity].fetch.loading = true;
    state.entities[entity].fetch.error = null;
  };

const defaultFetchSuccess =
  <
    T extends { items: Entity[]; hasMore?: boolean },
    Entity extends { id?: string } = {},
  >(
    entity: keyof AdapterState,
    withRewrite: boolean = false,
  ) =>
  (state: ContainerState, action: PayloadAction<T>) => {
    const { items, hasMore } = action.payload;
    if (withRewrite) {
      // TODO: FIX ME
      // @ts-ignore
      state.entities[entity].entries = toIdsMap(items);
      state.entities[entity].sortedIds = toIdsArray(items);
    } else {
      // TODO: FIX ME
      // @ts-ignore
      state.entities[entity].entries = {
        ...state.entities[entity].entries,
        ...toIdsMap(items),
      };
      state.entities[entity].sortedIds.push(
        ...toIdsArray(items, state.entities[entity].sortedIds),
      );
    }
    state.entities[entity].hasMore = !!hasMore;
    state.entities[entity].fetch.loading = false;
  };

const defaultFetchFailure =
  <T extends Error = Error>(entity: keyof AdapterState) =>
  (state: ContainerState, action: PayloadAction<T>) => {
    state.entities[entity].fetch.loading = false;
    state.entities[entity].fetch.error = action.payload.message;
  };

export const fetchReducersGenerator = (
  entityName: keyof AdapterState,
  withRewrite: boolean = false,
) => ({
  [`${entityName}Fetch`]: defaultFetch(entityName),
  [`${entityName}FetchSuccess`]: defaultFetchSuccess(entityName, withRewrite),
  [`${entityName}FetchFailure`]: defaultFetchFailure(entityName),
});
