import { FC } from 'react';

import * as S from './LoadingIndicator.styles';
import { LoadingIndicatorProps } from './LoadingIndicator.types';

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  type = 'plain',
  ...props
}) =>
  type === 'absolute' ? (
    <S.LoadingIndicatorAbsoluteContainer>
      <LoadingIndicatorSVG {...props} />{' '}
    </S.LoadingIndicatorAbsoluteContainer>
  ) : type === 'full' ? (
    <S.LoadingIndicatorFullContainer>
      <LoadingIndicatorSVG {...props} />
    </S.LoadingIndicatorFullContainer>
  ) : (
    <LoadingIndicatorSVG {...props} />
  );

const LoadingIndicatorSVG: FC<LoadingIndicatorProps> = ({ small }) => (
  <S.Svg viewBox="-24 -24 48 48" small={small}>
    <S.Circle cx="0" cy="0" r="20" fill="none" strokeWidth="4"></S.Circle>
  </S.Svg>
);
