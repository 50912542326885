import { customersApi } from 'app/api';

const api = {
  endpoint: customersApi,
  methods: {
    fetchItems: 'getBetPayMaxList',
    fetchItem: 'getBetPayMax',
    create: 'createBetPayMax',
    createList: 'createBetPayMaxList',
    update: 'updateBetPayMax',
    delete: 'deleteBetPayMax',
  },
};

export default api;

export const betPayMaxByEventApi = {
  endpoint: customersApi,
  methods: {
    fetchItems: 'getBetPayMaxEventList',
  },
};
