import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
`;

export const StyledIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  height: 24px;
  margin: -5px -5px;
  padding: 4px;
  width: 100%;
  color: ${p => p.theme.colors.primary[500]};
  font-weight: ${p => p.theme.fonts.typography['H100'].weight};
  font-size: ${p => p.theme.fonts.typography['H100'].size};

  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;

  background-color: transparent;

  outline: none;

  transition:
    border 0.2s ease,
    background-color 0.2s ease,
    box-shadow 0.2s ease;

  &:hover {
    border-color: ${p => p.theme.colors.primary[60]};
  }

  &::placeholder {
    color: ${p => p.theme.colors.primary[200]};
  }

  &:focus {
    border-color: ${p => p.theme.colors.primary[700]};
    box-shadow: 0 0 1px 1px ${p => p.theme.colors.primary[700]};
  }
`;
