import {
  Language,
  LanguageList,
  LanguagesApiDeleteLanguageRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    LanguageList,
    Language,
    LanguagesApiDeleteLanguageRequest,
    EntitiesErrorType
  >({
    entity: 'languages',
  }),
};

export default reducer;
