import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';
import { useField } from 'formik';
import {
  OutcomeMarket,
  OutcomeTypeCrossMappingParameter,
  SportEventParameterType,
} from 'sportsbook-openapi-react';
import styled from 'styled-components';

import { usePrevious } from 'hooks/usePrevious';

interface Props {
  name: string;
  market: OutcomeMarket;
  crossMarket: OutcomeMarket;
}

export const CrossParamsMapper: React.FC<Props> = ({
  name,
  market,
  crossMarket,
}) => {
  const prevMarket = usePrevious(market);
  const prevCrossMarket = usePrevious(crossMarket);

  const [field, , helpers] = useField<OutcomeTypeCrossMappingParameter[]>(name);

  const params = useMemo(
    () => field.value.map(item => item.paramType),
    [field.value],
  );
  const crossParams = useMemo(
    () => field.value.map(item => item.crossParamType),
    [field.value],
  );

  useEffect(() => {
    if (market.params.length === 0 && crossMarket.params.length === 0) {
      return;
    }
    if (
      (market && market.id !== prevMarket?.id) ||
      (crossMarket && crossMarket.id !== prevCrossMarket?.id)
    ) {
      helpers.setValue(
        market.params.map((_, index) => ({
          paramType: market.params[index],
          crossParamType: crossMarket.params[index],
        })),
      );
    }
  }, [crossMarket, helpers, market, prevCrossMarket, prevMarket]);

  const handleChange = useCallback(
    (key: keyof OutcomeTypeCrossMappingParameter) =>
      (value: SportEventParameterType[]) => {
        helpers.setValue(
          field.value.map((item, index) => ({
            ...item,
            [key]: value[index],
          })),
        );
      },
    [field.value, helpers],
  );

  return (
    <CrossParamsWrapper>
      <SortableComponent items={params} onChange={handleChange('paramType')} />
      <SortableComponent
        items={crossParams}
        onChange={handleChange('crossParamType')}
      />
    </CrossParamsWrapper>
  );
};

const CrossParamsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: fit-content;
`;

const SortableItem = SortableElement<{ value: SportEventParameterType }>(
  ({ value }) => {
    const { t } = useTranslation();
    return <StyledSortableItem>{t(`parameters.${value}`)}</StyledSortableItem>;
  },
);

const StyledSortableItem = styled.li`
  padding: 4px 8px;
  border-radius: 2px;
  z-index: 13;

  background-color: ${p => p.theme.colors.primary[0]};
  list-style-type: none;
  &:hover {
    background-color: ${p => p.theme.colors.primary[40]};
  }
`;

const SortableList = SortableContainer<{ items: SportEventParameterType[] }>(
  ({ items }) => {
    return (
      <StyledSortableList>
        {items.map((value, index) => (
          <SortableItem key={`item-${value}`} index={index} value={value} />
        ))}
      </StyledSortableList>
    );
  },
);

const StyledSortableList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

interface SortableComponentProps {
  items: SportEventParameterType[];
  onChange: (items: SportEventParameterType[]) => void;
}

const SortableComponent: React.FC<SortableComponentProps> = ({
  items: initialItems,
  onChange,
}) => {
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(prevState => {
      const newItems = arrayMove(prevState, oldIndex, newIndex);
      onChange(newItems);
      return newItems;
    });
  };

  return (
    <SortableList
      helperClass="sortableHelper"
      items={items}
      onSortEnd={onSortEnd}
    />
  );
};
