import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { keyBy, values } from 'lodash-es';
import {
  Comment,
  Competitor,
  Country,
  Currency,
  Group,
  MatchPhase,
  MatchType,
  OutcomeCategory,
  OutcomeType,
  Player,
  ShortName,
  Sport,
  Tournament,
  Translation,
} from 'sportsbook-openapi-react';

import { actionsNT } from 'app/providers/EntitiesProvider';

import { TranslatedEntities } from './types';

export const useUpdateTranslations = () => {
  const dispatch = useDispatch();

  const updateSportTranslation = useCallback(
    (sport, translation: Translation) => {
      const translations = keyBy(sport.translations, 'langCode');
      translations[translation.langCode] = translation;
      dispatch(
        actionsNT.sportsUpdate({
          sportId: sport.id,
          sportWithOutId: {
            ...sport,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteSportTranslation = useCallback(
    (sport: Sport, langCode: string) => {
      const translations = sport.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.sportsUpdate({
          sportId: sport.id,
          sportWithOutId: {
            ...sport,
            translations: translations,
          },
        }),
      );
    },
    [dispatch],
  );

  const updateGroupTranslation = useCallback(
    (group, translation: Translation) => {
      const translations = keyBy(group.translations, 'langCode');
      translations[translation.langCode] = translation;
      dispatch(
        actionsNT.groupsUpdate({
          groupId: group.id,
          groupWithOutId: {
            ...group,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteGroupTranslation = useCallback(
    (group: Group, langCode: string) => {
      const translations = group.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.groupsUpdate({
          groupId: group.id,
          groupWithOutId: {
            ...group,
            translations: translations,
          },
        }),
      );
    },
    [dispatch],
  );

  const updateTournamentTranslation = useCallback(
    (tournament, translation: Translation) => {
      const translations = keyBy(tournament.translations, 'langCode');
      translations[translation.langCode] = translation;
      dispatch(
        actionsNT.tournamentsUpdate({
          tournamentId: tournament.id,
          tournamentWithOutId: {
            ...tournament,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteTournamentTranslation = useCallback(
    (tournament: Tournament, langCode: string) => {
      const translations = tournament.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.tournamentsUpdate({
          tournamentId: tournament.id,
          tournamentWithOutId: {
            ...tournament,
            translations: translations,
          },
        }),
      );
    },
    [dispatch],
  );

  const updateCompetitorTranslation = useCallback(
    (competitor, translation: Translation) => {
      const translations = keyBy(competitor.translations, 'langCode');
      translations[translation.langCode] = translation;
      dispatch(
        actionsNT.competitorsUpdate({
          competitorId: competitor.id,
          competitorWithOutId: {
            ...competitor,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteCompetitorTranslation = useCallback(
    (competitor: Competitor, langCode: string) => {
      const translations = competitor.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.competitorsUpdate({
          competitorId: competitor.id,
          competitorWithOutId: {
            ...competitor,
            translations: translations,
            type: competitor?.type,
          },
        }),
      );
    },
    [dispatch],
  );

  const updateCountryTranslation = useCallback(
    (country, translation: Translation) => {
      const translations = keyBy(country.translations, 'langCode');
      translations[translation.langCode] = translation;
      dispatch(
        actionsNT.countriesUpdate({
          countryId: country.id,
          countryWithOutId: {
            ...country,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteCountryTranslation = useCallback(
    (country: Country, langCode: string) => {
      const translations = country.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.countriesUpdate({
          countryId: country.id,
          countryWithOutId: {
            ...country,
            translations: translations,
          },
        }),
      );
    },
    [dispatch],
  );

  const updatePlayerTranslation = useCallback(
    (player, translation: Translation) => {
      const translations = keyBy(player.translations, 'langCode');
      translations[translation.langCode] = translation;
      dispatch(
        actionsNT.playersUpdate({
          playerId: player.id!,
          playerWithOutId: {
            ...player,
            name: player.name!,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deletePlayerTranslation = useCallback(
    (player: Player, langCode: string) => {
      const translations = player.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.playersUpdate({
          playerId: player.id,
          playerWithOutId: {
            ...player,
            translations: translations,
          },
        }),
      );
    },
    [dispatch],
  );

  const updateCategoryTranslation = useCallback(
    (category, translation: Translation) => {
      const translations = keyBy(category.translations, 'langCode');
      translations[translation.langCode] = translation;
      dispatch(
        actionsNT.categoriesUpdate({
          categoryId: category.id,
          outcomeCategoryWithOutId: {
            ...category,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteCategoryTranslation = useCallback(
    (category: OutcomeCategory, langCode: string) => {
      const translations = category.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.categoriesUpdate({
          categoryId: category.id,
          outcomeCategoryWithOutId: {
            ...category,
            translations: translations,
          },
        }),
      );
    },
    [dispatch],
  );

  const updateTypeTranslation = useCallback(
    (type, translation: Translation) => {
      const translations = keyBy(type.translations, 'langCode');
      translations[translation.langCode] = translation;
      dispatch(
        actionsNT.typesUpdate({
          typeId: type.id,
          outcomeTypeWithOutId: {
            ...type,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteTypeTranslation = useCallback(
    (type: OutcomeType, langCode: string) => {
      const translations = type.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.typesUpdate({
          typeId: type.id,
          outcomeTypeWithOutId: {
            ...type,
            translations: translations,
          },
        }),
      );
    },
    [dispatch],
  );

  const updateTypeShortName = useCallback(
    (type: OutcomeType, shortName: ShortName) => {
      const shortNames = keyBy(type.shortNames, 'langCode');
      shortNames[shortName.langCode] = shortName;
      dispatch(
        actionsNT.typesUpdate({
          typeId: type.id,
          outcomeTypeWithOutId: {
            ...type,
            shortNames: values(shortNames),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteTypeShortName = useCallback(
    (type: OutcomeType, langCode: string) => {
      const shortNames = type.shortNames!.filter(
        shortName => shortName.langCode !== langCode,
      );
      dispatch(
        actionsNT.typesUpdate({
          typeId: type.id,
          outcomeTypeWithOutId: {
            ...type,
            shortNames: shortNames,
          },
        }),
      );
    },
    [dispatch],
  );

  const updateMatchTypeTranslation = useCallback(
    (matchType, translation: Translation) => {
      const translations = keyBy(matchType.translations, 'langCode');
      translations[translation.langCode] = translation;
      dispatch(
        actionsNT.matchTypesUpdate({
          matchTypeId: matchType.id,
          matchTypeWithOutId: {
            ...matchType,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteMatchTypeTranslation = useCallback(
    (matchType: MatchType, langCode: string) => {
      const translations = matchType.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.matchTypesUpdate({
          matchTypeId: matchType.id,
          matchTypeWithOutId: {
            ...matchType,
            translations: translations,
          },
        }),
      );
    },
    [dispatch],
  );

  const updateMatchPhaseTranslation = useCallback(
    (matchPhase, translation: Translation) => {
      const translations = keyBy(matchPhase.translations, 'langCode');
      translations[translation.langCode] = translation;
      dispatch(
        actionsNT.matchPhasesUpdate({
          matchphaseId: matchPhase.id,
          matchPhaseWithoutId: {
            ...matchPhase,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteMatchPhaseTranslation = useCallback(
    (matchPhase: MatchPhase, langCode: string) => {
      const translations = matchPhase.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.matchPhasesUpdate({
          matchphaseId: matchPhase.id,
          matchPhaseWithoutId: {
            ...matchPhase,
            translations: translations,
          },
        }),
      );
    },
    [dispatch],
  );
  const updateCommentTranslation = useCallback(
    (comment, translation: Translation) => {
      const translations = keyBy(comment.translations, 'langCode');
      translations[translation.langCode] = translation;
      dispatch(
        actionsNT.commentsUpdate({
          commentId: comment.id,
          commentWithoutId: {
            ...comment,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteCommentTranslation = useCallback(
    (comment: Comment, langCode: string) => {
      const translations = comment.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.commentsUpdate({
          commentId: comment.id,
          commentWithoutId: {
            ...comment,
            translations: translations,
          },
        }),
      );
    },
    [dispatch],
  );
  const updateCurrencyTranslation = useCallback(
    (currency: Currency, translation: Translation) => {
      const translations = keyBy(currency.translations, 'langCode');
      translations[translation.langCode] = translation;

      dispatch(
        actionsNT.currenciesUpdate({
          currencyId: currency.id,
          currencyWithOutId: {
            ...currency,
            translations: values(translations),
          },
        }),
      );
    },
    [dispatch],
  );

  const deleteCurrencyTranslation = useCallback(
    (currency: Currency, langCode: string) => {
      const translations = currency.translations!.filter(
        translation => translation.langCode !== langCode,
      );
      dispatch(
        actionsNT.currenciesUpdate({
          currencyId: currency.id,
          currencyWithOutId: {
            ...currency,
            translations: translations,
          },
        }),
      );
    },
    [dispatch],
  );

  return {
    [TranslatedEntities.SPORTS]: {
      updateTranslation: updateSportTranslation,
      deleteTranslation: deleteSportTranslation,
    },
    [TranslatedEntities.GROUPS]: {
      updateTranslation: updateGroupTranslation,
      deleteTranslation: deleteGroupTranslation,
    },
    [TranslatedEntities.TOURNAMENTS]: {
      updateTranslation: updateTournamentTranslation,
      deleteTranslation: deleteTournamentTranslation,
    },
    [TranslatedEntities.COMPETITORS]: {
      updateTranslation: updateCompetitorTranslation,
      deleteTranslation: deleteCompetitorTranslation,
    },
    [TranslatedEntities.COUNTRIES]: {
      updateTranslation: updateCountryTranslation,
      deleteTranslation: deleteCountryTranslation,
    },
    [TranslatedEntities.PLAYERS]: {
      updateTranslation: updatePlayerTranslation,
      deleteTranslation: deletePlayerTranslation,
    },
    [TranslatedEntities.CATEGORIES]: {
      updateTranslation: updateCategoryTranslation,
      deleteTranslation: deleteCategoryTranslation,
    },
    [TranslatedEntities.TYPES]: {
      updateTranslation: updateTypeTranslation,
      deleteTranslation: deleteTypeTranslation,
    },
    [TranslatedEntities.SHORT_NAMES]: {
      updateTranslation: updateTypeShortName,
      deleteTranslation: deleteTypeShortName,
    },
    [TranslatedEntities.MATCH_TYPES]: {
      updateTranslation: updateMatchTypeTranslation,
      deleteTranslation: deleteMatchTypeTranslation,
    },
    [TranslatedEntities.MATCH_PHASES]: {
      updateTranslation: updateMatchPhaseTranslation,
      deleteTranslation: deleteMatchPhaseTranslation,
    },
    [TranslatedEntities.COMMENTS]: {
      updateTranslation: updateCommentTranslation,
      deleteTranslation: deleteCommentTranslation,
    },
    [TranslatedEntities.CURRENCIES]: {
      updateTranslation: updateCurrencyTranslation,
      deleteTranslation: deleteCurrencyTranslation,
    },
  };
};
