import { FC, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { adaptersApi } from 'app/api';
import {
  CompetitorsTable,
  MatchPhasesTable,
  OutcomesTable,
  PlayersTable,
  SportsTable,
  TournamentsTable,
} from 'app/pages/Sources/components/EntitiesTables';
import { SOURCES_NAMES } from 'app/pages/Sources/components/SourcesPage/SourcesPage.constants';
import isEqual from 'lodash-es/isEqual';
import {
  AdapterCompetitor,
  AdapterSport,
  Sport,
  UserGroupEnum,
} from 'sportsbook-openapi-react';

import { FilterCacheKeys } from 'utils/filters';
import { getAdapterAsyncLoadOptions } from 'utils/select';
import { cache, uncache } from 'utils/sessionStorage';
import useBooleanState from 'hooks/useBooleanState';
import { useSearch } from 'hooks/useSearch';

import { AsyncSelect, Select, TextInput } from 'app/components/forms';
import { RoleGuards } from 'app/components/RoleGuards';
import { Button, Dialog, NavTabs } from 'app/components/ui';

import {
  adapterActions,
  selectAdapterSportsItems,
} from 'app/providers/AdaptersProvider';
import { selectSportsItems } from 'app/providers/EntitiesProvider/sports';

import { useUserStore } from 'store';
import { getIsOperator } from 'store/user';

import * as S from './MapEntities.styles';

interface Props {
  onClose: () => void;
  adapter: string;
}

interface Filters {
  mapped: boolean;
  adapterSport: AdapterSport;
  sport: Sport;
  searchQuery: string;
  adapterCompetitor: AdapterCompetitor;
}

export const MapEntities: FC<Props> = ({ onClose, adapter }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOperator = useUserStore(getIsOperator);

  const location = useLocation();

  const [isPreloadModalVisible, showPreloadModal, hidePreloadModal] =
    useBooleanState();

  const [mapped, setMapped] = useState(
    !!uncache<Filters>(FilterCacheKeys.SOURCE_ENTITIES)?.mapped,
  );
  const [adapterSport, setAdapterSport] = useState<Maybe<AdapterSport>>(
    uncache<Filters>(FilterCacheKeys.SOURCE_ENTITIES)?.adapterSport,
  );
  const [sport, setSport] = useState<Maybe<Sport>>(
    uncache<Filters>(FilterCacheKeys.SOURCE_ENTITIES)?.sport,
  );
  const { searchQuery, setSearchQueryDebounced } = useSearch(
    uncache<Filters>(FilterCacheKeys.SOURCE_ENTITIES)?.searchQuery,
  );

  const [adapterCompetitor, setAdapterCompetitor] = useState<
    Maybe<AdapterCompetitor>
  >(uncache<Filters>(FilterCacheKeys.SOURCE_ENTITIES)?.adapterCompetitor);

  useEffect(() => {
    if (!isOperator) {
      dispatch(adapterActions.parametersFetch({ adapter }));
    }
  }, [adapter, dispatch, isOperator]);

  useEffect(() => {
    const rehydratedFilters = uncache<Filters>(FilterCacheKeys.SOURCE_ENTITIES);
    if (
      !isEqual(rehydratedFilters, {
        adapterSport,
        sport,
        mapped,
        searchQuery,
        adapterCompetitor,
      })
    ) {
      cache(FilterCacheKeys.SOURCE_ENTITIES, {
        adapterSport,
        sport,
        adapterCompetitor,
        mapped,
        searchQuery,
      });
    }
  }, [adapterSport, sport, mapped, searchQuery, adapterCompetitor]);

  const adapterSportOptions = useSelector(selectAdapterSportsItems);
  const sportOptions = useSelector(selectSportsItems);
  const withEventsRef = useRef(false);

  const closePreloadModal = () => {
    withEventsRef.current = false;
    hidePreloadModal();
  };

  const tabs = useMemo(() => {
    if (!isOperator) {
      return [
        {
          label: t('sport type'),
          path: `/sources/${adapter}/sports${location.search}`,
        },
        {
          label: t('tournaments'),
          path: `/sources/${adapter}/tournaments${location.search}`,
        },
        {
          label: t('competitors'),
          path: `/sources/${adapter}/competitors${location.search}`,
        },
        {
          label: t('outcomes'),
          path: `/sources/${adapter}/types${location.search}`,
        },
        {
          label: t('match phases'),
          path: `/sources/${adapter}/match-phases${location.search}`,
        },
        {
          label: t('players'),
          path: `/sources/${adapter}/players${location.search}`,
        },
      ];
    } else {
      return [
        {
          label: t('sport type'),
          path: `/sources/${adapter}/sports${location.search}`,
        },
        {
          label: t('tournaments'),
          path: `/sources/${adapter}/tournaments${location.search}`,
        },
        {
          label: t('competitors'),
          path: `/sources/${adapter}/competitors${location.search}`,
        },
        {
          label: t('players'),
          path: `/sources/${adapter}/players${location.search}`,
        },
      ];
    }
  }, [isOperator, t, adapter, location.search]);

  const mapOptions = [
    { name: t('new'), id: false },
    { name: t('mapped'), id: true },
  ];

  useEffect(() => {
    dispatch(adapterActions.sportsFetch({ adapter }));
  }, [dispatch, adapter]);

  return (
    <S.Wrapper>
      <S.Heading>
        <S.BackButton onClick={onClose}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </S.BackButton>
        {`${t('dictionary')} ${SOURCES_NAMES[adapter]}`}
        <RoleGuards roles={[UserGroupEnum.ADMIN, UserGroupEnum.SUPERVISOR]}>
          <Button onClick={showPreloadModal} type="submit">
            {t('preload')}
          </Button>
        </RoleGuards>
      </S.Heading>
      <NavTabs tabs={tabs} />
      <S.ControlsWrapper>
        {t('status')}
        <Select
          options={mapOptions}
          value={mapOptions.find(option => option.id === mapped)}
          onChange={element => {
            if (element)
              setMapped((element as { id: boolean; name: string }).id!);
          }}
        />
        <TextInput
          placeholder={t('search')}
          defaultValue={searchQuery}
          onChange={event => {
            setSearchQueryDebounced(event.target.value);
          }}
          icon={<FontAwesomeIcon icon={faSearch} />}
        />
        <Routes>
          <Route
            path="match-phases"
            element={
              <Select
                placeholder={'Sport'}
                options={adapterSportOptions}
                value={adapterSport}
                onChange={setAdapterSport}
                isClearable
              />
            }
          />

          <Route
            path="types"
            element={
              <Select
                placeholder={'Sport'}
                options={adapterSportOptions}
                value={adapterSport}
                onChange={setAdapterSport}
                isClearable
              />
            }
          />

          <Route
            path="tournaments"
            element={
              <Select
                placeholder={'Sport'}
                options={adapterSportOptions}
                value={adapterSport}
                onChange={setAdapterSport}
                isClearable
              />
            }
          />

          <Route
            path="competitors"
            element={
              <>
                <Select
                  placeholder={'Sport'}
                  options={adapterSportOptions}
                  value={adapterSport}
                  onChange={setAdapterSport}
                  isClearable
                />
                <Select
                  placeholder={t('sport type')}
                  options={sportOptions}
                  value={sport}
                  onChange={setSport}
                  isClearable
                />
              </>
            }
          />

          <Route
            path="players"
            element={
              <AsyncSelect
                placeholder={t('competitor')}
                loadOptions={getAdapterAsyncLoadOptions({
                  adapter,
                  entity: 'competitors',
                })}
                value={adapterCompetitor}
                onChange={value =>
                  setAdapterCompetitor(value as AdapterCompetitor)
                }
                isClearable
                debounceTimeout={200}
              />
            }
          />
        </Routes>
      </S.ControlsWrapper>

      <Routes>
        <Route
          path="sports"
          element={
            <SportsTable
              mapped={mapped}
              adapter={adapter}
              searchQuery={searchQuery}
            />
          }
        />

        <Route
          path="tournaments"
          element={
            <TournamentsTable
              mapped={mapped}
              adapter={adapter}
              searchQuery={searchQuery}
              sport={adapterSport}
            />
          }
        />

        <Route
          path="competitors"
          element={
            <CompetitorsTable
              mapped={mapped}
              adapter={adapter}
              searchQuery={searchQuery}
              sport={adapterSport}
              sportsbookSport={sport}
            />
          }
        />

        <Route
          path="types"
          element={
            <RoleGuards
              roles={[UserGroupEnum.ADMIN, UserGroupEnum.SUPERVISOR]}
              withRedirect={true}
            >
              <OutcomesTable
                mapped={mapped}
                adapter={adapter}
                searchQuery={searchQuery}
                sport={adapterSport}
              />
            </RoleGuards>
          }
        />
        <Route
          path="match-phases"
          element={
            <RoleGuards
              roles={[UserGroupEnum.ADMIN, UserGroupEnum.SUPERVISOR]}
              withRedirect={true}
            >
              <MatchPhasesTable
                mapped={mapped}
                adapter={adapter}
                searchQuery={searchQuery}
                sport={adapterSport}
              />
            </RoleGuards>
          }
        />
        <Route
          path="players"
          element={
            <PlayersTable
              mapped={mapped}
              adapter={adapter}
              searchQuery={searchQuery}
              competitor={adapterCompetitor}
            />
          }
        />

        <Route element={<Navigate to="types" />} />
      </Routes>
      {isPreloadModalVisible && (
        <Dialog
          text=""
          onClose={closePreloadModal}
          onConfirm={() => {
            adaptersApi.requestPreload({
              adapter,
              withEvents: withEventsRef.current,
            });
          }}
        >
          <S.Label>
            <input
              type="checkbox"
              onChange={event => {
                withEventsRef.current = event.target.checked;
              }}
            />
            <>{t('including events')}</>
          </S.Label>
        </Dialog>
      )}
    </S.Wrapper>
  );
};
