import {
  MatchPhase,
  MatchPhaseList,
  MatchPhasesApiDeleteMatchPhaseRequest,
} from 'sportsbook-openapi-react';

import { EntitiesErrorType } from 'app/providers/EntitiesProvider/types';
import { entityReducersGenerator } from 'app/providers/EntitiesProvider/utils';

const reducer = {
  ...entityReducersGenerator<
    MatchPhaseList,
    MatchPhase,
    MatchPhasesApiDeleteMatchPhaseRequest,
    EntitiesErrorType
  >({
    entity: 'matchPhases',
  }),
};

export default reducer;
