import styled from 'styled-components';

import { ColumnWidth, StyledTableProps } from './Table.types';

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  position: relative;

  display: flex;
  flex-direction: column;
`;

export const StyledTable = styled.table<StyledTableProps>`
  font-family: ${p => p.theme.fonts.family};
  font-size: ${p => p.theme.fonts.typography['H100'].size};
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  ${p => (p.fixed ? `table-layout: fixed;` : ``)}
`;

export const StyledTHead = styled.thead``;

export const StyledTHeadTr = styled.tr``;

export interface TBodyTrProps {
  styles?: string;
  isSelected?: boolean;
}

export const TBodyTr = styled.tr<TBodyTrProps>`
  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${p => p.theme.colors.primary[30]};
    ${p => p.isSelected && `background-color: ${p.theme.colors.primary[50]}`}
  }
  ${p => p.styles}
  &:nth-of-type(odd) {
    background-color: ${p => p.theme.colors.primary[0]};
    &:hover {
      background-color: ${p => p.theme.colors.primary[30]};
      ${p => p.isSelected && `background-color: ${p.theme.colors.primary[50]}`}
    }
    ${p => p.isSelected && `background-color: ${p.theme.colors.primary[50]}`}
  }
  &:nth-of-type(4n - 1) {
    background-color: ${p => p.theme.colors.primary[20]};
    &:hover {
      background-color: ${p => p.theme.colors.primary[30]};
      ${p => p.isSelected && `background-color: ${p.theme.colors.primary[50]}`}
    }
    ${p => p.isSelected && `background-color: ${p.theme.colors.primary[50]}`}
  }
`;

export const StyledTBody = styled.tbody``;

type StyledThProps = {
  isTableInExpander?: boolean;
  size?: ColumnWidth;
};

export const StyledTh = styled.th<StyledThProps>`
  padding: 7px 8px;
  text-align: start;
  color: ${p => p.theme.colors.primary[200]};
  font-weight: ${p => p.theme.fonts.typography['H100'].weight};

  position: sticky;
  top: 0;
  z-index: ${p => (p.isTableInExpander ? 0 : 1)};
  background-color: ${p => p.theme.colors.primary[0]};
  box-shadow: 0px 1px ${p => p.theme.colors.primary[40]};

  ${p => {
    if (p.size === 'auto') {
      return 'width: auto';
    }
    if (p.size === 'fit-content') {
      return `width: 1%;
        white-space: nowrap;
      `;
    }
    if (p.size === 'min-content') {
      return `width: 1%;
        white-space: nowrap;
        padding: 0;
      `;
    }
    if (p.size) {
      return `width: ${p.size}%`;
    }
  }}
`;

export const Td = styled.td<StyledThProps>`
  padding: 12px 8px;
  color: ${p => p.theme.colors.primary[500]};
  font-weight: ${p => p.theme.fonts.typography['H100'].weight};

  &:disabled {
    color: ${p => p.theme.colors.primary[60]};
  }

  ${p => {
    if (p.size === 'fit-content') {
      return `white-space: nowrap;`;
    }
    if (p.size === 'min-content') {
      return `white-space: nowrap;
        padding: 0;
      `;
    }
  }}
`;

export const FilterWrapper = styled.div`
  margin-top: 7px;
`;

export const CollapseTd = styled.td`
  padding: 0;
`;

export const CollapseChildWrapper = styled.div`
  padding: 12px 8px;
`;

export const CollapseTr = styled.tr`
  ${CollapseChildWrapper}:nth-of-type(odd) {
    border-top: 1px solid ${p => p.theme.colors.primary[20]};
    border-bottom: 1px solid ${p => p.theme.colors.primary[20]};
  }
`;

export const EmptyPlaceholderWrapper = styled.div`
  flex: 1;
  padding: 1rem;
`;
