import styled from 'styled-components';

import { Span } from 'app/components/ui';

export const Column = styled.div`
  display: grid;
`;

export const StyledSpan = styled(Span)`
  cursor: pointer;
  padding: 8px 10px;
  margin: 0 -8px;
  &:hover {
    background-color: ${p => p.theme.colors.primary[20]};
  }
  &:active {
    background-color: ${p => p.theme.colors.primary[40]};
  }
`;

export const Divider = styled.div`
  margin: 8px 0;
  border-bottom: 1px solid ${p => p.theme.colors.primary[80]};
  height: 1px;
`;
