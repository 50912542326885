import { eventsApi } from 'app/api';

const api = {
  endpoint: eventsApi,
  methods: {
    fetchItem: 'getEventOdds',
    update: 'refreshEventOdds',
  },
};

export default api;

export const eventsOddsByCustomerApi = {
  endpoint: eventsApi,
  methods: {
    fetchItem: 'getCustomerEventOdds',
  },
};
