import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Size } from './DateRangePicker.types';

export interface DateRangePickerWrapperProps {
  size: Size;
}

export const DateRangePickerWrapper = styled.div<DateRangePickerWrapperProps>`
  box-sizing: border-box;
  display: flex;
  align-items: center;

  padding: 0 4px;
  width: fit-content;
  height: ${p => {
    switch (p.size) {
      case 's':
        return '32px';
      case 'm':
        return '40px';
    }
  }};

  border-width: 1px;
  border-style: solid;
  border-color: ${p => p.theme.colors.primary[60]};
  border-radius: 4px;

  &:hover {
    border-color: ${p => p.theme.colors.primary[500]};
  }

  &:focus-within {
    border-color: ${p => p.theme.colors.primary[500]};
    box-shadow: 0 0 1px 1px ${p => p.theme.colors.primary[500]};
  }
  color: ${p => p.theme.colors.primary[700]};
  font-size: ${p => p.theme.fonts.typography['H200'].size};

  transition:
    border-color 0.2s ease,
    box-shadow 0.2s ease;

  input {
    width: 140px;
    border: none;
    outline: none;
    &:focus {
      outline: none;
    }
    text-align: center;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  z-index: 1;
  padding: 4px;
`;
