import { currencyApi } from 'app/api';

const api = {
  endpoint: currencyApi,
  methods: {
    fetchItems: 'getCurrencies',
    fetchItem: 'getCurrency',
    create: 'createCurrency',
    update: 'updateCurrency',
    delete: 'deleteCurrency',
  },
};

export default api;
