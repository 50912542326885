import { darken } from 'polished';
import styled from 'styled-components';

import { Button } from 'app/components/ui';

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const TableWrapper = styled.div`
  overflow: auto;
  position: relative;
`;

export const StyledTable = styled.table`
  width: 100%;
`;

export const THead = styled.thead`
  th {
    background: white;
    position: sticky;
    top: 0px;
    z-index: 1;
  }
`;

export const TBody = styled.tbody``;

export const Tr = styled.tr`
  border-bottom: 1px solid ${p => p.theme.colors.primary[40]};
`;

interface StyledColumnsProps {
  weight?: number;
  textAlign?: string;
}

export const Td = styled.td<StyledColumnsProps>`
  padding: 0 20px;
  height: 56px;
  font-weight: normal;
  width: ${p => p.weight}%;
  font-size: 14px;
  text-align: ${p => (p.textAlign ? p.textAlign : 'left')};
`;

export const SelectTd = styled(Td)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  align-items: center;
`;

export const Th = styled.th<StyledColumnsProps>`
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: normal;
  width: ${p => p.weight}%;
  font-size: 14px;
  line-height: 130%;
  color: #808080;
`;

export const AddButton = styled(Button)`
  transition: all 0.2s ease;
  background-color: transparent;
  border: 1px solid #808080;
  border-radius: 4px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  &:hover {
    background-color: ${p => darken(0.1, p.theme.colors.primary[40])};
  }
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
`;

export const ButtonsBlock = styled.div`
  margin-left: auto;
  width: fit-content;
`;
