import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Header = styled.div`
  padding: 0 20px;
  height: 56px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
`;

export const StyledLink = styled(Link)`
  white-space: nowrap;
`;

export const FiltersBlock = styled.div`
  padding: 10px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto 150px auto auto auto auto 1fr auto;
  grid-column-gap: 10px;
  justify-items: flex-end;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  z-index: 2;
`;

export const TableLoading = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50);
  bottom: 50px;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 9;
`;
